import { defaultStep, getNewStepConfig } from './StepBarUtil';
import { TRANSACTION_RECORD_TYPE } from '../containers/record/records/TransactionList';
import {
  createTransaction,
  updateTransaction,
} from '../services/TransactionRecordsAPIHelper';
import { loading } from './LoadingUtil';
import {
  createAction,
  getFileNameFromUrl,
  saveToSessionStorage,
  removeFromSessionStorage,
  getObjectFromSessionStorage,
} from '../utils';

export const sessionDataKey = {
  objectKey: 'createTransaction',
  stepEndKey: 'createTransactionStepEnd',
  origionalData: 'createTransactionOriginalData',
};

export const CreateTransactionError = {
  customer: {
    name: 'customer',
    message: 'Please provide a customer.',
  },
  transactionDate: {
    name: 'transactionDate',
    message: 'Please provide a transaction date.',
  },
  store: {
    name: 'stote', //optional when type is POS
    message: 'Please provide a store.',
  },
  posInvoiceId: {
    name: 'posInvoiceID',
    message: 'Please provide a pos invoice id.',
  },
  invoiceId: {
    name: 'invoiceID',
    message: 'Please provide a invoicd id.',
  },
  offlineEventType: {
    name: 'offlineEventType',
    message: 'Please provide a offline event type.',
  },
  staffName: {
    name: 'staffName',
    message: 'Please provide a staff name.',
  },
  totalValue: {
    name: 'totalValue',
    message: 'Please provide a total value.',
  },
  totalValueFormat: {
    name: 'totalValueFormat',
    messages: 'Please enter total value in digits.',
  },
  purchasedItmeName: {
    name: 'purchasedItemName',
    message: 'Please provide a product name.',
  },
  purchasedItemQuantity: {
    name: 'purchasedItemQuanitity',
    message: 'Please provide a quantity.',
  },
  purchasedItemQuantityFormat: {
    name: 'purchasedItemQuantityFormat',
    message: 'Please enter quantity in digits.',
  },
  purchasedItemValue: {
    name: 'purchasedItemValue',
    message: 'Please provide a value.',
  },
  purchasedItemValueFormat: {
    name: 'purchasedItemValueFormat',
    message: 'Please enter value in digits.',
  },
  receiptImage: {
    name: 'receiptImage',
    message: 'Please provide a receipt image.',
  },
  creditCardSlipImage: {
    name: 'creditCardSlipImage',
    message: 'Please provide a credit card slip image.',
  },
};

function getTransactionInit() {
  return {
    pk: null,
    id: null,
    ssoUid: null,
    name: null,
    transactionType: null,
    transactionDisplayType: null,
    storeName: null,
    creationDate: null,
    transactionDate: null,
    transactionDetailDisplayDate: null,
    transactionDetailCreationDate: null,
    totalValue: null,
    onlineEventType: null,
    offlineEventType: null,
    displayOfflineEventType: null,
    staffName: null,
    shippingFee: null,
    otherCharge: null,
    remarks: null,
    posInvoiceId: null,
    invoiceId: null,
    receiptImage: null,
    creditCardSlipImage: null,
    purchasedItems: [
      {
        productName: null,
        sku: null,
        category: null,
        brand: null,
        quantity: null,
        value: null,
      },
    ],
    customer: {},
    store: {},
  };
}

function getInitState() {
  const stepNameList = ['Type', 'Content', 'Purchase Detail'];
  return {
    transaction: getTransactionInit(),
    errorFields: [],
    stepConfig: defaultStep(stepNameList),
    currentStep: 0,
    selectedType: null,
    transactionStatusChanged: false,
  };
}

function checkStepTwo(data, isBack) {
  const selectedCustomer = data.customer;
  const transactionDisplayType = data.transactionDisplayType;
  let errorFields = [];
  if (!selectedCustomer?.pk) {
    errorFields.push(CreateTransactionError.customer.name);
  }
  if (!data.transactionDate) {
    errorFields.push(CreateTransactionError.transactionDate.name);
  }
  if (!data.store?.storePK) {
    errorFields.push(CreateTransactionError.store.name);
  }
  if (
    transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS &&
    !data.posInvoiceId
  ) {
    errorFields.push(CreateTransactionError.posInvoiceId.name);
  }
  if (
    transactionDisplayType ===
      TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM &&
    !data.invoiceId
  ) {
    errorFields.push(CreateTransactionError.invoiceId.name);
  }
  if (
    transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS &&
    !data.offlineEventType
  ) {
    errorFields.push(CreateTransactionError.offlineEventType.name);
  }
  if (!data.staffName) {
    errorFields.push(CreateTransactionError.staffName.name);
  }
  if (!data.totalValue || data.totalValue === '') {
    errorFields.push(CreateTransactionError.totalValue.name);
  }
  if (data.totalValue && !parseInt(data.totalValue)) {
    errorFields.push(CreateTransactionError.totalValueFormat.name);
  }
  return {
    invalid: isBack ? false : errorFields.length > 0,
    errorFields: isBack ? [] : errorFields,
    data,
  };
}

function checkStepThree(data, isBack) {
  const transactionDisplayType = data.transactionDisplayType;
  let errorFields = [];
  if (transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
    const purchasedItems = data.purchasedItems;
    purchasedItems.forEach((item, index) => {
      if (!item.productName) {
        errorFields.push(
          `${CreateTransactionError.purchasedItmeName.name}-${index}`,
        );
      }
      if (!item.quantity) {
        errorFields.push(
          `${CreateTransactionError.purchasedItemQuantity.name}-${index}`,
        );
      }
      if (item.quantity && !parseInt(item.quantity)) {
        errorFields.push(
          `${CreateTransactionError.purchasedItemQuantityFormat.name}-${index}`,
        );
      }
      if (!item.value) {
        errorFields.push(
          `${CreateTransactionError.purchasedItemValue.name}-${index}`,
        );
      }
      if (item.value && !parseInt(item.value)) {
        errorFields.push(
          `${CreateTransactionError.purchasedItemValueFormat.name}-${index}`,
        );
      }
    });
  } else if (
    transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
  ) {
    const receiptImage = data.receiptImage;
    if (!receiptImage) {
      errorFields.push(CreateTransactionError.receiptImage.name);
    }
    if (!data.creditCardSlipImage) {
      errorFields.push(CreateTransactionError.creditCardSlipImage.name);
    }
  }
  return {
    invalid: isBack ? false : errorFields.length > 0,
    errorFields: isBack ? [] : errorFields,
    data,
  };
}

function parsePhotoUrlForAPI(image) {
  let imageUrl = image;
  if (image && image.value) {
    imageUrl = image.value;
  }
  return getFileNameFromUrl(imageUrl);
}

function parseCreateTransactionInputBody(data, isUpdate = false) {
  const receiptImage = data.receiptImage;
  const creditCardSlipImage = data.creditCardSlipImage;
  const transactionType = data.transactionDisplayType;
  let inputBody = {
    date: data.transactionDate,
    offlineEventType: data.offlineEventType,
    staffName: data.staffName,
    customer: data.customer.pk,
    totalValue: data.totalValue,
    store: data.store.storePK,
    remarks: data.remarks,
  };
  if (transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
    inputBody = {
      ...inputBody,
      posInvoiceId: data.posInvoiceId,
    };
  } else if (
    transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
  ) {
    inputBody = {
      ...inputBody,
      invoiceId: data.invoiceId,

      receiptImage: parsePhotoUrlForAPI(receiptImage),
      creditCardSlipImage: parsePhotoUrlForAPI(creditCardSlipImage),
    };
  }
  if (!isUpdate) {
    inputBody = {
      ...inputBody,
      transactionType: data.transactionType,
    };
  }
  if (isUpdate) {
    inputBody = {
      ...inputBody,
      id: data.pk,
    };
  }
  console.log('@@288: ', data, inputBody);
  return inputBody;
}

function parsePurchasedItemInputBody(data, transactionPK) {
  const inputBody = {
    // transaction: transactionPK,
    name: data.productName,
    sku: data.sku,
    category: data.category,
    brand: data.brand,
    quantity: data.quantity,
    value: data.value,
    id: data.pk,
  };
  return inputBody;
}

export default {
  namespace: 'createTransaction',
  state: getInitState(),

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    updateTransactionState(state, { payload }) {
      const transaction = { ...state.transaction, ...payload };
      console.log('@@433: ', transaction);
      saveToSessionStorage(sessionDataKey.objectKey, transaction);
      return {
        ...state,
        transaction: transaction,
      };
    },
    updateTransactionType(state, { payload }) {
      const { type } = payload;
      let originalType = null;
      if (type === TRANSACTION_RECORD_TYPE.TYPE_ONLINE) {
        originalType = 'ONLINE';
      } else if (type === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
        originalType = 'OFFLINE_POS';
      } else if (type === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM) {
        originalType = 'OFFLINE_REWARD_CLAIM';
      }
      const oldType = state.transaction.transactionDisplayType;
      if (type === oldType) {
        return {
          ...state,
        };
      }
      let transaction = getObjectFromSessionStorage('transactionDisplayType');
      if (!transaction) {
        transaction = getInitState().transaction;
      }
      transaction.transactionDisplayType = type;
      transaction.transactionType = originalType;
      saveToSessionStorage(sessionDataKey.objectKey, transaction);
      return {
        ...state,
        transaction: transaction,
      };
    },
    loadTransactionFromCookie(state, { payload }) {
      const transaction = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!transaction) {
        return {
          ...state,
        };
      }
      saveToSessionStorage(sessionDataKey.origionalData, transaction);
      saveToSessionStorage(sessionDataKey.objectKey, transaction);
      return {
        ...state,
        transaction: transaction,
      };
    },
    saveOrRemoveTransactionFromCookie(state, { payload }) {
      if (!payload) {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },
    addPurchasedItem(state, { payload }) {
      return {
        ...state,
        transaction: {
          ...state.transaction,
          purchasedItems: [...state.transaction.purchasedItems, { ...payload }],
        },
      };
    },
    updatePruchasedItems(state, { payload }) {
      const { index, data } = payload;
      return {
        ...state,
        transaction: {
          ...state.transaction,
          purchasedItems: [
            ...state.transaction.purchasedItems.slice(0, index),
            {
              ...state.transaction.purchasedItems[index],
              ...data,
            },
            ...state.transaction.purchasedItems.slice(index + 1),
          ],
        },
      };
    },
    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      let step = payload.step;
      let result = { invalid: false, errorFields: [], data: {} };
      if (step === 1) {
        result = checkStepTwo(state.transaction, isBack);
      }
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        result.invalid,
        isBack,
      );
      if (!result.invalid) {
        step = isBack ? step - 1 : step + 1;
      }
      return {
        ...state,
        stepConfig,
        currentStep: step,
        errorFields: result.errorFields,
      };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitState() };
    },
  },

  effects: {
    *setFieldToSession({ payload }, { select }) {
      const oldTransaction = yield select(
        (state) => state.createTransaction.transaction,
      );
      const transaction = { ...oldTransaction, ...payload };

      saveToSessionStorage(sessionDataKey.objectKey, transaction);
    },

    checkStepThree: [
      function* ({ payload }, { put, select }) {
        const isBack = payload.isBack;
        const afterActions = payload.afterActions || (() => {});
        const transaction = yield select(
          (state) => state.createTransaction.transaction,
        );
        const stepConfig = yield select(
          (state) => state.createTransaction.stepConfig,
        );
        const result = checkStepThree(transaction, isBack);
        const newStepConfig = getNewStepConfig(
          2,
          stepConfig,
          result.invalid,
          isBack,
        );
        yield put(
          createAction('updateState')({
            stepConfig: newStepConfig,
            errorFields: result.errorFields,
          }),
        );
        if (!result.invalid) {
          yield afterActions();
        }
      },
      { type: 'takeLatest' },
    ],
    createTransaction: [
      function* ({ payload }, { call, select, put }) {
        const transaction = yield select(
          (state) => state.createTransaction.transaction,
        );

        const data = parseCreateTransactionInputBody(transaction);
        if (
          transaction.transactionDisplayType ===
          TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
        ) {
          const purchasedItems = yield select(
            (state) => state.createTransaction.transaction.purchasedItems,
          );

          data.purchasedItemsInput = purchasedItems.map((item) => {
            return parsePurchasedItemInputBody(item);
          });
        }

        const serviceArgs = [createTransaction, data];

        saveToSessionStorage(sessionDataKey.stepEndKey, true);

        function* onSuccess(data) {
          const transactionData = data.createTransaction.node;
          yield put(
            createAction('updateTransactionState')({
              pk: transactionData?.pk,
              id: transactionData?.id,
            }),
          );

          yield put({
            type: 'updateState',
            payload: { transactionStatusChanged: true },
          });
          removeFromSessionStorage(sessionDataKey.objectKey);
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    updateTransaction: [
      function* ({ payload }, { put, select }) {
        const transaction = yield select(
          (state) => state.createTransaction.transaction,
        );
        let parsedInputBody = parseCreateTransactionInputBody(
          transaction,
          true,
        );

        if (
          transaction.transactionDisplayType ===
          TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
        ) {
          const purchasedItems = yield select(
            (state) => state.createTransaction.transaction.purchasedItems,
          );

          parsedInputBody.purchasedItemsInput = purchasedItems.map((item) => {
            return parsePurchasedItemInputBody(item, transaction.pk);
          });
        }

        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        const serviceArgs = [updateTransaction, parsedInputBody];
        function* onSuccess() {
          removeFromSessionStorage(sessionDataKey.objectKey);
          yield put({
            type: 'updateState',
            payload: { transactionStatusChanged: true },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
