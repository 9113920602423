import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getBrands = (
  afterCursor = '',
  rank = true,
  searchKey = '',
  isAll = false,
  moreSearch
) => {
  let searchString = isAll ? 'first: 100' : 'first: 20';
  let orderBy = '-pk';
  if (moreSearch?.sort) {
    orderBy = moreSearch?.sort;
  }
  if (afterCursor) {
    searchString += `, after: "${afterCursor}"`;
  }
  searchString += `, orderBy: "${orderBy}"`;
  if (searchKey) {
    searchString += `, name: "${searchKey}"`;
  }

  const query = `{
    brands(${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          icon
          description
          translations {
            edges {
              node {
                language
                name
                description
                pk
              }
            }
          }
          stores {
            edges {
              node {
                id
                pk
                name
                address
                description
                translations {
                  edges {
                    node {
                      language
                      name
                      address
                      description
                      pk
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getBrand = (brandID) => {
  const query = `
  {
    brand(id: "${brandID}") {
      id
      pk
      name
      description
      icon
      translations {
        edges {
          node {
            language
            name
            description
            pk
          }
        }
      }
      stores {
        edges {
          node {
            pk
            id
            name
            address
            description
            translations {
              edges {
                node {
                  language
                  name
                  address
                  description
                  pk
                }
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteBrand = (inputBody) => {
  const query = `
  mutation DeleteBrands($input: DeleteBrandsInput!) {
    deleteBrands(input: $input) {
      success
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createBrand = (inputBody) => {
  const query = `
  mutation CreateBrand($input: CreateBrandInput!) {
    createBrand(input: $input) {
      node {
        id
        pk
        name
        icon
        description
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateBrand = (inputBody) => {
  const query = `
  mutation UpdateBrand($input: UpdateBrandInput!) {
    updateBrand(input: $input) {
      node {
        id
        pk
        name
        icon
        description
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
