export const NavBarNames = {
  customer: 'Customer',
  segments: 'Segment',
  customerGroup: 'Customer group',
  levels: 'Levels',
  records: 'Records',
  campaigns: 'Campaigns',
  campaignCategory: 'Campaign categories',
  message: 'Messages',
  earnRules: 'Earning rules',
  coupons: 'Coupons',
  rewards: 'Rewards',
  brands: 'Brands',
  stores: 'Stores',
  storeCodes: 'Store code List',
  storeCategory: 'Store Categories',
  translations: 'Translations',
  transactions: 'Transactions',
  pointRecords: 'Point records',
  couponRecords: 'Coupon records',
  others: 'Webview content',
  admin: 'Administrators',
  adminGroup: 'Administrators group',
  dashboard: 'Dashboard',
  banners: 'Banners',
  productCategory: 'Product categories',
  featuredCampaign: 'Featured campaigns',
  featuredProduct: 'Featured products',
  langauge: 'Languages',
};
