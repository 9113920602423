export const EarningRuleErrorHandleField = {
  generalName: {
    name: 'generalName',
    message: 'Please provide name',
  },
  birthday: {
    name: 'birthday',
    message: 'Please provide birthday period.',
  },
  birthStart: {
    name: 'birthday',
    message: 'Please provide birthday period.',
  },
  birthEnd: {
    name: 'birthday',
    message: 'Please provide birthday period.',
  },
  minSpending: {
    name: 'minSpending',
    message: 'Please provide minSpending value.',
  },
  maxSpending: {
    name: 'maxSpending',
    message: 'The minimum value must be greater than the maximum value',
  },
  shortDescription: {
    name: 'shortDescription',
    message: 'Please provide short description.',
  },
  customerGroup: {
    name: 'customerGroup',
    message: 'Please provide customer group.',
  },
  coupons: {
    name: 'coupons',
    message: 'Please select coupon.',
  },
  rewardType: {
    name: 'rewardType',
    message: 'Please select reward type.',
  },
  quantity: {
    name: 'quantity',
    message: 'Please provide required/valid quantity.',
  },
  rewardTypeX: {
    name: 'rewardTypeX',
    message: 'Please provide required points.',
  },
  latitude: {
    name: 'latitude',
    message: 'Please provide required latitude.',
  },
  latitudeLimit: {
    name: 'latitudeLimit',
    message: 'Please make sure the length before dot shoud not longer than 3.',
  },
  longitude: {
    name: 'longitude',
    message: 'Please provide required longitude.',
  },
  longitudeLimit: {
    name: 'longitudeLimit',
    message: 'Please make sure the length before dot shoud not longer than 3.',
  },
  radius: {
    name: 'radius',
    message: 'Please provide required radius.',
  },
  qrCodes: {
    name: 'qrCodes',
    message: 'Please provide required qrcodes.',
  },
  fillingForm: {
    name: 'fillingForm',
    message: 'Please provide required filling form',
  },
  definitionType: {
    name: 'definitionType',
    message: 'Please provide required definition type',
  },
};
