import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormContext, Controller } from 'react-hook-form';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createAction, useCompare } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import {
  CheckStatus,
  SavedStatus,
  LanguageConfig,
  DELETE_RELATED_SECTIONS,
} from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';
import {
  getPreviewLayoutImages,
  defaultPreviewImage,
} from '../../../components/store/StorePreviewConfig';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import CreateStoreStepOneContentSection from '../../../components/store/CreateStoreStepOneContentSection';

function CreateStoreStepOne({
  store,
  checked,
  saved,
  languages,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { getValues, trigger, formState } = useFormContext();
  const { errors } = formState;

  const inputWithImageConfig = getPreviewLayoutImages();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [activeSection, setActiveSection] = useState();
  const [showAlertPrompt, setShowAlertPrompt] = useState({
    title: 'brand',
    show: false,
    pathname: 'brand/create',
  });

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
  }, [dispatch]);

  useEffect(() => {
    if (checked === CheckStatus.checkedWithSuccess) {
      dispatch(createAction('storeModel/createOrUpdate')({}));
    }
  }, [dispatch, checked]);

  useEffect(() => {
    if (
      saved === SavedStatus.savedWithSuccess &&
      history.location.pathname.includes('edit')
    ) {
      history.push('/stores');
    }
  }, [history, saved]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'storeModel/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const id = params.id;
  useEffect(() => {
    if (id) {
      dispatch(createAction('storeModel/getOneStore')({ id }));
    } else {
      dispatch({ type: 'storeModel/loadDataFromCookie' });
    }

    dispatch(createAction('storeModel/getDistrictList')({}));
    dispatch(createAction('storeCategoryList/getAllStoreSubcategoryList')({}));
  }, [dispatch, id]);


  const getLanguageTab = (language) => {
    return [
      [
        <CreateStoreStepOneContentSection language={language} onFocus={setActiveSection} setShowAlertPrompt={setShowAlertPrompt}/>
      ],
    ];
  };

  const languageTabConfig = {
    enContainer: getLanguageTab(LanguageConfig.english),
    hantContainer: getLanguageTab(LanguageConfig.traditionalChinese),
    hansContainer: getLanguageTab(LanguageConfig.simplifiedChinese),
  };

  const getTabs = (language, setActiveSection) => {
    const tabs = [
      <CreateStoreStepOneContentSection language={language} onFocus={setActiveSection} setShowAlertPrompt={setShowAlertPrompt}/>
    ];
    return tabs;
  };

  return(
    <>
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: getTabs(item.code, setActiveSection),
            key: item.code,
            title: item.sourceName,
          })),
        }}
        inputWithImageConfig={inputWithImageConfig}
        defaultImage={defaultPreviewImage()}
        activeSection={activeSection}
      />
      <SaveAndBackButtons
        saveTempText={params.id ? 'Delete' : null}
        saveText={params.id ? 'Update' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={async () => {


          let isValid = await trigger(
            [
              'storeTranslations.en.name',
              'displayPriority',
              'coverPhoto',
              'storeTranslations.en.address',
              'emailAddress',
              'storeTranslations.en.description',
              'subcategories',
              'selectedSubcategories',
              'latitude',
              'longitude',
              'staffCode',
            ],
            { shouldFocus: true },
          );
          console.log("@83", isValid, getValues(), isValid)
          if (isValid) {
            dispatch(
              createAction('storeModel/createOrUpdate')({
                data: getValues(),
                afterActions: () => history.push({ pathname: '/stores' }),
              }),
            );
          }
          // dispatch({ type: 'storeModel/checkValsValid' });
        }}
        saveTempAction={() => setShowDeletePrompt(true)}
        tempRequires={PermissionCodes.deleteStore}
      />
      <DeletePrompt
        message={{
          title: 'Delete this Store',
          content: 'Did you confirm to delete this Store?',
        }}
        title={'Store'}
        data={store}
        relatedSections={DELETE_RELATED_SECTIONS.STORE}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: `storeModel/updateState`,
            payload: { checkedList: [store] },
          });
          dispatch(
            createAction('storeModel/delete')({
              afterAction: () => {
                history.push('/stores/');
              },
            }),
          );
        }}
      />
      <BasePrompt
        show={showAlertPrompt.show}
        closeAction={() =>
          setShowAlertPrompt({ ...showAlertPrompt, show: false })
        }
        rightButton={{
          text: `Go to create ${showAlertPrompt.title}`,
          action: () => {
            history.push({
              pathname: showAlertPrompt.pathname,
              state: {
                from: history.location,
                title: 'Continue to create store?',
                content: 'You can continue to create the store.',
              },
            });
          },
        }}
        title={`Go to create a ${showAlertPrompt.title}?`}
        description={`You will leave the store creation process. After you create a new ${showAlertPrompt.title}, you can back to this page.`}
      />
    </>
  )
}

const mapPropsToState = (state) => ({
  store: state.storeModel.oneStore || {},
  checked: state.storeModel.checked,
  errorFields: state.storeModel.errorFields,
  saved: state.storeModel.saved,
  languages: state.language.allList,
  // districtList: state.storeModel.districtList,
  // subCategoryList: state.storeCategoryList.subCategoryList,
  // formChanged: state.storeModel.formChanged,
})

export default connect(mapPropsToState)(CreateStoreStepOne);
