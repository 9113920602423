import React from 'react';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import { Image } from 'react-bootstrap';
import LineImage from '../../assets/images/line.png';
import DotImage from '../../assets/images/dot.png';

function RecordTimelineCard({ title, data }) {
  return (
    <>
      <label className="create-section-title">points and level</label>
      {data.length <= 0 ? (
        <label className="custom-record-normal-font custom-timeline-time-color list-new-line">
          -
        </label>
      ) : (
        <div className="custom-record-table">
          {data.map((item, index) => {
            return (
              <div className="custom-timeline-area">
                <div className="custom-timeline-icon ">
                  <Image src={DotImage} />
                  {index === data.length - 1 ? null : (
                    <Image src={LineImage} className="custom-timeline-line" />
                  )}
                </div>
                <div className="d-flex flex-column">
                  <label className="custom-record-blod-font custom-timeline-font-color">
                    {item.text}
                  </label>
                  <label className="custom-record-normal-font custom-timeline-time-color">
                    {item.updateTime}
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default RecordTimelineCard;
