import React, { useState, useEffect, useRef } from 'react';
import './Header.scss';
import {
  Image,
  OverlayTrigger,
  Popover,
  Button,
  Overlay,
  Collapse,
} from 'react-bootstrap';
import settingIcon from '../../assets/images/setting_icon.svg';
import usePhoto from '../../assets/images/userPhoto.jpg';
import downloadIcon from '../../assets/images/download.svg';
import closeIcon from '../../assets/images/close.svg';
import taskUnio from '../../assets/images/taskUnio.svg';
import { withRouter, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CustomStatusTagFromText } from '../../components/base/CustomStatusTag';
import { DownloadTag } from '../../config/CustomEnums';
import { createAction, checkHasPermission } from '../../utils';
import { CustomTitleLabel } from '../../components/earning/CustomBaseComponments';
import LogoutBackButton from '../../assets/images/logout_dropdown.svg';
import AuthButton from '../../components/base/AuthButton';
import { PermissionCodes } from '../../config/PermissionCodes';
import { useHistory } from 'react-router-dom';
function Header(props) {
  // const [showTasks, setShowTasks] = useState(false);
  const [isOpenLogoutDropdown, setIsOpenLogoutDropdown] = useState(false);
  const dispatch = useDispatch();
  const target = useRef(null);
  const history = useHistory();
  const { user, systemTasks, downloadingCount, showTasks } = useSelector((state) => ({
    user: state.users,
    systemTasks: state.downloadAndImport.systemTasks,
    downloadingCount: state.downloadAndImport.downloadingCount,
    showTasks: state.downloadAndImport.showTasks,
  }));

  const usernameClicked = () => {
    setIsOpenLogoutDropdown(!isOpenLogoutDropdown);
  };

  const logout = () => {
    history.push({ pathname: '/login', state: { isLogout: true } });
  };

  const hasSystemTaskInfoPermission =
    checkHasPermission(user, PermissionCodes.addExportjob) ||
    checkHasPermission(user, PermissionCodes.addImportjob);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('This will run every 2 mins!');
      if (checkHasPermission(user, hasSystemTaskInfoPermission)) {
        dispatch(createAction('downloadAndImport/getSystemTasks')({}));
      }
    }, 1000 * 60 * 2);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getCancelOrDownload = (item) => {
    const showResultDownload = item?.errors?.numberOfFailed;
    if (showResultDownload && item.fileUrl) {
      return (
        <a
          href={item.resultFileUrl}
          className="download-cancel-button download-button"
        >
          Result-Download
        </a>
      );
    }
    switch (item.displayStatus) {
      case DownloadTag.pendingForExport:
      case DownloadTag.pendingForGenerate:
      case DownloadTag.pendingForImport:
        return (
          <button
            type="button"
            className="btn-link reset-button download-cancel-button"
            onClick={() => {
              dispatch(
                createAction('downloadAndImport/cancelTask')({ pk: item.pk, type: item.type, }),
              );
            }}
          >
            Cancel
          </button>
        );
      case DownloadTag.completed:
        return (
          <a
            href={item.fileUrl}
            className="download-cancel-button download-button"
          >
            Download
          </a>
          // <button
          //   type="button"
          //   className="btn-link reset-button download-cancel-button download-button"
          //   onClick={() => {
          //     // dispatch(
          //     //   createAction('downloadAndImport/downloadFile')({ id: item.id }),
          //     // );
          //     window.location = item.fileUrl;
          //   }}
          // >
          //   Download
          // </button>
        );
      default:
        return null;
    }
  };

  const generateTypes = [
    'GENERATE_CODE_COUPON',
    'GENERATE_GENERIC_COUPON',
    'GENERATE_QR_CODE_COUPON',
    'GENERATE_IMAGE_COUPON',
  ];

  const resultTag = (item, isSuccess) => {
    const errors = item.errors;
    const resource = item.resource;
    const type = item.type;
    const status = item.status;
    const successCount = errors?.numberOfImported;
    const failedCount = errors?.numberOfFailed;
    if (!successCount && !failedCount) {
      return null;
    }
    if (type === 'EXPORT_JOB') {
      return null;
    }
    const count = isSuccess ? successCount : failedCount;
    const extraStyle = isSuccess ? 'result-success-item' : 'result-failed-item';
    let title = '';
    if (status === 'COMPLETED') {
      title = 'Imported';
    }
    if (generateTypes.includes(resource) && status === 'COMPLETED') {
      title = 'Generated';
    }
    if (!isSuccess) {
      title = 'Failed';
    }
    if (type === 'CANCELLED') {
      title = 'Cancelled';
    }
    if (!title) {
      return null;
    }
    return (
      <label className={`result-common ${extraStyle}`}>
        •{title}:{count || 0}
      </label>
    );
  };

  const downloadIconClassName = (item) => {
    if (item?.couponLink) {
      return 'download-icon-cursor';
    }
  };

  const downloadIconOnClick = (item) => {
    if (item?.couponLink) {
      return () => {
        history.push(item.couponLink);
      };
    }
  };

  const downloadLine = (item, showBorder) => {
    const successResult = resultTag(item, true);
    const errorResult = resultTag(item);
    const showOneLine = successResult || errorResult;
    return (
      <>
        <div className="system-task-row">
          <div>
            <Image
              src={taskUnio}
              className={`download-icon ${downloadIconClassName(item)}`}
              onClick={downloadIconOnClick(item)}
            />
            <div>
              <label
                className={`download-title ${
                  showOneLine
                    ? 'download-title-line-1'
                    : 'download-title-line-2'
                }`}
              >
                {item.name || '-'}
              </label>
              <div className="system-task-result-container">
                {successResult}
                {errorResult}
              </div>
            </div>
            <div
              className={`d-flex flex-column ${
                item.status !== DownloadTag.cancelled
                  ? 'system-task-row-status-downloading'
                  : 'system-task-row-status'
              } `}
            >
              {CustomStatusTagFromText(
                item.displayStatus,
                'system-task-row-status-label',
              )}
              {getCancelOrDownload(item)}
            </div>
          </div>
        </div>
        {showBorder ? <div className="system-task-row-border" /> : null}
      </>
    );
  };

  return (
    <div className="header-container">
      <Image src={settingIcon} className="setting-icon" />
      <label className="header-text">Setting</label>
      <div
        onMouseEnter={usernameClicked}
        onMouseLeave={usernameClicked}
        className="header-user-area"
      >
        <div className="header-user-area-photo-area">
          <Image src={usePhoto} className="uesr-photo" />
          <Collapse in={isOpenLogoutDropdown}>
            <div className="header-user-area-logout-dropdown" onClick={logout}>
              <div className="header-user-area-logout-dropdown-container">
                <Image
                  className="header-user-area-logout-image"
                  src={LogoutBackButton}
                />
                <label className="header-user-area-logout-text">LOGOUT</label>
              </div>
            </div>
          </Collapse>
        </div>
        <label className="header-text">{user.payload?.username}</label>
      </div>
      {hasSystemTaskInfoPermission ? (
        <button
          className="system-task-area"
          ref={target}
          onClick={() => {
            dispatch(createAction('downloadAndImport/showTasks')({}));
            dispatch(createAction('downloadAndImport/getSystemTasks')({}));
          }}
          // requires={PermissionCodes.addExportjob}
        >
          <Image src={downloadIcon} className="download-icon" />
          <label className="header-text">System task</label>
          {downloadingCount > 0 ? (
            <label className="badge-icon">{downloadingCount}</label>
          ) : null}
        </button>
      ) : null}

      {/* <AuthButton
        customClass="btn-link reset-button system-task-area"
        ref={target}
        action={() => {
          setShowTasks(!showTasks);
          dispatch(createAction('downloadAndImport/getSystemTasks')({}));
        }}
        requires={PermissionCodes.addExportjob}
      >
        <Image src={downloadIcon} className="download-icon" />
        <label className="header-text">System task</label>
        {downloadingCount > 0 ? (
          <label className="badge-icon">{downloadingCount}</label>
        ) : null}
      </AuthButton> */}
      <Overlay target={target.current} show={showTasks} placement="bottom"
        rootClose
        onHide={() => {
          dispatch(createAction('downloadAndImport/hideTasks')({}));
        }}
      >
        {(props) => (
          // <Tooltip id="overlay-example" {...props} arrowProps={null}>
          <div
            {...props}
            className="overlay-trigger"
            style={{
              backgroundColor: '#ffffff',
              padding: '2px 10px',
              color: 'white',
              borderRadius: 3,
              ...props.style,
              zIndex: 3,
            }}
          >
            <Popover
              id={`popover-positioned-bottom`}
              style={{}}
              arrowProps={null}
            >
              <Popover.Title>
                <div className="system-task-header">
                  <label className="system-task">System task</label>

                  <button
                    className="custom-image-button margin-left-auto"
                    onClick={() => {
                      dispatch(createAction('downloadAndImport/hideTasks')({}));
                    }}
                  >
                    <Image src={closeIcon} />
                  </button>
                </div>
              </Popover.Title>
              <Popover.Content>
                {systemTasks.length > 0 ? (
                  
                  <div className="system-task-content">
                    {systemTasks.map((item, index) => {
                      return downloadLine(item, index < systemTasks.length - 1);
                    })}
                  </div>
                ) : (
                  <div className="system-task-no-content">
                    <CustomTitleLabel title="No task here !" />
                    <label className="tips-message">
                      No task here ! There is no uploaded or downloaded task at
                      the moment
                    </label>
                  </div>
                )}
              </Popover.Content>
            </Popover>
          </div>
          // </Tooltip>
        )}
      </Overlay>
      {/* <OverlayTrigger
        trigger="click"
        key="bottom"
        placement="bottom"
        show={showTasks}
        onToggle={() => {}}
        overlay={
          <div className="overlay-trigger">
            <Popover
              id={`popover-positioned-bottom`}
              style={{ maxWidth: '350px', width: '600px' }}
            >
              <Popover.Title>
                <div className="system-task-header">
                  <label className="system-task">System task</label>

                  <Button
                    className="custom-image-button margin-left-10-percent"
                    onClick={() => {
                      setShowTasks(false);
                    }}
                  >
                    <Image src={closeIcon} />
                  </Button>
                </div>
              </Popover.Title>
              <Popover.Content>
                {systemTasks.map((item, index) => {
                  return downloadLine(item, index < systemTasks.length - 1);
                })}
              </Popover.Content>
            </Popover>
          </div>
        }
      >
        <div className="system-task-area">
          <Image src={downloadIcon} className="download-icon" />
          <label className="header-text">System task</label>
          <label className="badge-icon">1</label>
        </div>
      </OverlayTrigger> */}
    </div>
  );
}

export default withRouter(Header);
