import React, { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import './CouponGeneral.scss';
import { useDispatch, connect } from 'react-redux';
import {
  COUPON_TEMPLATE_TYPE,
  COUPON_TEMPLATE_TYPE_NAME,
  LanguageConfig,
  STORE_LOADING_TYPE,
} from '../../../config/CustomEnums';
import { createAction } from '../../../utils';
import CustomMultipleSelect from '../../base/CustomMultipleSelect';
import CustomSwitchButton from '../../base/CustomSwitchButton';
import {
  isShowError,
  firstError,
} from '../../campaign/campaignCreation/CreateCampaignHandleError';
import { CouponErrorFields } from './CouponHandleError';
import ErrorFieldMessage from '../../base/ErrorFieldMessage';
import BaseMutipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import CustomTips from '../../base/CustomTips';
import i18n from '../../../I18n';
import BrandSelectDropdown from '../../campaign/campaignCreation/BrandSelectDropdown';
import BasePrompt from '../../base/prompt/BasePrompt';
import {
  CustomTitleLabel,
  CustomTitleWithRadio,
} from '../../earning/CustomBaseComponments';
import {
  hasError,
  ReactHookFormErrorMessage,
} from '../../base/ErrorFieldMessage';
import CustomEditor from '../../base/CustomEditor';
import InputFieldControl from '../../base/InputFieldControl';
import { ReactHookFormMarkDown } from '../../base/CustomMarkDownArea';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { SectionTopTitle } from '../../base/CommonComponent';

const CREATE_NEW_TYPE = {
  brand: 'brand',
  store: 'store',
  none: 'none',
};

const CouponGeneral = ({
  language,
  onFocus = (() => {}),
  appliableStoreList = [],
  brandStoreList = [],
  storeEditMode,
  originalBrandPK,
  originalLinkedStores,
  shouldBeUsedInStores,
  selectedStore
}) => {
  const [createNewType, setCreateNewType] = useState(CREATE_NEW_TYPE.none);
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const createNewText =
    createNewType === CREATE_NEW_TYPE.brand ? 'brand' : 'store';
  const { register, watch, setValue, control, formState, clearErrors } =
    useFormContext();
  const { errors } = formState;
  const watchRedemptionMethod = watch('redemptionMethod');
  const watchSelectedBrand = watch('selectedBrand') || {};
  const watchSelectedStores = watch('selectedStores') || [];
  const watchName = watch(`translations.${language}.name`);
  const watchShortDescription = watch(
    `translations.${language}.shortDescription`,
  );
  const watchshouldBeUsedInStores = watch("shouldBeUsedInStores");
  const history = useHistory();

  const getRelatedStores = (keys) => {
    console.log('getRelatedStores: ', keys);
    dispatch({
      type: 'storeModel/doStoreRequest',
      payload: { ...keys },
    });
  };

  const stopStoreLoading = () => {
    dispatch({
      type: 'storeModel/updateState',
      payload: { allListLoading: false },
    });
  };

  useEffect(() => {
    console.log('watchSelectedBrand2:', watchSelectedBrand);
    if (!watchSelectedBrand.pk || watchSelectedBrand.pk === originalBrandPK) {
      return;
    }
    dispatch({
      type: 'storeModel/updateState',
      payload: { storeEditMode: true },
    });
  }, [watchSelectedBrand.pk]);

  useEffect(() => {
    console.log('@123 watchSelectedBrand1:', id && !shouldBeUsedInStores);
    if (!watchSelectedBrand.pk || language !== LanguageConfig.english) {
      return;
    }
    if (id && !watchshouldBeUsedInStores && watchSelectedBrand.pk === originalBrandPK) {
      stopStoreLoading();
      return;
    }
    if (!id || watchSelectedBrand) {
      console.log("@123", watchSelectedBrand.pk)
      getRelatedStores({
        [STORE_LOADING_TYPE.BRAND]: watchSelectedBrand.pk,
      });
      return;
    }
    if (!id) {
      return;
    }
    if (originalBrandPK && originalBrandPK !== watchSelectedBrand.pk) {
      return;
    }
    getRelatedStores({
      [STORE_LOADING_TYPE.COUPON]: id,
      notBrandRelate: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchSelectedBrand.pk, watchshouldBeUsedInStores]);

  const brandSection = (
    <Controller
      control={control}
      name="selectedBrand"
      render={() => (
        <>
          <BrandSelectDropdown
            customClass={
              hasError(errors, 'selectedBrand') ? 'field-has-error' : ''
            }
            title={i18n.t('related_brand', {
              locale: language,
            })}
            defaultValue={{
              value: watchSelectedBrand,
              label: watchSelectedBrand?.name,
            }}
            onSelectChange={(item) => {
              onFocus('relatedBrand');
              if (item.value !== watchSelectedBrand) {
                setValue('selectedBrand', item.value, { shouldDirty: true });
                setValue('selectedStores', [], {
                  shouldDirty: true,
                });
                clearErrors('selectedStores');
              }
            }}
            addButton={{
              title: 'Create Now',
              customClass: 'multiple-select-option-add btn-add-button-common',
              action: () => {
                setCreateNewType(CREATE_NEW_TYPE.brand);
              },
            }}
            editable={language === LanguageConfig.english}
            onFocus={() => onFocus('relatedBrand')}
          />
          {language === LanguageConfig.english ? (
            <ReactHookFormErrorMessage errors={errors} id="selectedBrand" />
          ) : null}
        </>   
      )}
    />
  );
  

  const shouldShowInStoreView = () => {
    switch (language) {
      case LanguageConfig.english:
        return (
          <CustomSwitchButton
            defaultChecked={
              watchshouldBeUsedInStores
            }
            onChange={(isChecked) => {
              setValue('shouldBeUsedInStores', isChecked, {shouldDirty: true});
            }}
          />
        );
      case LanguageConfig.simplifiedChinese:
      case LanguageConfig.traditionalChinese:
        if (watchshouldBeUsedInStores) {
          return (
            <div className="second-section-content">
              {i18n.t('yes', { locale: language })}
            </div>
          );
        } else {
          return (
            <div className="second-section-content">
              {i18n.t('no', { locale: language })}
            </div>
          );
        }

      default:
        break;
    }
  };

  const showStoreName = (store) => {
    if (
      language !== LanguageConfig.english &&
      store.storeTranslations &&
      store.storeTranslations[language]
    ) {
      return store.storeTranslations[language].storeName;
    }
    return store.storeName;
  };
  const selectStoreView = () => {
    if (watchSelectedBrand?.pk && watchshouldBeUsedInStores) {
      switch (language) {
        case 'en':
          return (
            <div>
              <BaseMutipleSelectorV2
                title={i18n.t('applicable_stores', {locale: language})}
                size={["shorter"]}
                onFocusFunc={() => {
                  onFocus('selectedStores');
                }}
                namespace="storeModel"
                data={{
                  sourceData: brandStoreList,
                  targetData: watchSelectedStores,
                  targetChange: (value) => {
                    setValue('selectedStores', value, {
                      shouldDirty: true,
                    });
                  },
                }}
                addButton={{
                  action: () => {
                    setCreateNewType(CREATE_NEW_TYPE.store);
                  },
                  title: 'Add store',
                  requires: PermissionCodes.addStore,
                  customClass:
                    'general-section-add-new btn-add-button-common',
                }}
                error={{
                  error: hasError(errors, 'selectedStores'),
                }}
              />
              <ReactHookFormErrorMessage errors={errors} id="selectedStores" />
            </div>
          );
        default:
          if (watchSelectedStores && watchSelectedStores?.length > 0) {
            return (
              <div>
                {watchSelectedStores.map((store) => {
                  return (
                    <div
                      className="second-section-content"
                      key={store.storeName}
                    >
                      {showStoreName(store)}
                    </div>
                  );
                })}
              </div>
            );
          } else {
            return <div>-</div>;
          }
          // break;
      }
    }
  };

    return (
      <>
      <SectionTopTitle title={i18n.t('general', { locale: language })} />
      <InputFieldControl 
        name={`translations.${language}.name`}
        title={i18n.t('name', { locale: language })}
        value={watchName}
        setValue={(value) => {
          setValue(`translations.${language}.name`, value, { shouldDirty: true });
        }}
        onFocus={() => {
          onFocus('name');
        }}
      />
      <ReactHookFormMarkDown
        content={{
          label: i18n.t('short_description', { locale: language }),
          error:
            language === LanguageConfig.english
              ? hasError(errors, `translations.${language}.shortDescription`)
              : false,
          key: `translations.${language}.shortDescription`,
          focus: () => {
            onFocus('shortDescription')
          }
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id={`translations.${language}.shortDescription`} />
      {brandSection}
      <div
          className="second-section"
          hidden={watchSelectedBrand?.pk ? false : true}
          onFocus={() => onFocus('selectedStores')}
        >
          <div className="d-flex second-title-section">
            <div className="second-section-title">
              {i18n.t('use_store_switch', { locale: language })}
            </div>
            <CustomTips detail="Turn on this switch to select stores." />
          </div>
          {shouldShowInStoreView()}
        </div>
        {language !== LanguageConfig.english &&
        watchshouldBeUsedInStores ? (
          <div className="create-section-label create-section-label-bottom-space">
            {i18n.t('applicable_stores', { locale: language })}
          </div>
        ) : null}
        {selectStoreView()}
        <BasePrompt
          show={createNewType !== CREATE_NEW_TYPE.none}
          closeAction={() => {
            setCreateNewType(CREATE_NEW_TYPE.none);
          }}
          rightButton={{
            text: `Go to create ${createNewText}`,
            action: () => {
              setCreateNewType(CREATE_NEW_TYPE.none);
              const pathname =
                createNewType === CREATE_NEW_TYPE.brand
                  ? '/brands/create'
                  : '/stores/create';
              history.push({
                pathname: pathname,
                state: {
                  from: history.location,
                  title: 'Continue to create coupon?',
                  content: 'You can continue to create the coupon.',
                },
              });
            },
          }}
          title={`Go to create a ${createNewText}?`}
          description={`You will leave the coupon creation process. After you create a new ${createNewText}, you can back to this page.`}
        />
    </>
  )
}


const mapPropsToState = (state) => ({
  appliableStoreList: state.storeModel.appliableStoreList || [],
  brandStoreList: state.storeModel.brandStoreList || [],
  storeLoading: state.storeModel.allListLoading,
  storeEditMode: state.storeModel.storeEditMode,
  originalBrandPK: state.createCoupon.couponTemplate?.selectedBrand?.pk,
  originalLinkedStores: state.createCoupon.couponTemplate?.selectedStores,
  shouldBeUsedInStores: state.createCoupon.couponTemplate.shouldBeUsedInStores,
  selectedStore: state.createCoupon.couponTemplate.selectedStores,
});

export default connect(mapPropsToState)(CouponGeneral);
