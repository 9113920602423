import { LanguageConfig, CampaignType, APIStatus, SESSION_KEYS } from '../config/CustomEnums';
import {
  createCampaign,
  publishCampaign,
  getCampaign,
  unPublishCampaign,
  updateCampaign,
  deleteCampaign,
  getOneEarningRule,
  getOneCoupon,
} from '../services/CampaignAPIHelper';
import { CampaignErrorHandleField } from '../components/campaign/campaignCreation/CreateCampaignHandleError';
import { defaultStep, getNewStepConfig } from './StepBarUtil';
import {
  getFileNameFromUrl,
  saveToSessionStorage,
  removeFromSessionStorage,
  getObjectFromSessionStorage,
  createAction,
  delay,
  addDomainToImage,
  convertPKToId,
  getImageImage,
} from '../utils';
import { apiWithResponseHandle } from './LoadingUtil';
import {
  CREATE_CAMPAIGN_CATEGORY_FAILD,
  CREATE_CAMPAIGN_CATEGORY_SUCCESS,
} from './CreateCampaignCategoryModel';
import { getTypeDisplay } from './CampaignListModel';
import { createModel } from './BaseModel';
import { IMAGE_TYPES } from './UploadFilesModel';

export const sessionDataKey = {
  objectKey: SESSION_KEYS.CREATE_CAMPAIGN_SESSION_KEY,
  stepEndKey: SESSION_KEYS.CREATE_CAMPAIGN_END_SESSION_KEY,
  origionalData: SESSION_KEYS.CREATE_CAMPAIGN_ORIGIN_DATA_SESSION_KEY,
};

export const GENERAL_TYPE = {
  customerGroup: 'Customer group',
  segment: 'Segment',
  // level: 'Level',
};

export const LINK_COUPON_STRING = 'linkedCouponInfo';

// const isFollowTranslations = [
//   'isFollowCouponTemplateStores',
//   'isFollowCouponTemplateTourCard',
//   'isFollowCouponTemplateName',
//   'isFollowCouponTemplateShortDescription',
//   'isFollowCouponTemplateCoverPhoto',
//   'isFollowCouponTemplateDetailPhotos',
//   'isFollowCouponTemplateLogoLabels',
//   'isFollowCouponTemplateOfferSection',
//   'isFollowCouponTemplateInstructionSection',
//   'isFollowCouponTemplateMerchantTerms',
//   'isFollowCouponTemplateGenericTerms',
// ];

// const applyCouponToCampaign = ({
//   getValues,
//   setValue,
//   languages = [],
//   key,
// }) => {
//   const couponString = localStorage.getItem(LINK_COUPON_STRING);
//   const newCoupon = JSON.parse(couponString || '{}');
//   let couponKeyOne = '';
//   let couponKeyTwo = '';
//   switch (key) {
//     case 'isFollowCouponTemplateName':
//       couponKeyOne = 'generalName';
//       break;
//     case 'isFollowCouponTemplateShortDescription':
//       couponKeyOne = 'shortDescription';
//       break;
//     case 'isFollowCouponTemplateCoverPhoto':
//       couponKeyOne = 'coverPhoto';
//       break;
//     case 'isFollowCouponTemplateDetailPhotos':
//       couponKeyOne = 'detailPhotos';
//       break;
//     case 'isFollowCouponTemplateLogoLabels':
//       couponKeyOne = 'logoLabels';
//       break;
//     case 'isFollowCouponTemplateOfferSection':
//       couponKeyOne = 'offerDetailTitle';
//       couponKeyTwo = 'offerDetailContent';
//       break;
//     case 'isFollowCouponTemplateInstructionSection':
//       couponKeyOne = 'instructionSectionTitle';
//       couponKeyTwo = 'instructionSectionContent';
//       break;
//     case 'isFollowCouponTemplateMerchantTerms':
//       couponKeyOne = 'merchantTermsAndConditionsTitle';
//       couponKeyTwo = 'merchantTermsAndConditionsContent';

//       break;
//     case 'isFollowCouponTemplateGenericTerms':
//       couponKeyOne = 'genericTermsAndConditionsTitle';
//       couponKeyTwo = 'genericTermsAndConditionsContent';
//       break;
//     case 'isFollowCouponTemplateTourCard':
//       const tourCards = getTourCard(newCoupon.tourCards?.edges);
//       setValue('tourCards', tourCards);
//       setValue('showTourCard', newCoupon.showTourCard);
//       couponKeyOne = 'tourCardSectionTitle';
//       break;
//     default:
//       break;
//   }
//   const translations = getTranslationForCampaign(newCoupon);
//   languages.forEach((item) => {
//     const language = item.code;
//     if (!language) {
//       return;
//     }
//     if (couponKeyOne) {
//       const couponValueOne = translations?.[language]?.[couponKeyOne];
//       console.log('campaignKeyOne:', couponKeyOne, key, couponValueOne);
//       insertKeyToTranslations({
//         getValues,
//         setValue,
//         language,
//         key: couponKeyOne,
//         value: couponValueOne,
//       });
//     }

//     if (couponKeyTwo) {
//       const couponValueTwo = translations?.[language]?.[couponKeyTwo];
//       insertKeyToTranslations({
//         getValues,
//         setValue,
//         language,
//         key: couponKeyTwo,
//         value: couponValueTwo,
//       });
//     }
//   });
// };

// export const changeSwitch = ({
//   getValues,
//   setValue,
//   languages,
//   key,
//   value,
// }) => {
//   console.log('languages:', languages);
//   setValue(key, value, { shouldDirty: true });
//   if (!!value) {
//     applyCouponToCampaign({
//       getValues,
//       setValue,
//       languages,
//       key,
//     });
//   }
//   const allSwitchOn = isAllSwitchOn({ getValues });
//   console.log('allSwitchOn:', allSwitchOn);
//   setValue('isAllFollowCouponTemplate', !!allSwitchOn);
// };

// export const switchAll = ({ getValues, setValue, isOn }) => {
//   console.log('switchAll:');
//   try {
//     if (isOn) {
//       const couponString = localStorage.getItem(LINK_COUPON_STRING);
//       const newCoupon = JSON.parse(couponString);
//       transferCouponContentToCampagin({
//         newCoupon,
//         setValue,
//         getValues,
//       });
//     }
//   } catch (e) {
//     console.log('couponParseError: ', e);
//   }
//   console.log('switchAllcouponParseError: ', getValues());
//   isFollowTranslations.forEach((item) => {
//     setValue(item, isOn, {
//       shouldDirty: true,
//     });
//   });
//   setValue('isAllFollowCouponTemplate', isOn);
// };

// const isAllSwitchOn = ({ getValues }) => {
//   const switchValues = getValues(isFollowTranslations) || [];
//   console.log('switchValues:', switchValues);
//   const isAllSwitched = switchValues.every((item) => !!item);
//   return isAllSwitched;
// };

export const isShowCouponRelated = (campaignType) => {
  return campaignType === CampaignType.couponsCampaign
};

export const isShowEarningRuleRelated = (campaignType) => {
  return campaignType === CampaignType.earningRulesCampaign
}

const stepNames = ['Type', 'Content', 'Properties', 'Preview'];

export const saveDataToSessionStorage = (data = {}) => {
  saveToSessionStorage(sessionDataKey.objectKey, data);
};

export const removeDataFromSessionStorage = () => {
  removeFromSessionStorage(sessionDataKey.objectKey);
  removeFromSessionStorage(sessionDataKey.fortuneCoupons);
};

// export function fieldTransfer({ setValue, getValues }) {
//   try {
//     const couponString = localStorage.getItem(LINK_COUPON_STRING);
//     const newCoupon = JSON.parse(couponString);
//     if (!newCoupon) {
//       return;
//     }
//     if (newCoupon.brand) {
//       const brandSection = getBrandSectionForCampaign(newCoupon.brand);
//       setValue('linkedBrand', brandSection.linkedBrand);
//       setValue('brandSection', brandSection);
//     }
//     setValue('coupon_stock', newCoupon.stock || 0);
//     const switchValues = getValues(isFollowTranslations) || [];
//     const [selectedLanguages] = getValues(['selectedLanguages']) || [];
//     switchValues.forEach((item, index) => {
//       const fieldName = isFollowTranslations[index];
//       if (!!item) {
//         changeSwitch({
//           getValues,
//           setValue,
//           languages: selectedLanguages,
//           key: fieldName,
//           value: item,
//         });
//       }
//     });
//   } catch (e) {
//     console.log('fieldTransfer error: ', e);
//   }
// }

function checkStepTwoFields(data, translations, isBack) {
  const generalName = data[LanguageConfig.english].generalName;
  const coverPhoto = data[LanguageConfig.english].coverPhoto;
  const isExclusive = data.isExclusive;
  const square = data[LanguageConfig.english].squareCoverPhoto;
  const shortDescription = data[LanguageConfig.english].shortDescription;
  const errorFields = [];
  if (!generalName) {
    errorFields.push(CampaignErrorHandleField.generalName.name);
  }
  if (!shortDescription || shortDescription?.length > 200) {
    errorFields.push(CampaignErrorHandleField.shortDescription.name);
  }
  if (!coverPhoto) {
    errorFields.push(CampaignErrorHandleField.coverPhoto.name);
  }
  if (isExclusive && !square) {
    errorFields.push(CampaignErrorHandleField.squarePhoto.name);
  }
  return {
    invalid: isBack ? false : errorFields.length > 0,
    errorFields: isBack ? [] : errorFields,
    data: {
      translations: {
        [LanguageConfig.english]: {
          ...translations[LanguageConfig.english],
          ...data[LanguageConfig.english],
        },
        [LanguageConfig.traditionalChinese]: {
          ...translations[LanguageConfig.traditionalChinese],
          ...data[LanguageConfig.traditionalChinese],
        },
        [LanguageConfig.simplifiedChinese]: {
          ...translations[LanguageConfig.simplifiedChinese],
          ...data[LanguageConfig.simplifiedChinese],
        },
      },
    },
  };
}

function checkStepThreeFields(data, isBack, isExclusive) {
  const {
    activeStartDate,
    activeEndDate,
    visibleStartDate,
    visibleEndDate,
    campaignType,
    requiredPoints,
    isAlwaysActivePeriod,
    isAlwaysVisiblePeriod,
    customerGroup,
    segments,
    generalType,
  } = data;

  const errorFields = [];
  if (isExclusive) {
    if (
      generalType === GENERAL_TYPE.customerGroup &&
      customerGroup?.length === 0
    ) {
      errorFields.push(CampaignErrorHandleField.customerGroup.name);
    }
    if (generalType === GENERAL_TYPE.segment && segments?.length === 0) {
      errorFields.push(CampaignErrorHandleField.customerGroup.name);
    }
  }
  if (
    !isAlwaysActivePeriod &&
    activeStartDate.getTime() >= activeEndDate.getTime()
  ) {
    errorFields.push(CampaignErrorHandleField.activeEndDate.name);
  }
  if (
    !isAlwaysVisiblePeriod &&
    visibleStartDate.getTime() >= visibleEndDate.getTime()
  ) {
    errorFields.push(CampaignErrorHandleField.visibleEndDate.name);
  }
  if (
    campaignType === CampaignType.couponsCampaign &&
    requiredPoints !== 0 &&
    !requiredPoints
  ) {
    errorFields.push(CampaignErrorHandleField.requiredPoints.name);
  }
  return {
    invalid: isBack ? false : errorFields.length > 0,
    errorFields: isBack ? [] : errorFields,
    data: { ...data },
  };
}

function getTranlationDataForCreateCampaign(campaign, language, update) {
  let languageField = {};
  const translation = campaign?.translations?.[language] || {};
  if (language !== LanguageConfig.english) {
    languageField = {
      language: language,
    };
    if (translation.pk && update) {
      languageField['id'] = translation.pk;
    }
  }

  const result = {
    ...languageField,
    shortDescription: translation.shortDescription,
    name: translation.generalName,
    instructionSectionTitle: translation.instructionSectionTitle,
    instructionSectionContent: translation.instructionSectionContent,
    detailSectionTitle: translation.detailSectionTitle,
    detailSectionContent: translation.detailSectionContent,
    coverPhoto: getImageImage(translation.coverPhoto) || null,
    detailPhoto1: getImageImage(translation.detailPhotos?.[0]) || null,
    detailPhoto2: getImageImage(translation.detailPhotos?.[1]) || null,
    detailPhoto3: getImageImage(translation.detailPhotos?.[2]) || null,
    detailPhoto4: getImageImage(translation.detailPhotos?.[3]) || null,
    squareCoverPhoto: getImageImage(translation.squareCoverPhoto) || null,
  };
  // if (translation.squareCoverPhoto) {
  //   result['squareCoverPhoto'] = getImageImage(
  //     translation.squareCoverPhoto,
  //   );
  // }

  // if (translation.detailPhotoOne) {
  //   result['detailPhoto1'] = getFileNameFromUrl(translation.detailPhotoOne);
  // }
  // if (translation.detailPhotoTwo) {
  //   result['detailPhoto2'] = getFileNameFromUrl(translation.detailPhotoTwo);
  // }
  // if (translation.detailPhotoThree) {
  //   result['detailPhoto3'] = getFileNameFromUrl(translation.detailPhotoThree);
  // }
  // if (translation.detailPhotoFour) {
  //   result['detailPhoto4'] = getFileNameFromUrl(translation.detailPhotoFour);
  // }
  return result;
}

function getDataForCreateCampaign(campaign, update) {
  const categories = campaign.categories?.map((category) => category.pk);
  let earningRule = {};
  let coupon = {};
  if (campaign.campaignType === CampaignType.couponsCampaign) {
    coupon = {
      couponCampaignTypeCouponTemplate: campaign.linkedCoupon.pk,
      couponCampaignTypeShouldShowCouponStock: campaign.displayOverLimit,
      couponCampaignTypeOverallLimit: campaign.overallLimit,
      couponCampaignTypePerHeadLimit: campaign.perHeadLimit || null,
      couponCampaignTypeRequiredPoints: campaign.requiredPoints,
    };
  }
  if (campaign.campaignType === CampaignType.earningRulesCampaign) {
    earningRule = {
      earningCampaignTypeEarningRule: campaign.linkedEarningRules.pk,
    };
  }
  const formattedTranslation = getTranlationDataForCreateCampaign(
    campaign,
    LanguageConfig.english,
    update,
  );

  const otherTranslations = [];
  Object.keys(campaign?.translations || {}).forEach(language => {
    if (language === LanguageConfig.english) {
      return;
    }
    otherTranslations.push(getTranlationDataForCreateCampaign(
      campaign,
      language,
      update,
    ))
  })

  const result = {
    type: campaign.campaignType,
    categories: categories,
    isFeatured: campaign.isFeatured || false,
    isExclusive: campaign.isExclusive || false,
    brand: campaign.linkedBrand?.pk || null,
    stores: campaign.linkedStores?.map((item) => item.pk),
    applyCoverPhotoToAllLanguage: campaign.applyCoverPhotoToAllLanguage,
    applyDetailPhotosToAllLanguage: campaign.applyDetailPhotosToAllLanguage,
    applySquareCoverPhotoToAllLanguage: campaign.applySquareCoverPhotoToAllLanguage,
    translations: otherTranslations,
    ...formattedTranslation,
    ...coupon,
    ...earningRule,
  };
  const generalType = campaign.generalType;
  if (campaign.isExclusive && campaign.generalType) {
    let targetGroup = [];
    let segments = [];
    const groupLength = campaign.customerGroup?.length;
    const segmentsLength = campaign.segments?.length;
    if (generalType === GENERAL_TYPE.customerGroup && groupLength) {
      targetGroup = campaign.customerGroup?.map((group) => group.pk);
    } else if (generalType === GENERAL_TYPE.segment && segmentsLength) {
      segments = campaign.segments?.map((segment) => segment.pk);
    }
    result['targetedCustomerGroups'] = targetGroup
    result['targetedSegments'] = segments
  }
  if (!campaign.isAlwaysActivePeriod) {
    result['endDate'] = campaign.activeEndDate;
    result['startDate'] = campaign.activeStartDate;
  } else {
    result['endDate'] = null;
    result['startDate'] = campaign.creationDate || new Date();
  }
  if (!campaign.isAlwaysVisiblePeriod) {
    result['displayEndDate'] = campaign.visibleEndDate;
    result['displayStartDate'] = campaign.visibleStartDate;
  } else {
    result['displayEndDate'] = null;
    result['displayStartDate'] = campaign.creationDate || new Date();
  }
  return result;
}

function getBrandSectionForCampaign(brandSection) {
  if (!brandSection) {
    return { brandSection: {}, linkedBrand: {} };
  }
  // console.log('@@247: ', brandSection);
  return {
    brandSection: {
      id: brandSection.id,
      pk: brandSection.pk,
    },
    linkedBrand: {
      id: brandSection.id,
      pk: brandSection.pk,
      name: brandSection.name,
    },
  };
}

function getCouponAndEarningRuleForCampaign(couponSection) {
  if (!couponSection) {
    return {};
  }
  return {
    id: couponSection.id,
    pk: couponSection.pk,
    name: couponSection.name,
    stock: couponSection.stock
  };
}

function getCategoriesAndTargetGroupForCampaign(section) {
  if (!section.edges.length) {
    return [];
  }
  const targetGroup = section.edges.map((edge) => {
    const target = edge.node;
    return target
    // return {
    //   name: target.name,
    //   value: { ...target },
    // };
  });
  return targetGroup;
}

const getDetailPhotos = (node) => {
  const photos = [];
  if (node.detailPhoto1) {
    photos.push(addDomainToImage(node.detailPhoto1));
  }
  if (node.detailPhoto2) {
    photos.push(addDomainToImage(node.detailPhoto2));
  }

  if (node.detailPhoto3) {
    photos.push(addDomainToImage(node.detailPhoto3));
  }
  if (node.detailPhoto4) {
    photos.push(addDomainToImage(node.detailPhoto4));
  }
  return photos;
};

function translationAssemble(node, isCampaign) {
  if (!node) {
    return {};
  }
  const language = node.language || LanguageConfig.english;
  const data = {
    instructionSectionTitle: node.instructionSectionTitle,
    instructionSectionContent: node.instructionSectionContent,
    detailSectionTitle: node.detailSectionTitle,
    detailSectionContent: node.detailSectionContent,
    coverPhoto: addDomainToImage(node.coverPhoto),
    detailPhotos: getDetailPhotos(node),
  };
  if (isCampaign) {
    data.generalName = node.name;
    data.id = node.id;
    data.pk = node.pk;
    data.shortDescription = node.shortDescription;
    data.squareCoverPhoto = addDomainToImage(node.squareCoverPhoto);
  }
  return {
    [language]: data,
  };
}

function getTranslationForCampaign(campaign, isCampaign) {
  const enTranslation = translationAssemble(campaign, isCampaign);
  const chineseTranslation = campaign?.translations?.edges.map((item) => {
    return translationAssemble(item.node, isCampaign);
  });
  const reducedTranslation = chineseTranslation?.reduce(function (obj, item) {
    return {
      ...obj,
      ...item,
    };
  }, {});
  return {
    ...enTranslation,
    ...reducedTranslation,
  };
}

function getMillisecondsFromDate(date) {
  if (!date) {
    return '';
  }
  const momentTime = new Date(date);
  return momentTime;
}

function assembleCampaign(campaignData) {
  if (!campaignData) {
    return {};
  }
  campaignData.brandSection = campaignData.brand;

  const linkedCoupon = getCouponAndEarningRuleForCampaign(
    campaignData.couponCampaignTypeCouponTemplate,
  );
  const linkedEarningRules = {
    ...campaignData.earningCampaignTypeEarningRule,
    ...getCouponAndEarningRuleForCampaign(
      campaignData.earningCampaignTypeEarningRule,
    ),
  };
  const categories = getCategoriesAndTargetGroupForCampaign(
    campaignData.categories,
  );
  const targetGroup = getCategoriesAndTargetGroupForCampaign(
    campaignData.targetedCustomerGroups,
  );
  const segments = getCategoriesAndTargetGroupForCampaign(
    campaignData.targetedSegments,
  );
  const translations = getTranslationForCampaign(campaignData, true);
  const brandSection = getBrandSectionForCampaign(campaignData.brandSection);
  const startTime = getMillisecondsFromDate(campaignData.startDate);
  const endTime = getMillisecondsFromDate(campaignData.endDate);
  const visibleStartTime = getMillisecondsFromDate(
    campaignData.displayStartDate,
  );
  const visibleEndTime = getMillisecondsFromDate(campaignData.displayEndDate);
  const publicationTime = getMillisecondsFromDate(campaignData.publicationDate);
  let generalType = GENERAL_TYPE.customerGroup;
  if (segments && segments.length > 0) {
    generalType = GENERAL_TYPE.segment;
  }
  return {
    id: campaignData.id,
    pk: campaignData.pk,
    creationDate: campaignData.creationDate,
    campaignType: campaignData.type,
    isExclusive: campaignData.isExclusive || false,
    isFeatured: campaignData.isFeatured || false,
    isAlwaysActivePeriod: !endTime,
    activeStartDate: startTime,
    activeEndDate: endTime || startTime,
    isAlwaysVisiblePeriod: !visibleEndTime,
    visibleStartDate: visibleStartTime,
    visibleEndDate: visibleEndTime || visibleStartTime,
    publicationDate: publicationTime,
    overallLimit: campaignData.couponCampaignTypeOverallLimit,
    perHeadLimit: campaignData.couponCampaignTypePerHeadLimit,
    requiredPoints: campaignData.couponCampaignTypeRequiredPoints,
    displayOverLimit:
      campaignData.couponCampaignTypeShouldShowCouponStock || false,
    isPublished: campaignData.isPublished,
    linkedBrand: brandSection.linkedBrand,
    brandSection: brandSection,
    linkedStores: campaignData.stores?.edges?.map((item) => item.node),
    linkedEarningRules,
    linkedCoupon,
    categories,
    customerGroup: targetGroup || [],
    translations,
    generalType,
    segments: segments || [],
    typeDisplay: getTypeDisplay(campaignData.type),
    order: campaignData.displayPriority,
    applyCoverPhotoToAllLanguage: campaignData.applyCoverPhotoToAllLanguage,
    applyDetailPhotosToAllLanguage: campaignData.applyDetailPhotosToAllLanguage,
    applySquareCoverPhotoToAllLanguage: campaignData.applySquareCoverPhotoToAllLanguage,
  };
}

function getTranlation() {
  return {
    generalName: '',
    shortDescription: '',
    coverPhoto: '',
    squareCoverPhoto: '',
    detailPhotoOne: '',
    detailPhotoTwo: '',
    detailPhotoThree: '',
    detailPhotoFour: '',
    instructionSectionTitle: '',
    instructionSectionContent: '',
    detailSectionTitle: '',
    detailSectionContent: '',
  };
}

const campaignInit = () => ({
  id: null,
  pk: null,
  applyCoverPhotoToAllLanguage: false,
  applyDetailPhotosToAllLanguage: false,
  applySquareCoverPhotoToAllLanguage: false,
  isExclusive: false,
  creationDate: null,
  isFeatured: false,
  campaignType: '',
  isAlwaysActivePeriod: false,
  activeStartDate: null,
  activeEndDate: null,
  isAlwaysVisiblePeriod: false,
  visibleStartDate: null,
  visibleEndDate: null,
  overallLimit: null,
  perHeadLimit: null,
  requiredPoints: 0,
  displayOverLimit: false,
  linkedCoupon: null,
  linkedEarningRules: null,
  linkedBrand: null,
  order: null,
  typeDisplay: null,
  categories: [],
  customerGroup: [],
  segments: [],
  generalType: GENERAL_TYPE.customerGroup,
  translations: {},
});

const getInitialState = () => ({
  campaign: campaignInit(),
  hasUpdatedDefaultValues: false,
  formHasSubmitted: false,
  errorFields: [],
  stepConfig: defaultStep(stepNames),
  currentStep: 0,
  languageTag: LanguageConfig.english,
  createStatus: APIStatus.none,
  campaignPublish: APIStatus.none,
});

// function saveDataToSessions(oldCampaign) {
//   const localCampaign = getObjectFromSessionStorage(sessionDataKey.objectKey);

//   const localEn = localCampaign?.translations?.[LanguageConfig.english] || {};
//   const localTraditional =
//     localCampaign?.translations?.[LanguageConfig.traditionalChinese] || {};
//   const localSimplified =
//     localCampaign?.translations?.[LanguageConfig.simplifiedChinese] || {};

//   const finalCampaign = {
//     ...localCampaign,
//     ...oldCampaign,
//     translations: {
//       [LanguageConfig.english]: {
//         ...localEn,
//         ...oldCampaign.translations[LanguageConfig.english],
//       },
//       [LanguageConfig.traditionalChinese]: {
//         ...localTraditional,
//         ...oldCampaign.translations[LanguageConfig.traditionalChinese],
//       },
//       [LanguageConfig.simplifiedChinese]: {
//         ...localSimplified,
//         ...oldCampaign.translations[LanguageConfig.simplifiedChinese],
//       },
//     },
//   };
//   saveToSessionStorage(sessionDataKey.objectKey, finalCampaign);
// }

export default createModel({
  namespace: 'createCampaign',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    updateCampaign(state, { payload }) {
      console.log("@722 update campaign", payload)
      const campaign = { ...state.campaign, ...payload };
      // saveToSessionStorage(sessionDataKey.objectKey, campaign);
      // saveDataToSessions(campaign);
      return { ...state, campaign: campaign };
    },

    assembleCampaign(state, { payload }) {
      const campaignData = assembleCampaign(payload.campaign);
      const linkedCoupon = campaignData.linkedCoupon || {};
      const linkedEarningRules = campaignData.linkedEarningRules || {};
      const promptShowed =
        Object.keys(linkedCoupon).length > 0 ||
        Object.keys(linkedEarningRules).length > 0;
      const campaign = {
        ...state.campaign,
        ...campaignData,
        translations: {
          ...state.campaign?.translations,
          ...campaignData.translations,
        },
      };
      // saveToSessionStorage(sessionDataKey.origionalData, campaign);
      // saveToSessionStorage(sessionDataKey.objectKey, campaign);
      // saveDataToSessions(campaign);
      return {
        ...state,
        promptShowed,
        campaign: campaign,
        hasUpdatedDefaultValues: true,
      };
    },

    loadCampaignFromCookie(state, { payload }) {
      const campaign = payload.resume ? getObjectFromSessionStorage(sessionDataKey.objectKey) || campaignInit() : campaignInit();
      console.log("791", campaign)
      // if (!campaign) {
      //   return {
      //     ...state,
      //   };
      // }
      if (campaign?.pk) {
        removeDataFromSessionStorage();
      }
      const linkedCoupon = campaign.linkedCoupon || {};
      const linkedEarningRules = campaign.linkedEarningRules || {};
      const promptShowed =
        Object.keys(linkedCoupon).length > 0 ||
        Object.keys(linkedEarningRules).length > 0;
      // saveToSessionStorage(sessionDataKey.origionalData, campaign);
      // saveToSessionStorage(sessionDataKey.objectKey, campaign);
      // saveDataToSessions(campaign);
      console.log("@815", promptShowed, payload)
      return {
        ...state,
        promptShowed: promptShowed,
        campaign: campaign,
        hasUpdatedDefaultValues: true,
      };
    },

    // saveOrRemoveCampaignFromCookie(state, { payload }) {
    //   if (!payload) {
    //     removeFromSessionStorage(sessionDataKey.objectKey);
    //   }
    //   saveToSessionStorage(sessionDataKey.stepEndKey, true);
    //   return {
    //     ...state,
    //   };
    // },

    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      const data = payload.data;
      let step = payload.step;
      const isValid = payload.isValid;
      let result = { invalid: false, errorFields: [], data: {} };
      // if (step === 1) {
      //   data['isExclusive'] = state.campaign.isExclusive;
      //   result = checkStepTwoFields(data, state.campaign.translations, isBack);
      // }
      // if (step === 2) {
      //   data['campaignType'] = state.campaign.campaignType;
      //   result = checkStepThreeFields(data, isBack, state.campaign.isExclusive);
      // }
      // const errorFields = result.errorFields;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        !isValid,
        isBack,
      );

      if (isValid) {
        step = isBack ? step - 1 : step + 1;
      }
      // saveDataToSessions({
      //   ...state.campaign,
      //   ...result.data,
      // });
      return {
        ...state,
        currentStep: step,
        stepConfig,
        // errorFields: errorFields,
        createStatus: APIStatus.none,
        // campaign: {
        //   ...state.campaign,
        //   ...result.data,
        // },
      };
    },
    clearData(state, { payload }) {
      if (payload?.deleteSession) {
        removeDataFromSessionStorage();
      }
      localStorage.removeItem(LINK_COUPON_STRING);
      console.log("@873", payload)
      return { ...state, ...getInitialState() };
    },

    // linkedUpdate(state, { payload }) {
    //   const translations = getTranslationForCampaign(payload.data);
    //   const campaign = {
    //     ...state.campaign,
    //     translations: {
    //       [LanguageConfig.english]: {
    //         ...(state.campaign.translations[LanguageConfig.english] || {}),
    //         ...(translations[LanguageConfig.english] || {}),
    //       },
    //       [LanguageConfig.simplifiedChinese]: {
    //         ...(state.campaign.translations[LanguageConfig.simplifiedChinese] ||
    //           {}),
    //         ...(translations[LanguageConfig.simplifiedChinese] || {}),
    //       },
    //       [LanguageConfig.traditionalChinese]: {
    //         ...(state.campaign.translations[
    //           LanguageConfig.traditionalChinese
    //         ] || {}),
    //         ...(translations[LanguageConfig.traditionalChinese] || {}),
    //       },
    //     },
    //   };
    //   saveToSessionStorage(sessionDataKey.objectKey, campaign);
    //   saveDataToSessions(campaign);
    //   return {
    //     ...state,
    //     campaign: campaign,
    //   };
    // },

    // clearData(state, { payload }) {
    //   if (payload?.deleteSession) {
    //     removeFromSessionStorage(sessionDataKey.objectKey);
    //   }
    //   removeFromSessionStorage(CampaignType.couponsCampaign);
    //   removeFromSessionStorage(CampaignType.earningRulesCampaign);
    //   removeFromSessionStorage(CampaignType.generalMessageCampaign);
    //   return { ...state, ...getInitialState() };
    // },
  },

  effects: {
    // *setFieldToSession({ payload }, { select }) {
    //   const oldCampaign = yield select(
    //     (state) => state.createCampaign.campaign,
    //   );
    //   const language = payload.language;
    //   let campaign = {};
    //   if (language) {
    //     delete payload.language;
    //     campaign = {
    //       ...oldCampaign,
    //       translations: {
    //         ...oldCampaign.translations,
    //         [language]: {
    //           ...oldCampaign.translations[language],
    //           ...payload,
    //         },
    //       },
    //     };
    //   } else {
    //     campaign = { ...oldCampaign, ...payload };
    //   }

    //   saveToSessionStorage(sessionDataKey.objectKey, campaign);
    //   saveDataToSessions(campaign);
    // },

    // *typeChange({ payload }, { select, put }) {
    //   const stateCampaign = yield select(
    //     (state) => state.createCampaign.campaign,
    //   );
    //   const campaignType = payload.campaignType;
    //   yield put({
    //     type: 'updateCampaign',
    //     payload: {
    //       campaignType,
    //       linkedEarningRules: {
    //         ...stateCampaign?.linkedEarningRules,
    //         type: '',
    //       },
    //     },
    //   });
    //   const linkedCoupon = stateCampaign.linkedCoupon;
    //   const linkedEarningRules = stateCampaign.linkedEarningRules;
    //   const couponId = linkedCoupon?.pk;
    //   const earningRuleId = linkedEarningRules?.pk;
    //   let name = '';
    //   let actionName = '';
    //   if (campaignType === CampaignType.couponsCampaign && couponId) {
    //     name = linkedCoupon?.name;
    //     actionName = 'getAndLinkCouponDetail';
    //   }
    //   if (campaignType === CampaignType.earningRulesCampaign && earningRuleId) {
    //     name = linkedEarningRules?.name;
    //     actionName = 'getAndLinkEarningRuleDetail';
    //   }
    //   if (actionName) {
    //     yield put({
    //       type: actionName,
    //       payload: { name, couponId, earningRuleId },
    //     });
    //   }
    // },

    // publishOrUnpublishCamapaign: [
    //   function* ({ payload }, { call, select, put }) {
    //     yield put({
    //       type: 'updateState',
    //       payload: { createStatus: APIStatus.calling },
    //     });
    //     saveToSessionStorage(sessionDataKey.stepEndKey, true);
    //     let { campaignPk, isPublished } = yield select((state) => ({
    //       campaignPk: state.createCampaign.campaign.pk,
    //       isPublished: state.createCampaign.campaign.isPublished,
    //     }));

    //     if (campaignPk) {
    //       yield put.resolve({
    //         type: 'updateCampaignFields',
    //         payload,
    //       });
    //     } else {
    //       yield put.resolve({
    //         type: 'createOneCampaign',
    //         payload,
    //       });
    //     }
    //     campaignPk = yield select((state) => state.createCampaign.campaign.pk);
    //     const apiError = yield select((state) => state.createCampaign.apiError);
    //     if (apiError) {
    //       yield put({
    //         type: 'updateState',
    //         payload: { createStatus: APIStatus.failed },
    //       });
    //       return;
    //     }
    //     isPublished
    //       ? yield put({
    //           type: 'unPublishCampaign',
    //           payload: { ...payload, campaignPk },
    //         })
    //       : yield put({
    //           type: 'publishCampaign',
    //           payload: { ...payload, campaignPk },
    //         });
    //   },
    //   { type: 'takeLatest' },
    // ],

    publishCampaign: [
      function* ({ payload }, { all, select, put }) {
        const afterAction = payload.afterAction || (() => {});
        const campaignPk = payload.campaignPk;
        let serviceArgs = [unPublishCampaign, { id: campaignPk }];
        console.log("@1038", payload);
        if (!payload.isPublished) {
          serviceArgs = [publishCampaign, {id: campaignPk, otaAllocationNum: payload.otaAllocatedNum}]
        }
        // const serviceArgs = [
        //   publishCampaign,
        //   {
        //     id: campaignPk,
        //   },
        // ];
        function* onSuccess(data) {
          yield all([
            put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.success,
                campaignPublish: !payload.isPublished
                ? APIStatus.success
                : APIStatus.none,
              }
            }),
            put({
              type: 'updateCampaign',
              payload: { isPublished: payload.isPublished, notSave: true },
            }),
          ]);
          afterAction();
        }

        function* onError(response) {
          yield put({
            type: 'updateState',
            payload: {
              createStatus: APIStatus.failed,
              campaignPublish: APIStatus.failed,
            },
          });
          afterAction();
        }

        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
          onError,
          onError,
        );
      },
    ],

    // unPublishCampaign: [
    //   function* ({ payload }, { call, select, put }) {
    //     const afterAction = payload.afterAction || (() => {});
    //     const campaignPk = payload.campaignPk;
    //     const serviceArgs = [
    //       unPublishCampaign,
    //       {
    //         id: campaignPk,
    //       },
    //     ];

    //     function* onSuccess(data) {
    //       yield put({
    //         type: 'updateState',
    //         payload: { createStatus: APIStatus.success },
    //       });
    //       afterAction();
    //     }
    //     function* onError(response) {
    //       yield put({
    //         type: 'updateState',
    //         payload: { createStatus: APIStatus.failed },
    //       });
    //       afterAction();
    //     }

    //     function* onArgumentsError(response) {
    //       yield put({
    //         type: 'updateState',
    //         payload: { createStatus: APIStatus.failed },
    //       });
    //       afterAction();
    //     }
    //     yield apiWithResponseHandle(
    //       serviceArgs,
    //       onSuccess,
    //       onError,
    //       onArgumentsError,
    //     );
    //   },
    //   { type: 'takeLatest' },
    // ],

    createOrUpdateCampaign: [
      function* ({ payload }, { call, select, put, all }) {
        const resume = payload.resume || false
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        const { modelCampaign } = yield select((state) => ({
          modelCampaign: state.createCampaign.campaign,
        }));
        console.log("@1135 payload", payload)
        const originalCampaign = payload?.data || modelCampaign;
        let campaignData = {};
        console.log("@1141 campaign data", campaignData)
        campaignData = getDataForCreateCampaign(
          originalCampaign,
          originalCampaign.pk,
        );
        console.log("@1145", campaignData)
        let serviceArgs = [createCampaign, campaignData];
        if (originalCampaign.pk) {
          campaignData.id = originalCampaign.pk;
          serviceArgs = [updateCampaign, campaignData];
        }
        // const campaignPk = yield select(
        //   (state) => state.createCampaign.campaign.pk,
        // );
        // if (campaignPk) {
        //   yield put.resolve({ type: 'updateCampaignFields', payload });
        // } else {
        //   yield put.resolve({ type: 'createOneCampaign', payload });
        // }

        // const { newCampaignPk, apiError } = yield select((state) => ({
        //   newCampaignPk: state.createCampaign.campaign.pk,
        //   apiError: state.createCampaign.apiError,
        // }));

        // yield put({
        //   type: 'updateState',
        //   payload: {
        //     createStatus:
        //       !newCampaignPk || apiError ? APIStatus.failed : APIStatus.success,
        //   },
        // });
        function* onSuccess(data) {
          if (resume) {
            removeDataFromSessionStorage()
          }
          console.log("@1173", data)
          const campaign =
            data[`${originalCampaign.pk ? 'update' : 'create'}Campaign`].node;
          if (!campaign) {
            yield put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.failed,
              },
            });
            return;
          }

          yield all([
            put({
              type: 'updateState',
              payload: {
                apiError: false,
                createStatus: APIStatus.success,
                formHasSubmitted: true,
              },
            }),
            put({
              type: 'updateCampaign',
              payload: { id: campaign.id, pk: campaign.pk, campaignType: campaign.type },
            }),
            payload.isPublished !== undefined &&
              put({
                type: 'publishCampaign',
                payload: {
                  ...payload,
                  campaignPk: campaign.pk,
                  isPublished: !payload.isPublished,
                  otaAllocationNum: payload.otaAllocatedNum,
                },
              }),
          ]);
        }
        function* failed(data) {
          console.log('createOneCampaign failed:', data);
          yield put({ type: 'updateState', payload: { apiError: true } });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, failed, failed);
      },
      { type: 'takeLatest' },
    ],

    createOneCampaign: [
      function* ({ payload }, { call, select, put, all }) {
        const originalCampaign = yield select(
          (state) => state.createCampaign.campaign,
        );
        const createCampaignData = getDataForCreateCampaign(
          originalCampaign,
          false,
        );

        const serviceArgs = [createCampaign, createCampaignData];
        function* onSuccess(data) {
          const campaign = data.createCampaign.node;
          if (!campaign) {
            yield put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.failed,
              },
            });
            return;
          }
          yield put({
            type: 'updateState',
            payload: { apiError: false },
          });
          yield put({
            type: 'updateCampaign',
            payload: { id: campaign.id, pk: campaign.pk, campaignType: campaign.type },
          });
          yield put({ type: 'saveOrRemoveCampaignFromCookie', payload: false });
        }
        function* failed(data) {
          console.log('createOneCampaign failed:', data);
          yield put({ type: 'updateState', payload: { apiError: true } });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, failed, failed);
      },
      { type: 'takeLatest' },
    ],

    createCamapigncategory: [
      function* ({ payload }, { call, all, put, race, take }) {
        const {
          enCategory,
          simpleCategory,
          traditionalCategory,
          priority,
          successAction,
        } = payload;

        yield put(
          createAction('createCampaignCategory/createCampaignCategory')({
            enCategory,
            simpleCategory,
            traditionalCategory,
            priority,
            isForcedInactive: false,
            successAction
          }),
        );
        const [success, failed] = yield race([
          take(CREATE_CAMPAIGN_CATEGORY_SUCCESS),
          take(CREATE_CAMPAIGN_CATEGORY_FAILD),
        ]);
        if (success) {
          yield put(
            createAction('campaignCategoryList/getCampaignCategoryList')({status: true }),
          );
        }
      },
      { type: 'takeLatest' },
    ],

    getOneCampaign: [
      function* ({ payload }, { all, select, put }) {
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        const id = convertPKToId('CampaignNode', payload.id);
        const serviceArgs = [getCampaign, id];
        function* onSuccess(data) {
          console.log('getOneCampaign onSuccess :', data);
          yield all([
            put({
              type: 'assembleCampaign',
              payload: { 
                campaign: data.campaign,
                isDetail: payload.isDetail,
                newItems: payload,
              },
            }),
            put({
              type: 'updateState',
              payload: { createStatus: APIStatus.success },
            }),
          ]);
        }
        function* onError(err) {
          console.log('getOneCampaign onError :', err);
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
        }
        function* onArgumentsError(err) {
          console.log('getOneCampaign arguments error :', err);
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
        }
        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
          onError,
          onArgumentsError,
        );
      },
      { type: 'takeLatest' },
    ],
    deleteCampaigns: [
      function* ({ payload }, { call, select, put }) {
        const selectedCampaignIds = yield select(
          (state) => state.campaignList.checkedList,
        );
        const deleteCampaignIds = selectedCampaignIds?.map((item) => item.pk);
        console.log("@1344", deleteCampaignIds)
        const afterAction = payload.afterAction || (() => {});
        yield call(deleteCampaign, deleteCampaignIds);
        yield delay(1000);
        afterAction();
      },
      { type: 'takeLatest' },
    ],

    // deleteCampaignBrands: [
    //   function* ({ payload }, { call, select, put }) {
    //     const campaignPks = payload.campaignPks;
    //     const serviceArgs = [deleteCampaignBrand, campaignPks];
    //     yield apiWithResponseHandle(serviceArgs);
    //   },
    //   { type: 'takeLatest' },
    // ],

    // deleteCampaignTranslations: [
    //   function* ({ payload }, { call, select, put }) {
    //     const campaignPks = payload.campaignPks;
    //     const serviceArgs = [deleteCampaignTranslation, campaignPks];
    //     yield apiWithResponseHandle(serviceArgs);
    //   },
    //   { type: 'takeLatest' },
    // ],

    duplicateCampaign: [
      function* ({ payload }, { call, select, put }) {
        yield put({ type: 'clearData' });
        const campaignId = payload.campaignId;
        const afterAction = payload.afterAction || (() => {});
        yield put.resolve({
          type: 'getOneCampaign',
          payload: { id: campaignId },
        });
        const translations = yield select(
          (state) => state.createCampaign.campaign.translations,
        );
        const englishName = translations[LanguageConfig.english].generalName;
        translations[
          LanguageConfig.english
        ].generalName = `Copy of ${englishName}`;
        yield put.resolve({
          type: 'updateCampaign',
          payload: { translations },
        });
        yield put.resolve({ type: 'createOneCampaign' });
        afterAction();
      },
      { type: 'takeLatest' },
    ],

    updateCampaignFields: [
      function* ({ payload }, { all, select, put }) {
        const originalCampaign = yield select(
          (state) => state.createCampaign.campaign,
        );

        const campaignData = getDataForCreateCampaign(originalCampaign, true);
        campaignData.id = originalCampaign.pk;

        const serviceArgs = [updateCampaign, campaignData];
        function* onSuccess(data) {
          yield put({ type: 'saveOrRemoveCampaignFromCookie', payload: false });
          yield put({ type: 'updateState', payload: { apiError: false } });
        }
        function* failed(data) {
          yield put({ type: 'updateState', payload: { apiError: true } });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, failed, failed);
      },
      { type: 'takeLatest' },
    ],

    getAndLinkCouponDetail: [
      function* ({ payload }, { call, select, put }) {
        if (!payload.couponId) {
          return;
        }
        const couponId = convertPKToId('CouponTemplateNode', payload.couponId);
        const linkedCoupon = yield select(
          (state) => state.createCampaign.campaign.linkedCoupon,
        ) || {};
        const afterAction = payload?.afterAction || (() => {});
        const serviceArgs = [getOneCoupon, couponId];
        function* onSuccess(data) {
          yield afterAction({linkedCoupon : {
            ...linkedCoupon,
            ...data.couponTemplate,
          }})
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getAndLinkEarningRuleDetail: [
      function* ({ payload }, { call, select, put }) {
        if (!payload.earningRuleId) {
          return;
        }
        const earningRuleId = convertPKToId(
          'EarningRuleNode',
          payload.earningRuleId,
        );
        const linkedEarningRules = yield select(
          (state) => state.createCampaign.campaign.linkedEarningRules,
        ) || {};
        const serviceArgs = [getOneEarningRule, earningRuleId];
        const afterAction = payload?.afterAction || (() => {});
        function* onSuccess(data) {
          yield afterAction({linkedEarningRules : {
            ...linkedEarningRules,
            ...data.earningRule,
          }})
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
