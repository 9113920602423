import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import { listItem, listMultiItem } from '../../../components/banners/DisplayItems';
import { APIStatus, LanguageConfig } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';


const StoreCategoryDetail = ({
  category,
  createStatus,
  languages,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const renderTabForLanguage = (language) => {
    const subCategories = category.subCategories.map(
      (subCategoryData) => {
        const subName = subCategoryData?.translations?.[language]?.name || '-';
        const subOrder = `(${subCategoryData.order})`;
        return subName + subOrder
      }
    );
    const name = category?.translations?.[language]?.name || '-';
    const order = `(${category?.order})`;
    return [
      <>
        {listItem('Name(Order)', name + order)}
        {subCategories.length > 0
          ? listMultiItem('Subcategory(Order)', subCategories)
          : null}
      </>
    ];
  };

  const tabs = [
    {
      name: 'Detail',
      content: createStatus === APIStatus.calling
      ? <Loading />
      : (
        <ContentSections
          hidePreview
          languageTabContent={{
            containers: languages?.map((item) => ({
              container: [renderTabForLanguage(item.code)],
              key: item.code,
              title: item.sourceName,
            })),
          }}
          activeSection={LanguageConfig.english}
        />
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeStorecategory}
    />,
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    const categoryID = params.id;
    if (categoryID) {
      dispatch(
        createAction('createStoreCategory/getCategory')({ id: categoryID }),
      );
    }
    return () => {
      dispatch({ type: 'createStoreCategory/reset' });
    };
  }, []);

  return (
    <div className="campaign-category-detail">
      <CustomListComponent
        caution={{
          detail: '',
          title: category.name,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={category.name} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
};

const mapPropsToState = (state) => ({
  category: state.createStoreCategory.category,
  createStatus: state.createStoreCategory.createStatus,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(StoreCategoryDetail);
