import { EarningRuleType } from '../../../config/CustomEnums';

export const campaignAvaliableClue = (type) =>
  type === EarningRuleType.birthday
    ? 'Campaign available time. The start date can’t later then earning rules birthday start date and the end date can’t early then earning rules birthday end date'
    : 'Campaign available time.';

export const campaignVisibleClue = (type) =>
  type === EarningRuleType.birthday
    ? 'Display this campaign on app. Campaign available time. The start date can’t later then earning rules birthday start date and the end date can’t early then earning rules birthday end date'
    : 'Display this campaign on app.';

export const disableSwitchToAllPeriod = (type, date1, date2) => {
  return type === EarningRuleType.birthday && date1 && date2;
};

export const campaignLimitDate = (type, date) => {
  // console.log('campaignLimitDate:', type, date);
  if (EarningRuleType.birthday !== type) {
    return null;
  }
  return new Date(date);
};
