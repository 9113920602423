import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useFormContext, Controller } from 'react-hook-form';
import CustomTitleWithInput from '../../components/base/CustomTitleWithInput';
import DeletePrompt from '../../components/base/DeletePrompt';
import {
  LanguageConfig,
} from '../../config/CustomEnums';
import i18n from '../../I18n';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';



const CreateStoreSubCategoryContent = ({
  index,
  language,
}) => {
  const param = useParams();

  const { watch, setValue, control, formState, clearErrors } = useFormContext();
  const { errors } = formState;

  const [showDeletePrompt, setShowDeletePrompt] = React.useState(false);

  const nameField = `subCategories.${index}.translations.${language}.name`;
  const orderField = `subCategories.${index}.order`;
  const watchSubCategories = watch(`subCategories`);
  const watchSubCategory = watch(`subCategories.${index}`);
  const watchName = watch(nameField);
  const watchOrder = watch(orderField);

  console.log(
    '@@49: ',
    language,
    watchSubCategories,
  );
  const isEnglish = language === LanguageConfig.english;
  return (
    <>
      <div className="create-section-title">
        {i18n.t('subcategory', { locale: language })} #{index + 1}
        {isEnglish ? (
          <label
            className="delete-sub-category-btn"
            onClick={() => {
              setShowDeletePrompt(true);
            }}
          >
            Delete
          </label>
        ) : null}
      </div>
      <>
        <CustomTitleWithInput
          title={i18n.t('name', { locale: language })}
          type={'text'}
          useDefaultValue={false}
          setValue={(value) => {
            setValue(nameField, value, {  shouldDirty: true, });
          }}
          extra={{
            maxLength: 50,
          }}
          defaultValue={watchName}
          customClass={`custom-markdown-area-title `}
          error={{ error: hasError(errors, nameField) }}
        />
        <ReactHookFormErrorMessage errors={errors} id={nameField} />
      </>
      {isEnglish ? (
        <>
          <CustomTitleWithInput
            title={'Display order'}
            type={'number'}
            extra={{
              min: 1,
            }}
            setValue={(value) => {
              setValue(orderField, value, {  shouldDirty: true, });
            }}
            defaultValue={watchOrder}
            customClass={`coupon-campaign-section-input `}
            error={{ error: hasError(errors, orderField) }}
          />
          <ReactHookFormErrorMessage errors={errors} id={orderField} />
          <label className="coupon-campaign-section-caution-description">
            Minimum value is 1. The smaller value, the higher priority.
          </label>
        </>
      ) : null}

      <DeletePrompt
        message={{
          title: 'Confirm to delete subcategory',
          content: '',
        }}
        description={`Subcategory <sub ${index + 1}>, <sub ${index + 1}> may be associated with this any store, once deleted, the association will be cancelled.`}
        title={'Store subcategory'}
        data={watchSubCategory}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          const tempArray = [].concat(watchSubCategories);
          tempArray.splice(index, 1);
          setValue('subCategories', tempArray, {  shouldDirty: true, });
        }}
      />
    </>
  );
};

export default CreateStoreSubCategoryContent;



