import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { rewritePermission, checkHasPermission } from '../../utils';

function AuthButton({
  title,
  action,
  requires,
  customClass,
  children,
  ...others
}) {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => ({
    user: state.users,
  }));

  const hasPermission = () => {
    let newRequires = requires;
    if (!Array.isArray(requires)) {
      newRequires = [requires];
    }

    return (
      newRequires.filter((val) => checkHasPermission(user, val)).length > 0
    );
  };

  return (
    <>
      {hasPermission() && (title || children) ? (
        <Button
          className={`${customClass ? customClass : 'btn-further'}`}
          onClick={action}
          {...others}
        >
          {children || title}
        </Button>
      ) : null}
    </>
  );
}

export default AuthButton;
