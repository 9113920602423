import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import moment from 'moment';
import { CustomTitleLabel } from './CustomBaseComponments';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { EarningRuleErrorHandleField } from './EarningRuleHandleError';
import { useFormContext } from 'react-hook-form';

const { RangePicker } = DatePicker;

function BirthdaySection({

}) {
  const dateFormat = 'DD MMM YYYY (ddd)';

  const { watch, formState, setValue } = useFormContext();
  const errors = formState.errors;

  const birthStart = watch('birthStart')
  const birthEnd = watch('birthEnd')

  const onChange = (dates, dateStrings) => {
    const [start, end] = dates;
    setValue('birthStart', start?.format('yyyy-MM-DD'), { shouldDirty: true })
    setValue('birthEnd', end?.format('yyyy-MM-DD'), { shouldDirty: true })
  };


  return (
    <>
      <label className="create-section-title">BIRTHDAY</label>
      <CustomTitleLabel title="Birthday Period" />

      <RangePicker
        defaultValue={[
          birthStart ? moment(birthStart, 'yyyy-MM-DD') : null,
          birthEnd ? moment(birthEnd, 'yyyy-MM-DD') : null,
        ]}
        defaultPickerValue={[
          birthStart ? moment(birthStart, 'yyyy-MM-DD') : null,
          birthEnd ? moment(birthEnd, 'yyyy-MM-DD') : null,
        ]}
        className="date-picker"
        format={dateFormat}
        separator={'-'}
        onChange={onChange}
      />
      <ErrorFieldMessage
        id={`${EarningRuleErrorHandleField.birthday.name}`}
        error={errors.birthStart || errors.birthEnd}
        message={`${EarningRuleErrorHandleField.birthday.message}`}
      />
    </>
  );
}

export default BirthdaySection;
