import React, { Component } from 'react';
import './CouponTemplateOverview.scss';
import { parseCouponSetOverviewDatetime } from '../../../models/CouponUtil';
import BaseOverView from './BaseOverview';

class CouponTemplateOverview extends Component {
  render() {
    const titleList = [
      'Total Stock',
      'Available coupon',
      'Linked Campaign',
      'Create at',
      'Last Modified',
      'Status',
    ];
    const valueList = [
      this.props.couponSet?.totalNubmerOfGeneratedCoupons,
      this.props.couponSet?.stock,
      this.props.couponSet?.linkedCampaignsName,
      this.props.couponSet?.creationDate ? parseCouponSetOverviewDatetime(this.props.couponSet?.creationDate) : '-',
      this.props.couponSet?.lastModifiedDate ? parseCouponSetOverviewDatetime(this.props.couponSet?.lastModifiedDate) : '-',
      this.props.couponSet?.status,
    ];
    return (
      <BaseOverView itemTitleList={titleList} itemValueList={valueList} />
    );
  }
}

export default CouponTemplateOverview;
