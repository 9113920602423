import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import { listItem } from '../../components/banners/DisplayItems';
import {
  CustomTitleWithDropDown,
  CustomTitleWithDropDownAndInput,
} from './CustomTitleWithDropDown';
import CustomRadioWithTitleAndRelated from './CustomRadioWithTitleAndRelated';


function ContactInfoEditCard({ title }) {
  const dispatch = useDispatch();

  const { watch, setValue, control, formState, clearErrors } = useFormContext();
  const { errors } = formState;

  const phoneCountryCodeField = 'mobilePhoneNumberCountryCode';
  const phoneNumberField = 'mobilePhoneNumberSubscriberNumber';
  const emailField = 'emailAddress';
  const socialMediaField = 'socialMedia';
  const socialMediaInputField = 'socialMediaInput';
  const addressField = 'address';
  const typeField = 'signUpMethod';
  const watchMobilePhoneNumberCountryCode = watch(phoneCountryCodeField);
  const watchMobilePhoneNumberSubscriberNumber = watch(phoneNumberField);
  const watchEmailAddress = watch(emailField);
  const watchSocialMedia = watch(socialMediaField);
  const watchSocialMediaInput = watch(socialMediaInputField);
  const watchAddress = watch(addressField);
  const watchType = watch(typeField);
  const isTypeEmail = watchType === 'EMAIL_ADDRESS';
  const isTypePhone = watchType === 'MOBILE_PHONE_NUMBER';
  const mobileNumber = watch("mobileNumber");
  const signUpMethodDisplay = watch('signUpMethodDisplay');

  return (
    <>
      <label className="create-section-title">{ title }</label>

      {isTypePhone
        ? (
          <>
            {listItem('Mobile number', mobileNumber)}
          </>
        )
        : (
          <>
            <CustomTitleWithDropDownAndInput
              title={'Mobile number'}
              source={[
                // { name: '856', pk: '856' },
                { name: '852', pk: '852' },
                { name: '86', pk: '86' },
              ]}
              defaultValue={
                {
                  label: watchMobilePhoneNumberCountryCode,
                  value: watchMobilePhoneNumberCountryCode,
                }
              }
              defaultInputValue={watchMobilePhoneNumberSubscriberNumber}
              needFilter={false}
              customClass="dropdown-short"
              inputCustomClass="dropdown-input-long"
              setValue={(value) => {
                setValue(phoneCountryCodeField, value.value, { shouldDirty: true });
              }}
              setInputValue={(value) => {
                setValue(phoneNumberField, value, { shouldDirty: true });
              }}
            />
            <ReactHookFormErrorMessage errors={errors} id={phoneCountryCodeField} />
            <ReactHookFormErrorMessage errors={errors} id={phoneNumberField} />
          </>
        )
      }

      {isTypeEmail
        ? (
          <>
            {listItem('Email', watchEmailAddress)}
          </>
        )
        : (
          <>
            <CustomTitleWithInput
              title={'Email'}
              defaultValue={watchEmailAddress}
              useDefaultValue={false}
              setValue={(value) => {
                setValue(emailField, value, { shouldDirty: true });
              }}
              error={{ error: hasError(errors, emailField) }}
            />
            <ReactHookFormErrorMessage errors={errors} id={emailField} />
          </>
        )
      }

      {listItem('Sign up Method', signUpMethodDisplay)}


      {/* <CustomRadioWithTitleAndRelated
        title="Social media (optional)"
        options={[
          { label: 'Facebook', value: 'Facebook' },
          { label: 'Wechat', value: 'Wechat' },
        ]}
        defaultValue={watchSocialMedia}
        setValue={(value) => {
          setValue(socialMediaField, value, { shouldDirty: true });
        }}
        related={{
          type: 'input',
          defaultValue: watchSocialMediaInput,
          setValue: (value) => {
            setValue(socialMediaInputField, value, { shouldDirty: true });
          },
        }}
      /> */}

      <CustomTitleWithInput
        title={'Address (optional)'}
        defaultValue={watchAddress}
        useDefaultValue={false}
        setValue={(value) => {
          setValue(addressField, value, { shouldDirty: true });
        }}
        error={{ error: hasError(errors, addressField) }}
      />
      <ReactHookFormErrorMessage errors={errors} id={addressField} />
    </>
  );
}

export default ContactInfoEditCard;

