import React from 'react';
import { Image, OverlayTrigger, Popover } from 'react-bootstrap';
import './MessageChannelCard.scss';
import { MessageChannel } from '../../config/CustomEnums';
import CustomTips from '../base/CustomTips';
import Info from '../../assets/images/info.svg';

function MessageChannelCard(props) {
  const channelConfig = props.channelConfig || {};
  const selectedChannels = props.selectedChannels || [];
  const select = props.select || (() => {});

  const defaultToggledTip = (title) => {
    return (
      <OverlayTrigger
        key="right"
        placement="right"
        trigger="click"
        defaultShow={true}
        overlay={
          <Popover
            id={`popover-positioned-right`}
            className="caution-container"
          >
            <Popover.Content className="caution-content">
              {`We suggest also keeping your message in user's inbox`}
            </Popover.Content>
          </Popover>
        }
      >
        <div className="tips-pointer-container">
          <label className="campaign-type-title">{title}</label>
          <Image src={Info} className={'caution-icon'} />
        </div>
      </OverlayTrigger>
    );
  };

  const getChannelTitle = () => {
    if (
      selectedChannels.indexOf(MessageChannel.push) !== -1 &&
      channelConfig.channel === MessageChannel.inbox
    ) {
      if (selectedChannels.indexOf(MessageChannel.inbox) === -1) {
        return (
          <div className="channel-title-div">
            {defaultToggledTip(channelConfig.channel)}
          </div>
        );
      }
      return (
        <div className="channel-title-div">
          <CustomTips
            detail={`We suggest also keeping your message in user's inbox`}
          >
            <label className="campaign-type-title">
              {channelConfig.channel}
            </label>
          </CustomTips>
        </div>
      );
    }
    return (
      <label className="campaign-type-title">{channelConfig.channel}</label>
    );
  };

  return (
    <div
      className={`message-channel-container ${
        selectedChannels.indexOf(channelConfig.channel) === -1
          ? 'campaign-type-container'
          : 'campaign-type-container selected'
      }`}
      onClick={() => {
        select(channelConfig.channel);
      }}
    >
      {getChannelTitle()}
      <Image className="campaign-type-image" src={channelConfig.image} />
      <label className="campaign-type-description message-channel-description">
        {channelConfig.description}
      </label>
      <label className="message-channel-suggest">{channelConfig.suggest}</label>
    </div>
  );
}

export default MessageChannelCard;
