import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { ContinueWithBackButtons } from '../base/BottomStepComponent';
import ContentSections from '../base/ContentSections';
import { validate } from '../../containers/engagement/message/CreateMessageValidate';
import MessageSettingSection from './MessageSettingSection';

function CreateMessageStepThree() {
  const dispatch = useDispatch();

  const { getValues, trigger, setError, clearErrors, setFocus, formState } = useFormContext();
  const { errors } = formState;

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 2,
      isBack,
    });
    dispatch({
      type: 'createMessage/stepChange',
      payload: {
        isBack: isBack,
        step: 2,
        isValid
      },
    });
  };

  const sections = [
    <MessageSettingSection />,
  ];
  return (
    <>
      <ContentSections
        sections={sections}
        hidePreview={true}
      />
      <ContinueWithBackButtons
        backAction={() => {
          stepChangeAction(true);
        }}
        continueAction={() => {
          stepChangeAction(false);
        }}
      />
    </>
  );
}

export default CreateMessageStepThree;
