import React, { useEffect } from 'react';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CreateCouponStepOne from './CreateCouponStepOne';
import CreateCouponStepTwo from './CreateCouponStepTwo';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { useSelector, useDispatch, connect } from 'react-redux';
import { createCouponBreads } from '../../../config/CustomBreadConfig';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { createAction } from '../../../utils';
import { sessionDataKey } from '../../../models/CreateCouponModel';
import {
  getCmapaignCreateSucessStepBar,
  GENERATE_COUPON,
  CRATE_COUPON_SET,
} from '../../../components/base/prompt/NextStepConfig';
import {
  removeCouponSessionStroage,
  saveCouponToSessionStorage,
} from '../../../models/CreateCouponModel';
import { APIStatus, CampaignType } from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { CouponURLFragments } from '../../../components/coupon/CouponUrlConfig';
import { TAB_LIST_OF_COUPON_SETS } from './CouponList';
import GenerateCouponView from '../../../components/coupon/couponCreation/GenerateCouponView';
import CreateCouponStepThree from './CreateCouponStepThree';
import BaseForm from '../../../components/base/v2/BaseForm';
import NextStepPrompt from '../../../components/base/prompt/NextStepPrompt';
import { generateCoupon } from '../../../services/CouponAPIHelper';

function CreateCoupon({
  stepConfig,
  currentStep,
  formHasSubmitted,
  couponTemplate,
  hasUpdatedDefaultValues,
  createCouponTemplateStatus,
  generateCouponStatus,
  couponQuantity,
  couponGenerated,
  couponPK,
  couponFormat,
  showEditSuccessPrompt,
  generateCouponInfo
}) {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = params;

  const stepSet = [
    <CreateCouponStepOne />,
    <CreateCouponStepTwo />,
    <CreateCouponStepThree />
  ];

  useEffect(() => {
    const newItem = location.state || {};
    console.log('newItem:', location);
    if (id) {
      dispatch(
        createAction('couponList/getCouponSet')({ 
          couponSetPK: params.id,
          ...newItem,
        }),
      );
    } else {
      dispatch(createAction('createCoupon/loadCouponFromCookie')({ ...newItem }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    return () => {
      dispatch({ type: 'createCoupon/clearState' });
      dispatch({ type: 'storeModel/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateCouponButton = (title) => {
    return {
      text: title,
      action: () => {
        dispatch({
          type: 'createCoupon/updateGenerateCouponState',
          payload: {
            generateCoupon: true,
            generateCouponStatus: APIStatus.none,
            quantity: '',
            errorMessage: '',
          },
        });
        dispatch({
          type: 'createCoupon/updateState',
          payload: {
            // generateCouponStatus: APIStatus.none,
            createCouponTemplateStatus: APIStatus.none,
          },
        });
      },
    };
  };

  const goToCouponList = () => {
    history.push({ pathname: '/coupons', hash: TAB_LIST_OF_COUPON_SETS });
  };

  const goToSignleCouponList = () => {
    history.push({
      pathname: `/coupons/${couponPK ? couponPK : generateCouponInfo.couponTemplatePk}`,
      hash: CouponURLFragments.list,
    });
  };

  const mayBeLaterAction = () => {
    if (couponGenerated) {
      goToSignleCouponList();
      return;
    }
    goToCouponList();
  };

  const getNextStepConfig = () => {
    let title = 'Successfully Create!';
    let description =
      'The coupon set is successfully created. Let’s Generate some coupons base this coupon set.';
    let type = CRATE_COUPON_SET;
    const stepNames = getCmapaignCreateSucessStepBar(
      CampaignType.couponsCampaign,
      true,
    );
    let buttons = [generateCouponButton('Generate coupon')];
    if (couponGenerated) {
      type = GENERATE_COUPON;
      title = 'Successfully Generate!';
      description = `${generateCouponInfo.quantity} Coupon is successfully generted. Let’s create a coupon campaign based on these coupon or you can generate a single coupon to a customer.`;
      buttons = [
        {
          text: 'Create Coupon campaign',
          action: () =>
            history.push({
              pathname: '/campaigns/create',
              search: `type=${CampaignType.couponsCampaign}&id=${generateCouponInfo.couponTemplatePk}`,
            }),
          requires: PermissionCodes.addCampaign,
        },
        generateCouponButton('Continue generating coupon'),
      ];
    }
    buttons.push({
      text: 'Maybe Later',
      action: mayBeLaterAction,
    });
    return {
      title,
      description,
      type,
      stepNames,
      buttons,
    };
  };

  return (
    <>
      <BaseForm
        defaultValues={couponTemplate}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (save) {
            saveCouponToSessionStorage(getValues());
          } else {
            removeCouponSessionStroage();
          }
        }}
        nextStepConfig={{
          title: getNextStepConfig().title,
          description: getNextStepConfig().description,
          steps: {
            stepNames: getNextStepConfig().stepNames,
            current: getNextStepConfig().type,
          },
          buttons: getNextStepConfig().buttons,
        }}
        showFinishPop={
          !generateCouponInfo.generateCoupon && 
          (generateCouponStatus === APIStatus.success ||
          createCouponTemplateStatus === APIStatus.success)
        }
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: mayBeLaterAction,
          onClose: mayBeLaterAction,
        }}
        // sessionDataKey={sessionDataKey}
        content={stepSet}
        currentStep={currentStep}
        breadcrumb={
          <CustomBreadcrumb breadcrumbs={createCouponBreads(params?.id)} />
        }
        stepsConfig={stepConfig}
        caution={{
          detail: 'Coupon set stores the content and controls a list of couopn.After you create a coupon set, you can general a list of single coupons for your customers',
          title: 'Coupon Set',
        }}
      />
      {/* <GenerateCouponView
        couponFormat={couponFormat}
        couponTemplate={couponPK}
      /> */}
      <GenerateCouponView
        useGenerateCouponInfo={true}
      />
      <NextStepPrompt
        show={showEditSuccessPrompt}
        title="Successfully Saved!"
        description="Copuon is successfully saved."
        steps={null}
        buttons={[
          {
            text: 'Back to coupon listing page',
            action: goToCouponList,
            requires: PermissionCodes.viewCoupon,
          },
          {
            text: 'Continue edit',
            action: () => {
              dispatch({ type: 'createCoupon/hideEditorSuccessPrompt' });
            },
          },
        ]}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  stepConfig: state.createCoupon.stepConfig,
  currentStep: state.createCoupon.currentStep,
  formHasSubmitted: state.createCoupon.formHasSubmitted,
  couponTemplate: state.createCoupon.couponTemplate,
  hasUpdatedDefaultValues: state.createCoupon.hasUpdatedDefaultValues,
  createCouponTemplateStatus: state.createCoupon.createCouponTemplateStatus,
  generateCouponStatus: state.createCoupon.generateCouponStatus,
  couponQuantity: state.createCoupon.couponQuantity,
  couponGenerated: state.createCoupon.couponGenerated,
  couponPK: state.createCoupon.couponTemplate.pk,
  couponFormat: state.createCoupon.couponTemplate.formats,
  showEditSuccessPrompt: state.createCoupon.showEditSuccessPrompt,
  generateCouponInfo: state.createCoupon.generateCoupon,
})

export default connect(mapPropsToState)(CreateCoupon);
