import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../components/campaign/campaignCreation/CreateCampaignStepOne.scss';
import './CreateEarningRuleStepOne.scss';

import ContentSections from '../../../components/base/ContentSections';
import { getObjectFromSessionStorage, useCompare } from '../../../utils';
import ItemTypeSet, {
  MIN_WIDTH,
  SPACE_BETWEEN,
} from '../../../components/base/ItemTypeSet';
import {
  getScrollbarWidth,
  useMainContentWidth,
} from '../../../utils/ScreenUtil';
import Loading from '../../../components/base/Loading';
import { EarningRuleType, APIStatus } from '../../../config/CustomEnums';
import { OnlyContinueButton } from '../../../components/base/BottomStepComponent';

import EarningRuleQRCode from '../../../assets/images/earning/qrcode_scanning.png';
import EarningRuleGPS from '../../../assets/images/earning/gps_check_in.png';
import EarningRuleReferral from '../../../assets/images/earning/referral.png';
import EarningRuleGerneralPurchase from '../../../assets/images/earning/general_purchase.png';
import EarningRuleNewMember from '../../../assets/images/earning/new_member.png';
import EarningRuleBirthday from '../../../assets/images/earning/birthday.png';
import EarningRuleFillingForm from '../../../assets/images/earning/filling_form.png';
import { validate } from './CreateEarningRuleValidate';
import { useFormContext } from 'react-hook-form';

const EarningRuleTypesConfig = [
  {
    id: EarningRuleType.qrCodeScanning,
    title: 'QR Code Scanning',
    image: EarningRuleQRCode,
    // imageWidth: '101.61px',
    description:
      'Encourage customers to explore a place and scan its QR code(s)',
  },
  {
    id: EarningRuleType.gpsCheckIn,
    title: 'GPS Check-in',
    image: EarningRuleGPS,
    // imageWidth: '120px',
    description: 'Encourage customers to visit a place and do GPS check-in',
  },
  {
    id: EarningRuleType.memberReferral,
    title: 'Referral',
    image: EarningRuleReferral,
    // imageWidth: '120px',
    description: 'Encourage customers to refer new members',
  },

  {
    id: EarningRuleType.generalPurchase,
    title: 'Gerneral Purchase',
    image: EarningRuleGerneralPurchase,
    // imageWidth: '120px',
    description:
      'Encourage customers to purchase and claim for points or coupons',
  },
  {
    id: EarningRuleType.newMember,
    title: 'New Member',
    image: EarningRuleNewMember,
    // imageWidth: '93.54px',
    description: 'Encourage customers to sign up as new members',
  },
  {
    id: EarningRuleType.birthday,
    title: 'Birthday',
    image: EarningRuleBirthday,
    // imageWidth: '120px',
    description: 'Customers can enjoy exclusive offers on their birthday date',
  },
  {
    id: EarningRuleType.fillingForm,
    title: 'Filling Form',
    image: EarningRuleFillingForm,
    // imageWidth: '120px',
    description: 'Encourage customers to fill the form',
  },
];

function CreateEarnRuleStepOne({
  status,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const mainContentWidth = useMainContentWidth();
  const fullContainerWith = mainContentWidth - 80 - 60 - getScrollbarWidth();
  const {
    getValues,
    watch,
    setValue,
    control,
    formState,
    clearErrors,
    setError,
  } = useFormContext();
  const { errors } = formState;

  const earningRuleType = watch("type")


  const goToNextStep = (assignRemain) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      isBack: false,
    });
    console.log('isValid:', isValid, errors);
    setValue('assignRemain', assignRemain);
    console.log("@357 values", getValues)
    dispatch({
      type: 'createEarningRules/stepChange',
      payload: {
        step: 0,
        isValid,
      },
    });
  };

  const earningruleTypeSelectArea = () =>
    EarningRuleTypesConfig.map((typeItem, idx) => (
      <ItemTypeSet
        key={`${idx}-${typeItem.id}`}
        item={typeItem}
        selected={earningRuleType}
        onClick={(id) => {
          // setEarnningRuleType(id);
          setValue("type", id, { shouldDirty: true })
        }}
      />
    ));
  const sections = [
    <>
      {status === APIStatus.calling ? (
        <Loading />
      ) : (
        <>
          <label className="step-one-title">Earning Rule Type</label>
          <div>
            <div
              className="step-type-area"
              style={{
                justifyContent:
                  fullContainerWith < (MIN_WIDTH + SPACE_BETWEEN) * 2
                    ? 'center'
                    : 'flex-start',
              }}
            >
              {earningruleTypeSelectArea()}
            </div>
          </div>
        </>
      )}
    </>,
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <OnlyContinueButton
        continueAction={async () => {
          goToNextStep(true);
        }
      }
        disabledContinue={!earningRuleType}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  status: state.createEarningRules.createStatus,
})

export default connect(mapPropsToState)(CreateEarnRuleStepOne);
