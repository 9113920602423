import React, { useState, useEffect } from 'react';
import { Row, Collapse } from 'react-bootstrap';
import { useSelector, useDispatch, connect } from 'react-redux';
import ImageUploader, { ReactHookFormImageUploader } from '../../base/ImageUploader';
import './KeyVisualSection.scss';
import CustomRadios from '../../base/CustomRadios';
import { LanguageConfig } from '../../../config/CustomEnums';
import CustomTips from '../../base/CustomTips';
import { IMAGE_TYPES } from '../../../models/UploadFilesModel';
import i18n from '../../../I18n';
import {
  CampaignErrorHandleField,
  isShowError,
  firstError,
} from './CreateCampaignHandleError';
import {
  insertKeyToTranslations,
  createAction,
  insertKeyToAllLanguages,
} from '../../../utils';
import { Controller, useFormContext } from 'react-hook-form';
import { ApplySwitch } from '../../base/LabelWithSwitch';
import {
  ReactHookFormErrorMessage,
  errorMessage
} from '../../base/ErrorFieldMessage';

const CampaignLabel = {
  none: 'none',
  isFeatured: 'isFeatured',
  isExclusive: 'isExclusive',
};

const getRadioOptions = (language) => [
  {
    label: i18n.t('campaign.none', { locale: language }),
    value: CampaignLabel.none,
  },
  {
    label: i18n.t('campaign.featured_campaign', { locale: language }),
    value: CampaignLabel.isFeatured,
  },
  {
    label: i18n.t('campaign.exclusive_campaign', { locale: language }),
    value: CampaignLabel.isExclusive,
  },
];

function KeyVisualSection({
  language,
  onFocus = (() => {}),
  languages
}) {
  // const focusChange = props.focusChange;
  // const language = props.language;
  // const onSubmit = props.onSubmit;
  // const onSubmitAction = props.onSubmitAction || (() => {});
  // const {
  //   coverPhoto,
  //   squareCoverPhoto,
  //   detailPhotoTwo,
  //   detailPhotoOne,
  //   detailPhotoThree,
  //   detailPhotoFour,
  //   isExclusive,
  //   isFeatured,
  //   errorFields,
  // } = useSelector((state) => ({
  //   coverPhoto: state.createCampaign.campaign.translations[language].coverPhoto,
  //   squareCoverPhoto:
  //     state.createCampaign.campaign.translations[language].squareCoverPhoto,
  //   detailPhotoOne:
  //     state.createCampaign.campaign.translations[language].detailPhotoOne,
  //   detailPhotoTwo:
  //     state.createCampaign.campaign.translations[language].detailPhotoTwo,
  //   detailPhotoThree:
  //     state.createCampaign.campaign.translations[language].detailPhotoThree,
  //   detailPhotoFour:
  //     state.createCampaign.campaign.translations[language].detailPhotoFour,
  //   isExclusive: state.createCampaign.campaign.isExclusive,
  //   isFeatured: state.createCampaign.campaign.isFeatured,
  //   errorFields: state.createCampaign.errorFields,
  // }));
  const dispatch = useDispatch();
  const { watch, setValue, formState, control, getValues } = useFormContext();
  const errors = formState.errors;
  // const watchName = watch(`translations.${language}.generalName`);
  const coverPhoto = watch(`translations.${language}.coverPhoto`) || ''
  const detailPhotos = watch(`translations.${language}.detailPhotos`) || []
  const squareCoverPhoto = watch(`translations.${language}.squareCoverPhoto`) 
  const watchLabelType = watch('campaignLabelType')
  const isExclusive = watch("isExclusive")
  const isFeatured = watch("isFeatured")
  const applyCoverPhotoToAllLanguage = watch('applyCoverPhotoToAllLanguage');
  const applyDetailPhotosToAllLanguage = watch('applyDetailPhotosToAllLanguage');
  const applySquareCoverPhotoToAllLanguage = watch('applySquareCoverPhotoToAllLanguage');


  const campaignCoverPhoto = [coverPhoto]?.filter((item) => item);
  const campaignSquareCoverPhoto = [squareCoverPhoto].filter((item) => item);
  const campaignOtherPhotos = detailPhotos?.filter((item) => item);
  const selectedCampaignLabel = isExclusive ? CampaignLabel.isExclusive : CampaignLabel.none
  // const [selectedCampaignLabel, setSelectedCampaignLabel] = useState(
  //   isExclusive ? CampaignLabel.isExclusive : CampaignLabel.none,
  // );
  const radioOptions = getRadioOptions(language);
  // const [campaignCoverPhoto, setCoverPhoto] = useState(defaultCoverPhoto);
  // const [campaignSquareCoverPhoto, setSquareCoverPhoto] = useState(
  //   defaultSquareCoverPhoto,
  // );

  // const [campaignOtherPhotos, setOtherPhotos] = useState(defaultOtherPhotos);

  console.log("@113", campaignCoverPhoto, language)
  const getImageUrl = (data) => {
    if (!data) {
      return '';
    }
    if (data.type !== IMAGE_TYPES.TYPE_URL) {
      return '';
    }
    return data.value;
  };
  // useEffect(() => {
  //   if (onSubmit) {
  //     onSubmitAction({
  //       coverPhoto: getImageUrl(campaignCoverPhoto[0]),
  //       squareCoverPhoto: getImageUrl(campaignSquareCoverPhoto[0]),
  //       detailPhotoOne: getImageUrl(campaignOtherPhotos[0]),
  //       detailPhotoTwo: getImageUrl(campaignOtherPhotos[1]),
  //       detailPhotoThree: getImageUrl(campaignOtherPhotos[2]),
  //       detailPhotoFour: getImageUrl(campaignOtherPhotos[3]),
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [onSubmit]);

  // useEffect(() => {
  //   firstError(
  //     `${CampaignErrorHandleField.coverPhoto.name}-${LanguageConfig.english}`,
  //     errorFields,
  //     language,
  //   );
  //   firstError(
  //     `${CampaignErrorHandleField.squarePhoto.name}-${LanguageConfig.english}`,
  //     errorFields,
  //     language,
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [errorFields]);

  const getDefaultLabel = () => {
    if (isExclusive) {
      return CampaignLabel.isExclusive;
    }
    if (isFeatured) {
      return CampaignLabel.isFeatured;
    }
    return CampaignLabel.none;
  };

  const getRadioText = () => {
    let label = CampaignLabel.none;
    if (isFeatured) {
      label = CampaignLabel.isFeatured;
    }
    if (isExclusive) {
      label = CampaignLabel.isExclusive;
    }
    return radioOptions.filter((item) => item.value === label)[0].label;
  };

  const featuredAndExclusive = () => (
    <div className="create-section-label-bottom-space">
      <CustomTips
        detail={i18n.t('campaign.featured_or_exclusive_campaign_tips', {
          locale: language,
        })}
      >
        <label className="create-section-label featured-exclusive-row-tip-label">
          {i18n.t('campaign.featured_or_exclusive_campaign', {
            locale: language,
          })}
        </label>
      </CustomTips>
      <Row className="featured-exclusive-row">
        {language !== LanguageConfig.english ? (
          getRadioText()
        ) : (
          <Controller 
            control={control}
            name='campaignLabelType'
            render={() => (
              <CustomRadios
                disabled={language !== LanguageConfig.english}
                onChange={(value) => {
                  setValue('campaignLabelType', value, {shouldDirty: true})
                  setValue('isFeatured', CampaignLabel.isFeatured === value, {shouldDirty: true})
                  setValue('isExclusive', CampaignLabel.isExclusive === value, {shouldDirty: true})
                  // setSelectedCampaignLabel(value);
                  // dispatch({
                  //   type: 'createCampaign/updateCampaign',
                  //   payload: {
                  //     isFeatured: CampaignLabel.isFeatured === value,
                  //     isExclusive: CampaignLabel.isExclusive === value,
                  //   },
                  // });
                }}
                default={getDefaultLabel()}
                options={radioOptions}
              />
            )}
          />

        )}
      </Row>
    </div>
  );

  return (
    <>
      <label className="create-section-title">
        {i18n.t('key_visual', { locale: language })}
      </label>
      {language === LanguageConfig.english ? null : (
        <label className="create-campaign-cover-photo-reminder">
          {i18n.t('campaign.cover_photo_reminder', { locale: language })}
        </label>
      )}

      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t('cover_photo', { locale: language })}
      </label>
      <label className="create-campaign-cover-photo-reminder">
        {i18n.t('cover_photo_suggest', { locale: language })}
      </label>
      <ImageUploader
        minWidth={900}
        minHeight={600}
        disabled={language !== LanguageConfig.english && applyCoverPhotoToAllLanguage}
        images={campaignCoverPhoto}
        onImageStateChange={(newState) => {
          const applyLanguages = applyCoverPhotoToAllLanguage
            ? languages
            : [language];
          insertKeyToAllLanguages({
            languages: applyLanguages,
            key: 'coverPhoto',
            value: newState[0],
            setValue,
            getValues,
          });
        }}
        aspect={3 / 2}
        maxImageNum={1}
        uploadImageClicked={() => onFocus('coverPhoto')}
        language={language}
        photoSectionTitle={'cover_photo'}
        errorMessage={errorMessage(
          errors,
          `translations.${language}.coverPhoto`,
        )}
      />
      <span id={`${CampaignErrorHandleField.coverPhoto.name}-${language}`} />
      {/* <ReactHookFormErrorMessage errors={errors} id={`translations.${language}.coverPhoto`} /> */}
      <ApplySwitch
        disabled={
          false
        }
        show={language === LanguageConfig.english}
        checked={applyCoverPhotoToAllLanguage}
        onChange={(value) => {
          console.log("@267", value)
          setValue('applyCoverPhotoToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            console.log("@272")
            insertKeyToAllLanguages({
              languages,
              key: 'coverPhoto',
              value: coverPhoto,
              setValue,
              getValues,
            });
          }
        }}
      />
      {featuredAndExclusive()}
      <Collapse in={selectedCampaignLabel === CampaignLabel.isExclusive}>
        <div>
          <label className="create-section-label create-section-label-bottom-space">
            {i18n.t('campaign.square_cover_photo', { locale: language })}
          </label>
          <label className="create-campaign-cover-photo-reminder">
            {i18n.t('square_cover_photo_suggest', { locale: language })}
          </label>

          <ImageUploader
            images={campaignSquareCoverPhoto}
            disabled={language !== LanguageConfig.english && applySquareCoverPhotoToAllLanguage}
            onImageStateChange={(newState) => {
              setValue(`translations.${language}.squareCoverPhoto`, newState ? newState?.[0] ? newState?.[0] : null : null, {
                shouldDirty: true,
              });
            }}
            aspect={1 / 1}
            maxImageNum={1}
            language={language}
            photoSectionTitle={'square_photo'}
            uploadImageClicked={() => onFocus('squareCoverPhoto')}
            errorMessage={errorMessage(
              errors,
              `translations.${language}.squareCoverPhoto`,
            )}
          />
          <span
            id={`${CampaignErrorHandleField.squarePhoto.name}-${language}`}
          />
          <ApplySwitch
            disabled={false}
            show={language === LanguageConfig.english}
            checked={applySquareCoverPhotoToAllLanguage}
            onChange={(value) => {
              setValue('applySquareCoverPhotoToAllLanguage', value, {
                shouldDirty: true,
              });
              if (value) {
                insertKeyToAllLanguages({
                  languages,
                  key: 'squareCoverPhoto',
                  value: squareCoverPhoto,
                  setValue,
                  getValues,
                });
              }
            }}
          />
        </div>
      </Collapse>

      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t('other_photos', { locale: language })}
      </label>
      <label className="create-campaign-cover-photo-reminder">
        {i18n.t('cover_photo_suggest', { locale: language })}
      </label>
      <ImageUploader
        minWidth={900}
        minHeight={600}
        images={campaignOtherPhotos}
        disabled={language !== LanguageConfig.english && applyDetailPhotosToAllLanguage}
        onImageStateChange={(newState) => {
          const applyLanguages = applyDetailPhotosToAllLanguage
            ? languages
            : [language];
          insertKeyToAllLanguages({
            languages: applyLanguages,
            key: 'detailPhotos',
            value: newState,
            setValue,
            getValues,
          });
        }}
        aspect={3 / 2}
        maxImageNum={4}
        language={language}
        photoSectionTitle={'other_photo'}
        uploadImageClicked={() => onFocus('otherPhotos')}
      />
      <ApplySwitch
        disabled={false}
        show={language === LanguageConfig.english}
        checked={applyDetailPhotosToAllLanguage}
        onChange={(value) => {
          setValue('applyDetailPhotosToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            insertKeyToAllLanguages({
              languages,
              key: 'detailPhotos',
              value: detailPhotos,
              setValue,
              getValues,
            });
          }
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
})

export default connect(mapPropsToState)(KeyVisualSection);
