import React, { useState, useRef, useEffect, createRef } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import './QRCodeScanningSection.scss';
import {
  CustomTitleLabel,
} from './CustomBaseComponments';
import { useFormContext } from 'react-hook-form';
import LimitSection from './LimitSection';
import { EarningRuleDefinitionType } from '../../config/CustomEnums';
import CustomRadios from '../base/CustomRadios';
import { createAction } from '../../utils';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import { ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';

const buttonRef = createRef();

function FillingFormSection({
  earningRule,
  fillingFormList
}) {
  const { watch, formState, setValue } = useFormContext();
  const errors = formState.errors;
  const fieldError = Object.keys(errors || []).length;
  const definitionType = watch('definitionType') || setValue('definitionType', EarningRuleDefinitionType.allFields, { shouldDirty: true })
  const fillingForm = watch('fillingForm')

  const radioOptions = [
    { label: 'Fill in all fields', value: EarningRuleDefinitionType.allFields },
    { label: 'Fill in all mandatory fields', value: EarningRuleDefinitionType.mandatoryFields },
    { label: 'Fill in at least one field', value: EarningRuleDefinitionType.oneField },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createAction('fillingForms/getAllList')());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);


  return (
    <>
      <label className="create-section-title">Filling Form</label>
      <CustomTitleWithDropDown
        title={'Form'}
        defaultValue={{
          value: fillingForm,
          label: fillingForm?.name,
        }}
        setValue={(item) => {
          const value = item?.value || {}
          setValue('fillingForm', value, { shouldDirty: true });
        }}
        source={fillingFormList}
        loadMoreAction={'fillingForms/getAllList'}
        filterAction={'fillingForms/getAllList'}
      />
      <ReactHookFormErrorMessage errors={errors} id="fillingForm" />
      <CustomTitleLabel title="Definition of finish" />
      <div style={{ display: 'flex' }}>
        <CustomRadios
          onChange={(value) => {
            // setRewardType(value);
            setValue('definitionType', value, { shouldDirty: true})
          }}
          default={definitionType}
          options={radioOptions}
        />
      </div>
      <LimitSection
        tips={{
          'overallLimit': 'Maximum number of times to fill form per campaign.',
          'periodicLimit': 'Maximum number of times to fill form per period.',
          'perHeadLimit': 'Maximum number of times to fill form per customer.',
          'perHeadPeriodicLimit': 'Maximum number of times to fill form per customer per period.'
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  earningRule: state.createEarningRules.earningRule,
  fillingFormList: state.fillingForms?.notPagedAllList,
})

export default connect(mapPropsToState)(FillingFormSection);
