import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import {
  SectionTopTitle,
} from '../base/CommonComponent';
import {
  EMAIL_REG,
} from '../../config/CustomEnums';

import { AdminErrorHandleFields } from '../../containers/admin/admins/AdminErrorHandleFields';
import InputFieldControl from '../base/InputFieldControl';


function PersonalInfoSections() {
  const { watch, setValue } = useFormContext();
  const watchEmailAddress = watch('email');
  const watchFirstName = watch('firstName');
  const watchLastName = watch('lastName');
  const firstName = (
    <InputFieldControl
      name={'firstName'}
      title={'First name (optional)'}
      rules={{}}
      value={watchFirstName}
      setValue={(value) => {
        setValue('firstName', value, { shouldDirty: true });
      }}
    />
  );
  const lastName = (
    <InputFieldControl
      name={'lastName'}
      title={'Last name (optional)'}
      rules={{}}
      value={watchLastName}
      setValue={(value) => {
        setValue('lastName', value, { shouldDirty: true });
      }}
    />
  );

  const emailAddress = (
    <InputFieldControl
      name={'email'}
      title={'Email address (optional)'}
      rules={{
              validate: {
                validEmail: (watchEmailAddress) => {
                  if (!watchEmailAddress?.length) {
                    return true;
                  }
                  if (EMAIL_REG.test(watchEmailAddress)) {
                    return true;
                  }
                  return AdminErrorHandleFields.others.email.valid;
                },
              },
            }
      }
      value={watchEmailAddress}
      setValue={(value) => {
        setValue('email', value, { shouldDirty: true });
      }}
    />
  );

  return (
    <>
      <SectionTopTitle title="Personal Information" />
      {firstName}
      {lastName}
      {emailAddress}
    </>
  );
}


export default connect()(PersonalInfoSections);
