import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './ContentSections.scss';
import { Image, Row, Col } from 'react-bootstrap';
import LanguageTabs from './LanguageTab';
import { LanguageConfig } from '../../config/CustomEnums';
import i18n from '../../I18n';
import { useMainContentWidth, getScrollbarWidth } from '../../utils/ScreenUtil';
import { detectIsSafari } from '../../utils';
function ContentSections(props) {
  const mainContentWidth = useMainContentWidth();
  const preViewWidth = mainContentWidth - 80 - 245 - getScrollbarWidth();
  const sections = props.sections;
  const inputWithImageConfig = props.inputWithImageConfig;
  const activeSection = props.activeSection;
  const defaultImage = props.defaultImage;
  const languageTabContent = props.languageTabContent;
  const hidePreview = props.hidePreview || false;
  const language = props.language || LanguageConfig.english;
  const fieldsError = props.fieldsError;
  const sectionStyle = props.sectionStyle || '';
  const contentStyle = props.contentStyle || '';
  const languageTabsStyle = props.languageTabsStyle || '';
  const languageTabStyle = props.languageTabStyle || '';

  const getFormatedSections = (unFormatedSections, sectionWithLanguageTab) =>
    unFormatedSections?.map((section, index) =>
      section ? (
        <div
          key={`${index}-create-step-section`}
          className={`create-step-section-item ${
            sectionWithLanguageTab && index === 0
              ? 'create-step-section-item-first-section'
              : ''
          } ${sectionStyle}`}
        >
          {section}
        </div>
      ) : null,
    );

  const normalSection = getFormatedSections(sections);
  const languageSection = languageTabContent ? (
    <div className="create-section-container">
      <LanguageTabs
        fieldsError={fieldsError}
        containers={languageTabContent.containers?.map((item) => ({
          ...item,
          container: getFormatedSections(item.container, true),
        }))}
        enContainer={getFormatedSections(languageTabContent.enContainer, true)}
        hantContainer={getFormatedSections(
          languageTabContent.hantContainer,
          true,
        )}
        hansContainer={getFormatedSections(
          languageTabContent.hansContainer,
          true,
        )}
        languageTabsStyle={languageTabsStyle}
        languageTabStyle={languageTabStyle}
      />
    </div>
  ) : null;

  const sectionWithPreview = () => {
    const { height = 0, top = 0 } = props.previewImageCoverConfig || {};
    return (
      <>
        <Col className="create-section-container">
          {normalSection}
          {languageSection}
        </Col>
        <Col
          className="create-preview-col-section"
          style={{ marginLeft: preViewWidth <= 200 ? 15 : 30 }}
        >
          <div
            className={`create-preview-section ${
              detectIsSafari() ? 'create-preview-section-safari' : ''
            }`}
          >
            <label className="create-preview-title create-section-title">
              {i18n.t('sample_layout', { locale: language })}
            </label>
            {/* <PerfectScrollbar
              containerRef={(ref) => props.setPreviewImageContainerRef(ref)}
            > */}
              <div className="create-preview-img-container">
                <Image
                  src={
                    inputWithImageConfig[activeSection]
                      ? inputWithImageConfig[activeSection]
                      : defaultImage
                  }
                  className="create-preview-img"
                />
              </div>
              {height > 0 ? (
                <div
                  className="create-preview-cover"
                  style={{
                    top,
                    height,
                  }}
                />
              ) : null}
            {/* </PerfectScrollbar> */}
          </div>
        </Col>
      </>
    );
  };

  return (
    <Row className={`create-section-and-preview-container ${contentStyle}`}>
      {hidePreview ? (
        <div className="content-no-preview">
          {normalSection}
          {languageSection}
        </div>
      ) : (
        sectionWithPreview()
      )}
    </Row>
  );
}

export default ContentSections;
