import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import './CreateCampaignStepOne.scss';
import { Collapse, Image } from 'react-bootstrap';
import ItemTypeSet, { MIN_WIDTH, SPACE_BETWEEN } from '../../base/ItemTypeSet';
import CouponSelectDropdown from './CouponSelectDropdown';
import EarningRulesSelectDropdown from './EarningRulesSelectDropdown';
import CampaignLinkPrompt from './CampaignLinkPrompt';
import { CampaignType, APIStatus } from '../../../config/CustomEnums';
import { OnlyContinueButton } from '../../base/BottomStepComponent';
import { createAction } from '../../../utils';
import { useHistory } from 'react-router-dom';
import {
  getCampaignTypeSelectPromptConfig,
  CampaignTypesConfig,
} from './CreateCampaignStepOneConfigs';
import ContentSections from '../../base/ContentSections';
import Loading from '../../base/Loading';
import {
  getScrollbarWidth,
  useMainContentWidth,
} from '../../../utils/ScreenUtil';
import { ContinueCreate } from '../CampaignUrlConfig';
import BasePrompt from '../../base/prompt/BasePrompt';
import { PermissionCodes } from '../../../config/PermissionCodes';
import addImage from '../../../assets/images/addx1.png';
import { 
  isShowEarningRuleRelated, 
  isShowCouponRelated, 
  fieldTransfer,
} from '../../../models/CreateCampaignModel';
import { Controller, useFormContext } from 'react-hook-form';
import {
  ReactHookFormErrorMessage,
  hasError,
  errorMessage,
} from '../../../components/base/ErrorFieldMessage';
import { validate } from '../../../containers/engagement/campaign/CreateCampaignValidate';
import queryString from 'query-string';

function CreateCampaignStepOne({
  status,
  promptShowed,
  isPublished,
  linkedCoupon,
}) {
  const mainContentWidth = useMainContentWidth();
  const fullContainerWith = mainContentWidth - 80 - 60 - getScrollbarWidth();

  const [showCreateNewLinkPrompt, setShowCreateNewLinkPrompt] = useState(false);
  const [showLinkPrompt, setShowLinkPrompt] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    getValues,
    watch,
    setValue,
    control,
    formState,
    clearErrors,
    setError,
  } = useFormContext();
  const { errors } = formState;

  const watchCampaignType = watch('campaignType');
  const watchCoupon = watch('linkedCoupon') || {};
  const watchEarningRules = watch('linkedEarningRules')
  const isCouponRelated = isShowCouponRelated(watchCampaignType);
  const isEarningRuleRelated = isShowEarningRuleRelated(watchCampaignType);

  const selectRef = useRef('linkDropdown');
  // const [createNewLinkTitle, setCreateNewLinkTitle] = useState();

  const promptconfig = getCampaignTypeSelectPromptConfig(watchCampaignType);
  const search = queryString.parse(history.location.search);

  useEffect(() => {
    if (history.location.search && !watchCampaignType) {
      const type = search.type;
      const id = search.id;
      const actionName =
        type === CampaignType.couponsCampaign
          ? 'getAndLinkCouponDetail'
          : 'getAndLinkEarningRuleDetail';
      console.log("@90")
      dispatch(
        createAction(`createCampaign/${actionName}`)({
          couponId: id,
          earningRuleId: id,
          afterAction: (data) => {
            console.log("@93", data)
            setValue('campaignType', type)
            data.linkedCoupon ?
              setValue('linkedCoupon', data.linkedCoupon)
            : setValue('linkedEarningRules', data.linkedEarningRules)
            goToNextStep(true);
          }
        }),
      );
    } else if(watchCoupon) {
      dispatch(
        createAction(`createCampaign/getAndLinkCouponDetail`)({
          couponId: watchCoupon.pk,
          afterAction: (data) => {
            if(data.linkedCoupon?.stock !== watchCoupon.stock) {
              setValue('linkedCoupon', data.linkedCoupon)
            }
          }
        }),
      );
    }
  })

  // const setCoupon = (value) => {
  //   dispatch(
  //     createAction('createCampaign/getAndLinkCouponDetail')({
  //       couponId: value?.pk,
  //       name: value.name,
  //     }),
  //   );
  // };
  // useEffect(() => {
  //   if (watchCoupon?.pk) {
  //     dispatch(
  //       createAction('createCampaign/getAndLinkCouponDetail')({
  //         couponId: watchCoupon.pk,
  //         couponGetted: (coupon) => setValue('linkedCoupon', coupon),
  //       }),
  //     );
  //   }
  // }, [watchCoupon?.pk]);

  const couponLinkSet = (
    <Controller
      control={control}
      name="linkedCoupon"
      render={() => (
        <>
          <div className="step-link-coupon-area-select">
            <CouponSelectDropdown
            title={'Link to coupon set'}
            defaultValue={{
              value: watchCoupon,
              label: watchCoupon?.name,
            }}
            setValue={(item) => {
              console.log('couponChange:', item);
              const coupon = item || {};
              setValue('linkedCoupon', coupon, { shouldDirty: true });
            }}
            // addButtonAction={() => setShowCreateNewLinkPrompt(true)}
            addButtonInfo={{
              requires: PermissionCodes.changeCouponTemplate,
              children: <Image src={addImage} />,
              action: () => setShowCreateNewLinkPrompt(true),
              customClass: 'earning-rule-add-new-link-button',
            }}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id="linkedCoupon" />
        </>
      )}
    />
  );

  const earningRuleLinkSet = (
    <Controller
      control={control}
      name="linkedEarningRules"
      render={() => (
        <>
          <div className="step-link-coupon-area-select">
            <EarningRulesSelectDropdown
            title={'Link to earning rule set'}
            defaultValue={{
              value: watchEarningRules,
              label: watchEarningRules?.name,
            }}
            setValue={(item) => {
              console.log('earning rule Change:', item);
              const coupon = item || {};
              setValue('linkedEarningRules', coupon, { shouldDirty: true });
            }}
            // addButtonAction={() => setShowCreateNewLinkPrompt(true)}
            addButtonInfo={{
              requires: PermissionCodes.changeEarningRule,
              children: <Image src={addImage} />,
              action: () => setShowCreateNewLinkPrompt(true),
              customClass: 'earning-rule-add-new-link-button',
            }}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id="linkedEarningRules" />
        </>
      )}
    />
  );

  // const linkSet = () => {
  //   const linkFiledShow =
  //     !campaignType || campaignType === CampaignType.generalMessageCampaign;
  //   return (
  //     <Collapse in={!linkFiledShow}>
  //       <div className="step-link-coupon-area-select" ref={selectRef}>
  //         {campaignType === CampaignType.couponsCampaign ? (
  //           <CouponSelectDropdown
  //             title={'Link to coupon set'}
  //             defaultValue={{
  //               value: linkedCoupon,
  //               label: linkedCoupon?.name,
  //             }}
  //             setValue={setCoupon}
  //             // addButtonAction={() => setShowCreateNewLinkPrompt(true)}
  //             addButtonInfo={{
  //               requires: PermissionCodes.changeCouponTemplate,
  //               children: <Image src={addImage} />,
  //               action: () => setShowCreateNewLinkPrompt(true),
  //               customClass: 'earning-rule-add-new-link-button',
  //             }}
  //           />
  //         ) : (
  //           <EarningRulesSelectDropdown
  //             title={'Link to earning rule set'}
  //             defaultValue={{
  //               value: linkedEarningRules,
  //               label: linkedEarningRules?.name,
  //             }}
  //             // addButtonAction={() => setShowCreateNewLinkPrompt(true)}
  //             addButtonInfo={{
  //               requires: PermissionCodes.changeEarningRule,
  //               children: <Image src={addImage} />,
  //               action: () => setShowCreateNewLinkPrompt(true),
  //               customClass: 'earning-rule-add-new-link-button',
  //             }}
  //           />
  //         )}
  //       </div>
  //     </Collapse>
  //   );
  // };

  const showPrompt = () => {
    if (promptShowed) {
      return;
    }
    if (!showLinkPrompt) {
      return;
    }
    return (
      <CampaignLinkPrompt
        handleContinue={() => {
          dispatch({
            type: 'createCampaign/updateState',
            payload: { promptShowed: true },
          });
          dispatch({ type: 'createCampaign/stepChange', payload: { step: 0,  isValid: true} });
        }}
        closePrompt={() => {
          dispatch({
            type: 'createCampaign/updateState',
            payload: { promptShowed: true },
          });
          if (!watchCoupon && !watchEarningRules) {
            window.scrollTo(0, selectRef.current.offsetTop);
          }
        }}
        campaignType={watchCampaignType}
        linkedEarningRules={watchEarningRules}
        linkedCoupon={watchCoupon}
      />
    );
  };

  // const campaignTypeSelectArea = () => {
  //   return (
  //     <div
  //       className="step-type-area campaign-step-one-bottom"
  //       style={{
  //         justifyContent:
  //           fullContainerWith < (MIN_WIDTH + SPACE_BETWEEN) * 2
  //             ? 'center'
  //             : 'flex-start',
  //       }}
  //     >
  //       {CampaignTypesConfig.map((typeItem) => (
  //         <ItemTypeSet
  //           key={`${typeItem.id}-${typeItem.description}`}
  //           item={typeItem}
  //           selected={campaignType}
  //           onClick={(id) => {
  //             dispatch({
  //               type: 'createCampaign/typeChange',
  //               payload: { campaignType: id },
  //             });
  //             if (id !== CampaignType.generalMessageCampaign) {
  //               if (!promptShowed) {
  //                 setShowLinkPrompt(true);
  //               }
  //             }
  //           }}
  //         />
  //       ))}
  //     </div>
  //   );
  // };

  const campaignTypeSelectArea = (
    <Controller
      control={control}
      name="campaignType"
      render={() => (
      <div
        className="step-type-area campaign-step-one-bottom"
        style={{
          justifyContent:
            fullContainerWith < (MIN_WIDTH + SPACE_BETWEEN) * 2
              ? 'center'
              : 'flex-start',
        }}
      >
        {CampaignTypesConfig.map((typeItem) => (
          <ItemTypeSet
            key={`${typeItem.id}-${typeItem.description}`}
            item={typeItem}
            selected={watchCampaignType}
            disable={isPublished}
            onClick={(id) => {
              if (id === watchCampaignType) {
                return;
              }
              clearErrors();
              setValue('campaignType', id, { shouldDirty: true });
              dispatch({
                type: 'createCampaign/typeChange',
                payload: { campaignType: id },
              });
              if (id !== CampaignType.generalMessageCampaign) {
                if (!promptShowed) {
                  setShowLinkPrompt(true);
                }
              }
            }}
          />
        ))}
        </div>
      )}
    />
  )

  const sections = [
    <>
      {status === APIStatus.calling ? (
        <Loading />
      ) : (
        <div>
          <label className="step-one-title">Campaign Type</label>
          {campaignTypeSelectArea}
          {isCouponRelated ? couponLinkSet : null}
          {isEarningRuleRelated ? earningRuleLinkSet : null}
          {showPrompt()}
          <BasePrompt
            show={showCreateNewLinkPrompt}
            closeAction={() => setShowCreateNewLinkPrompt(false)}
            rightButton={{
              text: promptconfig.button,
              action: () => {
                const pathname =
                  watchCampaignType === CampaignType.couponsCampaign
                    ? '/coupons/create'
                    : '/earns/create';
                history.push({
                  pathname: pathname,
                  state: { from:
                    {...history.location,
                      state: {
                        ...history.location?.state,
                        resume: true
                      }}, ...ContinueCreate },
                });
              },
            }}
            title={promptconfig.title}
            description={promptconfig.message}
          />
        </div>
      )}
    </>,
  ];

  const continueAction = () =>
    dispatch({ type: 'createCampaign/stepChange', payload: { step: 0 } });
  
  const goToNextStep = (assignRemain) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      isBack: false,
    });
    console.log('isValid:', isValid, errors);
    setValue('assignRemain', assignRemain);
    console.log("@357 values", getValues)
    // if (isCouponRelated || isEarningRuleRelated) {
    //   fieldTransfer({ setValue, getValues });
    // }
    dispatch({
      type: 'createCampaign/stepChange',
      payload: {
        step: 0,
        isValid,
      },
    });
  };

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <OnlyContinueButton
        continueAction={async () => {
          goToNextStep(true);
        }}
        disabledContinue={
          !watchCampaignType ||
          (watchCampaignType === CampaignType.couponsCampaign &&
            !watchCoupon?.pk) ||
          (watchCampaignType === CampaignType.earningRulesCampaign &&
            !watchEarningRules?.pk)
        }
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  campaignType: state.createCampaign.campaign.campaignType,
  linkedCoupon: state.createCampaign.campaign.linkedCoupon,
  linkedEarningRules: state.createCampaign.campaign.linkedEarningRules,
  status: state.createCampaign.createStatus,
  promptShowed: state.createCampaign.promptShowed,
  isPublished: state.createCampaign.campaign.isPublished,
})

export default connect(mapPropsToState)(CreateCampaignStepOne);
