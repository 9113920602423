import React, { useEffect, useState } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { useSelector, useDispatch } from 'react-redux';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';

export default function CustomerGroupList() {
  const queryString = require('query-string');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const {
    pageInfo,
    totalCount,
    totalPage,
    displayFields,
    groupList,
    checkedList,
  } = useSelector((state) => ({
    pageInfo: state.customerGroup.pageInfo,
    totalCount: state.customerGroup.totalCount,
    totalPage: state.customerGroup.totalPage,
    displayFields: state.customerGroup.listDisplayFields,
    groupList: state.customerGroup.currentPageGroupList,
    checkedList: state.customerGroup.checkedList,
  }));

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const search = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch(
      createAction('customerGroup/getCurrentPageList')({
        search,
        rank,
        page,
        moreSearch: { ...searchDict },
      }),
    );
  }, [dispatch, location, queryString]);

  const buttons = [
    <AuthButton
      title="Create Customer Group"
      action={() => history.push('/customer_group/create')}
      requires={PermissionCodes.addCustomerGroup}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => setShowDeletePrompt(true),
      requires: PermissionCodes.deleteCustomerGroup,
    },
  ];
  const tabs = [
    {
      name: 'List of customer groups',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          listContent={
            <BaseListContainer
              fields={displayFields}
              model={'customerGroup'}
              permissionGroup={PermissionCodes.customergroup}
              dataList={groupList}
              totalPage={totalPage ? totalPage : 0}
              deleteInfo={{
                data: [],
                title: 'customer group',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.CUSTOMER_GROUP,
              }}
              actions={['Detail', 'Edit', 'Delete', 'Duplicate']}
            />
          }
        />
      ),
    },
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'Create various customer groups and target them with different experiences.',
          title: 'Customer groups',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={false}
        tabs={tabs}
      />
      <DeletePrompt
        title={'customer group'}
        data={checkedList}
        relatedSections={DELETE_RELATED_SECTIONS.CUSTOMER_GROUP}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('customerGroup/delete')({
              afterAction: () => {
                history.push('/customer_group');
              },
            }),
          );
        }}
      />
    </>
  );
}
