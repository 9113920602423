import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { EarningRuleType } from '../../../config/CustomEnums';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackWithOutTempButtons } from '../../../components/base/BottomStepComponent';
import RewardSection from '../../../components/earning/RewardSection';
import GeneralPurchaseSection from '../../../components/earning/GeneralPurchaseSection';
import GPSCheckInSection from '../../../components/earning/GPSCheckInSection';
import ReferralSection from '../../../components/earning/ReferralSection';
import BirthdaySection from '../../../components/earning/BirthdaySection';
import QRCodeScanningSection from '../../../components/earning/QRCodeScanningSection';
import { createAction, getObjectFromSessionStorage } from '../../../utils';
import { validate } from './CreateEarningRuleValidate';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import FillingFormSection from '../../../components/earning/FillingFormSection';

const dataArray = [];

function CreateEarningruleStepThree({
  earningRule
}) {
  const { getValues, watch, formState, clearErrors, setError } =
    useFormContext();
  const errors = formState.errors;
  const fieldError = Object.keys(errors || []).length;
  const [earningRuleType] = getValues(['type']);
  const history = useHistory();

  const dispatch = useDispatch();

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 2,
      isBack,
    });
    if (isBack) {
      dispatch({
        type: 'createEarningRules/stepChange',
        payload: { isBack: isBack, step: 2, isValid },
      });
    } else if (isValid) {
      dispatch(
        createAction('createEarningRules/createOrUpdateEarningRule')({
          isValid: isValid,
          data: getValues(),
          afterAction: earningRule.id ? () => history.push({ pathname: '/earns' }) : null
        }),
      );
    }
    
  };

  const earningRuleProperties = () => {
    let properties;
    switch (earningRuleType) {
      case EarningRuleType.generalPurchase:
        properties = (
          <GeneralPurchaseSection/>
        );
        break;
      case EarningRuleType.gpsCheckIn:
        properties = (
          <GPSCheckInSection/>
        );
        break;
      case EarningRuleType.memberReferral:
        properties = (
          <ReferralSection/>
        );
        break;
      case EarningRuleType.birthday:
        properties = (
          <BirthdaySection/>
        );
        break;
      case EarningRuleType.qrCodeScanning:
        properties = (
          <QRCodeScanningSection/>
        );
        break;
      case EarningRuleType.fillingForm:
        properties = (
          <FillingFormSection/>
        );
        break;
      default:
        properties = null;
        break;
    }
    return properties;
  };

  // const earningRulePropertie = earningRuleProperties();
  const sections = [
    earningRuleProperties(),
    <RewardSection/>,
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <SaveAndBackWithOutTempButtons
        saveText={'Save'}
        saveAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  earningRule: state.createEarningRules.earningRule
})

export default connect(mapPropsToState)(CreateEarningruleStepThree);
