import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import i18n from '../../I18n';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import './MessageGeneralSection.scss';
import { ReactHookFormImageUploader } from '../base/ImageUploader';
import CustomEditor from '../base/CustomEditor';
import CustomTitleWithInput from '../../components/base/CustomTitleWithInput';
import { hasError, errorMessage, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';


function MessageGeneralSection({
  language
}) {

  const { watch, setValue, getValues, onFocus, formState, clearErrors } = useFormContext();
  const { errors } = formState;

  const messageTitleField = `translations.${language}.name`;
  const messageContentField = `translations.${language}.content`;
  const messagePhotoField = `translations.${language}.photo`;

  const name = watch(messageTitleField);
  const content = watch(messageContentField);
  const photo = watch(messagePhotoField);

  return (
    <>
      <label className="create-section-title">
        {i18n.t('general', { locale: language })}
      </label>
      <CustomTitleLabel
        title={i18n.t('title(optional)', { locale: language })}
      />

      <CustomTitleWithInput
        title={i18n.t('use_in_inbox_push', { locale: language })}
        type={'text'}
        customClass={` text-input-field `}
        defaultValue={name}
        setValue={(value) => {
          setValue(messageTitleField, value, { shouldDirty: true });
        }}
        error={{ error: hasError(errors, messageTitleField) }}
      />
      <ReactHookFormErrorMessage errors={errors} id={messageTitleField} />

      <CustomTitleLabel title={i18n.t('content', { locale: language })} />
      <label className="create-message-suggest">
        {i18n.t('use_in_inbox_push', { locale: language })}
      </label>
      <CustomEditor
        initialValue={content}
        onValueChange={(value) => {
          console.log('@@75');
          setValue(messageContentField, value, { shouldDirty: true });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id={messageContentField} />

      <ReactHookFormImageUploader
        title={i18n.t('image(optional)', { locale: language })}
        uploadSuggest={i18n.t('message_photo_suggest', { locale: language })}
        name={messagePhotoField}
        minWidth={560}
        minHieght={420}
        value={photo}
        aspect={4 / 3}
        maxImageNum={1}
        language={language}
        uploadImageClicked={() => {}}
      />
    </>
  );
}

export default MessageGeneralSection;
