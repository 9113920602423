export const ToastType = {
  save: 'Saved successfully.',
  discard: 'Changes discarded.',
  couponGenerate: 'Single Coupon generated',
  none: 'none',
  passwordConfirmation: `The two password fields didn't match.`,
  serverError: 'Server error, please refresh or try again later',
  networkError:
    'The network broke down, please refreshing the page or try again later.',
};

export default {
  namespace: 'navBars',
  state: {
    isOnCreateMode: true,
    saveDiscardToastShowing: {
      value: false,
      type: ToastType.none,
    },
  },

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    initState(state, { payload }) {
      return { ...state, ...payload };
    },
    clearState(state, { payload }) {
      return {
        isOnCreateMode: false,
      };
    },
  },
};
