import { EarningRuleErrorHandleField } from '../../../components/earning/EarningRuleHandleError';
import { isValidHttpUrl, reducer } from '../../../utils';
import { EarningRuleRewardType, EarningRuleType } from '../../../config/CustomEnums';

// if (!isBack) {
//   const requiredFieldsConfig = [
//     [],
//     ['generalName'],
//     {
//       general_purchase: ['minSpending', 'maxSpending'],
//       gps_check_in: ['latitude', 'longitude', 'radius'],
//       birthday: ['birthStart', 'birthEnd'],
//       reward: ['rewardType', 'coupons', 'quantity', 'rewardTypeX'],
//       new_member: [],
//       member_referral: [],
//       qr_code_scanning: ['qrCodes'],
//     },
//   ];

//   const requiredFields =
//     currentStep === 2
//       ? [
//           ...requiredFieldsConfig[2][data.earningRuleType.toLowerCase()],
//           ...requiredFieldsConfig[currentStep]['reward'],
//         ]
//       : requiredFieldsConfig[currentStep];

//   requiredFields.forEach((field, index) => {
//     if (field === 'maxSpending') {
//       if (
//         data[field] &&
//         parseInt(data[field]) <= parseInt(data['minSpending'])
//       ) {
//         errorFields.push(EarningRuleErrorHandleField[field].name);
//         return;
//       }
//       return;
//     }

//     if (
//       field === 'coupons' &&
//       data['rewardType'] !== EarningRuleRewardType.coupons
//     ) {
//       return;
//     }

//     if (
//       field === 'rewardTypeX' &&
//       data['type'] !== EarningRuleType.generalPurchase
//     ) {
//       return;
//     }

//     if (
//       field === 'rewardTypeX' &&
//       data['rewardType'] !== EarningRuleRewardType.points &&
//       data['type'] === EarningRuleType.generalPurchase
//     ) {
//       return;
//     }

//     const value = currentStep === 1 ? data[LanguageConfig.english] : data;

//     if (!value[field] || value[field].length <= 0) {
//       errorFields.push(EarningRuleErrorHandleField[field].name);
//       return;
//     }

//     if (['latitude', 'longitude'].includes(field)) {
//       const dataField = data[field] + '';
//       if (dataField.split('.')[0].length > 3) {
//         errorFields.push(
//           EarningRuleErrorHandleField[`${field}Limit`].name,
//         );
//         return;
//       }
//     }
//   });

//   checked =
//     errorFields.length <= 0
//       ? CheckStatus.checkedWithSuccess
//       : CheckStatus.checkedWithFail;
// }
function firstStepValidate({ getValues, setError }) {
  return true;
}

function secondStepValidate({ getValues, setError }) {
  const [generalName] = getValues(['generalName'])
  const errorList = [];
  if (!generalName || generalName.length > 200) {
    errorList.push({
      name: 'generalName',
      message: EarningRuleErrorHandleField.generalName.message,
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

const isEmpty = (value) => {
  return value === undefined || value === null || value === '' || isNaN(value);
}

function thirdStepValidate({ getValues, setError }) {
  console.log("@122")
  const [
    type,
    rewardType,
    // coupons,
    // quantity,
    // rewardTypeX,
    // qrCodes,
    // generalPurchaseTypeMinimumSpending,
    // generalPurchaseTypeMaximumSpending,
    // gpsCheckInTypeLatitude,
    // gpsCheckInTypeLongitude,
    // gpsCheckInTypeRadiusInMeter,
    // birthdayTypePeriodStartDate,
    // birthdayTypePeriodEndDate,
  ] = getValues([
    'type',
    'rewardType',
    // 'coupons',
    // 'quantity',
    // 'rewardTypeX',
    // 'qrCodes',
    // 'generalPurchaseTypeMinimumSpending',
    // 'generalPurchaseTypeMaximumSpending',
    // 'gpsCheckInTypeLatitude',
    // 'gpsCheckInTypeLongitude',
    // 'gpsCheckInTypeRadiusInMeter',
    // 'birthdayTypePeriodStartDate',
    // 'birthdayTypePeriodEndDate',
  ])
  const requiredFieldsConfig = {
    general_purchase: ['minSpending', 'maxSpending'],
    gps_check_in: ['latitude', 'longitude', 'radius'],
    birthday: ['birthStart', 'birthEnd'],
    reward: ['rewardType', 'coupons', 'quantity', 'rewardTypeX'],
    new_member: [],
    member_referral: [],
    qr_code_scanning: ['qrCodes'],
    filling_form: ['definitionType', 'fillingForm'],
  }
  const requiredFields = [
    ...requiredFieldsConfig[type.toLowerCase()],
    ...requiredFieldsConfig['reward'],
  ]
  const errorList = []
  console.log("@155", requiredFields)
  requiredFields.forEach((field, index) => {
    console.log("@156", field)
    if (field === 'maxSpending') {
      if (
        getValues(field) &&
        parseInt(getValues(field)) <= parseInt(getValues('minSpending'))
      ) {
        errorList.push({
          name: 'maxSpending',
          message: EarningRuleErrorHandleField[field].message,
        });
        return;
      }
      return;
    }

    if (
      field === 'coupons' &&
      rewardType !== EarningRuleRewardType.coupons
    ) {
      return;
    }

    if (
      field === 'rewardTypeX' &&
      rewardType !== EarningRuleType.generalPurchase
    ) {
      return;
    }

    if (
      field === 'rewardTypeX' &&
      rewardType !== EarningRuleRewardType.points &&
      type === EarningRuleType.generalPurchase
    ) {
      return;
    }
    if (!getValues(field) || getValues(field).length <= 0 || (field === 'quantity' && getValues(field) <= 0) 
      || (field === 'coupons' && !getValues(field).name)) {
      errorList.push({
        name: field,
        message: EarningRuleErrorHandleField[field].message,
      });
      return;
    }

    if (['latitude', 'longitude'].includes(field)) {
      const dataField = getValues(field) + '';
      if (dataField.split('.')[0].length > 3) {
        errorList.push({
          name: field,
          message: EarningRuleErrorHandleField[field].message,
        });
        // errorFields.push(
        //   EarningRuleErrorHandleField[`${field}Limit`].name,
        // );
        return;
      }
    }
  })
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  console.log('@219', errorList)
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors, step, isBack }) {
  console.log('@71 earningValidate:', getValues(), step);
  if (isBack) {
    return true;
  }
  clearErrors();
  switch (step) {
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
    case 1:
      const validStepTwo = secondStepValidate({ getValues, setError });
      return validStepTwo;
    case 2:
      const validStepThree = thirdStepValidate({ getValues, setError });
      return validStepThree;
    default:
      const validOne = firstStepValidate({ getValues, setError });
      const validTwo = secondStepValidate({ getValues, setError });
      const validThree = thirdStepValidate({ getValues, setError });
      return validOne && validTwo && validThree;
  }
}
