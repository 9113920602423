import React, { useState, useEffect } from 'react';
import './CreateCampaignStepFour.scss';
import { useDispatch, useSelector, connect } from 'react-redux';
import ContentSections from '../../base/ContentSections';
import ProfileSection from './ProfileSection';
import ContentSection from './ContentSection';
import PublishCampaignPrompt from './PublishCampaignPrompt';
import { SaveAndBackButtons } from '../../base/BottomStepComponent';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { createAction } from '../../../utils';
import { APIStatus } from '../../../config/CustomEnums';
import Loading from '../../base/Loading';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { validate } from '../../../containers/engagement/campaign/CreateCampaignValidate';
import { useFormContext } from 'react-hook-form';
const ActionType = {
  save: 'save',
  publish: 'publish',
  none: 'none',
};

function CreateCampaignStepFour({
  createStatus,
  isPublished,
  campaignPublish
}) {
  const params = useParams();
  const { getValues, clearErrors, setError } = useFormContext();
  const location = useLocation();

  const [showPublishPrompt, setShowPublishPrompt] = useState(false);
  const [actionType, setActionType] = useState(ActionType.none);
  const dispatch = useDispatch();
  const history = useHistory();
  const sections = [<ProfileSection />];
  // const { createStatus, isPublished } = useSelector((state) => ({
  //   createStatus: state.createCampaign.createStatus,
  //   isPublished: state.createCampaign.campaign.isPublished,
  // }));

  useEffect(() => {
    if (createStatus === APIStatus.success) {
      setShowPublishPrompt(
        actionType === ActionType.save ||
          history.location.pathname.includes('edit'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 3,
      isBack,
    });
    dispatch({
      type: 'createCampaign/stepChange',
      payload: { isBack: isBack, step: 3, isValid },
    });
  };

  return (
    <>
      {createStatus !== APIStatus.calling ? (
        <>
          <ContentSection />
          <ContentSections sections={sections} hidePreview={true} />
        </>
      ) : (
        <ContentSections sections={[<Loading />]} hidePreview={true} />
      )}

      <SaveAndBackButtons
        saveTempText={params.id ? 'Update' : 'Publish Later'}
        saveText={isPublished ? 'UnPublished' : 'Publish'}
        backAction={() => stepChangeAction(true)}
        saveAction={() => {
          setActionType(ActionType.publish);
          dispatch(
            createAction('createCampaign/createOrUpdateCampaign')({
              data: getValues(),
              isPublished: !isPublished,
              resume: location.state?.resume
            }),
          );
        }}
        saveTempAction={() => {
          setActionType(ActionType.save);
          dispatch(
            createAction('createCampaign/createOrUpdateCampaign')({
              data: getValues(),
              resume: location.state?.resume
            }),
          );
        }}
        saveRequires={
          isPublished
            ? PermissionCodes.unPublishCampaign
            : PermissionCodes.publishCampaign
        }
      />

      <PublishCampaignPrompt
        isSave={actionType === ActionType.save}
        isPublish={!isPublished}
        show={showPublishPrompt}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  createStatus: state.createCampaign.createStatus,
  isPublished: state.createCampaign.campaign.isPublished,
  campaignPublish: state.createCampaign.campaignPublish,
})

export default connect(mapPropsToState)(CreateCampaignStepFour);
