import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import ContentSections from '../base/ContentSections';
import { OnlyContinueButton } from '../base/BottomStepComponent';
import { APIStatus, MessageChannel } from '../../config/CustomEnums';
import Loading from '../base/Loading';
import { validate } from '../../containers/engagement/message/CreateMessageValidate';
import { MessageChannelConfig } from './CreateMessageConfig';
import MessageChannelCard from './MessageChannelCard';
import CampaignSelectDropdown from './CampaignSelectDropdown';
import { hasError, errorMessage, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';

function CreateMessageStepOne({
  status,
  message,
}) {
  const dispatch = useDispatch();

  const { watch, setValue, getValues, setError, control, formState, clearErrors } = useFormContext();
  const { errors } = formState;

  const channelsField = 'channels';
  const relatedCampaignField = 'relatedCampaign';
  const channels = watch(channelsField);
  const relatedCampaign = watch(relatedCampaignField);

  const stepChangeAction = () => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      isBack: false,
    });
    dispatch({
      type: 'createMessage/stepChange',
      payload: {
        isBack: false,
        step: 0,
        isValid
      },
    });
  };

  if (message.pk) {
    stepChangeAction();
  }

  const sections = [
    <>
      {status === APIStatus.calling ? (
        <Loading />
      ) : (
        <div>
          <label className="step-one-title">Message channel</label>
          <div className="step-type-area message-channel-area">
            {MessageChannelConfig.map((channel) => (
              <MessageChannelCard
                key={channel.channel}
                channelConfig={channel}
                selectedChannels={channels}
                select={(channel) => {
                  let isSelectInbox = channels.indexOf(MessageChannel.inbox) !== -1;
                  let isSelectPush = channels.indexOf(MessageChannel.push) !== -1;
                  if (channel === MessageChannel.inbox) {
                    isSelectInbox = !isSelectInbox;
                  }
                  if (channel === MessageChannel.push) {
                    isSelectPush = !isSelectPush;
                    if (isSelectPush) {
                      isSelectInbox = true;
                    }
                  }
                  let selectedChannels = [];
                  if (isSelectInbox) {
                    selectedChannels.push(MessageChannel.inbox);
                  }
                  if (isSelectPush) {
                    selectedChannels.push(MessageChannel.push);
                  }
                  setValue(channelsField, selectedChannels, { shouldDirty: true });
                }}
              />
            ))}
          </div>
          <CampaignSelectDropdown
            title={'Related campaign (optional)'}
            isPrompt
            defaultValue={{ label: relatedCampaign?.name, value: relatedCampaign }}
            setValue={(value) => {
              setValue(relatedCampaignField, value, { shouldDirty: true });
            }}
          />
          <ReactHookFormErrorMessage errors={errors} id={relatedCampaignField} />
        </div>
      )}
    </>,
  ];

  return (
    <>
      <ContentSections
        sections={sections}
        hidePreview={true}
      />
      <OnlyContinueButton
        disabledContinue={channels.length === 0}
        continueAction={() => {
          stepChangeAction();
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  status: state.createMessage.createStatus,
  message: state.createMessage.message,
});

export default connect(mapPropsToState)(CreateMessageStepOne);
