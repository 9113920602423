import React from 'react';
import './NewCustomerCard.scss';
import { withResizeDetector } from 'react-resize-detector';
import { useSelector } from 'react-redux';
import NewCustomersChart from '../dashboard/NewCustomersChart';
const NewCustomerCard = ({ width }) => {
  const customerOverviewData = useSelector((state) => state.overview.customers);
  const newCustomersGrow =
    (customerOverviewData?.newCustomers?.change || 0) >= 0;
  return (
    <div className="overview-customer-card">
      <div className="overview-customer-card-title">New Customers</div>
      <NewCustomersChart
        data={customerOverviewData?.chartData}
        width={width - 75}
        height={250}
        dataGrow={newCustomersGrow}
      />
    </div>
  );
};

export default withResizeDetector(NewCustomerCard);
