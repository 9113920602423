import { LanguageConfig } from '../../../config/CustomEnums';


export const CreateMessageError = {
  content: {
    name: `translations.${LanguageConfig.english}.content`,
    message: 'Please provide a content.',
  },
  scheduledDate: {
    name: 'scheduledDate',
    message: 'Please provide a valid schedule time.',
  },
  channels: {
    name: 'channels',
    message: 'Please provide channels.',
  },
};

function firstStepValidate({ getValues, setError }) {
  const [
    channels,
  ] =  getValues([
    'channels',
  ]);
  const errorList = [];
  if (channels == null || channels.length === 0) {
    errorList.push({
      name: CreateMessageError.channels.name,
      message: CreateMessageError.channels.message,
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  console.log('errorListMessage:', errorList);
  return !errorList.length;
}

function secondStepValidate({ getValues, setError }) {
  const errorList = [];
  const [content] = getValues([`translations.${LanguageConfig.english}.content`]);
  if (!content) {
    errorList.push({
      name: CreateMessageError.content.name,
      message: CreateMessageError.content.message,
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  console.log('errorListMessage:', errorList);
  return !errorList.length;
}

function thirdStepValidate({ getValues, setError }) {
  const [scheduledDate] = getValues(['scheduledDate']);
  const errorList = [];
  if (!scheduledDate || new Date(scheduledDate) < new Date()) {
    errorList.push({
      name: CreateMessageError.scheduledDate.name,
      message: CreateMessageError.scheduledDate.message,
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors, step, isBack, languages }) {
  if (isBack) {
    return true;
  }
  clearErrors();
  switch (step) {
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
    case 1:
      const validStepTwo = secondStepValidate({ getValues, setError });
      return validStepTwo;
    case 2:
      const validStepThree = thirdStepValidate({ getValues, setError });
      return validStepThree;
    default:
      const validOne = firstStepValidate({ getValues, setError });
      const validTwo = secondStepValidate({ getValues, setError });
      const validThree = thirdStepValidate({ getValues, setError });
      return validOne && validTwo && validThree;
  }
}
