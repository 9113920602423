import axios from 'axios';
import Cookies from 'cookies-js';
import { authAPIHelper } from './AuthApiHelper';
import { TokenErrors } from '../config/CustomEnums';
const CMS_DOMAIN =
  process.env.REACT_APP_DOMAIN || 'https://aillia.hkdev.motherapp.com:443';

export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

const defaultHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const baseAPIinstance = axios.create({
  baseURL: CMS_DOMAIN,
  timeout: 15000,
  headers: defaultHeader,
});

export const addAuthorization = (token) => {
  Cookies.set(ACCESS_TOKEN, token || '');
  if (token) {
    baseAPIinstance.defaults.headers.common.Authorization = `JWT ${token}`;
    return;
  }
  delete baseAPIinstance.defaults.headers.common.Authorization;
};

export const removeAuthorization = () => {
  delete baseAPIinstance.defaults.headers.common.Authorization;
  Cookies.set(ACCESS_TOKEN, '');
  Cookies.set(REFRESH_TOKEN, '');
};

const shouldRefresh = (response) => {
  if (!response) {
    return false;
  }
  if (response.status === 403) {
    return true;
  }
  const errors = response.data.errors || [];
  console.log('errors[0].message:', errors[0]?.message);
  if (
    errors[0] &&
    (errors[0]?.message === TokenErrors.Expiry ||
      errors[0]?.message === TokenErrors.DecodingError ||
      errors[0]?.message === TokenErrors.NoPermission)
  ) {
    return true;
  }
  return false;
};

const attachTokenToRequest = (request, token) => {
  request.headers.Authorization = `JWT ${token}`;
};

export function updateRefreshToken(token) {
  Cookies.set(REFRESH_TOKEN, token || '');
}

const clearToken = () => {
  Cookies.set(REFRESH_TOKEN, '');
  Cookies.set(ACCESS_TOKEN, '');
  window.location.reload();
};

const refreshPost = (token) => {
  const refreshResponse = authAPIHelper.refreshAccessToken(token);
  return refreshResponse;
};

function handleTokenRefresh() {
  return new Promise((resolve, reject) => {
    const refreshToken = Cookies.get(REFRESH_TOKEN);
    const refreshPromise = refreshPost(refreshToken);
    refreshPromise
      .then((response) => {
        console.log('handleTokenRefresh1', response.data);
        const data = response.data || {};
        const errors = data.errors || [];
        if (
          errors[0] &&
          (errors[0]?.message === TokenErrors.RefreshTokenInvalid ||
            errors[0]?.message === TokenErrors.RefreshTokenExpired)
        ) {
          clearToken();
          reject(response);
          return;
        }
        resolve(response);
      })
      .catch((error) => {
        console.log('handleTokenRefresh2', error.response?.data);
        clearToken();
        reject(error.response);
      });
  });
}

export const baseRefreshToken = () => {
  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (token = null) => {
    failedQueue.forEach((prom) => {
      prom.resolve(token);
    });
    failedQueue = [];
  };

  const interceptor = (response) => {
    console.log('interceptor:', response);
    const needRefresh = shouldRefresh(response);
    if (!needRefresh) {
      return Promise.reject({ response });
    }
    const originalRequest = response.config;
    if (originalRequest.retry || originalRequest.queued) {
      return Promise.reject({ response });
    }

    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.queued = true;
          attachTokenToRequest(originalRequest, token);
          return baseAPIinstance.request(originalRequest);
        })
        .catch(() => {
          return Promise.reject({ response });
        });
    }

    originalRequest.retry = true;
    isRefreshing = true;
    return new Promise((resolve) => {
      const refreshResponse = handleTokenRefresh();
      refreshResponse
        .then((response) => {
          const { data } = response;
          const accessToken = data?.data?.refreshToken?.token;
          const refreshToken = data?.data?.refreshToken?.refreshToken;
          console.log('newRefreshToken:', accessToken, refreshToken);
          addAuthorization(accessToken);
          updateRefreshToken(refreshToken);
          attachTokenToRequest(originalRequest, accessToken);
          resolve(baseAPIinstance.request(originalRequest));
          processQueue(accessToken);
        })
        .catch((err) => {
          console.log('refreshTokenError 1:', err);
          clearToken();
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  };
  baseAPIinstance.interceptors.response.use(interceptor);
};
