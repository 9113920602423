import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import CustomViewer from '../../../components/base/CustomViewer';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import Loading from '../../../components/base/Loading';
import ContentSections from '../../../components/base/ContentSections';
import { CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';
import { listItem } from '../../../components/banners/DisplayItems';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import i18n from 'i18n-js';

function CustomerGroupDetail({
  customerGroup,
  hasUpdatedDefaultValues,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    dispatch({
      type: 'customerGroup/clearData',
    });
    dispatch({
      type: 'customerGroup/getCustomerGroup',
      payload: {
        id: params.id,
      }
    });
  }, [dispatch, params.id]);

  const getItemOrderList = (datas) => {
    if (!datas || !datas.length) {
      return '-';
    }
    return datas.map((data) => <li>{data.name}</li>);
  };

  const customerArea = getItemOrderList(customerGroup?.customers);

  const sections = [
    <>
      {listItem(
        i18n.t('name'),
        customerGroup?.name
      )}
      <CustomTitleLabel title={i18n.t('description_optional')} />
      <CustomViewer initValue={customerGroup?.description} />
      <CustomTitleLabel title={'TARGET CUSTOMER'} />
      {customerArea}
    </>
  ];

  const tabs = [
    {
      name: 'Detail',
      content: !hasUpdatedDefaultValues
        ? <Loading />
        : <ContentSections
            sections={sections}
            hidePreview={true}
          />,
    },
  ];

  const buttons = [
    <AuthButton
      className="btn-further"
      onClick={() => {
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeCustomerGroup}
    >
      Edit
    </AuthButton>,
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  return (
    <div className="customer">
      <CustomListComponent
        caution={{
          detail: '',
          title: customerGroup.name,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={customerGroup.name} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  customerGroup: state.customerGroup.selectedCustomerGroup,
  hasUpdatedDefaultValues: state.customerGroup.hasUpdatedDefaultValues,
});

export default connect(mapPropsToState)(CustomerGroupDetail);
