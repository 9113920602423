import React from 'react';
import { Image } from 'react-bootstrap';
import Slide from '../../assets/images/slide-1-image.svg';
import './OnBoardingSlideOne.scss';
function OnboardingSlideOne({ width, height }) {
  const isPadSize = width < 1000;

  const titleDes = () => {
    return (
      <div
        className={`onboarding-slide-one-text-container ${
          isPadSize ? 'onboarding-slide-one-text-container-pad' : ''
        }`}
      >
        <label
          className={`onboarding-slide-title onboarding-slide-text ${
            isPadSize ? 'center-text' : ''
          }`}
        >
          Welcome to Jujube.
        </label>
        <label
          className={`onboarding-slide-description onboarding-slide-text ${
            isPadSize ? 'center-text' : ''
          }`}
        >
          Jujube is a campaign-based, customer engagement solution. To get
          started, you may need to know the usage of each campaign type
        </label>
      </div>
    );
  };

  const slideImage = () => (
    <Image src={Slide} className="onboarding-slide-image" />
  );

  const order = () => {
    if (isPadSize) {
      return (
        <>
          {slideImage()}
          {titleDes()}
        </>
      );
    }
    return (
      <>
        {titleDes()}
        {slideImage()}
      </>
    );
  };

  return (
    <div className="onboarding-slide-container">
      <div className={`${isPadSize ? 'vertical-align' : 'horizontal-align'}`}>
        {order()}
      </div>
    </div>
  );
}

export default OnboardingSlideOne;
