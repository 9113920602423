import { defaultStep, getNewStepConfig } from './StepBarUtil';
import { loading } from './LoadingUtil';
import {
  APIStatus,
  LanguageConfig,
  MessageChannel,
  SESSION_KEYS,
} from '../config/CustomEnums';
import {
  createMessage,
  sendTestMessageBeforeCreate,
  sendTestMessageBeforeUpdate,
  updateMessage,
} from '../services/MessageApiHelper';
import {
  getFileNameFromUrl,
  saveToSessionStorage,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
} from '../utils';


export const sessionDataKey = {
  objectKey: SESSION_KEYS.CREATE_MESSAGE_SESSION_KEY,
  stepEndKey: SESSION_KEYS.CREATE_MESSAGE_STEP_END_SESSION_KEY,
  originalData: SESSION_KEYS.CREATE_MESSAGE_ORIGINAL_DATA_SESSION_KEY,
};

const getMessageInitState = () => {
  return {
    pk: null,
    id: null,
    name: null,
    content: null,
    photo: null,
    channels: [],
    relatedCampaign: {
      pk: null,
      name: null,
    },
    targetCustomerGroup: null,
    targetedSegments: null,
    scheduledDate: null,
    translations: {},
    formHasSubmitted: false,
    hasUpdatedDefaultValues: false,
  };
};

const stepNames = ['Channel', 'Content', 'Property', 'Preview & Send'];

const getInitialState = () => {
  return {
    message: getMessageInitState(),
    errorFields: [],
    stepConfig: defaultStep(stepNames),
    currentStep: 0,
    languageTag: LanguageConfig.english,
    createStatus: APIStatus.none,
    scheduledMessage: false,
  };
};

const parseMessageInputBody = (values, languages) => {
  const parsedChannels = (values.channels || []).map((item) => {
    if (item === MessageChannel.inbox) {
      return 'INBOX';
    }
    if (item === MessageChannel.push) {
      return 'PUSH_NOTIFICATION';
    }
  });

  const photo = values.translations?.[LanguageConfig.english]?.photo?.value;

  let inputBody = {
    channels: parsedChannels,
    scheduledDate: values.scheduledDate,
    title: values.translations?.[LanguageConfig.english]?.name,
    content: values.translations?.[LanguageConfig.english]?.content,
    photo: photo
      ? getFileNameFromUrl(photo)
      : null,
    isExclusive:
      values.targetCustomerGroup?.length > 0 ||
      values.targetedSegments?.length > 0,
    targetedCustomerGroups: values.targetCustomerGroup?.length > 0
      ? values.targetCustomerGroup.map((item) => item.pk)
      : [],
    targetedSegments: values.targetedSegments?.length
      ? values.targetedSegments.map((item) => item.pk)
      : [],
    campaign: values.relatedCampaign?.pk,
  };

  let translations = [];
  languages.forEach((language) => {
    if (language.code !== LanguageConfig.english) {
      const translationPhoto = values.translations?.[language.code]?.photo?.value;
      const translationData = {
        id: values.translations?.[language.code]?.pk,
        language: language.code,
        title: values.translations?.[language.code]?.name,
        content: values.translations?.[language.code]?.content,
        photo: translationPhoto
          ? getFileNameFromUrl(translationPhoto)
          : null,
      };
      translations.push(translationData);
    }
  });
  inputBody.translations = translations;
  return inputBody;
};

export default {
  namespace: 'createMessage',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },

    updateMessageState(state, { payload }) {
      const message = {
        ...state.message,
        ...payload
      };
      saveToSessionStorage(sessionDataKey.objectKey, message);
      return {
        ...state,
        message: message,
      };
    },

    loadMessageFromCookie(state, { payload }) {
      const message = getObjectFromSessionStorage(sessionDataKey.originalData);
      if (!message) {
        return {
          ...state,
        };
      }
      saveToSessionStorage(sessionDataKey.originalData, message);
      saveToSessionStorage(sessionDataKey.objectKey, message);
      return {
        ...state,
        message: message,
        hasUpdatedDefaultValues: true,
      };
    },

    saveOrRemoveMessageFromCookie(state, { payload }) {
      if (payload.save) {
        if (payload.values) {
          saveToSessionStorage(sessionDataKey.originalData, payload.values);
        }
      } else {
        removeFromSessionStorage(sessionDataKey.originalData);
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },

    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      let step = payload.step;
      const isValid = payload.isValid;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        !isValid,
        isBack,
      );
      if (isValid) {
        step = isBack ? step - 1 : step + 1;
      }
      return {
        ...state,
        currentStep: step,
        stepConfig,
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    createMessage: [
      function* ({ payload }, { select, put }) {
        const { isDraft } = payload;
        const { values, languages } = payload;
        const inputBody = parseMessageInputBody(values, languages);
        const uploadData = {
          ...inputBody,
          isDraft: isDraft,
        };
        const serviceArgs = [
          createMessage,
          uploadData,
        ];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        function* onSuccess(data) {
          const messageData = data.createMessage.node;
          yield put({
            type: 'updateState',
            payload: {
              scheduledMessage: true,
              formHasSubmitted: true,
            }
          });
          yield put({
            type: 'updateMessageState',
            payload: {
              ...values,
              pk: messageData.pk,
              id: messageData.id,
            }
          });

          removeFromSessionStorage(sessionDataKey.objectKey);
          const afterActions = payload.afterActions || (() => {});
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    testMessageSend: [
      function* ({ payload }, { select }) {
        const { values, languages } = payload;
        const message = yield select((state) => state.createMessage.message);
        const customer = payload.customer;
        const isEdit = payload.isEdit;
        let inputBody = parseMessageInputBody(values, languages);
        inputBody = {
          ...inputBody,
          isDraft: false,
          customer: customer.pk,
        };
        let testService = sendTestMessageBeforeCreate;
        if (isEdit) {
          inputBody.id = message.pk;
          testService = sendTestMessageBeforeUpdate;
        } else {
          inputBody.isDraft = true;
        }
        const serviceArgs = [testService, inputBody];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => {});
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    updateMessage: [
      function* ({ payload }, { select, put }) {
        const { isDraft, values, languages } = payload;
        const message = yield select((state) => state.createMessage.message);
        const inputBody = parseMessageInputBody(values, languages);
        const uploadData = {
          ...inputBody,
          isDraft: isDraft,
          id: message.pk,
        };
        const serviceArgs = [
          updateMessage,
          uploadData,
        ];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        function* onSuccess() {
          removeFromSessionStorage(sessionDataKey.objectKey);
          // yield put(createAction('createAndUpdateMessageTranslation')());
          yield put({
            type: 'updateState',
            payload: {
              formHasSubmitted: true,
            },
          });
          const afterActions = payload.afterActions || (() => {});
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
