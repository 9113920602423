import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';

function Filter({ backAction = () => {} }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchRecordType = parsedSearch['record_type'] || '';
  const searchExpiredDateType = parsedSearch['expired_date'] || '';
  const searchAbsoluteDate = parsedSearch['absolute'] || '';
  const searchRelativeDay = parsedSearch['relativeDay'] || '';

  const [recordType, setRecordType] = useState(
    ArrayStringData(searchRecordType),
  );
  const [expiredDateType, setExpiredDateType] = useState(searchExpiredDateType);
  const [absoluteDate, setAbsoluteDate] = useState(searchAbsoluteDate);
  const [relativeDay, setRelativeDay] = useState(searchRelativeDay);

  const content = [
    {
      title: 'Record type',
      data: [
        { name: 'Customer earned', pk: 'EARN' },
        { name: 'Customer acquired', pk: 'BUY' },
        { name: 'Admin added', pk: 'GRANT' },
        { name: 'Admin removed', pk: 'RECLAIM' },
        { name: 'Customer use', pk: 'USE' },
        { name: 'Coupon expired', pk: 'EXPIRED' },
      ],
      value: recordType,
      setValue: setRecordType,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Expired date',
      data: [
        { name: 'All time', pk: 'allTime', subComponment: null },
        {
          name: 'Time Range',
          pk: 'absolute',
          subComponment: {
            value: absoluteDate,
            setValue: setAbsoluteDate,
            componment: FilterTypeEnum.dateRange,
          },
        },
        {
          name: 'Since acquiring',
          pk: 'relativeDay',
          subComponment: {
            value: relativeDay,
            setValue: setRelativeDay,
            componment: FilterTypeEnum.input,
          },
        },
      ],
      value: expiredDateType,
      setValue: setExpiredDateType,
      componment: FilterTypeEnum.singleChoice,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
