import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import {
  SectionTopTitle,
} from '../base/CommonComponent';
import { AdminErrorHandleFields } from '../../containers/admin/admins/AdminErrorHandleFields';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { Button } from 'react-bootstrap';
import ChangePasswordPrompt from './ChangePasswordPrompt';
import { ToastType } from '../../models/NavBarModel';
import InputFieldControl from '../base/InputFieldControl';

const ChangePasswordCom = ({ changeAction }) => {
  return (
    <div className="d-flex flex-column">
      <CustomTitleLabel title="Password" />
      <label className="tips-message">
        Raw passwords are not stored, so there is no way to see this user's
        password.
      </label>
      <label className="tips-message">{'*'.repeat(10)}</label>
      <Button className="btn-back-button-common" onClick={changeAction}>
        Change
      </Button>
    </div>
  );
};

function GeneralSections({}) {
  const params = useParams();
  const { id } = params;
  const [showChangePassPrompt, setShowChangePassPrompt] = useState(false);
  const { watch, setValue } = useFormContext();
  const watchName = watch('name');
  const watchPassword = watch('password');
  const watchConfirmPassword = watch('confirmPassword');
  const adminName = useSelector((state) => state.admin.oneAdmin.name);
  const dispatch = useDispatch();

  const passwordRex = /\D/g;
  const nameSection = (
    <InputFieldControl
      name={'name'}
      title={'Name'}
      rules={{ required: AdminErrorHandleFields.required.username }}
      value={watchName}
      setValue={(value) => {
        setValue('name', value, { shouldDirty: true });
      }}
    />
  );
  const passwordSection = (
    <InputFieldControl
      name={'password'}
      title={'Password'}
      autoComplete="new-password"
      tips="Password at least 8 characters, can't be entirely numeric."
      type="password"
      rules={{
        validate: {
          lessThanEight: (value) => {
            return (
              value?.length >= 8 ||
              AdminErrorHandleFields.others.password.length
            );
          },
          allNumeric: (value) => {
            return (
              passwordRex.test(value) ||
              AdminErrorHandleFields.others.password.numeric
            );
          },
        },
      }}
      value={watchPassword}
      setValue={(value) => {
        setValue('password', value, { shouldDirty: true });
      }}
    />
  );
  const confirmPasswordSection = (
    <InputFieldControl
      name={'confirmPassword'}
      title={'Password confirmation'}
      tips="Enter the same password as before, for verification"
      type="password"
      autoComplete="new-password"
      rules={{
        validate: {
          lessThanEight: (value) => {
            return (
              value?.length ||
              AdminErrorHandleFields.required.passwordConfirmation
            );
          },
          allNumeric: (value) => {
            if (watchPassword !== value) {
              setValue('password', '', { shouldDirty: true });
              setValue('confirmPassword', '', { shouldDirty: true });
              dispatch({
                type: 'navBars/updateState',
                payload: {
                  saveDiscardToastShowing: {
                    value: true,
                    type: ToastType.passwordConfirmation,
                  },
                },
              });
            }
            return (
              watchPassword === value ||
              'Confirmation password should be same with password entered before.'
            );
          },
        },
      }}
      value={watchConfirmPassword}
      setValue={(value) => {
        setValue('confirmPassword', value, { shouldDirty: true });
      }}
    />
  );

  return (
    <>
      <SectionTopTitle title="General" />
      {nameSection}
      {id ? (
        <ChangePasswordCom changeAction={() => setShowChangePassPrompt(true)} />
      ) : (
        <>
          {passwordSection}
          {confirmPasswordSection}
        </>
      )}

      <ChangePasswordPrompt
        admin={{ username: adminName, pk: id }}
        show={showChangePassPrompt}
        onClose={() => setShowChangePassPrompt(false)}
        onConfirm={() => setShowChangePassPrompt(false)}
      />
    </>
  );
}


export default connect()(GeneralSections);
