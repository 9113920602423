import React, { useEffect, useState, useRef } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';

function Filter({ backAction = () => {} }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchMessageChannel = parsedSearch['message_channel'] || '';
  const searchDeliveryTime = parsedSearch['delivery_time'] || '';
  const searchStatus = parsedSearch['status'] || '';

  const [messageChannel, setMessageChannel] = useState(
    ArrayStringData(searchMessageChannel),
  );
  const [deliveryTime, setDeliveryTime] = useState(searchDeliveryTime);
  const [status, setStatus] = useState(ArrayStringData(searchStatus));

  const content = [
    {
      title: 'Message channel',
      data: [
        { name: 'Inbox', pk: 'INBOX' },
        { name: 'Push', pk: 'PUSH_NOTIFICATION' },
      ],
      value: messageChannel,
      setValue: setMessageChannel,
      componment: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Status',
      data: [
        { name: 'Sent', pk: 'STATE_COMPLETED' },
        { name: 'Schedule', pk: 'STATE_INITIAL' },
        { name: 'Saved', pk: 'STATE_INITIAL_DRAFT' },
        { name: 'Sending', pk: 'STATE_SENDING' },
        { name: 'Fail', pk: 'STATE_ERROR' },
      ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Delivery time',
      value: deliveryTime,
      setValue: setDeliveryTime,
      componment: FilterTypeEnum.datetime,
    },
  ];

  return <BaseFilter
    content={content}
    backAction={backAction}
  />;
}

export default Filter;
