import { BaseHelper } from './BaseGraphQLAPIHelper';

const STORE_NODE = `{
  id
  pk
  name
  displayPriority
  translations {
    edges {
      node {
        id
        pk
        language
        name
      }
    }
  }
  subcategories(orderBy: "displayPriority") {
    edges {
      node {
        pk
        name
        displayPriority
        translations {
          edges {
            node {
              id
              pk
              language
              name
            }
          }
        }
      }
    }
  }
}`;

export const getStoreCategoryList = (afterCursor, searchKey, moreSearch) => {
  let filter = '';
  if (searchKey) {
    filter = `, nameIcontains:"${searchKey}"`;
  }
  let orderBy = 'displayPriority';
  if(moreSearch?.sort) {
    orderBy = moreSearch.sort;
  }
  const query = `{
    storeCategories(first: 20, after:"${afterCursor}", orderBy: "${orderBy}"${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${STORE_NODE}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getStoreCategory = (id) => {
  const query = `{
    storeCategory(id: "${id}") ${STORE_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createStoreCategory = (values) => {
  const query = `mutation CreateStoreCategory($input: CreateStoreCategoryInput!) {
    createStoreCategory(input: $input) {
      node ${STORE_NODE}
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...values,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
export const updateStoreCategory = (values) => {
  const query = `mutation UpdateStoreCategory($input: UpdateStoreCategoryInput!) {
    updateStoreCategory(input: $input) {
      node ${STORE_NODE}
    }
  }`;
  const variables = {
    input: {
      ...values,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteStoreCategories = (ids) => {
  const query = `mutation DeleteStoreCategories($input: DeleteStoreCategoriesInput!) {
    deleteStoreCategories(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getStoreSubCategoryList = () => {
  const query = `{
    storeSubcategories(first: 100, orderBy: "pk") {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        pk
        category {
            name
            displayPriority
        }
        name
        displayPriority
      }
    }
  }
}`;

  // translations {
  //   edges {
  //     node {
  //       language
  //       name
  //     }
  //   }
  // }
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};
