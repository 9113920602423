import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Filter.scss';
import queryString from 'query-string';
import { createAction, ArrayStringData } from '../../utils';
import { useLocation } from 'react-router-dom';
import BaseFilter, { FilterTypeEnum } from '../base/BaseFilter';

function Filter({ backAction = () => {} }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const genderSource = [
    { name: 'Male', pk: 'MALE' },
    { name: 'Female', pk: 'FEMALE' },
  ];

  const { groupSource, segmentSource, levelSource } = useSelector((state) => ({
    groupSource: state.customerGroup.groupList,
    segmentSource: state.segments.segmentList,
    levelSource: state.levels.levelList,
    filters: state.customerList.filters,
  }));

  const parsedSearch = queryString.parse(location.search);
  const searchLevels = parsedSearch['levels'] || '';
  const searchSegments = parsedSearch['segments'] || '';
  const searchGroups = parsedSearch['customer_groups'] || '';
  const searchGenders = parsedSearch['gender'] || '';
  const searchAge = parsedSearch['age'] || '';
  const searchBirthStart = parsedSearch['start_date'] || '';
  const searchBirthEnd = parsedSearch['end_date'] || '';

  const [age, setAge] = useState(() => {
    const ages = ArrayStringData(searchAge);
    if (ages.length > 0) return ages;
    return [0, 0];
  });
  const [genders, setGenders] = useState(ArrayStringData(searchGenders));

  const [groups, setGroups] = useState(ArrayStringData(searchGroups));
  const [segments, setSegments] = useState(ArrayStringData(searchSegments));
  const [levels, setLevels] = useState(ArrayStringData(searchLevels));

  const [startDate, setStartDate] = useState(searchBirthStart);
  const [endDate, setEndDate] = useState(searchBirthEnd);

  useEffect(() => {
    dispatch(createAction('customerGroup/getGroupList')({}));
    dispatch(createAction('levels/getLevelList')({}));
    dispatch(createAction('segments/getSegmentsList')({}));
  }, [dispatch]);

  const content = [
    {
      title: 'Customer groups',
      data: groupSource,
      value: groups,
      setValue: setGroups,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Segments',
      data: segmentSource,
      value: segments,
      setValue: setSegments,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Levels',
      data: levelSource,
      value: levels,
      setValue: setLevels,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Gender',
      data: genderSource,
      value: genders,
      setValue: setGenders,
      componment: FilterTypeEnum.segment,
    },
    {
      title: 'Age',
      data: [0, 80],
      value: age,
      setValue: setAge,
      componment: FilterTypeEnum.slider,
    },
    {
      title: 'Start date',
      value: startDate,
      setValue: setStartDate,
      componment: FilterTypeEnum.date,
    },
    {
      title: 'End date',
      value: endDate,
      setValue: setEndDate,
      componment: FilterTypeEnum.date,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
