import React from 'react';
import { useSelector } from 'react-redux';
import './CampaignOverviewTab.scss';
import OverviewCard from '../../dashboard/OverviewCard';

const CAMPAINGNS_DATA_LIST_CONFIG = [
  {
    title: 'Total number of published campaign',
    key: 'totalPublishedCampaign',
  },
  {
    title: 'Total number of campaign conversion',
    key: 'totalCampaignConversion',
  },
  {
    title: 'Total number of view',
    key: 'totalCampaignViews',
  },
  {
    title: 'Coupon campaign conversion rate',
    key: 'couponCampaignConversionRate',
    showPercentage: true,
  },
  {
    title: 'Earning campaign conversion rate',
    key: 'earningCampaignConversionRate',
    showPercentage: true,
  },
  {
    title: 'Campaign share rate',
    key: 'campaignShareRate',
    showPercentage: true,
  },
];

const POINTS_DATA_LIST_CONFIG = [
  // {
  //   title: 'Total points balance (as of TODAY)',
  //   key: '',
  // },
  {
    title: 'Total points earned value',
    key: 'totalPointsEarned',
  },
  {
    title: 'Total points used value',
    key: 'totalPointsUsed',
  },
];

const COUPOINS_DATA_LIST_CONFIG = [
  {
    title: 'Total number of coupon activity',
    key: 'totalCouponActivities',
  },
  {
    title: 'Total number of coupon redeemed by points',
    key: 'totalCouponRedeemByPoints',
  },
  {
    title: 'Total number of coupon used',
    key: 'totalCouponUsed',
  },
  {
    title: 'Total number of coupon aquired by campaigns',
    key: 'totalCouponAcquiredByCampaigns',
  },
];

const CampaignOverviewTab = () => {
  const campaignOverviewData = useSelector((state) => state.overview.campaigns);
  return (
    <div>
      <div className="overview-row-title overview-campaigns-title">
        campaigns
      </div>
      <div className="overview-row">
        {CAMPAINGNS_DATA_LIST_CONFIG.map((config, index) => (
          <OverviewCard
            title={config.title}
            value={campaignOverviewData[config.key]?.value}
            change={campaignOverviewData[config.key]?.change}
            showPercentage={config.showPercentage}
          />
        ))}
      </div>

      <div className="overview-row-title">points</div>
      <div className="overview-row">
        {POINTS_DATA_LIST_CONFIG.map((config, index) => (
          <OverviewCard
            title={config.title}
            value={campaignOverviewData[config.key]?.value}
            change={campaignOverviewData[config.key]?.change}
          />
        ))}
      </div>

      <div className="overview-row-title">coupons</div>
      <div className="overview-row">
        {COUPOINS_DATA_LIST_CONFIG.map((config, index) => (
          <OverviewCard
            title={config.title}
            value={campaignOverviewData[config.key]?.value}
            change={campaignOverviewData[config.key]?.change}
          />
        ))}
      </div>
    </div>
  );
};

export default CampaignOverviewTab;
