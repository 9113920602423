import { LanguageConfig } from '../config/CustomEnums';
import {
  COUPON_REDEMPTION_METHOD_CONFIG,
  COUPON_TYPES_CONFIG,
} from '../containers/engagement/coupon/CreateCouponStepOne';
import { addDomainToImage } from '../utils';
import { parseBrand, parseStore } from './BrandModel';
function getCouponTemplateTranslation(node) {
  const language = node.language || LanguageConfig.english;
  let otherPhotos = [];
  if (addDomainToImage(node.detailPhoto1)) {
    otherPhotos.push(addDomainToImage(node.detailPhoto1));
  }
  if (addDomainToImage(node.detailPhoto2)) {
    otherPhotos.push(addDomainToImage(node.detailPhoto2));
  }
  if (addDomainToImage(node.detailPhoto3)) {
    otherPhotos.push(addDomainToImage(node.detailPhoto3));
  }
  if (addDomainToImage(node.detailPhoto4)) {
    otherPhotos.push(addDomainToImage(node.detailPhoto4));
  }
  return {
    [language]: {
      name: node.name,
      shortDescription: node.shortDescription,
      id: node.id,
      pk: node.pk,
      instructionSectionTitle: node.instructionSectionTitle,
      instructionSectionContent: node.instructionSectionContent,
      detailSectionTitle: node.detailSectionTitle,
      detailSectionContent: node.detailSectionContent,
      coverPhoto: addDomainToImage(node.coverPhoto),
      otherPhoto: otherPhotos,
    },
  };
}

export function parseApplicationStore(applicableStores) {
  let selectedStores = applicableStores?.edges;
  if (selectedStores && selectedStores.length > 0) {
    selectedStores = selectedStores.map((store) => parseStore(store));
  }
  return selectedStores
}

export function parseCouponSet(data) {
  if (!data) {
    return;
  }
  const translationEdges = data.translations?.edges;
  const enTranslation = getCouponTemplateTranslation(data);
  let reducedTranslation = null;
  if (translationEdges) {
    const chineseTranslation = translationEdges.map((item) => {
      return getCouponTemplateTranslation(item.node);
    });
    reducedTranslation = chineseTranslation.reduce(function (obj, item) {
      return {
        ...obj,
        ...item,
      };
    }, {});
  }

  const selectedBrand = data.brand;
  const selectedStores = parseApplicationStore(data.applicableStores)
  return {
    id: data.id,
    pk: data.pk,
    title: `[${data.pk}] ${data.name}`,
    name: data.name,
    lastModifiedDate: data.lastModifiedDate,
    displayLastModifiedDate: parseCouponSetListDate(data.lastModifiedDate),
    creationDate: data.creationDate,
    displayCreationDate: parseCouponSetListDate(data.creationDate),
    linkedCampaignsName: data.linkedCampaignsName,
    shortDescription: data.shortDescription,
    applyCoverPhotoToAllLanguage: data.applyCoverPhotoToAllLanguage,
    applyDetailPhotosToAllLanguage: data.applyDetailPhotosToAllLanguage,
    absoluteEffectiveDate: data.absoluteEffectiveDate,
    absoluteExpiryDate: data.absoluteExpiryDate,
    status: data.status,
    stock: data.stock,
    totalNubmerOfGeneratedCoupons: data.totalNubmerOfGeneratedCoupons,
    validPeriodType: data.validPeriodType,
    numberOfDaysToExpireAfterAcquisition:
      data.numberOfDaysToExpireAfterAcquisition,
    translations: {
      ...enTranslation,
      ...reducedTranslation,
    },
    selectedBrand: selectedBrand ? parseBrand(selectedBrand) : null,
    selectedStores: selectedStores,
    shouldBeUsedInStores: data.shouldUseInStore,
    isForcedInactive: data.isForcedInactive,
    expiryDate: parseExpiredDate(data),

    redemptionMethod: data.redemptionMethod,
    redemptionMethodDisplay:
      COUPON_REDEMPTION_METHOD_CONFIG.filter(
        (item) => item.value === data.redemptionMethod,
      )?.[0]?.label || '',
    formats: data.formats,
    barcodeFormat: data?.barcodeFormat,
    couponSetType: data.couponTemplateType,
    couponSetTypeDisplay:
      COUPON_TYPES_CONFIG.filter(
        (item) => item.id === data.couponTemplateType,
      )?.[0]?.title || '',
    
    couponCodeType: data.couponCodeType,
    genericCode: data.genericCode,
  };
}

export const parseExpiredDate = (couponTemplate) => {
  if (couponTemplate.validPeriodType === 'ABSOLUTE') {
    const startDate = parseCouponSetListDate(
      couponTemplate.absoluteEffectiveDate,
    );
    const endDate = parseCouponSetListDate(couponTemplate.absoluteExpiryDate);
    return `${startDate} - ${endDate}`;
  } else if (couponTemplate.validPeriodType === 'RELATIVE') {
    return `${couponTemplate.numberOfDaysToExpireAfterAcquisition} days since acquiring`;
  } else {
    return `All time valid`;
  }
};

export function assembleCouponTemplates(couponTemplatesData) {
  return couponTemplatesData.map((data) => {
    return parseCouponSet(data.node);
  });
}

const parseCouponExpiryDate = (couponSet, coupon) => {
  const effectiveDate = coupon.effectiveDate;
  const expiryDate = coupon.expiryDate;
  if (effectiveDate && expiryDate) {
    return `${parseCouponSetListDate(effectiveDate)} - ${parseCouponSetListDate(
      expiryDate,
    )}`;
  }
  if (couponSet?.validPeriodType === 'ALL_TIME') {
    return 'All time valid';
  }
  if (couponSet?.validPeriodType === 'ABSOLUTE') {
    return `${effectiveDate ? parseCouponSetListDate(effectiveDate) : ''} - ${
      expiryDate ? parseCouponSetListDate(expiryDate) : ''
    }`;
  }
  if (couponSet?.validPeriodType === 'RELATIVE') {
    return `${couponSet.numberOfDaysToExpireAfterAcquisition} days since acquiring`;
  }
  return '-';
};

export const parseSingleCoupon = (couponSet, singleCouponData) => {
  const ownerObject = singleCouponData.node.owner;
  let owner = '-';
  if (ownerObject) {
    owner = `${ownerObject.firstName} ${ownerObject.lastName}`;
  }
  return {
    ...singleCouponData,
    id: singleCouponData.node.id,
    pk: singleCouponData.node.pk,
    name: singleCouponData.node.serialNumber,
    serialNumber: singleCouponData.node.serialNumber,
    owner: { ...singleCouponData.node.owner, owner: owner },
    customerName: owner,
    expiryDate: singleCouponData.node.expiryDate,
    displayExpiryDate: parseCouponExpiryDate(couponSet, singleCouponData.node),
    creationDate: singleCouponData.node.creationDate,
    displayCreationDate: parseCouponSetListDate(
      singleCouponData.node.creationDate,
    ),
    lastModifiedDate: singleCouponData.node.lastModifiedDate,
    displayLastModifiedDate: parseCouponSetListDate(
      singleCouponData.node.lastModifiedDate,
    ),
    dateOfPurchase: singleCouponData.node.dateOfPurchase,
    dateOfEarning: singleCouponData.node.dateOfEarning,
    dateOfGrant: singleCouponData.node.dateOfGrant,
    isUsed: singleCouponData.node.isUsed,
    isExpired: singleCouponData.node.isExpired,
    isActive: singleCouponData.node.isActive,
    isForcedInactive: singleCouponData.node.isForcedInactive,
    effectiveDate: singleCouponData.node.effectiveDate,
    store: singleCouponData.node.store,
  };
};

export const parseCouponSetListDate = (dateString) => {
  if (!dateString || dateString === '') {
    return '';
  }
  const options = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  };
  const date = new Date(dateString);
  const format = new Intl.DateTimeFormat('en', options);
  const [
    { value: weekday },
    ,
    { value: month },
    ,
    { value: day },
    ,
  ] = format.formatToParts(date);
  const year = date.getFullYear();
  const currentYear = new Date().getFullYear();
  if (currentYear !== year) {
    return `${day} ${month} ${year}`;
  }
  return `${day} ${month} (${weekday})`;
};

export const parseCouponSetOverviewDatetime = (datetimeString) => {
  const datetime = new Date(datetimeString);
  const weekday = new Intl.DateTimeFormat('en', { weekday: 'short' }).format(
    datetime,
  );
  let localeString = datetime.toLocaleString('en');
  localeString = localeString.replace(',', `(${weekday})`);
  return localeString;
};
