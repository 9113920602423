import React, { useState, useRef, useEffect, createRef } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import './QRCodeScanningSection.scss';
import {
  CustomTitleLabel,
  CustomNumberMutipleInputWithUnit,
} from './CustomBaseComponments';
import { useFormContext } from 'react-hook-form';
import InputFieldControl from '../base/InputFieldControl';

function LimitSection({
  tips = {}
}) {
  const { watch, formState, setValue } = useFormContext();
  const overallLimit = watch('overallLimit')
  const periodicLimit = watch('periodicLimit')
  const periodicLimitDays = watch('periodicLimitDays')
  const perHeadLimit = watch('perHeadLimit')
  const perHeadPeriodicLimit = watch('perHeadPeriodicLimit')
  const perHeadPeriodicLimitDays = watch('perHeadPeriodicLimitDays')
  return (
    <>
      <InputFieldControl
        name={'overallLimit'}
        title={'Overall limit (optional)'}
        value={overallLimit}
        setValue={(value) => {
          setValue('overallLimit', value, { shouldDirty: true });
        }}
        type='number'
        unit='times'
        className='coupon-campaign-section-input'
        tips={tips.overallLimit}
      />
      <CustomTitleLabel title="Periodic limit (optional)" />
      <label className="tips-message">
        {tips.periodicLimit}
      </label>
      <CustomNumberMutipleInputWithUnit
        per="times per"
        unit="days"
        setFirstValue={(value) => {
          setValue("periodicLimit", value, { shouldDirty: true})
        }}
        firstDefaultValue={periodicLimit}
        setSecondValue={(value) => {
          setValue("periodicLimitDays", value, { shouldDirty: true })
        }}
        secondDefaultValue={periodicLimitDays}
      />

      <InputFieldControl
        name={'perHeadLimit'}
        title={'Per head limit (optional)'}
        value={perHeadLimit}
        setValue={(value) => {
          setValue('perHeadLimit', value, { shouldDirty: true });
        }}
        type='number'
        unit='times'
        className='coupon-campaign-section-input'
        tips={tips.perHeadLimit}
      />

      <CustomTitleLabel title="Per head periodic limit (optional)" />
      <label className="tips-message">
        {tips.perHeadPeriodicLimit}
      </label>
      <CustomNumberMutipleInputWithUnit
        per="times per"
        unit="days"
        setFirstValue={(value) => {
          setValue("perHeadPeriodicLimit", value, { shouldDirty: true})
        }}
        firstDefaultValue={perHeadPeriodicLimit}
        setSecondValue={(value) => {
          setValue("perHeadPeriodicLimitDays", value, { shouldDirty: true })
        }}
        secondDefaultValue={perHeadPeriodicLimitDays}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
})

export default connect(mapPropsToState)(LimitSection);
