import React, { useEffect } from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomSwitchButton from './CustomSwitchButton';

import ErrorFieldMessage from '../base/ErrorFieldMessage';

function CustomTitleWithSwitch({
  title,
  defaultValue,
  setValue,
  error = {},
  tips,
}) {
  return (
    <div className="d-flex flex-column">
      <CustomTitleLabel title={title} />
      {tips ? <label className="tips-message">{tips}</label> : null}
      <CustomSwitchButton
        defaultChecked={defaultValue}
        onChange={(isChecked) => {
          setValue(isChecked);
        }}
      />
      <ErrorFieldMessage id={error.id} error={error} message={error.message} />
    </div>
  );
}

export default CustomTitleWithSwitch;
