import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { DatePicker } from 'antd';
import './DateRangeSelection.scss';
import { createAction } from '../../utils';
const { RangePicker } = DatePicker;

const RANGE_TYPES = {
  TODAY: '0',
  LAST_7_DAYS: '1',
  LAST_30_DAYS: '2',
  CUSTOM: '3',
};

export const getStartEndDate = (range, start, end) => {
  if (range === RANGE_TYPES.TODAY) {
    return { rangeType: range, startDate: moment(), endDate: moment() };
  }
  if (range === RANGE_TYPES.LAST_30_DAYS) {
    return {
      rangeType: range,
      startDate: moment().add(-30, 'days'),
      endDate: moment().add(-1, 'days'),
    };
  }
  if (range === RANGE_TYPES.CUSTOM) {
    return {
      rangeType: range,
      startDate: moment(start),
      endDate: moment(end),
    };
  }
  return {
    rangeType: RANGE_TYPES.LAST_7_DAYS,
    startDate: moment().add(-7, 'days'),
    endDate: moment().add(-1, 'days'),
  };
};

const DateRangeSelection = ({ applyDateRangeEvent, clearDataEvent, onTab }) => {
  const history = useHistory();
  const location = useLocation();
  const { range, start, end } = queryString.parse(location.search);
  const { rangeType, startDate, endDate } = getStartEndDate(range, start, end);
  const [tempStartDate, setTempStartDate] = React.useState(startDate);
  const [tempEndDate, setTempEndDate] = React.useState(endDate);
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);
  React.useEffect(() => {
    applyDateRangeEvent && applyDateRangeEvent(startDate, endDate);
    setTempStartDate(startDate);
    setTempEndDate(endDate);
  }, [range, start, end]);

  React.useEffect(() => {
    if (!datePickerOpen && initialized) {
      history.push({
        pathname: location.pathname,
        search: `?range=${RANGE_TYPES.CUSTOM}&start=${tempStartDate.format(
          'YYYY-MM-DD',
        )}&end=${tempEndDate.format('YYYY-MM-DD')}`,
        hash: location.hash,
      });
    }
  }, [datePickerOpen]);

  React.useEffect(() => {
    applyDateRangeEvent && applyDateRangeEvent(startDate, endDate);
    setInitialized(true);
    return () => {
      clearDataEvent && clearDataEvent();
    };
  }, []);
  return (
    <div
      className={`list-section-container-header-buttons dashboard-header-right ${
        onTab ? 'dashboard-tab-range-selection' : ''
      }`}
    >
      <div
        className={`dashboard-date-range-btn ${
          rangeType === RANGE_TYPES.TODAY ? 'active' : ''
        }`}
        onClick={() => {
          if (rangeType !== RANGE_TYPES.TODAY) {
            history.push({
              pathname: location.pathname,
              search: `?range=${RANGE_TYPES.TODAY}`,
              hash: location.hash,
            });
          }
        }}
      >
        Today
      </div>
      <div
        className={`dashboard-date-range-btn ${
          rangeType === RANGE_TYPES.LAST_7_DAYS ? 'active' : ''
        }`}
        onClick={() => {
          if (rangeType !== RANGE_TYPES.LAST_7_DAYS) {
            history.push({
              pathname: location.pathname,
              search: `?range=${RANGE_TYPES.LAST_7_DAYS}`,
              hash: location.hash,
            });
          }
        }}
      >
        Last 7 days
      </div>
      <div
        className={`dashboard-date-range-btn ${
          rangeType === RANGE_TYPES.LAST_30_DAYS ? 'active' : ''
        }`}
        onClick={() => {
          if (rangeType !== RANGE_TYPES.LAST_30_DAYS) {
            history.push({
              pathname: location.pathname,
              search: `?range=${RANGE_TYPES.LAST_30_DAYS}`,
              hash: location.hash,
            });
          }
        }}
      >
        Last 30 days
      </div>
      <RangePicker
        disabledTime
        value={[tempStartDate, tempEndDate]}
        className="date-picker-extra"
        format="D MMM, YYYY"
        disabledDate={(current) => current > moment().endOf('day')}
        separator={'-'}
        onChange={(values) => {
          console.log(values);
          setTempStartDate(values[0]);
          setTempEndDate(values[1]);
        }}
        onOpenChange={setDatePickerOpen}
      />
    </div>
  );
};

export default DateRangeSelection;
