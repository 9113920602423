import { saveAs } from 'file-saver';

const saveFile = (url, data) => {
  const fileNmme = url.substr(url.lastIndexOf('/') + 1);
  saveAs(data, fileNmme);
};

export const downloadZipFiles = (url) => {
  if (url?.includes('.zip')) {
    saveFile(url, url);
    return;
  }
  fetch(url, { cache: 'no-cache' })
    .then((response) => response.blob())
    .then((blob) => {
      console.log('response blob:', blob);
      saveFile(url, blob);
    })
    .catch((error) => console.log('save error:', error));
};
