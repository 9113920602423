import React from 'react';
import CustomerList from '../containers/customers/customer/CustomerList';
import LevelList from '../containers/customers/level/LevelList';
import TransactionList from '../containers/record/records/TransactionList';
import SegmentList from '../containers/customers/segments/SegmentList';
import CampaignList from '../containers/engagement/campaign/CampaignList';
import CouponList from '../containers/engagement/coupon/CouponList';
import EarningRuleList from '../containers/engagement/earningRule/EarningRuleList';
import MessageList from '../containers/engagement/message/MessageList';
import RewardList from '../containers/engagement/rewards/RewardList';
import BrandList from '../containers/merchants/brands/BrandList';
import StoreList from '../containers/merchants/stores/StoreList';
import Dashboard from '../containers/dashboard/Dashboard';
import { NavBarNames } from './NavBarNameList';
import { PermissionCodes } from './PermissionCodes';
import TranslationList from '../containers/merchants/translations/TranslationList';
import { URLFragments } from '../components/campaign/CampaignUrlConfig';
import { CouponURLQueryKeys } from '../components/coupon/CouponUrlConfig';
import CustomerGroupList from '../containers/customers/customerGroup/CustomerGroupList';
import CampaignCategory from '../containers/engagement/campaignCategory/CampaignCategory';
import StoreCategory from '../containers/merchants/storeCategory/StoreCategory';
import WebviewContent from '../containers/others/WebviewContent';
import CouponTransaction from '../containers/record/couponRecords/CouponTransaction';
import PointTransactionList from '../containers/record/pointRecords/PointTransactionList';
import AdminList from '../containers/admin/admins/AdminList';
import AdminGroupList from '../containers/admin/adminGroups/AdminGroupList';
import BannerList from '../containers/homeManage/banners/BannerList';
import FeaturedCampaignList from '../containers/homeManage/featuredCampaigns/FeaturedCampaignList';
import StoreCodeList from '../containers/merchants/stores/StoreCodeList';
import LanguageList from '../containers/engagement/language/LanguageList';

import {
  pointRecordQueryKey,
  couponRecordQueryKey,
  transactionQueryKey,
} from '../components/pointRecord/RecordsUrlConfig';
const queryString = require('query-string');
const dashboard = {
  path: '/',
  name: 'Dashboard',
  location: {
    pathname: '/',
  },
  exact: true,
  main: () => <Dashboard />,
};

const admin = {
  name: 'administrators',
  list: [
    {
      path: '/administrator_groups',
      location: {
        pathname: '/administrator_groups',
      },
      exact: true,
      name: NavBarNames.adminGroup,
      codename: PermissionCodes.administratorgroup,
      main: () => <AdminGroupList />,
    },
    {
      path: '/administrators',
      location: {
        pathname: '/administrators',
      },
      exact: true,
      name: NavBarNames.admin,
      codename: PermissionCodes.administrator,
      main: () => <AdminList />,
    },
  ],
};

const customers = {
  name: 'user',
  list: [
    {
      path: '/customers',
      location: {
        pathname: '/customers',
      },
      exact: true,
      name: NavBarNames.customer,
      codename: PermissionCodes.customer,
      main: () => <CustomerList />,
    },
    {
      path: '/segments',
      location: {
        pathname: '/segments',
      },
      exact: true,
      name: NavBarNames.segments,
      codename: PermissionCodes.segment,
      main: () => <SegmentList />,
    },
    {
      path: '/customer_group',
      location: {
        pathname: '/customer_group',
        search: queryString.stringify({
          page: 1,
          rank: true,
          search: '',
        }),
      },
      exact: true,
      name: NavBarNames.customerGroup,
      codename: PermissionCodes.customergroup,
      main: () => <CustomerGroupList />,
    },
  ],
};

const engagement = {
  name: 'Engagement',
  list: [
    {
      path: '/campaigns',
      location: {
        pathname: '/campaigns',
        hash: URLFragments.overview,
      },
      exact: true,
      name: NavBarNames.campaigns,
      codename: PermissionCodes.campaign,
      main: () => <CampaignList />,
    },
    {
      path: '/campaign_category',
      location: {
        pathname: '/campaign_category',
      },
      exact: true,
      name: NavBarNames.campaignCategory,
      codename: PermissionCodes.campaigncategory,
      main: () => <CampaignCategory />,
    },
    {
      path: '/messages',
      location: {
        pathname: '/messages',
        search: queryString.stringify({
          page: 1,
          rank: true,
          search: '',
        }),
      },
      exact: true,
      name: NavBarNames.message,
      codename: PermissionCodes.message,
      main: () => <MessageList />,
    },
    {
      path: '/earns',
      location: {
        pathname: '/earns',
      },
      exact: true,
      name: NavBarNames.earnRules,
      codename: PermissionCodes.earningrule,
      main: () => <EarningRuleList />,
    },
    {
      path: '/coupons',
      location: {
        pathname: '/coupons',
      },
      exact: true,
      name: NavBarNames.coupons,
      codename: PermissionCodes.coupontemplate,
      main: () => <CouponList />,
    },
    {
      path: '/languages',
      location: {
        pathname: '/languages',
      },
      exact: true,
      name: NavBarNames.langauge,
      codename: PermissionCodes.language,
      main: () => <LanguageList />,
    },
  ],
};

const merchants = {
  name: 'Merchants',
  list: [
    {
      path: '/brands',
      location: {
        pathname: '/brands',
        search: queryString.stringify({
          page: 1,
          rank: true,
          search: '',
        }),
      },
      exact: true,
      name: NavBarNames.brands,
      codename: PermissionCodes.brand,
      main: () => <BrandList />,
    },
    {
      path: '/stores',
      location: {
        pathname: '/stores',
      },
      exact: true,
      name: NavBarNames.stores,
      codename: PermissionCodes.store,
      main: () => <StoreList />,
    },
    {
      path: '/store_category',
      location: {
        pathname: '/store_category',
      },
      exact: true,
      name: NavBarNames.storeCategory,
      codename: PermissionCodes.storecategory,
      main: () => <StoreCategory />,
    },
    {
      path: '/store_codes',
      location: {
        pathname: '/store_codes',
      },
      exact: true,
      name: NavBarNames.storeCodes,
      codename: PermissionCodes.store,
      main: () => <StoreCodeList />,
    },
  ],
};

const others = {
  name: 'Others',
  list: [
    {
      path: '/webview',
      // location: {
      //   pathname: '/webview',
      // },
      exact: true,
      name: NavBarNames.others,
      main: () => <WebviewContent />,
    },
  ],
};

const records = {
  name: 'Record',
  list: [
    {
      path: '/transactions',
      location: {
        pathname: '/transactions',
        search: queryString.stringify({
          [transactionQueryKey.searchKey]: '',
          [transactionQueryKey.page]: 1,
          [transactionQueryKey.rank]: true,
          [transactionQueryKey.tab]: 'all',
        }),
      },
      exact: true,
      name: NavBarNames.transactions,
      codename: PermissionCodes.transaction,
      main: () => <TransactionList />,
    },
    {
      path: '/point_records',
      location: {
        pathname: '/point_records',
        search: queryString.stringify({
          [pointRecordQueryKey.searchKey]: '',
          [pointRecordQueryKey.page]: 1,
          [pointRecordQueryKey.rank]: true,
          [pointRecordQueryKey.tab]: 'all',
        }),
      },
      exact: true,
      name: NavBarNames.pointRecords,
      codename: PermissionCodes.pointtransaction,
      main: () => <PointTransactionList />,
    },
    {
      path: '/coupon_records',
      location: {
        pathname: '/coupon_records',
        search: queryString.stringify({
          [couponRecordQueryKey.searchKey]: '',
          [couponRecordQueryKey.page]: 1,
          [couponRecordQueryKey.rank]: true,
          [couponRecordQueryKey.tab]: 'all',
        }),
      },
      exact: true,
      name: NavBarNames.couponRecords,
      codename: PermissionCodes.coupontransaction,
      main: () => <CouponTransaction />,
    },
  ],
};

const homemanager = {
  name: 'Manage Home page',
  list: [
    {
      path: '/banners',
      location: {
        pathname: '/banners',
      },
      exact: true,
      name: NavBarNames.banners,
      codename: PermissionCodes.banners,
      main: () => <BannerList />,
    },
    {
      path: '/home_campaigns',
      location: {
        pathname: '/home_campaigns',
      },
      exact: true,
      name: NavBarNames.featuredCampaign,
      codename: PermissionCodes.campaign,
      main: () => <FeaturedCampaignList />,
    },
  ],
};

export const tabList = [
  dashboard,
  customers,
  engagement,
  merchants,
  others,
  records,
  admin,
  homemanager,
];
