import React from 'react';
import { useSelector } from 'react-redux';
import './CouponsOverview.scss';
import OverviewCard from '../../dashboard/OverviewCard';

const COUPOINS_DATA_LIST_CONFIG = [
  {
    title: 'Total number of coupon activity',
    key: 'totalCouponActivities',
  },
  {
    title: 'Total number of coupon redeemed by points',
    key: 'totalCouponRedeemByPoints',
  },
  {
    title: 'Total number of coupon used',
    key: 'totalCouponUsed',
  },
  {
    title: 'Total number of coupon aquired by campaigns',
    key: 'totalCouponAcquiredByCampaigns',
  },
];

const CouponsOverview = () => {
  const couponsOverviewData = useSelector((state) => state.overview.coupons);
  return (
    <div className="overview-coupons-container">
      <div className="overview-row-title">coupons</div>
      <div className="overview-row">
        {COUPOINS_DATA_LIST_CONFIG.map((config) => (
          <OverviewCard
            title={config.title}
            value={couponsOverviewData[config.key]?.value}
            change={couponsOverviewData[config.key]?.change}
          />
        ))}
      </div>
    </div>
  );
};

export default CouponsOverview;
