import React, { useEffect } from 'react';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { createAction } from '../../utils';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import CustomRadios from '../base/CustomRadios';

function CustomInputWithTitle({
  title,
  defaultValue,
  setValue,
  options,
  error,
  related = {},
}) {
  const relatedComponment = () => {
    // console.log('@@@20:', related);
    switch (related.type) {
      case 'input':
        // console.log('@@@20');
        return (
          <input
            type="text"
            onChange={({ target }) => {
              related.setValue(target.value);
            }}
            value={related.defaultValue}
            className={`custom-markdown-area-title custom-markdown-area-title-short`}
            onFocus={() => {}}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div style={{ display: 'grid' }}>
      <CustomTitleLabel title={title} />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <CustomRadios
          onChange={(value) => {
            // setRewardType(value);
            setValue(value);
          }}
          default={defaultValue}
          options={options}
        />
      </div>
      {relatedComponment()}
    </div>
  );
}

export default CustomInputWithTitle;
