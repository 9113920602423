import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import BaseMultipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import BaseEditSection from '../../containers/base/BaseEditSection';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { AdminGroupErrorHandleFields } from '../../containers/admin/adminGroups/AdminGroupErrorHandleFields'; 
import {
    ReactHookFormErrorMessage,
  } from '../base/ErrorFieldMessage';  

function CreateAdminGroupStepOneGeneralSection({
  allPermissions,
}) {
  const { watch, setValue, control, formState } =
  useFormContext();
  const dispatch = useDispatch();
  const { errors } = formState;
  const watchName = watch('name');
  const watchPermissions = watch('permissions') || [];

  useEffect(() => {
    dispatch({
      type: 'admin/getAllPermissions',
      payload: {}
    });
    return () => {
      dispatch({
        type: 'admin/clearState',
      })
    };
  }, [])

  return (
    <>
      <BaseEditSection
      title="General"
      fields={[
        <Controller
        control={control}
        name="name"
        rules={{ required: AdminGroupErrorHandleFields.name.required }}
        render={() => (
          <CustomTitleWithInput
            title={'Name'}
            defaultValue={watchName}
            setValue={(value) => {
              setValue('name', value, {
                shouldDirty: true,
              });
            }}
          />
        )}
        />,
        <ReactHookFormErrorMessage errors={errors} id="name" />,
        <Controller
        control={control}
        name="permissions"
        render={() => (
          <BaseMultipleSelectorV2
            title={'Administrator group(s) permission(optional)'}
            namespace="admin"
            data={{
              sourceData: allPermissions,
              targetData: watchPermissions,
              targetChange: (value) => {
                console.log('targetChange @65', value);
                setValue('permissions', value, {
                  shouldDirty: true,
                });
              },
            }}
          />
          )}
        />,
        <ReactHookFormErrorMessage errors={errors} id="permissions" />,
      ]}
    />
    </>
  )
}

const mapPropsToState = (state) => ({
  allPermissions: state.admin.allPermissionList, // state.admin.cmsAllPermissions,
});

export default connect(mapPropsToState)(CreateAdminGroupStepOneGeneralSection)