import React, { useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';
import {
  EarningRuleRewardType,
  EarningRuleType,
} from '../../../config/CustomEnums';

function Filter({ backAction = () => {} }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchType = parsedSearch['type'] || '';
  const searchRewardType = parsedSearch['reward_type'] || '';

  const [type, setType] = useState(searchType);
  const [rewardType, setRewardType] = useState(
    ArrayStringData(searchRewardType),
  );

  const content = [
    {
      title: 'Type',
      data: [
        { pk: EarningRuleType.qrCodeScanning, name: 'QR Code' },
        { pk: EarningRuleType.gpsCheckIn, name: 'GPS' },
        { pk: EarningRuleType.memberReferral, name: 'Referral' },
        { pk: EarningRuleType.generalPurchase, name: 'Purchase' },
        { pk: EarningRuleType.newMember, name: 'New Member' },
        { pk: EarningRuleType.birthday, name: 'Birthday' },
        { pk: EarningRuleType.fillingForm, name: 'Filling Form'}
      ],
      value: type,
      setValue: setType,
      componment: FilterTypeEnum.singleChoice,
    },
    {
      title: 'Reward type',
      data: [
        { name: 'Coupons', pk: EarningRuleRewardType.coupons },
        { name: 'Points', pk: EarningRuleRewardType.points },
      ],
      value: rewardType,
      setValue: setRewardType,
      componment: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
