import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import i18n from '../../I18n';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomEditor from '../base/CustomEditor';
import { ReactHookFormImageUploader } from '../base/ImageUploader';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import ImageUploader from '../base/ImageUploader';
import { LanguageConfig } from '../../config/CustomEnums';
import './CreateBrandContent.scss';

function CreateBrandContent({
  language,
  selectedBrand,
}) {
  const params = useParams();
  const dispatch = useDispatch();

  const { watch, setValue, formState, control, getValues } = useFormContext();
  const errors = formState.errors;

  const coverPhotoField = 'coverPhoto';
  const nameField = `translations.${language}.name`;
  const descriptionField = `translations.${language}.description`;

  const coverPhoto = watch(coverPhotoField);
  const name = watch(nameField);
  const description = watch(descriptionField);

  const noneTranslationArea = () => {
    if (language !== LanguageConfig.english) {
      return null;
    }
    return (
      <div className="second-section">
        <div className="second-section-title">Logo image</div>
        <div className="second-section_description">
          {i18n.t('square_cover_photo_suggest', { locale: language })}
        </div>
        <span id={'brandIcon'} />
        <ImageUploader
          images={coverPhoto ? [coverPhoto] : []}
          aspect={1 / 1}
          maxImageNum={1}
          minWidth={600}
          minHeight={600}
          language={LanguageConfig.english}
          onImageStateChange={(data) => {
            setValue(coverPhotoField, data?.[0], { shouldDirty: true });
          }}
          uploadImageClicked={() => {}}
        />
        <ReactHookFormErrorMessage errors={errors} id={coverPhotoField} />
        {params.id
          ? (
            <>
              <div>
                <CustomTitleLabel title={'Related store'} />
              </div>
              <label className="related-store-name">
                {selectedBrand.relatedStoreNames || '-'}
              </label>
            </>
          )
          : null}
      </div>
    );
  };

  return (
    <>
      <div className="first-section-title">
        {i18n.t('general', { locale: language })}
      </div>
      <CustomTitleWithInput
        title={i18n.t('name', { locale: language })}
        error={{ error: hasError(errors, nameField) }}
        type={'text'}
        customClass={`text-input-field date-picker `}
        defaultValue={name}
        setValue={(value) => {
          setValue(nameField, value, { shouldDirty: true, });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id={nameField} />
      <CustomTitleLabel
        title={i18n.t('description_optional', { locale: language })}
      />
      <CustomEditor
        initialValue={description}
        onValueChange={(data) => {
          setValue(descriptionField, data, { shouldDirty: true });
        }}
      />
      {noneTranslationArea()}
    </>
  );
}

const mapPropsToState = (state) => ({
  selectedBrand: state.brand.selectedBrand,
})

export default connect(mapPropsToState)(CreateBrandContent);
