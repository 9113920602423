import React, { useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { BANNER_CONTENT_TYPE } from '../../../config/CustomEnums';

function Filter({ backAction = () => {} }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchType = parsedSearch['type'] || '';

  const [type, setType] = useState(searchType);

  const content = [
    {
      title: 'Type',
      data: [
        { pk: BANNER_CONTENT_TYPE.CAMPAIGN, name: 'Campaigns' },
        { pk: BANNER_CONTENT_TYPE.PRODUCT, name: 'Products' },
      ],
      value: type,
      setValue: setType,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
