import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { createAction } from '../../utils';
import {
  SectionTopTitle,
  FieldControl,
} from '../base/CommonComponent';
import AddNewAdminGroupPrompt from './AddNewAdminGroupPrompt';
import { PermissionCodes } from '../../config/PermissionCodes';
import BaseMutipleSelectorV2 from '../base/BaseMultipleSelectorV2';

const PERMISSION_TYPE = {
  GROUP: 'GROUP',
  PERMISSION: 'PERMISSION',
};

function PermissionFieldControl({
  name,
  type,
  datas,
  selected,
  setValue,
  button,
}) {
  const isGroup = type === PERMISSION_TYPE.GROUP;
  const title = isGroup ? 'Administrator groups' : 'Administrator permission';
  const namespace = isGroup ? 'adminGroup' : 'admin';
  const tips = isGroup
    ? 'The groups this administrator belongs to. A administrator will get all permissions granted to each of their groups.'
    : 'Specific permissions for this user.';
  return (
    <FieldControl
      name={name}
      render={() => (
        <>
          <BaseMutipleSelectorV2
            title={title}
            namespace={namespace}
            data={{
              sourceData: datas,
              targetData: selected,
              targetChange: setValue,
            }}
            requires={PermissionCodes.addAdministratorGroup}
            addButton={button}
            tips={tips}
          />
        </>
      )}
    />
  );
}

function PermissionSections({
    permissionGroups,
    allPermissions,
}) {
  const { watch, setValue } = useFormContext();
  const watchPermissionGroups = watch('groups') || [];
  const watchPermissions = watch('permissions') || [];
  const groupName = 'groups';
  const dispatch = useDispatch();
  const [showAddAdminGroupPrompt, setShowAddAdminGroupPrompt] = useState(false);
  useEffect(() => {
    dispatch({
      type: 'admin/getAllPermissions',
      payload: { },
    });
    dispatch({ type: 'adminGroup/getAllList', payload: {} });
  }, []);

  const getPermissionGroup = ({
    type,
    datas,
    selected,
    setValue,
    button,
    name,
  }) => {
    return (
      <PermissionFieldControl
        type={type}
        datas={datas}
        selected={selected}
        setValue={setValue}
        button={button}
        name={name}
      />
    );
  };

  const hktbGroup = getPermissionGroup({
    type: PERMISSION_TYPE.GROUP,
    datas: permissionGroups,
    selected: watchPermissionGroups,
    setValue: (value) => {
      setValue('groups', value, { shouldDirty: true });
    },
    button: {
      title: 'Add Administrator group',
      action: () => setShowAddAdminGroupPrompt(true),
      customClass: 'btn-back-button-common add-remove-botton-style',
    },
    name: 'groups',
  });

  const hktbPermission = getPermissionGroup({
    type: PERMISSION_TYPE.PERMISSION,
    datas: allPermissions,
    selected: watchPermissions,
    setValue: (value) => {
      setValue('permissions', value, { shouldDirty: true });
    },
    name: 'permissions',
  });



  return (
    <>
      <SectionTopTitle title="Permission(optional)" />
        <>
          {hktbGroup}
          {hktbPermission}
        </>

      <AddNewAdminGroupPrompt
        show={showAddAdminGroupPrompt}
        onClose={() => setShowAddAdminGroupPrompt(false)}
        onConfirm={(item) => {
          dispatch(
            createAction('adminGroup/getAllList')({}),
          );
          setValue(
            groupName,
            [
              ...watchPermissionGroups,
              item,
            ],
            {
              shouldDirty: true,
            },
          );
          setShowAddAdminGroupPrompt(false);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
    permissionGroups: state.adminGroup.notPagedAllList || [],
    allPermissions: state.admin.allPermissionList || [],
})

export default connect(mapPropsToState)(PermissionSections);
