import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import ContentSections from '../base/ContentSections';
import MembershipInfoCard from '../customer/MembershipInfoCard';
import MessageOverviewTableSection from './MessageOverviewTableSection';
import {
  BackAndMultipleButtons,
  SaveTempButton,
  SaveButton,
} from '../base/BottomStepComponent';
import { formatDate, TimeFormater } from '../../utils/TimeFormatUtil';
import TestAccountPrompt from './TestAccountPrompt';
import CreateMessageSuccessPrompt from './CreateMessageSuccessPrompt';
import { PermissionCodes } from '../../config/PermissionCodes';
import BasePrompt from '../base/prompt/BasePrompt';
import ListButtonsGroup from '../base/ListButtonsGroup';
import { validate } from '../../containers/engagement/message/CreateMessageValidate';


function CreateMessageStepFour({
  languages
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const { watch, getValues, setValue, control, formState, setError, clearErrors } = useFormContext();
  const { errors } = formState;

  const message = getValues();
  message.displayChannels = message.channels?.toString();
  message.relatedCampaignName = message.relatedCampaign.name;
  message.displayTargetCustomer = (message.targetCustomerGroup || []).map((item) => item.name).toString();
  message.displayTargetedSegments = (message.targetedSegments || []).map((item) => item.name).toString();
  message.deliveryDate = formatDate(message.scheduledDate, TimeFormater.dayMonthYearWeekTimeA);

  const [showTestPrompt, setShowTestPrompt] = useState(false);
  const [showSentPrompt, setShowSentPrompt] = useState(false);
  const [isTestSend, setIsTestSend] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [showTimeoutPrompt, setShowTimeoutPrompt] = useState(false);

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 3,
      isBack,
    });
    dispatch({
      type: 'createMessage/stepChange',
      payload: {
        isBack: isBack,
        step: 3,
        isValid
      },
    });
  };


  const renderTabForLanguage = (language) => {
    return [
      <MessageOverviewTableSection
        message={message}
        language={language}
      />,
      <MembershipInfoCard
        title={'PROPERTIES'}
        data={message}
        fields={[
          [{ title: 'Message channel', field: 'displayChannels' }],
          [{ title: 'Related campaign', field: 'relatedCampaignName' }],
          [{ title: 'Target customers', field: 'displayTargetCustomer' }],
          [{ title: 'Target segments', field: 'displayTargetedSegments' }],
          [{ title: 'Delivery Time', field: 'deliveryDate' }],
        ]}
      />,
    ];
  };

  const saveButtonAction = (isSaveOnly) => {
    if (new Date(message.scheduledDate) < new Date()) {
      setShowTimeoutPrompt(true);
      return;
    }
    setIsDraft(isSaveOnly);
    if (location.pathname.includes('edit')) {
      dispatch({
        type: 'createMessage/updateMessage',
        payload: {
          isDraft: isSaveOnly,
          values: getValues(),
          languages: languages,
          afterActions: () => {
            setIsTestSend(false);
            setShowSentPrompt(true);
          },
        },
      });
    } else {
      dispatch({
        type: 'createMessage/createMessage',
        payload: {
          isDraft: isSaveOnly,
          values: getValues(),
          languages: languages,
          afterActions: () => {
            setIsTestSend(false);
            setShowSentPrompt(isSaveOnly);
          },
        },
      });
    }
  };

  return (
    <>
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: renderTabForLanguage(item.code),
            key: item.code,
            title: item.sourceName,
          })),
        }}
        hidePreview={true}
      />
      <BackAndMultipleButtons
        backAction={() => {
          stepChangeAction(true)
        }}
        multipleButtons={[
          <ListButtonsGroup
            hideExtraButtonWidth={600}
            hideAllButtonWidth={400}
            hideExtraPopPosition="top"
            hideAllPopPosition="topRight"
            extraButtons={[
              <SaveTempButton
                text="Test Channel"
                action={() => {
                  setShowTestPrompt(true);
                }}
              />,
              <SaveTempButton
                text="Save Only"
                action={() => {
                  saveButtonAction(true);
                }}
              />,
            ]}
            extraPopContent={[
              {
                content: 'Test Channel',
                action: () => {
                  setShowTestPrompt(true);
                },
                requires: PermissionCodes.testMessage,
              },
              {
                content: 'Save Only',
                action: () => saveButtonAction(true),
              },
            ]}
            primaryButton={
              <SaveButton
                text="Schedule"
                action={() => saveButtonAction(false)}
              />
            }
            primaryPopContent={{
              requires: PermissionCodes.addAdministratorGroup,
              action: () => saveButtonAction(false),
              content: 'Create Administrator group',
            }}
          />,
          // {
          //   text: 'Test Channel',
          //   action: () => {
          //     setShowTestPrompt(true);
          //   },
          //   requires: PermissionCodes.testMessage,
          // },
          // {
          //   text: 'Save Only',
          //   action: () => saveButtonAction(true),
          // },
          // {
          //   text: 'Schedule',
          //   action: () => saveButtonAction(false),
          // },
        ]}
      />
      <TestAccountPrompt
        show={showTestPrompt}
        onHide={() => setShowTestPrompt(false)}
        onConfirm={(selectedAccount) => {
          setShowTestPrompt(false);
          dispatch({
            type: 'createMessage/testMessageSend',
            payload: {
              isEdit: location.pathname.includes('edit'),
              values: getValues(),
              languages: languages,
              customer: selectedAccount,
              afterActions: () => {
                setIsTestSend(true);
                setShowSentPrompt(true);
              },
            },
          });
        }}
      />

      <CreateMessageSuccessPrompt
        isTest={isTestSend}
        isDraft={isDraft}
        show={showSentPrompt}
        onClose={() => setShowSentPrompt(false)}
      />

      <BasePrompt
        show={showTimeoutPrompt}
        closeAction={() => setShowTimeoutPrompt(false)}
        rightButton={{
          text: 'Reschedule',
          action: () => {
            setShowTimeoutPrompt(false);
            dispatch({
              type: 'createMessage/updateState',
              payload: {
                currentStep: 2,
              }
            });
          },
        }}
        leftButton={{
          text: 'Cancel',
          action: () => setShowTimeoutPrompt(false),
        }}
        title={'Schedule timeout'}
        description={
          'Your schecule time is expried, please update delivery time.'
        }
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
})

export default connect(mapPropsToState)(CreateMessageStepFour);
