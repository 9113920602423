import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseForm from '../../../components/base/v2/BaseForm';
import DeletePrompt from '../../../components/base/DeletePrompt';
import {
  DELETE_RELATED_SECTIONS,
} from '../../../config/CustomEnums';
import CreateBrandStepOne from './CreateBrandStepOne';

function CreateBrand({
  stepConfig,
  selectedBrand,
  hasUpdatedDefaultValues,
  formHasSubmitted,
}) {
  const queryString = require('query-string');
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [completeProcess, setShowCompleteProcess] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  useEffect(() => {
    if (params.id) {
      dispatch({
        type: 'brand/getBrand',
        payload: {
          brandPK: params.id,
        }
      });
    } else {
      dispatch({
        type: 'brand/loadBrandFromCookie',
        payload: {}
      });
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'brand/cleanSelectedBrand',
        payload: {}
      });
    };
  }, [dispatch]);

  const stepContent = [
    <CreateBrandStepOne
      setShowCompleteProcess={setShowCompleteProcess}
      setShowDeletePrompt={setShowDeletePrompt}
    />,
  ];

  const gotoBrandList = () => {
    history.push({
      pathname: '/brands',
      search: queryString.stringify({
        page: 1,
        rank: true,
        search: '',
      }),
    });
  };

  return (
    <>
      <BaseForm
        defaultValues={selectedBrand}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          dispatch({
            type: 'brand/saveOrRemoveBrandFromCookie',
            payload: {
              save,
              values: getValues()
            },
          });
        }}
        stepsConfig={stepConfig}
        content={stepContent}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb name={params.id ? selectedBrand.name : ''} />
        }
        caution={{
          detail: '', //Create various brands and select them in a campaign.
          title: params.id ? 'Edit ' + selectedBrand.name : 'Create Brand',
        }}
        showFinishPop={completeProcess}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Brand is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to brand list',
              action: gotoBrandList,
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: gotoBrandList,
          onClose: gotoBrandList,
        }}
        hasleavePagePrompt={params.id}
      />

      <DeletePrompt
        title={'Brand'}
        data={selectedBrand}
        relatedSections={DELETE_RELATED_SECTIONS.BRAND}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'brand/delete',
            payload: {
              afterAction: () => history.push('/brands'),
            }
          });
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  stepConfig: state.brand.stepConfig,
  selectedBrand: state.brand.selectedBrand,
  hasUpdatedDefaultValues: state.brand.hasUpdatedDefaultValues,
  formHasSubmitted: state.brand.formHasSubmitted,
});

export default connect(mapPropsToState)(CreateBrand);
