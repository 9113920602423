import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import CustomMultipleSelect from '../base/CustomMultipleSelect';
import { Image } from 'react-bootstrap';
import addOn from '../../assets/images/addOn.svg';
import addOff from '../../assets/images/addOff.svg';
import removeOn from '../../assets/images/removeOn.svg';
import removeOff from '../../assets/images/removeOff.svg';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { createAction, useCompare } from '../../utils';
import './CustomAddRemoveMutipleSelector.scss';
import AuthButton from '../base/AuthButton';
import { useDispatch } from 'react-redux';
import { withResizeDetector } from 'react-resize-detector';

const options = (source) => {
  if (!source) {
    return [];
  }
  return source.map((item) => {
    return {
      name: item.name,
      value: item.value,
    };
  });
};

function CustomAddRemoveMutipleSelector({
  width,
  title,
  data = [],
  selectedList = [],
  addButton,
  setValue,
  sourceReminderText,
  amidReminderText,
  error = {},
  isSourceReminderFilterable = false,
  isAmidRreminderFilterable = false,
  sourceReminderEnabelSelectAll = false,
  sourceReminderSubfilterKey,
  aimdReminderSubfilterKey,
  sourceReminderSubFilterItems = [],
  aimdReminderSubFilterItems = [],
  selectAllTitle,
  requires,
  onFocusFunc = () => {},
  loadMoreAction,
  filterAction,
  isReset = false,
  defaultFilter = {},
  groupKey,
}) {
  let hasMultiLines = false;
  if (width < 707) {
    hasMultiLines = true;
  }
  const history = useHistory();
  const dispatch = useDispatch();

  const getFilteredList = (operatedList, filteredList) => {
    return operatedList?.filter((val) => {
      if (val.value) {
        if (val.value.pk)
          return (
            filteredList?.filter((item) => item.value.pk === val.value.pk)
              .length === 0
          );
        return (
          filteredList?.filter((item) => item.value === val.value).length <= 0
        );
      } else {
        // return !filteredList?.includes(val);
        return (
          filteredList?.length <= 0 ||
          filteredList?.filter((item) => {
            // console.log('@@======@@:', item.pk, val.pk, item.pk !== val.pk);
            return item.pk !== val.pk;
          }).length === filteredList.length
        );
      }
    });
  };
  const [amid, setAmid] = useState(selectedList || []);
  const [sourceSelected, setSourceSelected] = useState();
  const [selected, setSelected] = useState();
  const [removeResetSelected, setRemoveResetSelected] = useState(false);
  const [addResetSelected, setAddResetSelected] = useState(false);
  const [source, setSource] = useState(getFilteredList(data, selectedList));

  const add = () => {
    if (!sourceSelected?.length) {
      return;
    }
    setSource(source.filter((val) => !sourceSelected?.includes(val)));
    setAmid([...amid, ...sourceSelected]);
    setSourceSelected([]);
    setAddResetSelected(true);
  };

  const remove = () => {
    if (!selected?.length) {
      return;
    }
    setAmid(getFilteredList(amid, selected));
    setSource([...source, ...selected]);
    setSelected([]);
    setRemoveResetSelected(true);
  };

  useEffect(() => {
    loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isReset) {
      setSource(getFilteredList(data, selectedList));
      setAmid(selectedList);
      setAddResetSelected(true);
      setRemoveResetSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReset]);

  const dataChanged = useCompare(data);
  const selectedListChanged = useCompare(selectedList);
  useEffect(() => {
    if (dataChanged) {
      const newSource = getFilteredList(data, selectedList);
      console.log('@@124: ', source, newSource, currentPage);
      setSource(currentPage > 1 ? [...source, ...newSource] : newSource);
    }
    if (selectedListChanged) {
      setAmid(selectedList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataChanged, selectedListChanged, selectedList]);

  const amidValueHasChanged = useCompare(amid);
  useEffect(() => {
    if (amidValueHasChanged) {
      setValue(amid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amid, setValue, amidValueHasChanged]);

  const [currentPage, setCurrentPage] = useState(0);
  const [filterKey, setFilterKey] = useState('');
  const [moreFilter, setMoreFilter] = useState('');

  const loadMore = () => {
    if (!loadMoreAction) {
      return;
    }

    const nextPage = currentPage + 1;
    console.log('@@55: ', nextPage, filterKey, defaultFilter);
    dispatch(
      createAction(loadMoreAction)({
        page: nextPage,
        search: filterKey,
        searchKey: filterKey,
        isSelectorLoad: true,
        moreSearch: moreFilter,
        ...defaultFilter,
      }),
    );

    setCurrentPage(nextPage);
  };

  const filter = (search, moreSearch) => {
    if (!filterAction) {
      return;
    }

    let nextPage = currentPage + 1;
    if (search !== filterKey) {
      nextPage = 1;
    }

    if (moreSearch !== moreFilter) {
      nextPage = 1;
    }
    console.log('@@55: ', search, nextPage);
    dispatch(
      createAction(filterAction)({
        page: nextPage,
        search: search,
        searchKey: search,
        isSelectorLoad: true,
        reverse: true,
        moreSearch: moreSearch,
      }),
    );

    setCurrentPage(nextPage);
    setFilterKey(search);
    setMoreFilter(moreSearch);
  };

  return (
    <div className="d-flex flex-column">
      {title ? <CustomTitleLabel title={title} /> : null}
      <div className="custom-add-remove-mutiple-selector">
        <div
          className="d-flex flex-column"
          style={width < 330 ? { width } : { width: 330 }}
        >
          <CustomMultipleSelect
            isFilterable={isSourceReminderFilterable}
            enableSelectAll={sourceReminderEnabelSelectAll}
            subFilterKey={sourceReminderSubfilterKey}
            subFilterItems={sourceReminderSubFilterItems}
            defaultSelected={[]}
            options={source}
            selectAllTitle={selectAllTitle}
            onSelectChange={(options) => {
              //   setSelectStores(options);
              setAddResetSelected(false);
              setSourceSelected(options);

              onFocusFunc();
              // console.log('@@69: ', options);
            }}
            hideAddButton={true}
            hideTitle={true}
            isReset={addResetSelected}
            reminder={sourceReminderText}
            error={error.error}
            loadMoreAction={loadMore}
            filterAction={filter}
            groupKey={groupKey}
          />
        </div>
        <div
          className={`${
            hasMultiLines
              ? 'custom-add-remove-mutiple-selector-buttons-line-break'
              : 'custom-add-remove-mutiple-selector-buttons'
          }`}
        >
          <div
            className={`d-flex custom-add-remove-mutiple-selector-buttons-area ${
              hasMultiLines ? 'flex-row' : 'flex-column'
            }`}
          >
            <Image
              className="add-remove-botton margin-left-10-percent"
              onClick={() => add()}
              src={sourceSelected?.length ? addOn : addOff}
            />

            <Image
              src={selected?.length ? removeOn : removeOff}
              className="add-remove-botton margin-left-10-percent add-remove-botton-space"
              onClick={() => remove()}
            />
          </div>
        </div>

        <CustomMultipleSelect
          isFilterable={isAmidRreminderFilterable}
          subFilterKey={aimdReminderSubfilterKey}
          subFilterItems={aimdReminderSubFilterItems}
          defaultSelected={[]}
          options={amid}
          onSelectChange={(options) => {
            //   setSelectStores(options);
            setRemoveResetSelected(false);
            setSelected(options);

            onFocusFunc();
          }}
          hideAddButton={true}
          isReset={removeResetSelected}
          hideTitle={true}
          reminder={amidReminderText}
          error={error.error}
          groupKey={groupKey}
        />
      </div>
      {addButton ? (
        <div className="custom-add-remove-mutiple-selector-add-btn-container">
          <AuthButton
            title={addButton.title}
            requires={requires}
            customClass="btn-further add-remove-botton-style"
            action={() => {
              if (addButton.link) {
                history.push(addButton.link);
              }
              if (addButton.action) {
                addButton.action();
              }
            }}
          />
        </div>
      ) : null}
      <ErrorFieldMessage id={error.id} error={error} message={error.message} />
    </div>
  );
}

export default withResizeDetector(CustomAddRemoveMutipleSelector);
