import { CampaignErrorHandleField } from '../../../components/campaign/campaignCreation/CreateCampaignHandleError';
import { isValidHttpUrl, reducer } from '../../../utils';
import { CampaignType } from '../../../config/CustomEnums';
import {
  GENERAL_TYPE,
  isShowCouponRelated,
  isShowEarningRuleRelated,
} from '../../../models/CreateCampaignModel';

function firstStepValidate({ getValues, setError }) {
  return true;
}

function secondStepValidate({ getValues, setError }) {
  const [
    generalName,
    coverPhoto,
    isExclusive,
    square,
    shortDescription,
  ] = getValues([
    'translations.en.generalName',
    'translations.en.coverPhoto',
    'isExclusive',
    'translations.en.squareCoverPhoto',
    'translations.en.shortDescription'
  ])
  const errorList = [];
  if (!generalName || generalName.length > 200) {
    errorList.push({
      name: 'translations.en.generalName',
      message: CampaignErrorHandleField.generalName.message,
    });
  }
  if (!shortDescription || shortDescription?.length > 200) {
    errorList.push({
      name: 'translations.en.shortDescription',
      message: CampaignErrorHandleField.shortDescription.message,
    });
  }
  if (!coverPhoto) {
    errorList.push({
      name: 'translations.en.coverPhoto',
      message: CampaignErrorHandleField.coverPhoto.message,
    });
  }
  if (isExclusive && !square) {
    errorList.push({
      name: 'translations.en.squareCoverPhoto',
      message: CampaignErrorHandleField.squarePhoto.message,
    });
  }

  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

const isEmpty = (value) => {
  return value === undefined || value === null || value === '' || isNaN(value);
}

function thirdStepValidate({ getValues, setError }) {
  const [
    isExclusive,
    activeStartDate,
    activeEndDate,
    visibleStartDate,
    visibleEndDate,
    campaignType,
    requiredPoints,
    isAlwaysActivePeriod,
    isAlwaysVisiblePeriod,
    customerGroup,
    segments,
    generalType,
    overallLimit,
    perHeadLimit,
    displayOverLimit,
    linkedCoupon,
  ] = getValues([
    'isExclusive',
    'activeStartDate',
    'activeEndDate',
    'visibleStartDate',
    'visibleEndDate',
    'campaignType',
    'requiredPoints',
    'isAlwaysActivePeriod',
    'isAlwaysVisiblePeriod',
    'customerGroup',
    'segments',
    'generalType',
    'overallLimit',
    'perHeadLimit',
    'displayOverLimit',
    'linkedCoupon'
  ])
  const errorList = [];
  if (isExclusive) {
    if (
      generalType === GENERAL_TYPE.customerGroup &&
      customerGroup?.length === 0
    ) {
      errorList.push({
        name: 'customerGroup',
        message: CampaignErrorHandleField.customerGroup.message,
      });
    }
    if (generalType === GENERAL_TYPE.segment && segments?.length === 0) {
      errorList.push({
        name: 'segments',
        message: CampaignErrorHandleField.segment.message,
      });
    }
  }
  if (
    !isAlwaysActivePeriod &&
    activeStartDate.getTime() >= activeEndDate.getTime()
  ) {
    errorList.push({
      name: 'activeEndDate',
      message: CampaignErrorHandleField.activeEndDate.message,
    });
    // errorFields.push(CampaignErrorHandleField.activeEndDate.name);
  }
  if (
    !isAlwaysVisiblePeriod &&
    visibleStartDate.getTime() >= visibleEndDate.getTime()
  ) {
    errorList.push({
      name: 'visibleEndDate',
      message: CampaignErrorHandleField.visibleEndDate.message,
    });
    // errorFields.push(CampaignErrorHandleField.visibleEndDate.name);
  }
  if (
    campaignType === CampaignType.couponsCampaign &&
    ((requiredPoints !== 0 &&
    !requiredPoints) || requiredPoints < 0)
  ) {
    errorList.push({
      name: 'requiredPoints',
      message: CampaignErrorHandleField.requiredPoints.message,
    });
    // errorFields.push(CampaignErrorHandleField.requiredPoints.name);
  }
  
  if(campaignType === CampaignType.couponsCampaign){
    const couponStock = linkedCoupon?.stock
    if(Number(overallLimit) > Number(couponStock)) {
      errorList.push({
        name: 'overallLimit',
        message: CampaignErrorHandleField.overallLimit.message,
      });
    }
    if(Number(perHeadLimit) > Number(overallLimit)) {
      errorList.push({
        name: 'perHeadLimit',
        message: CampaignErrorHandleField.perHeadLimit.message,
      });
    }
  }
  // return {
  //   invalid: isBack ? false : errorFields.length > 0,
  //   errorFields: isBack ? [] : errorFields,
  //   data: { ...data },
  // };
  // return true;
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors, step, isBack }) {
  console.log('@71 campaignValidate:', getValues());
  if (isBack) {
    return true;
  }
  clearErrors();
  switch (step) {
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
    case 1:
      const validStepTwo = secondStepValidate({ getValues, setError });
      return validStepTwo;
    case 2:
      const validStepThree = thirdStepValidate({ getValues, setError });
      return validStepThree;
    default:
      const validOne = firstStepValidate({ getValues, setError });
      const validTwo = secondStepValidate({ getValues, setError });
      const validThree = thirdStepValidate({ getValues, setError });
      return validOne && validTwo && validThree;
  }
}
