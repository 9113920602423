import {
  importCustomers,
  createDownloadTask,
  downloadFile,
  getSystemTasks,
  cancelSystemTask,
} from '../services/DownloadAndImportAPIHelper';
import { getFileNameFromUrl } from '../utils';
import { apiWithResponseHandle } from './LoadingUtil';
import { DownloadTag } from '../config/CustomEnums';
import { ToastType } from './NavBarModel';

export const ImportResource = {
  customer: 'CUSTOMER',
  coupon: 'COUPON',
  transaction: 'TRANSACTION',
  couponRecord: 'COUPON_RECORD',
  pointRecotd: 'POINTS_RECORD',
  generateCodeCoupon: 'GENERATE_CODE_COUPON',
  generateGenericCoupon: 'GENERATE_GENERIC_COUPON',
  generateQRCodeCoupon: 'GENERATE_QR_CODE_COUPON',
  generateImageCoupon: 'GENERATE_IMAGE_COUPON',
  storeQRCodeExport: 'STORE_QR_CODE'
};

const parseStatus = (item) => {
  if (item.resource === ImportResource.generateCodeCoupon) {
    switch (item.status) {
      case 'COMPLETED':
        if (item.type === 'EXPORT_JOB') return DownloadTag.completed;
        return DownloadTag.generated;
      case 'PENDING':
        if (item.type === 'EXPORT_JOB') return DownloadTag.pendingForExport;
        return DownloadTag.pendingForGenerate;
      case 'PROCESSING':
        if (item.type === 'EXPORT_JOB') return DownloadTag.downloading;
        return DownloadTag.generating;
      case 'ERROR':
        return DownloadTag.error;
      case 'CANCELLED':
        return DownloadTag.cancelled;
      default:
        return '';
    }
  }
  switch (item.status) {
    case 'COMPLETED':
      if (item.type === 'EXPORT_JOB') return DownloadTag.completed;
      return DownloadTag.importCompleted;
    case 'PENDING':
      if (item.type === 'EXPORT_JOB') return DownloadTag.pendingForExport;
      return DownloadTag.pendingForImport;
    case 'PROCESSING':
      if (item.type === 'EXPORT_JOB') return DownloadTag.downloading;
      return DownloadTag.importing;
    case 'ERROR':
      return DownloadTag.error;
    case 'CANCELLED':
      return DownloadTag.cancelled;
    default:
      return '';
  }
};

export default {
  namespace: 'downloadAndImport',
  state: {
    customerDownloads: [],
    customerImports: [],
    systemTasks: [],
    downloadingCount: 0,
    error: null,
    success: false,
    showTasks: false,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearData(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    showTasks(state, { payload }) {
      return {
        ...state,
        showTasks: true,
      };
    },
    hideTasks(state, { payload }) {
      return {
        ...state,
        showTasks: false,
      };
    },
  },
  effects: {
    getSystemTasks: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [getSystemTasks];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              systemTasks: data?.systemTasks.edges.map((item) => {
                if (
                  item.node.errors?.numberOfImported !== undefined &&
                  item.node.errors.numberOfImported !== null
                ) {
                  item.node.status = 'COMPLETED';
                }
                if (item.node.status === 'COMPLETED' && 
                   (item.node?.resource === ImportResource.generateCodeCoupon|| 
                    item.node?.resource === ImportResource.coupon)) {
                  item.node.couponLink = {
                    pathname: `/coupons/${item.node?.related?.templateId}`,
                    hash: "#Single%20Coupons%20Generated",
                  }
                }
                return {
                  ...item.node,
                  name: item.node.fileName,
                  displayStatus: parseStatus(item.node),
                };
              }),
              downloadingCount: data?.systemTasks.pendingAndProcessingCount,
            },
          });
        }
        function* onFailed(data) {
          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.serverError,
              },
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    importFile: [
      function* ({ payload }, { call, put }) {
        const data = {
          resource: payload.from,
          file: getFileNameFromUrl(payload.file?.import),
          related: payload.related,
        };

        const serviceArgs = [importCustomers, data];
        function* onSuccess(data) {
          yield put({ type: 'getSystemTasks' });

          yield put({
            type: 'uploadFiles/updateState',
            payload: {
              importedFile: '',
            },
          });

          yield put({
            type: 'updateState',
            payload: {
              error: {},
            },
          });
          if (payload.successAction) {
            payload.successAction();
          }
        }

        function* onFailed(data) {
          yield put({
            type: 'uploadFiles/updateState',
            payload: {
              importedFile: '',
            },
          });
          if (data.errors) {
            yield put({
              type: 'updateState',
              payload: {
                error: {
                  title: `Invalid ${payload.from.toLowerCase()} csv. file`,
                  content: `This file does not match the ${payload.from.toLowerCase()}.  Please check and try again.`,
                },
              },
            });
          }
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],
    createDownloadTask: [
      function* ({ payload }, { call, put }) {
        const afterAction = payload.afterAction || (() => {});
        const data = {
          resource: payload.from,
          related: payload.related,
        };
        const serviceArgs = [createDownloadTask, data];
        function* onSuccess(data) {
          yield put({ type: 'getSystemTasks' });
          afterAction();
        }
        function* onFailed(data) {
          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.serverError,
              },
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    downloadFile: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [downloadFile, payload.id];
        function* onSuccess(data) {
          // yield put({ type: 'getSystemTasks' });
        }
        function* onFailed(data) {
          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.serverError,
              },
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    cancelTask: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [
          cancelSystemTask,
          { id: payload.pk, type: payload.type },
        ];
        function* onSuccess(data) {
          yield put({ type: 'getSystemTasks' });
        }
        function* onFailed(data) {
          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.serverError,
              },
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
};
