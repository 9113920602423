import React from 'react';
import CreateCampaign from '../containers/engagement/campaign/CreateCampaign';
import CreateCoupon from '../containers/engagement/coupon/CreateCoupon';
import Dashboard from '../containers/dashboard/Dashboard';
import CampaignItemOverview from '../containers/engagement/campaign/CampaignItemOverview';
import CouponSetDetail from '../containers/engagement/coupon/CouponSetDetail';
import SingleCouponDetail from '../containers/engagement/coupon/SingleCouponDetail';
import CreateEarningRule from '../containers/engagement/earningRule/CreateEarningRule';
import CreateSegment from '../containers/customers/segments/CreateSegment';
import SegmentDetail from '../containers/customers/segments/SegmentDetail';
import CustomerOverviewDetail from '../containers/customers/customer/CustomerOverviewDetail';
import CustomerEditForm from '../containers/customers/customer/CustomerEditForm';
import PointTransactionList from '../containers/record/pointRecords/PointTransactionList';
import CreatePointRecord from '../containers/record/pointRecords/CreatePointRecord';
import PointTransactionOverview from '../containers/record/pointRecords/PointTransactionOverview';
import CouponTransaction from '../containers/record/couponRecords/CouponTransaction';
import CouponTransactionOverview from '../containers/record/couponRecords/CouponTransactionOverview';
import TransactionOverView from '../containers/record/records/TransactionOverview';
import CreateTransaction from '../containers/record/records/CreateTransaction';
import CreateAdminGroup from '../containers/admin/adminGroups/CreateAdminGroup';
import CreateAdmin from '../containers/admin/admins/CreateAdmin';
import CreateBrand from '../containers/merchants/brands/CreateBrand';
import BrandDetail from '../containers/merchants/brands/BrandDetail';
import CreateMessage from '../containers/engagement/message/CreateMessage';
import MessageOverview from '../containers/engagement/message/MessageOverview';
import AdminDetail from '../containers/admin/admins/AdminDetail';
import CampaignCategoryDetail from '../containers/engagement/campaignCategory/CampaignCategoryDetail';
import CreateCampaignCategory from '../containers/engagement/campaignCategory/CreateCampaignCategory';
import StoreDetail from '../containers/merchants/stores/StoreDetail';
import CreateStore from '../containers/merchants/stores/CreateStore';
import StoreCategoryDetail from '../containers/merchants/storeCategory/StoreCategoryDetail';
import CreateStoreCategory from '../containers/merchants/storeCategory/CreateStoreCategory';
import CreateCustomerGroup from '../containers/customers/customerGroup/CreateCustomerGroup';
import CustomerGroupDetail from '../containers/customers/customerGroup/CustomerGroupDetail';
import { PermissionCodes } from './PermissionCodes';
import CreateBanner from '../containers/homeManage/banners/CreateBanner';
import BannerDetail from '../containers/homeManage/banners/BannerDetail';
import FeaturedCampaignDetail from '../containers/homeManage/featuredCampaigns/FeaturedCampaignDetail';
import CreateLangauge from '../containers/engagement/language/CreateLanguage';
import LanguageDetail from '../containers/engagement/language/LanguageDetail';
import CampaignDetail from '../containers/engagement/campaign/CampaignDetail';

export const pageRoutes = [
  {
    path: '/',
    name: 'Home',
    exact: true,
    main: () => <Dashboard />,
  },
  {
    path: ['/campaigns/create', '/campaigns/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <CreateCampaign />,
  },
  {
    path: ['/campaigns/:id/overview'],
    exact: true,
    name: 'Overview',
    main: () => <CampaignItemOverview />,
  },
  {
    path: ['/campaigns/:id'],
    exact: true,
    codename: PermissionCodes.viewCampaign,
    main: () => <CampaignDetail />,
  },
  {
    path: ['/coupons/create', '/coupons/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeCouponTemplate,
    main: () => <CreateCoupon />,
  },
  {
    path: ['/coupons/:id/'],
    exact: true,
    codename: PermissionCodes.viewCouponTemplate,
    main: () => <CouponSetDetail />,
  },
  {
    path: [
      '/coupons/couponSet/:couponSetId/singleCoupon/:singleCouponId',
      '/coupons/:id/:singleCouponId/edit',
    ],
    exact: true,
    codename: PermissionCodes.viewCoupon,
    main: () => <SingleCouponDetail />,
  },
  {
    name: 'Create Earning Rule',
    path: '/earns/create',
    exact: true,
    codename: PermissionCodes.addEarningRule,
    main: () => <CreateEarningRule />,
  },
  {
    name: 'Edit Earning Rule',
    path: ['/earns/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeEarningRule,
    main: () => <CreateEarningRule />,
  },
  {
    name: 'Create Segment',
    path: ['/segments/create/'],
    exact: true,
    codename: PermissionCodes.addSegment,
    main: () => <CreateSegment />,
  },
  {
    path: ['/segments/:id/'],
    exact: true,
    codename: PermissionCodes.viewSegment,
    main: () => <SegmentDetail />,
  },
  {
    path: ['/segments/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeSegment,
    main: () => <CreateSegment />,
  },

  {
    path: ['/customers/:id/'],
    exact: true,
    codename: PermissionCodes.viewCustomer,
    main: () => <CustomerOverviewDetail />,
  },
  {
    path: ['/customers/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeCustomer,
    main: () => <CustomerEditForm />,
  },
  {
    name: 'Update Point record',
    path: ['/point_records/create', '/point_records/:id/edit'],
    exact: true,
    codename: PermissionCodes.changePointtransaction,
    main: () => <CreatePointRecord />,
  },
  {
    name: 'View Point record',
    path: ['/point_records/:id/'],
    exact: true,
    codename: PermissionCodes.viewPointtransaction,
    main: () => <PointTransactionOverview />,
  },
  {
    name: 'View Coupon records',
    path: ['/coupon_records/:id/'],
    exact: true,
    codename: PermissionCodes.viewCouponTransaction,
    main: () => <CouponTransactionOverview />,
  },
  {
    name: 'Create Transaction',
    path: ['/transactions/create'],
    exact: true,
    codename: PermissionCodes.addTransaction,
    main: () => <CreateTransaction />,
  },
  {
    name: 'Edit Transaction',
    path: ['/transactions/:id/edit'],
    exact: true,
    codename: PermissionCodes.addTransaction,
    main: () => <CreateTransaction />,
  },
  {
    name: 'View transaction',
    path: ['/transactions/:id/'],
    exact: true,
    codename: PermissionCodes.viewTransaction,
    main: () => <TransactionOverView />,
  },
  {
    name: 'Create Administrators group',
    path: ['/administrator_groups/create'],
    exact: true,
    codename: PermissionCodes.addAdministratorGroup,
    main: () => <CreateAdminGroup />,
  },
  {
    path: ['/administrator_groups/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeAdministratorGroup,
    main: () => <CreateAdminGroup />,
  },
  {
    name: 'Create Administrators',
    path: ['/administrators/create'],
    exact: true,
    codename: PermissionCodes.addAdministrator,
    main: () => <CreateAdmin />,
  },
  {
    path: ['/administrators/:id/'],
    exact: true,
    codename: PermissionCodes.viewAdministrator,
    main: () => <AdminDetail />,
  },

  {
    path: ['/administrators/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeAdministrator,
    main: () => <CreateAdmin />,
  },
  {
    name: 'Create Brand',
    path: ['/brands/create/'],
    exact: true,
    codename: PermissionCodes.changeBrand,
    main: () => <CreateBrand />,
  },
  {
    path: ['/brands/:id/'],
    exact: true,
    codename: PermissionCodes.brand,
    main: () => <BrandDetail />,
  },
  {
    path: ['/brands/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeBrand,
    main: () => <CreateBrand />,
  },
  {
    name: 'Create Message',
    path: ['/messages/create'],
    exact: true,
    codename: PermissionCodes.addMessage,
    main: () => <CreateMessage />,
  },
  {
    path: ['/messages/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeMessage,
    main: () => <CreateMessage />,
  },
  {
    name: 'View Message',
    path: ['/messages/:id/'],
    exact: true,
    codename: PermissionCodes.viewMessage,
    main: () => <MessageOverview />,
  },
  {
    name: 'Create campaign category',
    path: ['/campaign_category/create'],
    exact: true,
    codename: PermissionCodes.addCampaignCategory,
    main: () => <CreateCampaignCategory />,
  },
  {
    path: ['/campaign_category/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeCampaignCategory,
    main: () => <CreateCampaignCategory />,
  },
  {
    path: ['/campaign_category/:id/'],
    exact: true,
    codename: PermissionCodes.viewCampaignCategory,
    main: () => <CampaignCategoryDetail />,
  },

  {
    name: 'Create store',
    path: ['/stores/create'],
    exact: true,
    codename: PermissionCodes.addStore,
    main: () => <CreateStore />,
  },
  {
    path: ['/stores/:id/'],
    exact: true,
    codename: PermissionCodes.viewStore,
    main: () => <StoreDetail />,
  },
  {
    path: ['/stores/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeStore,
    main: () => <CreateStore />,
  },
  {
    name: 'Create store category',
    path: ['/store_category/create'],
    exact: true,
    codename: PermissionCodes.addStorecategory,
    main: () => <CreateStoreCategory />,
  },
  {
    path: ['/store_category/:id/'],
    exact: true,
    codename: PermissionCodes.viewSorecategory,
    main: () => <StoreCategoryDetail />,
  },
  {
    path: ['/store_category/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeStorecategory,
    main: () => <CreateStoreCategory />,
  },
  {
    name: 'Create Customer Group',
    path: ['/customer_group/create'],
    exact: true,
    codename: PermissionCodes.addCustomerGroup,
    main: () => <CreateCustomerGroup />,
  },
  {
    path: ['/customer_group/:id/'],
    exact: true,
    codename: PermissionCodes.customergroup,
    main: () => <CustomerGroupDetail />,
  },
  {
    path: ['/customer_group/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeCustomerGroup,
    main: () => <CreateCustomerGroup />,
  },
  {
    name: "Create Banner",
    path: ['/banners/create/'],
    exact: true,
    codename: PermissionCodes.changeBanner,
    main: () => <CreateBanner />,
  },
  {
    path: ['/banners/:id/'],
    exact: true,
    codename: PermissionCodes.viewBanner,
    main: () => <BannerDetail />,
  },
  {
    name: "Edit Banner",
    path: ['/banners/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeBanner,
    main: () => <CreateBanner />,
  },
  {
    path: ['/home_campaigns/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <FeaturedCampaignDetail />,
  },
  {
    path: ['/languages/create', '/languages/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeLanguage,
    main: () => <CreateLangauge />,
  },
  {
    path: ['/languages/:id/'],
    exact: true,
    codename: PermissionCodes.viewLanguage,
    main: () => <LanguageDetail />,
  },
];
