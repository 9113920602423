import React, { useState, useEffect } from 'react';
import './CustomRadios.scss';
import { Image } from 'react-bootstrap';
import CheckedRadio from '../../assets/images/checked_radio.svg';
import UncheckedRadio from '../../assets/images/unchecked_radio.svg';
function CustomRadios(props) {
  const onChange = props.onChange;
  const options = props.options;
  const [selected, setSelected] = useState();
  const disabled = props.disabled || false;

  useEffect(() => {
    setSelected(props.default);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.default]);

  return options.map((item, index) => {
    return (
      <div
        className={`radio-button-container`}
        key={`radios-${index}-div-${item.value}`}
        onClick={() => {
          if (disabled) {
            return;
          }
          if (item.value === selected) {
            return;
          }
          setSelected(item.value);
          onChange(item.value);
        }}
      >
        <Image
          src={selected === item.value ? CheckedRadio : UncheckedRadio}
          className="radio-button"
        />
        <label className={`radio-button-label ${props.labelClass}`}>
          {item.label}
        </label>
      </div>
    );
  });
}

export default CustomRadios;
