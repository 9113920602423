import React, { useState, useRef, useEffect, createRef } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import './QRCodeScanningSection.scss';
import { CSVReader } from 'react-papaparse';
import {
  CustomTitleLabel,
  CustomNumberInputWithUnit,
  CustomNumberMutipleInputWithUnit,
} from './CustomBaseComponments';
import { Button, Image } from 'react-bootstrap';
import { ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import deleteImage from '../../assets/images/earning/deletex1.png';
import { removeElementFromArray, useCompare } from '../../utils';
import { useFormContext } from 'react-hook-form';
import InputFieldControl from '../base/InputFieldControl';
import LimitSection from './LimitSection';

const buttonRef = createRef();

function QRCodeScanningSection({
  earningRule
}) {
  const { watch, formState, setValue } = useFormContext();
  const errors = formState.errors;
  const fieldError = Object.keys(errors || []).length;
  const camma = ',';

  const dispatch = useDispatch();

  const [showFileBox, setShowFileBox] = useState(false);
  const inputQRCodes = watch("inputQRCodes") || earningRule?.qrCodes || []
  const importQRCodes = watch("importQRCodes") || []
  const qrCodes = watch('qrCodes') || []

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data) => {
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');

    let currentImportedQRCodes = [];
    data.map((element) => {
      const hasValueData = removeElementFromArray(element.data, '');
      if (hasValueData.length > 0) {
        currentImportedQRCodes = [...currentImportedQRCodes, ...hasValueData];
      }
    });
    setValue("importQRCodes", currentImportedQRCodes, { shouldDirty: true })
    setQRCodesWithoutDuplicate(inputQRCodes, currentImportedQRCodes);

    setShowFileBox(true);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');
  };

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
      const test = qrCodes.filter(function (val) {
        return importQRCodes.indexOf(val) === -1;
      })
      setValue('qrCodes',
        qrCodes.filter(function (val) {
          return importQRCodes.indexOf(val) === -1;
        }),
        { shouldDirty: true}
      );
      setValue("importQRCodes", [], { shouldDirty: true})

      setShowFileBox(false);
    }
  };

  const setQRCodesWithoutDuplicate = (inputQRCodes, importQRCodes) => {
    const nonEmptyInputQRcodes = removeElementFromArray(inputQRCodes, '');
    const nonEmptyImportQRcodes = removeElementFromArray(importQRCodes, '');

    const importRemoveDuplicateFromInput = nonEmptyImportQRcodes?.filter(
      (val) => !nonEmptyInputQRcodes.includes(val),
    ) || [];

    console.log("@181", importRemoveDuplicateFromInput, nonEmptyInputQRcodes)
    setValue("qrCodes", [...importRemoveDuplicateFromInput, ...nonEmptyInputQRcodes], {shouldDirty: true})
  };

  return (
    <>
      <label className="create-section-title">QR CODE SCANNING</label>

      <CustomTitleLabel title="Input QR codes" />
      <label className="tips-message">Separate the QR codes by comma.</label>
      <textarea
        onChange={({ target }) => {
          const currentQRcodes = target.value.trim().split(camma);
          setValue("inputQRCodes", currentQRcodes, { shouldDirty: true })
          setQRCodesWithoutDuplicate(currentQRcodes, importQRCodes);
        }}
        className="custom-textarea-input-box custom-textarea-input-font"
        onFocus={() => {}}
        value={inputQRCodes?.join(camma)}
      />
      <ReactHookFormErrorMessage errors={errors} id='qrCodes' />

      <CustomTitleLabel title="Import QR codes" />
      <label className="tips-message">
        Upload a CSV file contains QR codes.
      </label>

      <CSVReader
        ref={buttonRef}
        onFileLoad={handleOnFileLoad}
        onError={handleOnError}
        noClick
        noDrag
        onRemoveFile={handleOnRemoveFile}
      >
        {({ file }) => (
          <div>
            <div style={{ display: showFileBox ? 'flex' : 'none' }}>
              <div className="custom-file-box">{file && file.name}</div>
              <Button
                className="custom-image-button"
                onClick={handleRemoveFile}
              >
                <Image src={deleteImage} />
              </Button>
            </div>

            <Button
              className="multiple-select-option-add btn-add-button-common upload-file-button"
              onClick={handleOpenDialog}
            >
              Choose file
            </Button>
          </div>
        )}
      </CSVReader>
      <ReactHookFormErrorMessage errors={errors} id='qrCodes' />

      <CustomTitleLabel title="QR codes" />
      <div>
        {qrCodes?.map((element) => {
          return (
            <div className="qr-codes-delete-button">
              <label className="custom-textarea-input-font qr-codes-name-label">
                {element}
              </label>
              <Button
                className="custom-image-button"
                onClick={() => {
                  setValue("qrCodes", removeElementFromArray(qrCodes, element), {shouldDirty: true})
                  setValue("inputQRCodes", removeElementFromArray(inputQRCodes, element), {shouldDirty: true})
                  setValue("importQRCodes", removeElementFromArray(importQRCodes, element), {shouldDirty: true})
                }}
              >
                <Image src={deleteImage} />
              </Button>
            </div>
          );
        })}
      </div>
      <LimitSection
        tips={{
          'overallLimit': 'Maximum number of times to scan QR code check-in per campaign.',
          'periodicLimit': 'Maximum number of times to scan QR code check-in per period.',
          'perHeadLimit': 'Maximum number of times to scan QR code check-in per customer.',
          'perHeadPeriodicLimit': 'Maximum number of times to scan QR code check-in per customer per period.'
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  earningRule: state.createEarningRules.earningRule
})

export default connect(mapPropsToState)(QRCodeScanningSection);
