import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { connect, useDispatch, useSelector } from 'react-redux';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction, getHashKeyString } from '../../../utils';

import { PermissionCodes } from '../../../config/PermissionCodes';
import AuthButton from '../../../components/base/AuthButton';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';

import DeletePrompt from '../../../components/base/DeletePrompt';
import { NavBarNames } from '../../../config/NavBarNameList';

function LanguageList({
  totalCount,
  pageInfo,
  totalPage,
  displayFields,
  pagedList,
  checkedList,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const actionList = [
    {
      name: 'Delete',
      action: () => setShowDeleteView(true),
      requires: PermissionCodes.changeLanguage,
    },
  ];

  const tabs = [
    {
      name: 'List',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={actionList}
          pageInfo={pageInfo}
          totalCount={totalCount || 0}
          listContent={
            <BaseListContainer
              fields={displayFields}
              model={'language'}
              permissionGroup={PermissionCodes.language}
              dataList={pagedList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: 'Language',
                relatedName: '',
                onComfirm: {},
                relatedSections: [],
              }}
              actions={['Edit', 'Detail', 'Delete']} //['Detail']
            />
          }
        />
      ),
    },
  ];

  const [showDeleteView, setShowDeleteView] = useState(false);

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    console.log(searchKey);
    dispatch(
      createAction('language/getList')({
        ...searchDict,
        rank: rank,
        filterName: searchKey,
        page: page,
      }),
    );
  }, [dispatch, location]);

  const buttons = [
    <AuthButton
      title="Create Language"
      action={() => {
        dispatch(createAction('langauge/clearData')());
        history.push('/languages/create');
      }}
      requires={PermissionCodes.changeLanguage}
    />,
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '...',
          title: NavBarNames.langauge,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <DeletePrompt
        data={checkedList}
        show={showDeleteView}
        title={'Language'}
        relatedName=""
        relatedSections={[]}
        onClose={() => setShowDeleteView(false)}
        onConfirm={() => {
          setShowDeleteView(false);
          dispatch(
            createAction('language/delete')({
              afterAction: () => history.push(location),
            }),
          );
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  totalCount: state.language?.totalCount,
  pageInfo: state.language?.pageInfo,
  totalPage: state.language?.totalPage,
  displayFields: state.language?.listDisplayFields,
  pagedList: state.language?.pagedList,
  checkedList: state.language?.checkedList,
})

export default connect(mapPropsToState)(LanguageList)