import React, { useState, useEffect } from 'react';
import BasePrompt from '../../base/prompt/BasePrompt';
import CreateCategoryPrompt from './CreateCategoryPrompt';
import { CampaignType } from '../../../config/CustomEnums';
import './PropertiesGeneralSection.scss';
import { useSelector, useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CustomRadios from '../../base/CustomRadios';
import CustomAddRemoveMutipleSelector from '../../customer/CustomAddRemoveMutipleSelector';
import {
  CampaignErrorHandleField,
  isShowError,
  firstError,
} from './CreateCampaignHandleError';
import { Row } from 'react-bootstrap';
import { GENERAL_TYPE } from '../../../models/CreateCampaignModel';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { ContinueCreate } from '../CampaignUrlConfig';
import { useFormContext } from 'react-hook-form';
import { hasError, errorMessage } from '../../base/ErrorFieldMessage';
import BaseMultipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import InputFieldControl from '../../base/InputFieldControl';

const options = (source) => {
  if (!source) {
    return [];
  }
  return source.map((item) => {
    return {
      name: item.name,
      value: item,
    };
  });
};

const getRadioOptions = () => [
  {
    label: GENERAL_TYPE.customerGroup,
    value: GENERAL_TYPE.customerGroup,
  },
  {
    label: GENERAL_TYPE.segment,
    value: GENERAL_TYPE.segment,
  },
  // {
  //   label: GENERAL_TYPE.level,
  //   value: GENERAL_TYPE.level,
  // },
];

function PropertiesGeneralSection({
  groups,
  segments,
  categories,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;
  const campaignCategories = watch('categories') || [];
  const campaignSegments = watch('segments') || [];
  const customerGroup = watch('customerGroup') || [];
  const campaignType = watch('campaignType');
  const isExclusive = watch('isExclusive');
  const generalType = watch('generalType');
  const watchEarningRules = watch('linkedEarningRules')
  const birthdayStartDate = watchEarningRules?.birthdayTypePeriodStartDate
  const birthdayEndDate = watchEarningRules?.birthdayTypePeriodEndDate
  const requiredPoints = watch('requiredPoints');


  const [showAddCustomerGroup, setShowAddCustomerGroup] = useState(false);
  const [showCreateCategory, setShowCreateCategory] = useState(false);
  const [showAddSegment, setShowAddSegment] = useState(false);

  useEffect(() => {
    // firstError(`${CampaignErrorHandleField.customerGroup.name}`, []);
    console.log("@74")
    dispatch({
      type: 'campaignCategoryList/getCampaignCategoryList',
      payload: { status: true },
    });
    dispatch({
      type: 'segments/getSegmentsList',
      payload: { status: true },
    });
    dispatch({
      type: 'customerGroup/getCurrentPageList',
      payload: { status: true },
    });
  }, [dispatch]);
  // console.log("@80 categories", categories)
  // const {
  //   birthdayStartDate,
  //   birthdayEndDate,
  //   categories,
  //   customerGroup,
  //   campaignType,
  //   campaignCategories,
  //   groups,
  //   isExclusive,
  //   errorFields,
  //   segments,
  //   campaignSegments,
  //   storeGeneralType,
  // } = useSelector((state) => ({
  //   campaignCategories: state.createCampaign.campaign.categories,
  //   campaignSegments: state.createCampaign.campaign.segments,
  //   customerGroup: state.createCampaign.campaign.customerGroup,
  //   campaignType: state.createCampaign.campaign.campaignType,
  //   categories: state.campaignCategoryList.categoryList,
  //   groups: state.customerGroup.currentPageGroupList,
  //   isExclusive: state.createCampaign.campaign.isExclusive,
  //   errorFields: state.createCampaign.errorFields,
  //   segments: state.segments.segmentList,
  //   birthdayStartDate:
  //     state.createCampaign.campaign.linkedEarningRules
  //       ?.birthdayTypePeriodStartDate,
  //   birthdayEndDate:
  //     state.createCampaign.campaign.linkedEarningRules
  //       ?.birthdayTypePeriodEndDate,
  //   storeGeneralType: state.createCampaign.campaign.generalType,
  // }));
  // const dispatch = useDispatch();
  // const history = useHistory();
  // const onSubmit = props.onSubmit;
  // const onSubmitAction = props.onSubmitAction || (() => {});
  // const [selectedCategory, setSelectCategories] = useState(campaignCategories);
  // const [selectedSegments, setSelectSegments] = useState(campaignSegments);
  const getGroup = () => {
    if (!isExclusive) {
      return [];
    }
    return customerGroup || [];
  };
  // const [generalType, setGeneralType] = useState(storeGeneralType);
  // const [selectedCustomerGroup, setSelectCustomerGroup] = useState(getGroup());

  // useEffect(() => {
  //   if (onSubmit) {
  //     onSubmitAction({
  //       categories: selectedCategory,
  //       customerGroup: selectedCustomerGroup,
  //       segments: selectedSegments,
  //       generalType: generalType,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [onSubmit]);

  // useEffect(() => {
  //   firstError(`${CampaignErrorHandleField.customerGroup.name}`, errorFields);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [errorFields]);

  // const isError = isShowError(
  //   CampaignErrorHandleField.customerGroup.name,
  //   errorFields,
  // );

  const segmentConfig = {
    title: 'Target segments',
    data: segments,
    selectedList: campaignSegments,
    setValue: (option) => {
      setValue('segments', option, {shouldDirty: true})
    },
    // sourceReminderText: 'All item(s)',
    // amidReminderText: 'Select item(s)',
    addButton: {
      title: 'Add Segments',
      action: () => {
        setShowAddSegment(true);
      },
    },
    requires: PermissionCodes.addSegment,
    // error: { errors, message: CampaignErrorHandleField.segment.message },
    // selectAllTitle: 'all segments',
    loadMoreAction: 'segments/getSegmentsList',
    errorIdKey: 'segments',
    defaultFilter:
      birthdayStartDate && birthdayEndDate
        ? {
            dateOfBirthRange: `${birthdayStartDate},${birthdayEndDate}`,
          }
        : {},
  };

  const customerGroupConfig = {
    title: 'Target customer groups',
    namespace: 'customerGroupsList',
    data: groups,
    selectedList: customerGroup,
    setValue: (option) => {
      setValue('customerGroup', option, {shouldDirty: true})
    },
    // sourceReminderText: 'All item(s)',
    // amidReminderText: 'Select item(s)',
    addButton: {
      title: 'Add Customer Group',
      action: () => {
        setShowAddCustomerGroup(true);
      },
    },
    requires: PermissionCodes.addCustomerGroup,
    // error: { errors, message: CampaignErrorHandleField.customerGroup.message },
    // selectAllTitle: 'all customer groups',
    loadMoreAction: 'customerGroup/getCurrentPageList',
    errorIdKey: 'customerGroup',
  };

  const campaignCategoryConfig = {
    title: 'Campaign category (optional)',
    namespace: 'campaignCategoryList',
    data: categories,
    selectedList: campaignCategories,
    setValue: (option) => {
      setValue('categories', option, {shouldDirty: true})
    },
    // sourceReminderText: 'Select categories',
    addButton: {
      title: 'Add Campaign Category',
      action: () => {
        setShowCreateCategory(true);
      },
    },
    requires: PermissionCodes.addCampaignCategory,
    errorIdKey: 'categories',
    // selectAllTitle: 'all campaign categories',
    loadMoreAction: 'campaignCategoryList/getCampaignCategoryList',
    // defaultFilter: { status: true },
  };

  const getSelectorWithConfig = (config) => {
    return (
      // <CustomAddRemoveMutipleSelector
      //   title={config.title}
      //   data={config.data}
      //   selectedList={config.selectedList}
      //   setValue={config.setValue}
      //   sourceReminderText={config.sourceReminderText}
      //   amidReminderText={config.amidReminderText}
      //   sourceReminderEnabelSelectAll={true}
      //   addButton={config.addButton}
      //   error={errors ? config.error : {}}
      //   requires={config.requires}
      //   selectAllTitle={config.selectAllTitle}
      //   loadMoreAction={config.loadMoreAction}
      //   defaultFilter={config.defaultFilter}
      // />
      <BaseMultipleSelectorV2
          title={config.title}
          namespace={config.namespace}
          data={{
            sourceData: config.data,
            targetData: config.selectedList,
            targetChange: (value) => {
              config.setValue(value);
            },
          }}
          addButton={config.addButton}
          error={{
            error: hasError(errors, config.errorIdKey),
            id: config.errorIdKey,
            message: errorMessage(errors, config.errorIdKey),
          }}
          requires={config.requires}
          groupKey={config.groupKey}
        />
    );
  };

  return (
    <>
      {campaignType === CampaignType.couponsCampaign ? (
        <label className="create-section-title">General</label>
      ) : null}
      <span id={`${CampaignErrorHandleField.customerGroup.name}`} />
      {isExclusive ? (
        <>
          <div
            className={`${
              campaignType === CampaignType.couponsCampaign
                ? ''
                : 'category-select-title-top'
            }`}
          >
            <label className="create-section-label create-section-label-bottom-space">
              Target customer
            </label>
            <Row className="featured-exclusive-row">
              <CustomRadios
                onChange={(value) => {
                  setValue('generalType', value, { shouldDirty: true});
                }}
                default={generalType}
                options={getRadioOptions()}
              />
            </Row>
          </div>
          {generalType === GENERAL_TYPE.customerGroup
            ? getSelectorWithConfig(customerGroupConfig)
            : null}
          {generalType === GENERAL_TYPE.segment
            ? getSelectorWithConfig(segmentConfig)
            : null}
        </>
      ) : null}
      {getSelectorWithConfig(campaignCategoryConfig)}
      {campaignType === CampaignType.couponsCampaign ? (
        <>
          <InputFieldControl
            name={'requiredPoints'}
            title={'Required points'}
            value={requiredPoints}
            setValue={(value) => {
              setValue('requiredPoints', value, { shouldDirty: true });
            }}
            type='number'
            unit='points'
            className='coupon-campaign-section-input'
          />
        </>
      ) : null}

      <BasePrompt
        show={showAddCustomerGroup}
        closeAction={() => setShowAddCustomerGroup(false)}
        rightButton={{
          text: 'Create customer group',
          action: () =>
            history.push({
              pathname: '/customer_group/create',
              state: { from:
                {...history.location,
                  state: {
                    ...history.location?.state,
                    resume: true
                  }
                },
                ...ContinueCreate },
            }),
        }}
        title={'Go to create customer group?'}
        description={'Go to create customer group'}
      />

      <BasePrompt
        show={showAddSegment}
        closeAction={() => setShowAddSegment(false)}
        rightButton={{
          text: 'Create segments',
          action: () =>
            history.push({
              pathname: '/segments/create',
              state: { from:
                {...history.location,
                  state: {
                    ...history.location?.state,
                    resume: true
                  }
                },
                ...ContinueCreate },
            }),
        }}
        title={'Go to create segment?'}
        description={'Go to create segment'}
      />
      <CreateCategoryPrompt
        show={showCreateCategory}
        onClose={() => setShowCreateCategory(false)}
        successAction={(data) => {
          if (data) {
            setValue(
              'categories',
              [...campaignCategories, data],
              {
                shouldDirty: true,
              },
            );
          }
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  categories: state.campaignCategoryList.categoryList,
  groups: state.customerGroup.currentPageGroupList,
  segments: state.segments.segmentList,
});

export default connect(mapPropsToState)(PropertiesGeneralSection);
