import { createModel } from './BaseModel';
import { SESSION_KEYS } from '../config/CustomEnums';
import { getFillingForm, getFillingFormList } from '../services/FillingFormAPIHelper';

// const parseDetail = (data) => {
//   return {
//     ...data,
//   };
// };

export default createModel({
  namespace: 'fillingForms',
  states: {
    // detail: {
    //   sourceName: '',
    //   parameter: '',
    // },
  },
  reducers: {},
  params: {
    listAPI: getFillingFormList,
    parse: (data) =>
      data?.fillingForms.edges.map((item) => item.node),
    pkNode: 'FillingFormNode',
    detailAPI: getFillingForm,
    parseDetail: (data) => data.fillingForms,
    objectKey: 'fillingForms'
  },
  effects: {
  },
});
