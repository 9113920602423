import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createAction } from '../../utils';
import FilterableDropdown from '../base/FilterableDropdown';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { LanguageConfig } from '../../config/CustomEnums';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';

function CustomerFilterableDropdown(props) {
  const selectCustomer = props.selectCustomer || (() => {});
  const defaultSelectedCustomer = props.defaultSelectedCustomer;
  const filterTestAccount = props.filterTestAccount || false;
  const needLevelFilter = props.needLevelFilter || false;
  const title = props.title;
  const errorName = props.errorName || '';
  const errorMessage = props.errorMessage;
  const errorFields = props.errorFields || [];
  const moreSearch = props.moreSearch || {};

  const { customers, levelList } = useSelector((state) => ({
    customers: state.customerList.customerList,
    levelList: state.levels.levelList,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    let search = [];
    if (filterTestAccount) {
      search.push('isAssignedAsTestingCustomer: true ');
    }

    dispatch(createAction('levels/getLevelList')({}));
    dispatch(
      createAction('customerList/getPagedCustomers')({ moreSearch: search }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    firstError(errorName, errorFields, LanguageConfig.english);
  }, [errorFields, errorName]);

  return (
    <>
      <CustomTitleWithDropDown
        title={title}
        source={customers}
        defaultValue={defaultSelectedCustomer}
        setValue={selectCustomer}
        loadMoreAction={'customerList/getPagedCustomers'}
        filterAction={'customerList/getPagedCustomers'}
        filterButtons={
          needLevelFilter ? levelList?.map((item) => item.name) : null
        }
        errors={errorFields}
        errorName={errorName}
        defaultFilter={moreSearch}
        labelContainPk={true}
      />
    </>
  );
}

export default CustomerFilterableDropdown;
