import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getPagedAdmins = (afterCursor, search = {}) => {
  let searchString = `, orderBy: "${search.reverse ? '-pk' : 'pk'}"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search.sort}"`;
  }
  if (search.search) {
    searchString += `, nameIcontains: "${search.search}"`;
  }
  if (search.admin_type) {
    searchString += `, isSuperuser: ${search.admin_type}`;
  }
  if (search.status) {
    searchString += `, isActive: ${search.status}`;
  }

  const query = `{
    administrators(first: 10, after:"${afterCursor}"${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          lastLogin
          isSuperuser
          userPermissions(first: 10) {
            edges {
              node {
                id
                pk
                name
                codename
              }
            }
          }
          username
          firstName
          lastName
          email
          isActive
          dateJoined
          isStaff
          groups(first: 10) {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
        }
      }
    }
  }
  `;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getAllAdmins = () => {
  const query = `
  {
    administrators(first: 100) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          lastLogin
          isSuperuser
          userPermissions(first: 10) {
            edges {
              node {
                id
                pk
                name
                codename
              }
            }
          }
          username
          firstName
          lastName
          email
          isActive
          dateJoined
          isStaff
          groups(first: 10) {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteAdmin = (pks) => {
  const query = `mutation DeleteAdministrators($input: DeleteAdministratorsInput!) {
    deleteAdministrators(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ids: pks,
    },
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateAdminStatus = (isActive, pk) => {
  const query = ``;

  const variables = {
    input: {
      id: pk,
    },
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const duplicateAdmin = (pk) => {
  const query = ``;

  const variables = {
    input: {
      id: pk,
    },
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneAdmin = (id) => {
  const query = `{
    administrator(id: "${id}") {
      id
      pk
      username
      firstName
      lastName
      email
      isActive
      dateJoined
      isStaff
      lastLogin
      isSuperuser
      groups(first: 20) {
        edges {
          node {
            id
            pk
            name
            permissions(first: 100) {
              edges {
                node {
                  name
                  codename
                }
              }
            }
          }
        }
      }
      userPermissions(first: 100) {
        edges {
          node {
            id
            pk
            name
            codename
          }
        }
      }


    }
  }
  `;

  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateOrCreateAdmin = (values) => {
  let query = `mutation CreateAdministrator($input: CreateAdministratorInput!) {
    createAdministrator(input: $input) {
      node {
        id
        pk
        username
        firstName
        lastName
        email
        isActive
        dateJoined
        isStaff
      }
      errors {
        field
        messages
      }
    }
  }
  `;

  if (values.id) {
    query = `mutation UpdateAdministrator($input: UpdateAdministratorInput!) {
      updateAdministrator(input: $input) {
        node {
          id
          pk
          username
          firstName
          lastName
          email
          isActive
          dateJoined
          isStaff
        }
        errors {
          field
          messages
        }
      }
    }

    `;
  }
  console.log(values);
  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getAllPermissions = (afterCursor) => {
  const query = `{
    permissions(first: 100, after: "${afterCursor}") {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          codename
          contentType {
            appLabel
          }
        }
      }
    }
  }

  `;

  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const changePassword = (values) => {
  const query = `mutation ChangeAdministratorPassword($input: ChangeAdministratorPasswordInput!) {
    changeAdministratorPassword(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
  `;

  const variables = { input: values };

  return BaseHelper.callGraphQLAPI({ query, variables });
};
