import { checkUrlIsValid } from '../../../utils';
import { 
  COUPON_CODE_TYPE,
  COUPON_REDEMPTION_METHOD,
  COUPON_SET_TYPES
 } from '../../../config/CustomEnums';
import {
  DISCOUNT_CODE_FORMAT,
  VALID_PERIOD_TYPE,
} from '../../../models/CreateCouponModel';
import { CouponErrorFields } from '../../../components/coupon/couponCreation/CouponHandleError'


function firstStepValidate({ getValues, setError }) {
  return true;
}

function secondStepValidate({ getValues, setError }) {
  const [
    name,
    coverPhoto,
    shortDescription,
    selectedBrand,
    shouldUseInStore,
    selectedStores,
  ] = getValues([
    'translations.en.name',
    'translations.en.coverPhoto',
    'translations.en.shortDescription',
    'selectedBrand',
    'shouldBeUsedInStores',
    'selectedStores',
  ])
  const errorList = [];
  if (!name || name.length > 200) {
    errorList.push({
      name: 'translations.en.name',
      message: CouponErrorFields.generalName.message,
    });
  }
  if (!shortDescription || shortDescription?.length > 200) {
    errorList.push({
      name: 'translations.en.shortDescription',
      message: CouponErrorFields.shortDescription.message,
    });
  }
  if (!coverPhoto) {
    errorList.push({
      name: 'translations.en.coverPhoto',
      message: CouponErrorFields.coverPhotoEmpty.message,
    });
  }
  if (!selectedBrand) {
    errorList.push({
      name: 'selectedBrand',
      message: CouponErrorFields.brand.message
    });
  }
  console.log("@59", selectedStores, selectedBrand, shouldUseInStore)
  if (selectedBrand && shouldUseInStore && selectedStores.length === 0) {
    console.log("@61")
    errorList.push({
      name: 'selectedStores',
      message: CouponErrorFields.applicationStore.message
    });
  }

  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

const isEmpty = (value) => {
  return value === undefined || value === null || value === '' || isNaN(value);
}

function thirdStepValidate({ getValues, setError }) {
  const [
    validPeriodType,
    numberOfDaysToExpireAfterAcquisition,
    absoluteEffectiveDate,
    absoluteExpiryDate,
    couponSetType,
    formats,
    couponCodeType,
    genericCode,
    discountCodeFormat,
    redemptionMethod,
  ] = getValues([
    'validPeriodType',
    'numberOfDaysToExpireAfterAcquisition',
    'absoluteEffectiveDate',
    'absoluteExpiryDate',
    'couponSetType',
    'formats',
    'couponCodeType',
    'genericCode',
    'discountCodeFormat',
    'redemptionMethod',
  ]);
  const errorList = [];

  if (validPeriodType === VALID_PERIOD_TYPE.ABSOLUTE &&
    absoluteExpiryDate <= absoluteEffectiveDate) {
    errorList.push({
        name: 'absoluteExpiryDate',
        message: CouponErrorFields.endDatetime.message,
      });
  }
  if (validPeriodType === VALID_PERIOD_TYPE.RELATIVE &&
    !numberOfDaysToExpireAfterAcquisition) {
    errorList.push({
        name: 'numberOfDaysToExpireAfterAcquisition',
        message: CouponErrorFields.relativeDay.message,
      });
  }
  if (couponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON) {
    if (
      redemptionMethod !==
      COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE
    ) {
      if (formats === null || formats === undefined) {
        errorList.push({
          name: 'formats',
          message: "Please select code formart.",
        });
      } 
    }

    if (couponCodeType === null || couponCodeType === undefined) {
      errorList.push({
        name: 'couponCodeType',
        message: "Please select coupon code type.",
      });
    }

    if (
      couponCodeType === COUPON_CODE_TYPE.GENERIC &&
      (genericCode === null || genericCode === undefined || !genericCode)
    ) {
      errorList.push({
        name: 'genericCode',
        message: "Please input Generic code.",
      });
    } else if (
      discountCodeFormat === DISCOUNT_CODE_FORMAT.URL &&
      couponCodeType === COUPON_CODE_TYPE.GENERIC &&
      !checkUrlIsValid(genericCode)
    ) {
      errorList.push({
        name: 'genericCode',
        message: "Please input Generic code.",
      });
    }
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length
}

export function validate({ getValues, setError, clearErrors, step, isBack }) {
  console.log('@200 couponValidate:', getValues());
  if (isBack) {
    return true;
  }
  clearErrors();
  switch (step) {
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
    case 1:
      const validStepTwo = secondStepValidate({ getValues, setError });
      return validStepTwo;
    case 2:
      const validStepThree = thirdStepValidate({ getValues, setError });
      return validStepThree;
    default:
      const validOne = firstStepValidate({ getValues, setError });
      const validTwo = secondStepValidate({ getValues, setError });
      const validThree = thirdStepValidate({ getValues, setError });
      return validOne && validTwo && validThree;
  }
}
