import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import MembershipInfoEditCard from '../../../components/customer/MembershipInfoEditCard';
import PersonalInfoEditCard from '../../../components/customer/PersonalInfoEditCard';
import ContactInfoEditCard from '../../../components/customer/ContactInfoEditCard';
import Loading from '../../../components/base/Loading';
import { validate } from './CustomerEditValidate';
import {
  CheckStatus,
  DELETE_RELATED_SECTIONS,
  SavedStatus,
} from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';


function CustomerEditFormStepOne({
  customer,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const { watch, setValue, control, setError, clearErrors, getValues } = useFormContext();
  const watchFirstName = watch('firstName');
  console.log(watchFirstName);

  const save = function() {
    console.log('@@53: ', getValues());
    let isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
    });
    if (isValid) {
      let uploadData = {
        ...getValues()
      };
      dispatch({
        type: 'customerList/updateCustomer',
        payload: {
          data: uploadData,
          customer: customer,
        },
      });
      return;
    }
  };

  return (
    <>
      {customer.pk ? (
        <ContentSections
          sections={[
            <MembershipInfoEditCard
              title="Membership Information"
            />,
            <PersonalInfoEditCard
              title="Personal Information"
            />,
            <ContactInfoEditCard
              title="Contact Information"
            />,
          ]}
          hidePreview={true}
        />
      ) : (
        <ContentSections
          sections={[
            <Loading />
          ]}
          hidePreview={true}
        />
      )}
      <SaveAndBackButtons
        saveTempText={'Delete'}
        saveText={'Update'}
        backAction={() => {
          history.goBack();
        }}
        saveAction={() => {
          save();
        }}
        saveTempAction={() => {
          setShowDeletePrompt(true);
        }}
        tempRequires={PermissionCodes.deleteCustomer}
      />
      <DeletePrompt
        message={{
          title: 'Delete this Customer',
          content: 'Did you confirm to delete this customer?',
        }}
        title={'Customer'}
        data={customer}
        relatedSections={DELETE_RELATED_SECTIONS.CUSTOMER}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: `customerList/updateState`,
            payload: { checkedList: [customer] },
          });
          dispatch({
            type: 'customerList/delete',
            payload: {
              afterAction: () => {
                history.push('/customers#List%20of%20Customers');
              },
            }
          });
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  customer: state.customerList.customer || {},
});

export default connect(mapPropsToState)(CustomerEditFormStepOne);

