import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomEditor from '../base/CustomEditor';
import {
  CustomTitleWithDropDown,
  CustomTitleWithDropDownAndInput,
} from './CustomTitleWithDropDown';
import { checkHasPermission } from '../../utils';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';
import BaseMultipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import { PermissionCodes } from '../../config/PermissionCodes';
import CustomerFilterableDropdown from '../transactions/CustomerFilterableDropdown';
import { hasError, errorMessage, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';


function MembershipInfoEditCard({
  couponList,
  user,
  title,
}) {
  const dispatch = useDispatch();

  const { watch, setValue, control, formState, clearErrors } = useFormContext();
  const { errors } = formState;

  const watchPointTransaction = watch('pointTransaction');
  const watchTransactionPoint = watch('transactionPoint');
  const watchPointTransactionRemark = watch('pointTransactionRemark');
  const watchReferrerUser = watch('referrerUser');
  const watchIsForcedInactive = watch('isForcedInactive');
  const watchOwnedCoupons = watch('ownedCoupons');
  const watchRemovedCoupons = watch('removedCoupons');
  const watchInGroups = watch('inGroups');
  const watchLeaveGroups = watch('leaveGroups');

  useEffect(() => {
    dispatch({
      type: 'campaignList/getCampaignList',
      payload: {}
    });
  }, [dispatch]);

  const getPointTransactionSource = () => {
    let source = [];
    if (checkHasPermission(user, PermissionCodes.addCustomerPoints)) {
      source.push({ name: 'Add transaction', pk: 'ADD_POINTS' });
    }
    if (checkHasPermission(user, PermissionCodes.removeCustomerPoints)) {
      source.push({ name: 'Remove transaction', pk: 'REMOVE_POINTS' });
    }
    return source;
  };

  return (
    <div>
      <label className="create-section-title">{title}</label>

      {/* <CustomTitleWithDropDown
        title={'Current level'}
        source={levelList}
        defaultValue={{
          label: currentLevel?.levelName,
          value: currentLevel,
        }}
        needFilter={false}
        setValue={setCurrentLevel}
        disabled={true}
        error={{}}
      /> */}

      <CustomTitleWithDropDownAndInput
        title="Change points transaction(Optional)"
        source={getPointTransactionSource()}
        defaultValue={''}
        needFilter={false}
        unit="Points"
        customClass="edit-custom-transaction-selector"
        setValue={(value) => {
          setValue('pointTransaction', value, { shouldDirty: true });
        }}
        setInputValue={(value) => {
          setValue('transactionPoint', value, { shouldDirty: true });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id='pointTransaction' />
      <ReactHookFormErrorMessage errors={errors} id='transactionPoint' />

      <CustomTitleLabel title="Points transaction remark(Optional)" />
      <CustomEditor
        initialValue={''}
        onValueChange={(value) => {
          setValue('pointTransactionRemark', value, { shouldDirty: true });
        }}
        onFocus={() => {}}
        errorMessage={''}
        error={''}
      />
      <ReactHookFormErrorMessage errors={errors} id='pointTransactionRemark' />

      <BaseMultipleSelectorV2
        title={'Owned coupons(Optional)'}
        data={{
          sourceData: watchOwnedCoupons,
          targetData: watchRemovedCoupons,
          targetChange: (value) => {
            setValue('removedCoupons', value, { shouldDirty: true, });
          },
        }}
        addButton={{
          title: 'Add Single Coupons ',
          link: '/coupons'
        }}
        error={{
          error: hasError(errors, 'removedCoupons'),
          id: 'removedCoupons',
          message: errorMessage(errors, 'removedCoupons'),
        }}
      />

      <BaseMultipleSelectorV2
        title={'Customer groups(Optional)'}
        data={{
          sourceData: watchInGroups,
          targetData: watchLeaveGroups,
          targetChange: (value) => {
            setValue('leaveGroups', value, { shouldDirty: true });
          },
        }}
        error={{
          error: hasError(errors, 'leaveGroups'),
          id: 'leaveGroups',
          message: errorMessage(errors, 'leaveGroups'),
        }}
      />

      <CustomerFilterableDropdown
        title={'Referrer(Optional)'}
        defaultSelectedCustomer={watchReferrerUser}
        selectCustomer={(value) => {
          setValue('referrerUser', value, { shouldDirty: true });
        }}
      />

      {/* <CustomTitleWithDropDown
        title={'Referred by campaign(Optional)'}
        source={campaignList}
        defaultValue={referrerCampaign}
        setValue={setReferrerCampaign}
      /> */}

      <CustomTitleWithSwitch
        title={'Is forced inactive'}
        defaultValue={watchIsForcedInactive}
        setValue={(value) => {
          setValue('isForcedInactive', value, { shouldDirty: true });
        }}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  couponList: state.couponList.couponSetList,
  user: state.users,
});

export default connect(mapPropsToState)(MembershipInfoEditCard);

