import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  hasError,
  ReactHookFormErrorMessage,
} from './ErrorFieldMessage';
import {
  FieldControl,
} from './CommonComponent';
import CustomTitleWithInput from './CustomTitleWithInput';

function InputFieldControl({
  name,
  title,
  rules,
  type = 'text',
  tips,
  value,
  setValue,
  optional,
  onFocus = () => {},
  customTitleClass,
  customClass,
  disabled,
  blurAction = (target) => {},
  useDefaultValue = true,
  unit,
  per,
  shortDescription,
  ...inputValues
}) {
  const { formState } = useFormContext();
  const { errors } = formState;
  return (
    <FieldControl
      name={name}
      rules={rules}
      render={() => (
        <>
          <CustomTitleWithInput
            title={title}
            type={type}
            error={{ error: hasError(errors, name) }}
            setValue={setValue}
            focusChange={onFocus}
            defaultValue={value}
            extra={{ ...inputValues, value: value }}
            customTitleClass={customTitleClass}
            customClass={customClass}
            disabled={disabled}
            blurAction={blurAction}
            useDefaultValue={useDefaultValue}
            unit={unit}
            per={per}
            shortDescription={shortDescription}
            tips={tips}
          />
          {optional ? null : (
            <ReactHookFormErrorMessage errors={errors} id={name} />
          )}
        </>
      )}
    />
  );
}

export default InputFieldControl;