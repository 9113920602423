import React, { useState, useEffect } from 'react';
import './CustomMultipleSelect.scss';
import { Button, Image } from 'react-bootstrap';
import Search from '../../assets/images/drop_down_filter.svg';
import NarrowUp from '../../assets/images/drop_down_not_show_narrow.svg';
import { removeElementFromArray, useCompare } from '../../utils';
import AuthButton from './AuthButton';

function CustomMultipleSelect(props) {
  const defaultSelected = props.defaultSelected || [];
  const onSelectChange = props.onSelectChange;
  const title = props.title || 'Campaign category (optional)';
  const createButtonTitle = props.createTitle || 'Create Now';
  const options = props.options || [];
  const selectAllTitle = props.selectAllTitle || 'All cusotmers';
  const enableSelectAll = props.enableSelectAll || false;
  const reminderText = props.reminder || 'Please select item(s)';
  const hideAddButton = props.hideAddButton || false;
  const hideTitle = props.hideTitle || false;
  const addAction = props.addAction;
  const error = props.error;
  const name = props.name;
  const isReset = props.isReset || false;
  const hasOptionsChanged = useCompare(options);
  const isFilterable = props.isFilterable || false;
  const subFilterKey = props.subFilterKey || '';
  const subFilterItems = props.subFilterItems || [];
  const addRequires = props.addRequires || null;

  const loadMoreAction = props.loadMoreAction || (() => {});
  const filterAction = props.filterAction || null;
  const groupKey = props.groupKey || null;

  const [selectedOption, setSelectedOption] = useState(defaultSelected);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [filterKey, setFilterKey] = useState();
  const [subFilterValue, setSubFilterValue] = useState();
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [openedSections, setOpenedSections] = useState([]);

  const addButtonExtraClass = props.addButtonExtraClass;

  const isOptionSelected = (value) => {
    return (
      selectedOption.findIndex((option) => {
        if (option?.value?.pk && value?.value?.pk) {
          return option.value.pk === value.value.pk;
        } else {
          return option.name === value.name;
        }
      }) !== -1
    );
  };

  const selectChange = (selects) => {
    setSelectedOption(selects);
    onSelectChange(selects);
  };

  const optionClick = (value) => {
    const selected = isOptionSelected(value);
    let newSelectedArray = [];
    if (selected) {
      newSelectedArray = selectedOption.filter((option) => {
        if (option?.value?.pk && value?.value?.pk) {
          return option.value.pk !== value.value.pk;
        } else {
          return option.name !== value.name;
        }
      });
    } else {
      newSelectedArray = [...selectedOption, value];
    }
    const filtered = newSelectedArray.filter((val) => {
      const optionsList = options.filter((option) => {
        if (option?.value?.pk) {
          return option.value.pk === val.value?.pk;
        }
        return option.pk === val.pk;
      });
      if (optionsList.length > 0) {
        return optionsList[0];
      }
    });
    selectChange(filtered);
  };

  const optionItem = (optionvalue, index) => {
    return (
      <label
        key={`${optionvalue?.name}-${index}`}
        className={`multiple-select-option-item ${
          isOptionSelected(optionvalue)
            ? 'multiple-select-option-item-selected'
            : ''
        }`}
        onClick={() => optionClick(optionvalue)}
      >
        {optionvalue?.name}
      </label>
    );
  };

  const groupBy = (data, key) => {
    return data.reduce(function (source, item) {
      (source[`${item[key].name}`] = source[`${item[key].name}`] || []).push(
        item,
      );
      return source;
    }, {});
  };

  const selectAllOption = () => {
    if (!enableSelectAll) {
      return;
    }
    return (
      <label
        className="multiple-select-option multiple-select-all"
        onClick={() =>
          selectChange(
            selectedOption?.length === options?.length ? [] : options,
          )
        }
      >
        {selectAllTitle}
      </label>
    );
  };

  const reminder = () => {
    return <label className="multiple-select-option">{reminderText}</label>;
  };

  const getFilteredList = (filterInputValue, filterBtnValue) => {
    let filteredList = options;
    console.log('@@123: ', filterInputValue, filterBtnValue);
    if (filterInputValue) {
      filteredList = filteredList.filter((option) =>
        option.name.toLowerCase().includes(filterInputValue.toLowerCase()),
      );
    }
    if (filterBtnValue) {
      filteredList = filteredList.filter(
        (option) => option.value.subFilterField === filterBtnValue,
      );
    }
    return filteredList;
  };

  const filterBar = () => {
    return (
      <div className="filterable-search-bar multiple-select-filter-bar">
        <Image src={Search} className="filterable-search-bar-icon" />
        <input
          className="filterable-search-bar-input"
          placeholder="Search for..."
          onChange={(e) => {
            setFilterKey(e.target.value);

            if (filterAction) {
              filterAction(
                e.target.value,
                subFilterValue
                  ? [
                      `${subFilterKey}: "${
                        subFilterValue ? subFilterValue.value.pk : ''
                      }"`,
                    ]
                  : [],
              );
            } else {
              const filterList = getFilteredList(
                e.target.value,
                subFilterValue ? subFilterValue.name : '',
              );
              setFilteredOptions(filterList);
            }
          }}
          value={filterKey}
        />
      </div>
    );
  };

  const subFilterBar = () => {
    return (
      <div className="d-flex multiple-select-filter-tiems-bar">
        {subFilterItems.map((item) => {
          return (
            <button
              key={item}
              className={`subfilter-item-btn ${
                subFilterValue && subFilterValue.name === item.name
                  ? 'filter-btn-selected'
                  : 'ff'
              }`}
              onClick={() => {
                const value =
                  subFilterValue && subFilterValue.value.pk === item.value.pk
                    ? ''
                    : item;
                setSubFilterValue(value);
                console.log('@@181: ', value);
                if (filterAction) {
                  filterAction(filterKey, [
                    `${subFilterKey}: "${value ? value.value.pk : ''}"`,
                  ]);
                } else {
                  const filterList = getFilteredList(filterKey, value?.name);
                  setFilteredOptions(filterList);
                }
              }}
            >
              {item.name}
            </button>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    if (isReset) {
      setSelectedOption(defaultSelected);
    }
  }, [defaultSelected, isReset]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const selection_pointer_area_class = () => {
    if (isFilterable && subFilterValue) {
      return 'multiple-select-pointer-area-with-2filters';
    }

    if (isFilterable || subFilterValue) {
      return 'multiple-select-pointer-area-with-filter';
    }

    return 'multiple-select-pointer-area-without-filters';
  };

  return (
    <>
      {hideTitle ? null : (
        <label className="create-section-label create-section-label-bottom-space">
          {title}
        </label>
      )}

      <div className="option-and-add-button">
        <div
          className={`multiple-select-option-container ${
            error ? 'custom-multiple-select-error' : ''
          }`}
        >
          {isFilterable ? filterBar() : null}
          {subFilterKey ? subFilterBar() : null}
          {reminder()}
          <div
            className={`multiple-select-pointer-area ${selection_pointer_area_class()}`}
            onScroll={(event) => {
              if (
                event.nativeEvent.target.scrollTop +
                  event.nativeEvent.target.clientHeight ===
                event.nativeEvent.target.scrollHeight
              ) {
                // setOptions([
                //   ...options,
                //   { label: 'fake test', value: { pk: 'f01', name: 'fake test' } },
                // ]);
                // load more action
                loadMoreAction();
                setIsLoadMore(true);
              }
            }}
          >
            {selectAllOption()}
            {isFilterable
              ? filteredOptions.map((optionvalue, index) =>
                  optionItem(optionvalue, index),
                )
              : groupKey
              ? Object.keys(groupBy(options, groupKey)).map((group) => {
                  return (
                    <>
                      <div className="display-flex-column">
                        <label
                          className="multiple-select-option multiple-select-all multiple-select-group"
                          onClick={() => {
                            if (openedSections.includes(group)) {
                              setOpenedSections(
                                removeElementFromArray(openedSections, group),
                              );
                            } else {
                              setOpenedSections([...openedSections, group]);
                            }
                          }}
                        >
                          {group}
                          <Image
                            src={NarrowUp}
                            className={`filterable-toogle-area-indicator margin-left-auto ${
                              openedSections.includes(group) ? 'rtoate180' : ''
                            }`}
                          />
                        </label>
                        {/* <button
                          className="reset-button margin-left-auto"
                          onClick={() => setOpenedSections(group)}
                        ></button> */}
                      </div>

                      {openedSections.includes(group)
                        ? groupBy(options, groupKey)[group].map((data, index) =>
                            optionItem(data, index, true),
                          )
                        : null}
                    </>
                  );
                })
              : options.map((optionvalue, index) =>
                  optionItem(optionvalue, index),
                )}
          </div>
        </div>
        {hideAddButton ? null : (
          <AuthButton
            title={createButtonTitle}
            action={() => addAction()}
            customClass={`multiple-select-option-add btn-add-button-common ${addButtonExtraClass}`}
            requires={addRequires}
          />
        )}
      </div>
    </>
  );
}

export default CustomMultipleSelect;
