import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';


function CampaignSelectDropdown(props) {
  const title = props.title || null;
  const defaultValue = props.defaultValue || null;
  const setValue = props.setValue || (() => {});
  const { campaignList } = useSelector((state) => ({
    campaignList: state.campaignList.allList,
  }));

  const onSelectChange = (value) => {
    setValue(value?.value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: 'campaignList/getCampaignList',
      payload: {
        isSelectorLoad: true,
        isExpired: false,
        isPublished: true,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomTitleWithDropDown
      title={title}
      titleStyle={props.isPrompt ? '' : 'create-section-label-no-top-space'}
      setValue={onSelectChange}
      source={campaignList}
      defaultValue={defaultValue}
      loadMoreAction={'campaignList/getCampaignList'}
      filterAction={'campaignList/getCampaignList'}
    />
  );
}

export default CampaignSelectDropdown;
