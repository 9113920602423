import React from 'react';

const CheckboxItem = ({ checked, onChange, title }) => (
  <label className="checkbox-container checkbox-container-no-margin">
    {title}
    <input type="checkbox" checked={checked} onChange={onChange} />
    <span className="checkmark"></span>
  </label>
);

export default CheckboxItem;
