import { BaseHelper } from './BaseGraphQLAPIHelper';
export const getCampaignList = (
  afterCursor,
  filter
  // type,
  // reverse,
  // filterName,
  // ssoUid,
  // isAvailable,
  // others,
) => {
  let {type, reverse, filterName, ssoUid, isAvailable, others} = filter;
  if (!filterName) {
    filterName = ''
  }
  if (!type) {
    type = ''
  }
  let userFilter = '';
  if (ssoUid) {
    userFilter = `, isAvailable: true, customerSsoUid: "${ssoUid}"`;
  }

  if (!ssoUid && isAvailable) {
    userFilter = `, isAvailable: true`;
  }

  // if (others.moreSearch) {
  //   userFilter += ', ' + others.moreSearch;
  // }

  if (others?.status) {
    userFilter += `, isPublished: ${others.status}`;
  }
  if (others?.expired) {
    userFilter += `, isExpired: ${others.expired}`;
  }
  if (others?.message_channel) {
    userFilter += `, messageChannelsIn: "${others.message_channel}"`;
  }
  if (others?.brand) {
    userFilter += `, brandIn: "${others.brand}"`;
  }
  if (others?.active_period) {
    const activePeriod = others.active_period.split(',');
    userFilter += `, startDateGte: "${activePeriod[0]}", endDateLte: "${activePeriod[1]}"`;
  }
  if (others?.visable_period) {
    const visablePeriod = others.visable_period.split(',');
    userFilter += `, displayStartDateGte: "${visablePeriod[0]}", displayEndDateLte: "${visablePeriod[1]}"`;
  }
  if (others?.target_customer) {
    if (others[others.target_customer])
      userFilter += `, ${others.target_customer}: "${
        others[others.target_customer]
      }"`;
  }
  let orderBy = reverse ? '-pk' : 'pk';
  if (others?.is_featured) {
    orderBy = 'displayPriority';
    userFilter += `, isFeatured: true`;
  }
  if (others?.sort) {
    orderBy = others.sort;
  }

  if (others?.all || others?.isAll || filter?.isAll) {
    userFilter += `, first: ${filter?.pageSize || 100}`;
  } else {
    userFilter += `, first: 20`;
  }
  console.log('@@46: ', others);
  console.log('@@userFilter: ', userFilter);
  const query = `{
      campaigns(after:"${afterCursor}", orderBy: "${orderBy}", nameIcontains:"${filterName}", type: "${
    type === 'AllTypes' ? '' : type
  }"${userFilter}) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            pk
            id
            type
            name
            startDate
            endDate
            displayStartDate
            displayEndDate
            isPublished
            lastModifiedDate
            publicationDate
            displayPriority
            status
            coverPhoto
            brand {
              pk
              name
            }
            stores {
              edges {
                node {
                  pk
                  name
                }
              }
            }
            numberOfCustomersVisible
            messageChannels
            translations {
              edges {
                node {
                  language
                }
              }
            }
          }
        }
      }
    }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCampaign = (id) => {
  const query = `{
    campaign(id: "${id}") {
      id
      pk
      type
      name
      creationDate
      lastModifiedDate
      shortDescription
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      startDate
      endDate
      displayStartDate
      displayEndDate
      isFeatured
      isExclusive
      applyCoverPhotoToAllLanguage
      applyDetailPhotosToAllLanguage
      applySquareCoverPhotoToAllLanguage
      isPublished
      publicationDate
      coverPhoto
      squareCoverPhoto
      detailPhoto1
      detailPhoto2
      detailPhoto3
      detailPhoto4
      language
      displayPriority
      categories {
        edges {
          node{
            pk
            name
            id
          }
        }
      }
      targetedCustomerGroups {
        edges {
          node {
            pk
            name
            id
          }
        }
      }
      targetedSegments {
        edges {
          node{
            pk
            name
            id
          }
        }
      }
      translations {
        edges {
          node {
            id
            pk
            name
            shortDescription
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
            coverPhoto
            squareCoverPhoto
            detailPhoto1
            detailPhoto2
            detailPhoto3
            detailPhoto4
            language
          }
        }
      }
      couponCampaignTypeCouponTemplate {
          id
          pk
          name
          stock
      }
      couponCampaignTypeShouldShowCouponStock
      couponCampaignTypeOverallLimit
      couponCampaignTypePerHeadLimit
      couponCampaignTypeRequiredPoints
      earningCampaignTypeEarningRule {
          id
          pk
          name
          type
          birthdayTypePeriodStartDate
          birthdayTypePeriodEndDate
      }
      brand {
          id
          pk
          name
      }
      stores {
        edges {
          node {
            pk
            name
          }
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCampaignCategoryList = ({ search }) => {
  let searchString = '';
  // console.log('@@225: ', search);
  // if (search.isActive) {
  //   searchString += `, is_active: ${search.isActive}`;
  // }
  const query = `{
    campaignCategories(first: 20 ${searchString}) {
      edges {
        cursor
        node {
          id
          pk
          name
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createCampaignCategory = (input) => {
  const query = `mutation CreateCampaignCategory($input: CreateCampaignCategoryInput!) {
    createCampaignCategory(input: $input) {
      node {
        id
        pk
        name
        displayPriority
        translations {
          edges {
            node {
              language
              name
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createCampaign = (campaign) => {
  const query = `mutation CreateCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      node {
        id
        pk
        type
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...campaign,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const publishCampaign = (input) => {
  const query = `mutation PublishCampaign($input: PublishCampaignInput!) {
    publishCampaign(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const unPublishCampaign = (input) => {
  const query = `mutation UnpublishCampaign($input: UnpublishCampaignInput!) {
    unpublishCampaign(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteCampaign = (ids) => {
  const query = `mutation DeleteCampaigns($input: DeleteCampaignsInput!) {
    deleteCampaigns(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

// export const deleteCampaignTranslation = (ids) => {
//   const query = `mutation DeleteCampaignTranslation($input: DeleteCampaignTranslationInput!) {
//     deleteCampaignTranslation(input: $input) {
//       success
//     }
//   }
//   `;
//   const variables = ids.map((item) => {
//     return {
//       query: query,
//       variables: {
//         input: {
//           id: item,
//         },
//       },
//     };
//   });
//   return BaseHelper.callGraphQLAPIMultiple(variables);
// };

// export const deleteCampaignBrand = (ids) => {
//   const query = `mutation DeleteCampaignBrandSection($input: DeleteCampaignBrandSectionInput!) {
//     deleteCampaignBrandSection(input: $input) {
//       success
//     }
//   }
//   `;
//   const variables = ids.map((item) => {
//     return {
//       query: query,
//       variables: {
//         input: {
//           id: item,
//         },
//       },
//     };
//   });
//   return BaseHelper.callGraphQLAPIMultiple(variables);
// };

export const duplicateCampaign = (id) => {
  const query = `mutation duplicateCampaign($input: DuplicateCampaignInput!) {
    duplicateCampaign(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCampaign = (input) => {
  const query = `mutation UpdateCampaign($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      success
      node {
        id
        pk
        name
        type
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneEarningRule = (id) => {
  const query = `
  {
    earningRule(id: "${id}") {
      id
      pk
      name
      type
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      birthdayTypePeriodStartDate
      birthdayTypePeriodEndDate
      translations {
        edges {
          node {
            language
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
          }
        }
      }
    }
  }
  `;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneCoupon = (id) => {
  const query = `
  {
    couponTemplate(id: "${id}") {
      id
      pk
      name
      stock
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      coverPhoto
      detailPhoto1
      detailPhoto2
      detailPhoto3
      detailPhoto4
      translations {
        edges {
          node {
            language
            coverPhoto
            detailPhoto1
            detailPhoto2
            detailPhoto3
            detailPhoto4
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
          }
        }
      }
    }
  }
  `;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
