import React, { useState, forwardRef, useEffect } from 'react';
import { Image, Dropdown } from 'react-bootstrap';
import './FilterableDropdown.scss';
import deleteSelectedButton from '../../assets/images/drop_down_delete_selected.png'
import NarrowUp from '../../assets/images/drop_down_not_show_narrow.svg';
import Search from '../../assets/images/drop_down_filter.svg';
export const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    className="filterable-toogle-area"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

export const CustomMenu = forwardRef(
  (
    {
      children,
      style,
      className,
      'aria-labelledby': labeledBy,
      options,
      filter,
      filterButtons,
      filterAction,
      isLoadMore,
      placeholder,
    },
    ref,
  ) => {
    const [value, setValue] = useState('');
    const [filterBtn, setFilterBtn] = useState('');
    const [filteredList, setfilteredList] = useState(
      options.map((item) => item.label),
    );
    const getFilteredList = (filter1, filter2) => {
      // const prevList = options;
      // let filterList = prevList;
      // if (filter1) {
      //   filterList = filterList.filter((item) =>
      //     item.label.toLowerCase().includes(filter1.toLowerCase()),
      //   );
      // }
      // if (filter2) {
      //   filterList = filterList.filter(
      //     (item) =>
      //       item.value.subFilterField.toLowerCase() === filter2.toLowerCase(),
      //   );
      // }
      // setfilteredList(filterList.map((item) => item.label));
      filterAction(filter1);
      isLoadMore(false);
    };
    // console.log('@@127: ', options, filteredList);
    useEffect(() => {
      setfilteredList(options.map((item) => item.label));
    }, [options]);
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {filter ? (
          <div className="filterable-search-bar">
            <Image src={Search} className="filterable-search-bar-icon" />
            <input
              autoFocus
              className="filterable-search-bar-input"
              placeholder={placeholder}
              onChange={(e) => {
                setValue(e.target.value);
                getFilteredList(e.target.value, filterBtn);
              }}
              value={value}
            />
          </div>
        ) : null}
        {filterButtons && filterButtons.length > 0 ? (
          <div className="filter-buttons-bar">
            {filterButtons.map((button) => {
              return (
                <button
                  className={`filter-btn ${
                    filterBtn === button ? 'filter-btn-selected' : ''
                  }`}
                  onClick={() => {
                    setFilterBtn(button);
                    getFilteredList(value, button);
                  }}
                >
                  {button}
                </button>
              );
            })}
          </div>
        ) : null}
        <ul className="filterable-dropdown-menu-select-area">
          {React.Children.toArray(children).filter((child) => {
            return filteredList.includes(child.props.children);
          })}
        </ul>
      </div>
    );
  },
);

const EmptyMenu = forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');
    return null;
  },
);

function FilterableDropdown(props) {
  const needFilter = props.needFilter === undefined ? true : props.needFilter;
  const filterButtons = props.filterButtons || [];
  const placeholder = props.placeholder || "Search for...";
  const customClass = props.customClass;
  const onSelectChange = props.selectChange || (() => {});
  const onToggle = props.onToggle || (() => {});
  // const options = props.options || [];
  const [options, setOptions] = useState(props.options);
  const [value, setValue] = useState(props.defaultValue?.label);
  const [isOpen, setIsOpen] = useState();
  const isPromptDropdown = props.isPromptDropdown || false;

  const filterAction = props.filterAction || (() => {});
  const loadMoreAction = props.loadMoreAction || (() => {});
  const [isLoadMore, setIsLoadMore] = useState(false);
  useEffect(() => {
    setValue(props.defaultValue?.label || props.defaultValue?.value?.label);
  }, [props.defaultValue]);
  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);


  return (
    <Dropdown
      onToggle={(isOpen) => {
        setIsOpen(isOpen);
        onToggle();
      }}
      className={`${
        isPromptDropdown
          ? 'filterable-dropdown-area'
          : 'filterable-dropdown-area-prompt'
      } ${customClass}`}
      onScroll={(event) => {
        if (
          event.nativeEvent.target.scrollTop +
            event.nativeEvent.target.clientHeight ===
          event.nativeEvent.target.scrollHeight
        ) {
          // setOptions([
          //   ...options,
          //   { label: 'fake test', value: { pk: 'f01', name: 'fake test' } },
          // ]);
          // load more action
          loadMoreAction();
          setIsLoadMore(true);
        }
      }}
    >
      <Dropdown.Toggle as={CustomToggle}>
        <>
          <label
            className={`btn-link-text-label ${
              value ? 'btn-link-text-label-active' : ''
            }`}
          >
            {value ? `${value}` : 'Please select'}
          </label>
          <Image
            src={deleteSelectedButton}
            className="filterable-dropdown-delete-selected"
            onClick={ (e) =>{
              e.stopPropagation();
              setValue('');
              onSelectChange({value: null, label: null});
            }}
          />
          <Image
            src={NarrowUp}
            className={`filterable-toogle-area-indicator ${
              isOpen ? 'rtoate180' : ''
            }`}
          />
        </>
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={CustomMenu}
        className="filterable-dropdown-menu"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          margin: 0,
          right: 'auto',
          bottom: 'auto',
        }}
        options={options}
        filter={needFilter}
        filterButtons={filterButtons}
        filterAction={filterAction}
        isLoadMore={setIsLoadMore}
        placeholder={placeholder}
      >
        {options.map((option, index) => (
          <Dropdown.Item
            key={`${index}-${option.label}`}
            className="filterable-item"
            onSelect={(eventKey) => {
              onSelectChange(option);
              setValue(option.label);
              // console.log('@@125: ', option);
            }}
            eventKey={index}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default FilterableDropdown;
