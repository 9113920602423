import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFormContext, Controller } from 'react-hook-form';
import ContentSections from '../base/ContentSections';
import { validate } from '../../containers/engagement/message/CreateMessageValidate';
import MessageGeneralSection from './MessageGeneralSection';
import {
  ContinueWithBackButtons,
  OnlyContinueButton,
} from '../base/BottomStepComponent';

function CreateMessageStepTwo({ languages }) {
  const dispatch = useDispatch();
  const params = useParams();

  const { watch, getValues, setValue, control, formState, setError, clearErrors } = useFormContext();
  const { errors } = formState;

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 1,
      isBack,
      languages: languages,
    });
    dispatch({
      type: 'createMessage/stepChange',
      payload: {
        isBack: isBack,
        step: 1,
        isValid
      },
    });
  };

  const renderTabForLanguage = (language) => {
    return [<MessageGeneralSection language={language} />];
  };

  return (
    <>
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: [renderTabForLanguage(item.code)],
            key: item.code,
            title: item.sourceName,
          })),
        }}
        hidePreview={true}
      />
      {params.id ? (
        <OnlyContinueButton
          continueAction={() => {
            stepChangeAction(false);
          }}
        />
      ) : (
        <ContinueWithBackButtons
          backAction={() => {
            stepChangeAction(true);
          }}
          continueAction={() => {
            stepChangeAction(false);
          }}
        />
      )}
    </>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CreateMessageStepTwo);
