import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createAction } from '../../../utils';
import { CustomTitleWithDropDown } from '../../customer/CustomTitleWithDropDown';
function EarningRulesSelectDropdown(props) {
  const title = props.title;
  const defaultValue = props.defaultValue;
  const isPrompt = props.isPrompt || false;
  const addButtonInfo = props.addButtonInfo || {};
  const setValue = props.setValue || (() => {});

  const dispatch = useDispatch();
  const { earningRuleList } = useSelector((state) => ({
    // linkedEarningRules: state.createCampaign.campaign.linkedEarningRules,
    earningRuleList: state.earningRuleList.earningRuleList,
  }));

  //TODO: remove save linked earning rule
  const onSelectChange = (value) => {
    setValue(value?.value);
  };

  useEffect(() => {
    dispatch(createAction('earningRuleList/getEarningRuleListWithTypes')({}));
  }, [dispatch]);

  return (
    <CustomTitleWithDropDown
      title={title}
      titleStyle={isPrompt ? '' : 'create-section-label-no-top-space'}
      defaultValue={defaultValue}
      setValue={onSelectChange}
      source={earningRuleList}
      loadMoreAction={'earningRuleList/getEarningRuleListWithTypes'}
      filterAction={'earningRuleList/getEarningRuleListWithTypes'}
      errors={props.errors}
      errorName={props.errorName}
      addButtonInfo={addButtonInfo}
    />
  );
}

export default EarningRulesSelectDropdown;
