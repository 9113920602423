import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import { createAction } from '../../utils';
function ProductSelectDropdown(props) {
  const title = props.title || null;
  const defaultValue = props.defaultValue || null;
  const setValue = props.setValue || (() => {});
  const { productList } = useSelector((state) => ({
    productList: state.bannerList.productList,
  }));

  const onSelectChange = (value) => {
    setValue(value?.value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(createAction('bannerList/getProductList')());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomTitleWithDropDown
      title={title}
      titleStyle={props.isPrompt ? '' : 'create-section-label-no-top-space'}
      setValue={onSelectChange}
      source={productList}
      defaultValue={defaultValue}
      loadMoreAction={() => {}}
      filterAction={'bannerList/getProductList'}
    />
  );
}

export default ProductSelectDropdown;
