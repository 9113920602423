import React, { useState, useEffect } from 'react';
import '../../../components/campaign/campaignCreation/CreateCampaignStepTwo.scss';
import { useSelector, useDispatch, connect } from 'react-redux';
import { CampaignType, LanguageConfig } from '../../../config/CustomEnums';
import ContentSections from '../../../components/base/ContentSections';
import OverviewSection from '../../../components/earning/OverviewSection';
import DetailSection from '../../../components/campaign/campaignCreation/DetailSection';
import InstructionSection from '../../../components/campaign/campaignCreation/InstructionSection';
import { ContinueWithBackButtons } from '../../../components/base/BottomStepComponent';
import {
  defaultPreviewImage,
  earningRulesCampaignPreview,
} from '../../../components/campaign/campaignCreation/CampaignStepTwoPreviewConfig';
import { validate } from './CreateEarningRuleValidate';
import { useFormContext } from 'react-hook-form';

function CreateEarningRuleStepTwo({
  languages,
}) {
  const dispatch = useDispatch();
  const { getValues, watch, formState, clearErrors, setError } = useFormContext();
  const errors = formState.errors;
  const fieldError = Object.keys(errors || []).length;

  const inputWithImageConfig = earningRulesCampaignPreview;
  const defaultImage = defaultPreviewImage(CampaignType.earningRulesCampaign);

  const [activeSection, setActiveSection] = useState();

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 1,
      isBack,
    });
    dispatch({
      type: 'createEarningRules/stepChange',
      payload: { isBack: isBack, step: 1, isValid },
    });
  };

  const getTabs = (language) => {
    return [
      <OverviewSection
        onFocus={setActiveSection}
        language={language}
        areaTitle={'overview'}
      />,

      <InstructionSection
        focusChange={setActiveSection}
        language={language}
      />,

      <DetailSection
        focusChange={setActiveSection}
        language={language}
      />,
    ];
  };

  return (
    <>
      <ContentSections
        fieldsError={fieldError}
        languageTabContent={{
          containers: languages?.map((language) => {
              return {
                container: getTabs(language.code, setActiveSection),
                key: language.code,
                title: language.sourceName,
              };
            }),
        }}
        inputWithImageConfig={inputWithImageConfig}
        defaultImage={defaultImage}
        activeSection={activeSection}
      />
      <ContinueWithBackButtons
        continueAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
})

export default connect(mapPropsToState)(CreateEarningRuleStepTwo);
