import React, { Component } from 'react';
import './CreateCouponStepTwo.scss';
import CouponDateTimeSelector from '../../../components/coupon/couponCreation/CouponDateTimeSelector';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createAction } from '../../../utils';
import { CouponErrorFields } from '../../../components/coupon/couponCreation/CouponHandleError';
import { APIStatus, COUPON_SET_TYPES } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import { useHistory } from 'react-router';
import { useFormContext } from 'react-hook-form';
import { validate } from './CreateCouponValidate';
import './CreateCouponStepThree.scss'

const CreateCouponStepThree = ({
  createCouponTemplateStatus
}) =>  {
  const {
    getValues, 
    setError, 
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const fromEdit = history.location.pathname.endsWith('edit') ||
    history.location.pathname.endsWith('edit/');

  const saveAction = (isGenerater, isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 2,
      isBack,
    });
    if (!isValid || isBack) {
      dispatch({
        type: 'createCoupon/stepChange',
        payload: { isBack: isBack, step: 2, isValid },
      });
    } else {
      if (fromEdit) {
        dispatch(
          createAction('createCoupon/updateCouponTemplateInput')({
            isGenerater: isGenerater,
            data: getValues(),
          }),
        );
      } else {
        dispatch(
          createAction('createCoupon/createCouponTemplate')({
            isGenerater: isGenerater,
            data: getValues(),
          }),
        );
      }
    }

  }


  const watchCouponSetType = watch('couponSetType');
    return createCouponTemplateStatus === APIStatus.calling ? (
      <Loading />
    ) : (
      <div className="create-coupon-step-three-container has-bottom-margin">
        <CouponDateTimeSelector />
        <SaveAndBackButtons
          saveTempText="Save Only"
          saveText={`Save and ${
            watchCouponSetType === COUPON_SET_TYPES.SYSTEM_GENERATED
            ? 'Generate'
            : 'Import'
          } Coupon`}
          saveTempAction={() => {
            saveAction(false, false);
          }}
          saveAction={() => {
            saveAction(true, false);
          }}
          backAction={() => {
            saveAction(false, true)
          }}
        />
      </div>
    );
  
}

const mapPropsToState = (state) => ({
  createCouponTemplateStatus: state.createCoupon.createCouponTemplateStatus
})

export default connect(mapPropsToState)(CreateCouponStepThree);
