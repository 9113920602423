import React, { useState, useEffect, useRef } from 'react';
import ContentItemSection from './ContentItemSection';

export default function ContentSection(props) {
  const segment = props.defaultValue;

  const initData = (data) => {
    if (data && data.length > 0) {
      // console.log('@@10: ', Object.keys(data.slice(-1)[0]));
      // if (Object.keys(data.slice(-1)[0]).length > 0) {
      //   return [...data, {}];
      // }
      // return data;
      return [...data, {}];
    } else {
      return [{}];
    }
  };

  const [data, setData] = useState(initData(segment?.conditionGroup));

  const addNewContent = (index) => {
    console.log('@@24: ', index, data.length);
    if (index === data.length - 1) setData(initData(data));
  };

  useEffect(() => {
    if (segment?.conditionGroup) {
      setData(initData(segment.conditionGroup));
    }
  }, [segment?.conditionGroup]);

  // console.log('@@17-1: ', segment.conditionGroup);
  // console.log('@@17-2: ', data);

  return (
    <>
      {data?.map((item, index) => (
        <>
          {index > 0
            ? (
              <div className="content-condition-area content-condition-or-area">
                <div className="content-condition-solid"></div>
                <label>Or</label>
              </div>
            )
            : null}

          <ContentItemSection
            segment={item}
            index={index}
            addNewAction={addNewContent}
          />
        </>
      ))}
    </>
  );
}
