import React, { useEffect, useState } from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';

import ErrorFieldMessage from '../base/ErrorFieldMessage';

function CustomTitleWithInput({
  title,
  defaultValue,
  setValue,
  error = {},
  type = 'text',
  tips,
  focusChange = () => {},
  extra,
  customTitleClass,
  customClass,
  disabled,
  blurAction = (target) => {},
  useDefaultValue = true,
  unit,
  per,
  shortDescription,
}) {
  useEffect(() => {
    console.log('@@15: ', defaultValue);
  }, [defaultValue]);

  // console.log('@@15: ', error);

  return (
    <div className="d-flex flex-column">
      <CustomTitleLabel title={title} className={customTitleClass}/>
      {tips ? <label className="tips-message">{tips}</label> : null}
      <div style={unit ? null : per ? {display: "flex"} : {display: "contents"}}>
      {per ? <label className="help-message margin-right-10-percent">{per}</label> : null}
      <input
        type={type}
        onChange={({ target }) => setValue(target.value)}
        {...(useDefaultValue
          ? { defaultValue: defaultValue || '' }
          : { value: defaultValue || '' })}
        className={`custom-markdown-area-title ${
          error.error ? 'error-field' : ''
        } custom-markdown-area-title-short ${
          type === 'number' ? 'custom-number-input-short' : ''
        } ${customClass}`}
        onFocus={focusChange}
        {...extra}
        disabled={disabled}
        onBlur={({ target }) => blurAction(target)}
      />
      {unit ? <label className="section-short-description">{unit}</label> : null}
      </div>
      {shortDescription ? <label className="section-caution-description">{shortDescription}</label>: null}

      {error.error ? <ErrorFieldMessage id={error.id} error={error} message={error.message} /> : null}
    </div>
  );
}

export default CustomTitleWithInput;
