import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import CustomRadios from '../base/CustomRadios';
import { Row } from 'react-bootstrap';
import CampaignSelectDropdown from '../../components/message/CampaignSelectDropdown';
import ProductSelectDropdown from '../../components/banners/ProductSelectDropdown';
import { listItem } from '../../components/banners/DisplayItems';
import { ReactHookFormImageUploader } from '../base/ImageUploader';
import {
  BannerErrorFields,
} from '../../models/HomeBannersModel';
import { LanguageConfig } from '../../config/CustomEnums';
import { getReadablePeriod } from '../../utils/TimeFormatUtil';
import { useFormContext, Controller } from 'react-hook-form';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import {
  BANNER_CONTENT_TYPE,
} from '../../config/CustomEnums';


export const ContentType = {
  Campaign: 'Campaign',
  Product: 'Product',
};

const getRadioOptions = () => [
  {
    label: ContentType.Campaign,
    value: BANNER_CONTENT_TYPE.CAMPAIGN,
  },
  {
    label: ContentType.Product,
    value: BANNER_CONTENT_TYPE.PRODUCT,
  },
];


const CreateBannerContent = ({ language }) => {
  const param = useParams();

  const { watch, setValue, control, formState, clearErrors } = useFormContext();
  const { errors } = formState;
  const watchBannerType = watch(`contentType`);
  const watchCoverPhotos = watch(`translations.${language}.coverPhotos.0`);
  const watchCampaign = watch(`campaign`);
  const watchProduct = watch(`product`);
  const watchOrder = watch(`order`);

  console.log(
    '@@49: ',
    language,
    watchCoverPhotos,
  );

  return (
    <>
      {language === LanguageConfig.english ? (
        <>
          <label className={`create-section-label landing-title-margin-bottom `} >
            {'Banner type'}
          </label>
          <Row className="featured-exclusive-row">
            <CustomRadios
              onChange={(value) => {
                setValue('contentType', value, { shouldDirty: true });
              }}
              default={watchBannerType}
              options={getRadioOptions()}
            />
          </Row>
          <ReactHookFormErrorMessage errors={errors} id='contentType' />

          {watchBannerType === BANNER_CONTENT_TYPE.CAMPAIGN ? (
            <>
              <CampaignSelectDropdown
                title='Related campaign'
                isPrompt
                defaultValue={{ label: watchCampaign?.name, value:watchCampaign }}
                setValue={(value) => {
                  setValue('campaign', value, { shouldDirty: true });
                }}
              />
              <ReactHookFormErrorMessage errors={errors} id='campaign' />
              {listItem('Campaign name', watchCampaign?.name)}
              {listItem('Campaign ID', watchCampaign?.pk)}
              {listItem(
                'Campaign’s active period',
                getReadablePeriod(
                  watchCampaign?.startDate,
                  watchCampaign?.endDate,
                ),
              )}
              {listItem(
                'Campaign’s visible period',
                getReadablePeriod(
                  watchCampaign?.displayStartDate,
                  watchCampaign?.displayEndDate,
                ),
              )}
            </>
          ) : (
            <>
              <ProductSelectDropdown
                title='Related Product'
                isPrompt
                defaultValue={{ label: watchProduct?.name, value: watchProduct }}
                setValue={(value) => {
                  setValue('product', value, { shouldDirty: true });
                }}
              />
              <ReactHookFormErrorMessage errors={errors} id='product' />
              {listItem('Product name', watchProduct?.name)}
              {listItem('Product ID', watchProduct?.pk)}
              {listItem('Quantity', watchProduct?.quantity)}
            </>
          )}

          <ReactHookFormErrorMessage errors={errors} id={`publishLocation`} />
        </>
      ) : null}
      <>
        <ReactHookFormImageUploader
          title={'Banner image'}
          uploadSuggest={'Suggested Image Size: 900 px * 600 px (Less than 2MB, support JPG, PNG and GIF only.)'}
          name={`translations.${language}.coverPhotos.0`}
          minWidth={900}
          minHeight={600}
          value={watchCoverPhotos}
          aspect={3 / 2}
          maxImageNum={1}
          language={language}
        />
      </>

      {language === LanguageConfig.english ? (
        <>
          <CustomTitleWithInput
            title={'Display order'}
            error={{ error: hasError(errors, 'order') }}
            defaultValue={watchOrder}
            setValue={(value) => {
              setValue('order', value, { shouldDirty: true, });
            }}
            type={'number'}
            extra={{
              min: 1,
            }}
          />
          <ReactHookFormErrorMessage errors={errors} id='order' />
          <label className="create-message-suggest">
            {`Minimum value is 1. The smaller value, the higher priority.`}
          </label>
        </>
      ) : null}
    </>
  );
};

export default CreateBannerContent;
