import React from 'react';
import { useSelector } from 'react-redux';
import './CustomerOverview.scss';
import OverviewCard from '../dashboard/OverviewCard';
import NewCustomerCard from './NewCustomerCard';

const CUSTOMER_CARD_CONFIG = [
  {
    title: 'Total Customers',
    key: 'totalCustomers',
  },
  {
    title: 'New Customers',
    key: 'newCustomers',
  },
  {
    title: 'Active Customers',
    key: 'activeCustomers',
  },
  {
    title: 'Non-registered customers in app',
    key: 'nonRegistedCustomers',
  },
];

const CustomerOverview = () => {
  const customerOverviewData = useSelector((state) => state.overview.customers);
  return (
    <div>
      <div className="overview-row-title overview-campaigns-title">
        Customers
      </div>
      <div className="overview-row customer-overview-row">
        {CUSTOMER_CARD_CONFIG.map((config) => {
          return (
            <OverviewCard
              title={config.title}
              value={customerOverviewData[config.key]?.value}
              change={customerOverviewData[config.key]?.change}
            />
          );
        })}
      </div>
      <NewCustomerCard />
    </div>
  );
};
export default CustomerOverview;
