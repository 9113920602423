// export const AdminErrorHandleFields = {
//   username: {
//     required: 'Please provide name',
//   },
//   password: {
//     required: 'Please provide password',
//     length: 'Password at least 8 characters',
//   },
//   passwordConfirmation: {
//     required: 'Please make sure the password and passwordConfirmation is same',
//   },
//   email: {
//     valid: 'please input the valid email',
//   },
// };

export const AdminErrorHandleFields = {
  required: {
    username: 'Please provide name',
    password: 'Please provide password',
    passwordConfirmation: 'Please provide confirmation password ',
  },
  others: {
    password: {
      length: 'Password at least 8 characters',
      numeric: 'Password can’t be entirely numeric',
    },
    email: {
      valid: 'please input the valid email',
    },
    periodEndDate: {
      valid:
        'The end date & time of a campaign cannot be on or before the start date and time.',
    },
  },
};
