import React, { useEffect, useState } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import './CouponTransaction.scss';
import { useDispatch, useSelector, connect } from 'react-redux';
import { createAction } from '../../../utils';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import { ImportResource } from '../../../models/DownloadImportModel';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Filter from './Filter';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import ExportCouponView, {
  EXPORT_BY,
} from '../../../components/coupon/couponList/ExportCouponView';

function CouponTransaction({
  tableFields,
  dataList,
  pageInfo,
  totalCount,
  totalPage,
  currentPage,
}) {
  const queryString = require('query-string');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showExportView, setShowExportView] = useState(false);

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    // const couponTransactionType = searchDict['type'];
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch(
      createAction('couponTransactions/getCurrentPageCouponTransactions')({
        ...searchDict,
        // couponTransactionType,
        searchKey,
        rank,
        page,
      }),
    );
  }, [dispatch, location, queryString]);
  const viewCouponSetAction = () => {
    history.push({
      pathname: '/coupons',
      search: queryString.stringify({
        page: 1,
        rank: true,
        searchKey: '',
      }),
    });
  };
  const exportCSVAction = () => {
    dispatch(
      createAction('downloadAndImport/createDownloadTask')({
        from: ImportResource.couponRecord,
      }),
    );
  };
  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={600}
      hideAllButtonWidth={530}
      extraButtons={[
        <AuthButton
          title="Export .csv"
          customClass="btn-back-button-common btn-download"
          action={() => setShowExportView(true)}
          requires={PermissionCodes.addExportjob}
        />,
      ]}
      // extraPopContent={[
      //   {
      //     requires: PermissionCodes.addExportjob,
      //     action: exportCSVAction,
      //     content: 'Export .csv',
      //   },
      // ]}
      primaryButton={
        <AuthButton
          title="View Coupon Set"
          action={viewCouponSetAction}
          requires={PermissionCodes.viewCoupon}
        />
      }
      primaryPopContent={{
        requires: PermissionCodes.viewCoupon,
        action: viewCouponSetAction,
        content: 'View Coupon Set',
      }}
    />,
  ];

  const couponRecordTypeTabs = [
    { key: 'all', name: 'All Types' },
    { key: 'EARN', name: 'Customer earned' },
    { key: 'BUY', name: 'Customer Acquired' },
    { key: 'GRANT', name: 'Admin added' },
    { key: 'RECLAIM', name: 'Admin removed' },
  ];

  const tableTabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={couponRecordTypeTabs}
          pageInfo={pageInfo}
          totalCount={totalCount}
          groupActions={[]}
          filter={{ hasFilter: true, componment: Filter }}
          listContent={
            <BaseListContainer
              fields={tableFields}
              model={'couponTransactions'}
              dataList={dataList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: '',
                relatedName: '',
                onComfirm: {},
              }}
              useCustomCommonActions={true}
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'View details',
                    action: () => {
                      return history.push(`${location.pathname}/${item.pk}/`);
                    },
                  },
                ];
                return (
                  <ActionsDropdownForItem object={item} actions={actions} />
                );
              }}
              customClassName="coupon-record-table"
            />
          }
        />
      ),
    },
  ];

  return (
    <>
    <CustomListComponent
      caution={{
        detail:
          'All coupon records are listed here, you can view the coupon transactions of each customer.',
        title: 'Coupon records',
      }}
      buttons={buttons}
      breadcrumb={<CustomBreadcrumb />}
      tabs={tableTabs}
      hideTab={true}
    />
    <ExportCouponView
      show={showExportView}
      onHide={() => {
        setShowExportView(false);
      }}
      exportBy={EXPORT_BY.campaign}
    />
    </>
  );
}

const mapPropsToState = (state) => ({
  tableFields: state.couponTransactions.listDisplayFields,
  dataList: state.couponTransactions.currentPageCouponTransactionList,
  pageInfo: state.couponTransactions.pageInfo,
  totalCount: state.couponTransactions.totalCount,
  totalPage: state.couponTransactions.totalPage,
  currentPage: state.couponTransactions.currentPage,
})

export default connect(mapPropsToState)(CouponTransaction);
