import React, { useState, useEffect } from 'react';
import './CustomPagination.scss';
import { Button, Image } from 'react-bootstrap';
import OmitPage from '../../assets/images/omit_page.svg';
import Next from '../../assets/images/next_page.svg';
import { createNumberArray } from '../../utils';

function CustomPagination(props) {
  const pageSize = props.pageSize || 1;
  const initPage = props.initPage || 0;
  const pageList = createNumberArray(pageSize);
  const pageChange = props.pageChange || (() => {});
  const [currentPage, setCurrentPage] = useState();

  useEffect(() => {
    setCurrentPage(initPage);
  }, [initPage]);

  const getOmittPageItem = () => {
    return (
      <Image
        src={OmitPage}
        className="custom-page-item-common custom-page-next"
      />
    );
  };

  const getNextPageItem = () => {
    const disabled = currentPage === pageList.length - 1;
    return (
      <Image
        src={Next}
        onClick={() => {
          if (!disabled) {
            pageChange(currentPage + 1);
          }
        }}
        className={`custom-page-item-common custom-page-next ${
          disabled ? 'custom-page-disabled' : ''
        }`}
      />
    );
  };
  const getPreviousPageItem = () => {
    const disabled = currentPage === 0;
    return (
      <Image
        onClick={() => {
          if (!disabled) {
            pageChange(currentPage - 1);
          }
        }}
        src={Next}
        className={`custom-page-item-common custom-page-previous ${
          disabled ? 'custom-page-disabled' : ''
        }`}
      />
    );
  };

  const getPageItem = (page) => {
    return (
      <Button
        index={page + 1}
        key={page}
        active={page === currentPage}
        onClick={() => {
          pageChange(page);
        }}
        className="custom-page-item-common custom-page-button-item-common"
      >
        {page + 1}
      </Button>
    );
  };

  const getPagesWithLessThanSeven = () => {
    return (
      <>
        {getPreviousPageItem()}
        {pageList.map((page) => getPageItem(page))}
        {getNextPageItem()}
      </>
    );
  };

  const getMiddlePageList = () => {
    return [
      currentPage - 3,
      currentPage - 2,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      currentPage + 2,
      currentPage + 3,
    ];
  };

  const getPagesWithMoreThanSeven = () => {
    if (currentPage < 5) {
      const newPageList = createNumberArray(5);
      return (
        <>
          {getPreviousPageItem()}
          {newPageList.map((page) => getPageItem(page))}
          {getOmittPageItem()}
          {getPageItem(pageList.length - 1)}
          {getNextPageItem()}
        </>
      );
    }

    if (currentPage > pageList.length - 5) {
      return (
        <>
          {getPreviousPageItem()}
          {getPageItem(0)}
          {getOmittPageItem()}
          {pageList.slice(pageList.length - 7).map((page) => getPageItem(page))}
          {getNextPageItem()}
        </>
      );
    }

    return (
      <>
        {getPreviousPageItem()}
        {getPageItem(0)}
        {getOmittPageItem()}
        {getMiddlePageList().map((page) => getPageItem(page))}
        {getOmittPageItem()}
        {getPageItem(pageList.length - 1)}
        {getNextPageItem()}
      </>
    );
  };

  return (
    <div className="custom-page-container">
      {pageList.length <= 7
        ? getPagesWithLessThanSeven()
        : getPagesWithMoreThanSeven()}
    </div>
  );
}

export default CustomPagination;
