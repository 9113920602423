import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function NoPermission(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("no permission -> logout")
    dispatch({ type: 'users/logout' });
  }, []);

  //   const history = useHistory();
  //   return history.push({ pathname: '/login', state: { isLogout: true } });
  return <div style={{ marginTop: '100px' }}>403 Forbidden</div>;
}
export default NoPermission;
