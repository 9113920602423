import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import BaseEditSection from '../../containers/base/BaseEditSection';
import BaseMultipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import './TargetCustomerSection.scss';


function TargetCustomerSection({
  customerList,
}) {
  const dispatch = useDispatch();
  const { watch, getValues, setValue, setError, formState, clearErrors } = useFormContext();

  const customersField = 'customers';
  const selectedCustomerList = watch(customersField);

  const optionList = (list) => {
    if (!list) {
      return [];
    }
    return list.map((item) => {
      const currentLevel = item.pointAccount?.currentLevel;
      return {
        ...item,
        pk: item.pk,
        name: item.name,
        tagKey: {
          pk: currentLevel.pk,
          id: currentLevel.id,
          name: currentLevel.levelName,
          levelName: currentLevel.levelName,
        },
      };
    });
  };

  useEffect(() => {
    dispatch({
      type: 'customerList/getAllCustomers',
      payload: {}
    });
  }, [dispatch]);

  return (
    <div className="create-customer-group-target">
      <BaseEditSection
        title="TARGET CUSTOMER"
        fields={[
          <BaseMultipleSelectorV2
            title={'Customer'}
            namespace="customerList"
            data={{
              sourceData: optionList(customerList),
              targetData: optionList(selectedCustomerList),
              targetChange: (options) => {
                setValue(customersField, options, { shouldDirty: true, });
              },
            }}
            groupKey="tagKey"
          />
        ]}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  customerList: state.customerList.customerList,
});

export default connect(mapPropsToState)(TargetCustomerSection);
