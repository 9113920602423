import { LanguageConfig } from '../../../config/CustomEnums';
import { anchorElementWithId } from '../../../utils';
export const CampaignErrorHandleField = {
  generalName: {
    name: 'generalName',
    message: 'Please provide a campaign name.',
  },
  coverPhoto: {
    name: 'coverPhoto',
    message: 'Please provide a cover photo.',
  },
  squarePhoto: {
    name: 'squarePhoto',
    message: 'Please provide an square photo.',
  },
  shortDescription: {
    name: 'shortDescription',
    message:
      'Please provide a short description and ensure the length not more than 200.',
  },
  customerGroup: {
    name: 'customerGroup',
    message: 'Please provide a target customer group.',
  },
  segment: {
    name: 'customerGroup',
    message: 'Please provide a target segment.',
  },
  activeEndDate: {
    name: 'activeEndDate',
    message:
      'The end date & time of a campaign cannot be on or before the start date and time.',
  },
  visibleEndDate: {
    name: 'visibleEndDate',
    message:
      'The end date & time of a campaign cannot be on or before the start date and time.',
  },
  requiredPoints: {
    name: 'requiredPoints',
    message: 'Please provide a required/valid points.',
  },
  overallLimit:{
    name: 'overallLimit',
    message: 'Coupon campaign overall limit should be smaller than the number of generated coupon of given coupon template.'
  },
  perHeadLimit:{
    name: 'perHeadLimit',
    message: 'Coupon campaign overall limit must be larger than per head limit.'
  }
};

export const firstError = (id, errors, language = LanguageConfig.english) => {
  const firstError = errors[0];
  const error = id?.includes(firstError) && language === LanguageConfig.english;
  if (error) {
    setTimeout(() => {
      anchorElementWithId(id);
    }, 100);
  }
};

export const isShowError = (
  field,
  errors,
  language = LanguageConfig.english,
) => {
  const error =
    errors.indexOf(field) > -1 && language === LanguageConfig.english;
  return error;
};
