import React, { useEffect } from 'react';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { useSelector, useDispatch, connect } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { createAction } from '../../../utils';

import { APIStatus } from '../../../config/CustomEnums';
import BaseForm from '../../../components/base/v2/BaseForm';

import CreateLanguageContent from './CreateLanguageContent';

function CreateLangauge({
  formHasSubmitted,
  language,
  hasUpdatedDefaultValues,
  createStatus,
  apiStatus,
}) {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = params;

  const stepSet = [<CreateLanguageContent />];

  useEffect(() => {
    if (id) {
      dispatch(createAction('language/getOneDetail')({ id: params.id }));
    } else {
      dispatch(createAction('language/loadFromCookie')());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'language/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (apiStatus === APIStatus.success) history.push('/languages');
  }, [apiStatus]);

  return (
    <>
      <BaseForm
        defaultValues={language}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (!save) {
            dispatch({
              type: 'language/removeFromCookie',
            });
          } else {
            dispatch({
              type: 'language/saveToCookie',
              payload: getValues(),
            });
          }
        }}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Language is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to language list',
              action: () => {
                history.push({
                  pathname: '/languages',
                });
              },
            },
          ],
        }}
        showFinishPop={createStatus === APIStatus.success}
        content={stepSet}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            name={params.id ? language.sourceName : 'Create language'}
          />
        }
        caution={{
          detail: 'coming soon',
          title: params.id ? language.sourceName : 'Create language',
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  formHasSubmitted: state.language?.formHasSubmitted,
  language: state.language?.detail,
  hasUpdatedDefaultValues: state.language?.hasUpdatedDefaultValues,
  createStatus: state.language?.createStatus,
  apiStatus: state.language?.apiStatus,
})

export default connect(mapPropsToState)(CreateLangauge)