import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import RecordTableCard from '../../../components/customer/RecordTableCard';
import RecordTimelineCard from '../../../components/customer/RecordTimelineCard';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';

function SegmentDetail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const { id } = params;

  const {
    segment
  } = useSelector((state) => ({
    segment: state.segments.segment,
  }));
  const tabs = [
    {
      name: 'Detail',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <MembershipInfoCard
                title={''}
                data={segment}
                fields={[
                  [{ title: 'Name', field: 'name' }],
                  [{ title: 'Description', field: 'description' }],
                  [
                    {
                      title: 'Number of customers',
                      field: 'numberOfcustomers',
                    },
                  ],
                ]}
              />,
            ]}
            hidePreview={true}
            // sectionStyle="detail-info-section-item"
          />
        </div>
      ),
    },
  ];

  const buttons = [
    <AuthButton
      className="btn-further"
      onClick={() => {
        // dispatch({ type: 'customerList/clearData' });
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeSegment}
    >
      Edit
    </AuthButton>,
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    dispatch({ type: 'segments/clearData' });

    dispatch(createAction('segments/getOneSegment')({ id, view: true }));
  }, [dispatch, id]);

  return (
    <div className="customer">
      <CustomListComponent
        caution={{
          detail: '',
          title: segment.name,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={segment.name} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
          //   let search = '';
          //   if (key !== 'Overview') {
          //     // search = queryString.stringify({
          //     //   [URLFilterKeys.type]: CampaignType.allTypes,
          //     //   [URLFilterKeys.page]: 1,
          //     //   [URLFilterKeys.searchKey]: '',
          //     //   [URLFilterKeys.rank]: true,
          //     // });
          //   }
          //   history.push({ pathname: '/customer', hash: key, search: search });
        }}
      />
    </div>
  );
}

export default SegmentDetail;
