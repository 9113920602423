import React, { useState } from 'react';
import './PublishCampaignPrompt.scss';
import { useHistory } from 'react-router-dom';
import { URLFragments } from '../CampaignUrlConfig';
import NextStepPrompt from '../../base/prompt/NextStepPrompt';
import { PermissionCodes } from '../../../config/PermissionCodes';

function PublishCampaignPrompt(props) {
  const defaultShow = props.show || false;
  const isPublish = props.isPublish;
  const isSave = props.isSave;
  const history = useHistory();
  const [show, setShow] = useState(false);

  if (!show && defaultShow) {
    setShow(true);
  }

  const getTitle = () => {
    if (isSave) {
      return 'Successfully Saved!';
    }
    if (isPublish) {
      return 'Successfully Published!';
    }
    return 'Successfully UnPublished!';
  };

  const getDescription = () => {
    if (isSave) {
      return `Campaign is successfully saved. 
      You can publish by edit the status.`;
    }
    if (isPublish) {
      return `Campaign is successfully published. 
      Let your customers know about the campaign.`;
    }
    return `Campaign is successfully unpublished. 
    You can publish again by edit the status.`;
  };

  return (
    <NextStepPrompt
      show={show}
      title={getTitle()}
      description={getDescription()}
      steps={null}
      buttons={[
        {
          text: 'Back to list',
          action: () =>
            history.push({
              pathname: '/campaigns',
              hash: URLFragments.list,
            }),
          requires: PermissionCodes.changeCampaign,
        },
      ]}
    />
  );
}

export default PublishCampaignPrompt;
