import axios from 'axios';
const SHOPIFY_DOMAIN = process.env.REACT_APP_SHOPIFY_DOMAIN;
const SHOPIFY_TOKEN = process.env.REACT_APP_SHOPIFY_TOKEN;
// const SHOPIFY_GRAPHQA_URL = `${SHOPIFY_DOMAIN}/api/2020-04/graphql.json`;
const CMS_DOMAIN =
  process.env.REACT_APP_DOMAIN || 'https://aillia.hkdev.motherapp.com:443';
const shopifyAPIInstance = axios.create({
  baseURL: CMS_DOMAIN + '/third_party/products',
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

class APIHelper {
  filterProducts = (searchKey) => {
    //totalInventory
    const params = {
      "first": 100,
    }
    if(searchKey) {
      params["query[0]"] = `["title", "=", "${searchKey}"]`
    }
    return shopifyAPIInstance.get('', {params: params}).catch((error) => error);

  };
}

const ShopifyAPIHelper = new APIHelper();
export default ShopifyAPIHelper;
