import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import i18n from '../../../I18n.js';
import CustomMarkDownArea from '../../base/CustomMarkDownArea';
import { useCompare } from '../../../utils';
import { useFormContext } from 'react-hook-form';

function DetailSection({
  language,
  focusChange,
  titleTips,
  contentTips,
}) {
  // const language = props.language;

  // const focusChange = props.focusChange || (() => {});
  // const onSubmit = props.onSubmit;
  // const onSubmitAction = props.onSubmitAction || (() => {});

  // const defaultValue = props.defaultValue || {};
  // const valueChangedAction = props.valueChangedAction || '';
  // let detailTitle, detailContent;
  // if (defaultValue) {
  //   detailTitle = defaultValue['detailSectionTitle'] || '';
  //   detailContent = defaultValue['detailSectionContent'] || '';
  // }

  // const [detailSectionTitle, setDetailSectionTitle] = useState(detailTitle);
  // const [detailSectionContent, setDetailSectionContent] = useState(
  //   detailContent,
  // );

  // const dispatch = useDispatch();
  // const vals = { detailSectionTitle, detailSectionContent };
  // const hasValChanged = useCompare(vals);
  const dispatch = useDispatch();
  const { watch, setValue, formState, control, getValues } = useFormContext();
  const errors = formState.errors;

  const detailSectionTitle = watch(`translations.${language}.detailSectionTitle`) || '';
  const detailSectionContent = watch(`translations.${language}.detailSectionContent`) || '';
  // useEffect(() => {
  //   if (hasValChanged) {
  //     console.log('@@31: ', valueChangedAction);
  //     dispatch({
  //       type: valueChangedAction,
  //       payload: { vals, language },
  //     });
  //   }
  // });

  // useEffect(() => {
  //   if (onSubmit) {
  //     onSubmitAction({
  //       detailSectionTitle,
  //       detailSectionContent,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [onSubmit]);

  return (
    <CustomMarkDownArea
      areaTitle={i18n.t('detail', { locale: language })}
      title={{
        label: i18n.t('detail_section_title', { locale: language }),
        value: detailSectionTitle,
        valueChange: (value) => {
          setValue(`translations.${language}.detailSectionTitle`, value, {shouldDirty: true});
        },
        tips: { ...titleTips },
        focus: () => focusChange('detailsTitle'),
      }}
      content={{
        label: i18n.t('detail_section_content', { locale: language }),

        value: detailSectionContent,
        valueChange: (value) => {
          setValue(`translations.${language}.detailSectionContent`, value, {shouldDirty: true});
        },
        tips: { ...contentTips },
        focus: () => focusChange('detailsContent'),
      }}
    />
  );
}

const mapPropsToState = (state) => ({

})

export default connect(mapPropsToState)(DetailSection);
