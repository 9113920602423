import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { createAction, useCompare } from '../../../utils';
import {
  BannerErrorFields,
} from '../../../models/HomeBannersModel';
import { useFormContext } from 'react-hook-form';
import CreateBannerContent from '../../../components/banners/CreateBannerContent';
import {
  BANNER_CONTENT_TYPE,
  LanguageConfig
} from '../../../config/CustomEnums';


function CreateBannerStepOne({
  banner,
  languages,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { getValues, trigger, setError, clearErrors, setFocus } = useFormContext();

  const getLanguageTab = (language) => {
    return [
      <CreateBannerContent
        language={language}
      />
    ];
  };

  useEffect(() => {
    dispatch({
      type: 'language/getList',
      payload: { isSelectorLoad: true }
    });
  }, [dispatch]);

  const languageTabConfig = {
    containers: languages?.map((item) => ({
      container: getLanguageTab(item.code),
      key: item.code,
      title: item.sourceName,
    })),
  };

  const save = async (active) => {
    clearErrors();
    let isValid = true;

    console.log('@@53: ', getValues());
    const [
      contentType,
      campaign,
      product,
      translations,
      order,
    ] = getValues([
      'contentType',
      'campaign',
      'product',
      'translations',
      'order'
    ]);
    console.log('@83', isValid, getValues());
    if (contentType === BANNER_CONTENT_TYPE.CAMPAIGN) {
      if (campaign?.pk == null) {
        isValid = false;
        setError(
          `campaign`,
          {
            type: 'require',
            message: BannerErrorFields.campaign.message,
          },
          {
            shouldFocus: true
          },
        );
      }
    } else {
      if (product?.pk == null) {
        isValid = false;
        setError(
          `product`,
          {
            type: 'require',
            message: BannerErrorFields.product.message,
          },
          {
            shouldFocus: true
          },
        );
      }
    }
    let coverPhoto = translations?.[LanguageConfig.english]?.coverPhotos?.[0]?.value;
    if (!coverPhoto) {
      isValid = false;
      setError(
        `translations.${LanguageConfig.english}.coverPhotos.0`,
        {
          type: 'require',
          message: BannerErrorFields.bannerImage.message,
        },
        {
          shouldFocus: true
        },
      );
    }
    let newTranslations = [];
    for (const language of languages) {
      if (language.code === LanguageConfig.english) {
        continue;
      } else {
        const data = {
          id: banner.translations?.[language.code]?.pk,
          coverPhoto: translations?.[language.code]?.coverPhotos?.[0]?.value,
          language: language.code,
        };
        newTranslations.push(data);
      }
    }
    if (order == null) {
      isValid = false;
      setError(
        `order`,
        {
          type: 'require',
          message: BannerErrorFields.order.message,
        },
        {
          shouldFocus: true
        },
      );
    }
    let contentReferenceId =
      contentType === BANNER_CONTENT_TYPE.CAMPAIGN
        ? campaign?.pk
        : product?.pk;
    let uploadData = {
      ...getValues(),
      coverPhoto: coverPhoto,
      contentReferenceId: contentReferenceId,
      active: !!active,
      translations: newTranslations,
    };
    console.log('@@133: ', isValid, params.id, uploadData);
    if (isValid) {
      dispatch({
        type: 'bannerList/createOrUpdateBanner',
        payload: {
          checkStep: true,
          isCreate: params.id ? false : true,
          data: uploadData,
        }
      });
      return;
    }
  };

  return (
    <>
      <ContentSections languageTabContent={languageTabConfig} hidePreview />
      <SaveAndBackButtons
        saveTempText={'Save only'}
        saveText={`Save and ${banner.active ? 'UnPublish' : 'Publish'}`}
        saveTempAction={async () => save(banner.active)}
        saveAction={async () => save(!banner.active)}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  banner: state.bannerList.banner,
  languages: state.language.allList,
})

export default connect(mapPropsToState)(CreateBannerStepOne);
