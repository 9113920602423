import { EditCustomerHandleError } from '../../../components/customer/EditCustomerHandleError';


function firstStepValidate({ getValues, setError }) {
  const [
    firstName,
    lastName,
    pointTransaction,
    transactionPoint,
    signUpMethod,
    mobilePhoneNumberCountryCode,
    mobilePhoneNumberSubscriberNumber,
    emailAddress,
  ] = getValues([
    'firstName',
    'lastName',
    'pointTransaction',
    'transactionPoint',
    'signUpMethod',
    'mobilePhoneNumberCountryCode',
    'mobilePhoneNumberSubscriberNumber',
    'emailAddress',
  ]);
  const errorList = [];
  if (firstName == null || firstName === '') {
    errorList.push({
      name: EditCustomerHandleError.firstName.name,
      message: EditCustomerHandleError.firstName.message,
    });
  }
  if (lastName == null || lastName === '') {
    errorList.push({
      name: EditCustomerHandleError.lastName.name,
      message: EditCustomerHandleError.lastName.message,
    });
  }
  const transactionPointInt = parseInt(transactionPoint);
  if (
    (transactionPoint != null && transactionPoint !== '')
    || (pointTransaction != null && pointTransaction !== '')
  ) {
    if (isNaN(transactionPointInt) || transactionPointInt <= 0) {
      errorList.push({
        name: EditCustomerHandleError.transactionPoint.name,
        message: EditCustomerHandleError.transactionPoint.message,
      });
    }
    if (pointTransaction == null || pointTransaction.value == null) {
      errorList.push({
        name: EditCustomerHandleError.pointTransaction.name,
        message: EditCustomerHandleError.pointTransaction.message,
      });
    }
  }
  if (signUpMethod !== 'MOBILE_PHONE_NUMBER') {
    if (!mobilePhoneNumberCountryCode && !mobilePhoneNumberSubscriberNumber) {
      // account phone number can be empty
    } else {
      if (!mobilePhoneNumberCountryCode) {
        errorList.push({
          name: EditCustomerHandleError.mobilePhoneNumberCountryCode.name,
          message: EditCustomerHandleError.mobilePhoneNumberCountryCode.message,
        });
      }
      if (!mobilePhoneNumberSubscriberNumber) {
        errorList.push({
          name: EditCustomerHandleError.mobilePhoneNumberSubscriberNumber.name,
          message: EditCustomerHandleError.mobilePhoneNumberSubscriberNumber.message,
        });
      } else {
        const length = mobilePhoneNumberSubscriberNumber.length;
        if (mobilePhoneNumberCountryCode === '852') {
          if (length !== 8) {
            errorList.push({
              name: EditCustomerHandleError.mobilePhoneNumberSubscriberNumberInvalid.name,
              message: EditCustomerHandleError.mobilePhoneNumberSubscriberNumberInvalid.message,
            });
          }
        } else if (mobilePhoneNumberCountryCode === '86') {
          if (length !== 11) {
            errorList.push({
              name: EditCustomerHandleError.mobilePhoneNumberSubscriberNumberInvalid.name,
              message: EditCustomerHandleError.mobilePhoneNumberSubscriberNumberInvalid.message,
            });
          }
        }
      }
    }
  }
  errorList.forEach((error) => {
    setError(
      error.name,
      {
        type: 'require',
        message: error.message,
      },
      { shouldFocus: true, },
    );
  });
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors, step }) {
  console.log('@71 customerValidate:', getValues());
  clearErrors();
  switch (step) {
    default:
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
  }
}
