import { authAPIHelper } from '../services/AuthApiHelper';
import {
  addAuthorization,
  removeAuthorization,
  updateRefreshToken,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
} from '../services/BaseRefreshHelper';
import Cookies from 'cookies-js';
import { SESSION_KEYS } from '../config/CustomEnums';
import { removeFromSessionStorage } from '../utils/index';

const userPermissionsKey = 'userPermissions';
const allPermissionsKey = 'allPermissions';

export const getUserPermissions = () => {
  const permissions = getPermissionsFromStorage(userPermissionsKey) || [];
  return permissions;
};

const getPermissionsFromStorage = (key) => {
  const storagePermissions = localStorage.getItem(key) || '[]';
  let permissions = [];
  try {
    permissions = JSON.parse(fromBase64(storagePermissions));
  } catch (err) {
    localStorage.removeItem(key);
  }
  return permissions;
};

function toBase64(str) {
  return window.btoa(encodeURIComponent(str));
}

function fromBase64(str) {
  return decodeURIComponent(window.atob(str));
}


const removeAPIHelperToken = () => {
  removeAuthorization();
};

const setUpAPIHelperToken = (token) => {
  addAuthorization(token);
};

const initUser = {
  isLogin: false,
  firstLogin: false,
  loginFailed: false,
  isSuperuser: false,
  allPermissions: [],
  userPermissions: [],
};

export default {
  namespace: 'users',
  state: initUser,

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    resetUser(state, { payload }) {
      return initUser;
    },
  },
  effects: {
    *startUp({ payload }, { call, put, select }) {
      const accessToken = Cookies.get(ACCESS_TOKEN);
      const refreshToken = Cookies.get(REFRESH_TOKEN);
      console.log('startUp refreshKey:', refreshToken);
      if (refreshToken) {
        yield put({ type: 'updateState', payload: { isLogin: true } });
      } else {
        yield put({ type: 'logout' });
      }
      setUpAPIHelperToken(accessToken);

      const userPermissions = getUserPermissions();
      const allPermissions = getPermissionsFromStorage(allPermissionsKey);
      yield put({
        type: 'updateState',
        payload: {
          userPermissions,
          allPermissions,
        },
      });
    },
    *login({ payload }, { call, put }) {
      const { username, password } = payload;
      const response = yield call(authAPIHelper.createAccessToken, {
        username,
        password,
      });
      console.log('login:', response);
      if (response.status === 200) {
        const tokenAuthData = response.data.data.tokenAuth;
        if (tokenAuthData) {
          setUpAPIHelperToken(tokenAuthData.token);
          updateRefreshToken(tokenAuthData.refreshToken);
          const userPermissions =
            tokenAuthData.administrator.allPermissions?.map(
              (val) => val.split('.')[1],
            );
          const allPermissions = tokenAuthData.administrator.allPermissions;
          try {
            const userPermissionString = JSON.stringify(userPermissions);
            console.log('permissions:', userPermissionString.length);
            localStorage.setItem(
              userPermissionsKey,
              toBase64(userPermissionString),
            );
            const allPermissionsString = JSON.stringify(allPermissions);
            localStorage.setItem(
              allPermissionsKey,
              toBase64(allPermissionsString),
            );
          } catch (err) {
            console.log('setLocalError');
          }
          yield put({
            type: 'updateState',
            payload: {
              payload: {
                username: tokenAuthData.payload.username,
                exp: tokenAuthData.payload.exp,
              },
              isLogin: true,
              loginFailed: false,
              isSuperuser: tokenAuthData.administrator.isSuperuser,
              allPermissions,
              userPermissions,
              username: tokenAuthData.administrator.username,
              firstLogin: true,
            },
          });
        } else {
          yield put({
            type: 'updateState',
            payload: {
              loginFailed: true,
              isLogin: false,
            },
          });
        }
      } else {
        yield put({
          type: 'updateState',
          payload: {
            loginFailed: true,
            isLogin: false,
          },
        });
      }
    },

    logout: [
      function* ({ payload }, { call, put, select }) {
        const cookieToken = Cookies.get(REFRESH_TOKEN);
        const refreshToken = yield select(
          (state) => state.users.refreshToken,
        ) || cookieToken;
        yield call(authAPIHelper.logout, refreshToken);
        Object.keys(SESSION_KEYS).forEach((key) =>
          removeFromSessionStorage(SESSION_KEYS[key]),
        );
        removeAPIHelperToken();
        localStorage.removeItem(userPermissionsKey);
        localStorage.removeItem(allPermissionsKey);
        yield put({
          type: 'resetUser',
        });
      },
      { type: 'takeLatest' },
    ],
  },
};
