import React, { useState, useEffect } from 'react';
import '../../components/base/CustomListComponent.scss';
import './BaseListContainer.scss';
// import './EarningruleListWithType.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  createAction,
  removeElementFromArray,
  checkHasPermission,
} from '../../utils';
import { ActionsDropdownForItem } from '../../components/base/ActionsDropdown';
import { CustomStatusTagFromText } from '../../components/base/CustomStatusTag';
import CustomPagination from '../../components/base/CustomPagination';
import { APIStatus, StatusTag, MessageTag } from '../../config/CustomEnums';
import Loading from '../../components/base/Loading';
import NoDataPage from '../../components/base/NoDataPage';
import DeletePrompt from '../../components/base/DeletePrompt';
import CustomEditableRank from '../../components/base/CustomEditableRank';
import CustomListCheckbox from '../../components/base/CustomListCheckbox';
import CustomLinkableName from '../../components/base/CustomLinkableName';
import { SortableTableBody } from '../../components/base/SortableTable';
import DisplayOrderTableData from '../../components/base/DisplayOrderTableData';
import { PermissionCodes } from '../../config/PermissionCodes';
import PreviewImage from '../../components/base/prompt/PreviewImage';
import QRCodeCollapse from '../merchants/stores/QRCodeCollapse';
// import { URLFilterKeys } from '../CampaignUrlConfig';

function checkHasField(fields, name) {
  for (let i = 0; i < fields?.length; i++) {
    if (fields[i].displayName === name) {
      return true;
    }
  }
  return false;
}

function getCommonFieldsCount(fields) {
  let count = 0;

  for (let i = 0; i < fields.length; i++) {
    if (
      ['ID', 'Name', 'Name (preferred name)', 'Status'].indexOf(
        fields[i].displayName,
      ) < 0
    ) {
      count = count + 1;
    }
  }

  return count;
}

function BaseListContainer(props) {
  const [checkedList, setCheckedList] = useState([]);
  const [top, setTop] = useState(0);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const dataList = props.dataList || [];

  const {
    totalPage,
    model,
    fields = [],
    deleteInfo,
    customActions,
    extraRankStyle,
    hasOtherActions,
    actions = ['Edit', 'Detail', 'Delete'],
    sortable = false,
    onSortEnd,
    permissionGroup,
    onOrderChangedByEdit,
    sortDisabled,
    rankDisabled = false,
  } = props;
  const hideActions = props.hideActions || false;
  const useCustomCommonActions = props.useCustomCommonActions;
  const customCommonActions = props.customCommonActions || (() => {});
  const customClassName = props.customClassName || '';
  const queryString = require('query-string');
  const { status } = useSelector((state) => {
    // console.log('@@48: ', state.earningRuleList.earningRuleTypeList);
    return {
      status: state.loading.status,
    };
  });

  const [showPrompt, setShowPrompt] = useState(false);
  const [operatedItem, setOperatedItem] = useState();
  const searchQuery = queryString.parse(location.search);
  const currentPage = searchQuery['page']
    ? parseInt(searchQuery['page']) - 1
    : 0;
  const [enlargeImage, setEnlargeImage] = useState();

  const { user } = useSelector((state) => ({
    user: state.users,
  }));

  useEffect(() => {
    setCheckedList([]);
    dispatch({
      type: `${model}/updateState`,
      payload: { checkedList: [] },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const AllActions = [
    {
      name: 'Edit',
      action: (item) => {
        dispatch({ type: `${model}/clearData` });
        return history.push(`${location.pathname}/${item.pk}/edit/`);
      },
      requires: `change_${permissionGroup}`,
    },
    {
      name: 'Detail',
      action: (item) => {
        dispatch({ type: `${model}/clearData` });
        return history.push(`${location.pathname}/${item.pk}/`);
      },
      requires: `view_${permissionGroup}`,
    },
    {
      name: 'Delete',
      action: (item) => {
        setOperatedItem(item);
        setShowPrompt(true);
      },
      requires: `delete_${permissionGroup}`,
    },
    {
      name: 'Duplicate',
      action: (item) => {
        dispatch(
          createAction(`${model}/duplicate`)({
            data: item,
            afterAction: () => history.push(location),
          }),
        );
      },
      requires: `add_${permissionGroup}`,
    },
  ];

  const getNormalActions = (item) => {
    let filteredActions = AllActions.filter((val) =>
      actions.includes(val.name),
    );
    if (item.status) {
      switch (item.status) {
        case MessageTag.sent:
          filteredActions = filteredActions.filter(
            (val) => val.name !== 'Edit',
          );
          break;

        default:
          break;
      }
    }
    return filteredActions;
  };

  const otherActions = (data) => {
    if (!hasOtherActions) {
      return null;
    }

    let actions = [];
    if (customActions.deactive) {
      actions.push({
        name: `${
          data.status === StatusTag.active ||
          data.status === StatusTag.activeRequired
            ? 'Deactive'
            : 'Active'
        }`,
        action: (item) => {
          console.log('create action to deactive item: ', item);
          customActions.deactive(item);
        },
        requires: `change_${permissionGroup}`,
      });
    }

    if (customActions.moveToFirst) {
      actions.push({
        name: 'Move to first',
        action: (item) => {
          customActions.moveToFirst(item);
        },
        requires: `change_${permissionGroup}`,
      });
    }

    if (customActions.moveToLast) {
      actions.push({
        name: 'Move to last',
        action: (item) => {
          customActions.moveToLast(item);
        },
        requires: `change_${permissionGroup}`,
      });
    }

    if (customActions.publish) {
      actions.push({
        name: data.isPublished ? 'UnPublish' : 'Publish',
        action: (item) => {
          customActions.publish(item);
        },
        requires: data.isPublished
          ? PermissionCodes.unPublishCampaign
          : PermissionCodes.publishCampaign,
      });
    }

    return actions;
  };

  const stringSort = queryString.parse(location.search)['sort'] || '';
  let sorts = [];
  if (typeof stringSort === 'string' && stringSort) {
    sorts = stringSort.split(',');
  }

  const orientationForSort = (columnName) => {
    const sortKey = sorts.find((sort) => sort.includes(columnName));
    if (!sortKey || !sortKey?.includes('-')) {
      return false;
    }
    return true;
  };

  function iterate(relate, newSorts) {
    const otherSearchIndex = newSorts.findIndex((item) => relate === item);
    newSorts.splice(otherSearchIndex, 1);
  }

  const reDefineSort = (columnName, relatedOrderField) => {
    let newSorts = sorts?.filter((item) => item.length > 0) || [];
    const sortIndex = newSorts.findIndex((sort) => sort.includes(columnName));
    const sort = newSorts[sortIndex];
    if (sortIndex > -1) {
      newSorts.splice(sortIndex, 1);
    }
    if (relatedOrderField && relatedOrderField.length > 0) {
      relatedOrderField.forEach((item) => iterate(item, newSorts));
      newSorts.unshift(...relatedOrderField);
    }
    if (sort?.includes('-')) {
      newSorts.unshift(columnName);
    } else {
      newSorts.unshift(`-${columnName}`);
    }
    return newSorts.toString();
  };

  const checkAll = () => {
    let list = dataList;
    if (dataList.length === checkedList.length) {
      list = [];
    }
    dispatch({
      type: `${model}/updateState`,
      payload: { checkedList: list },
    });
    setCheckedList(list);
  };

  const itemCheck = (item) => {
    const list = [];
    let processed = false;
    checkedList.forEach((checkedItem) => {
      if (checkedItem.pk !== item.pk) {
        list.push(checkedItem);
      } else {
        processed = true;
      }
    });
    if (!processed) {
      list.push(item);
    }
    setCheckedList(list);
    dispatch({
      type: `${model}/updateState`,
      payload: { checkedList: list },
    });
  };

  const fieldsHasStatus = checkHasField(fields, 'Status');
  const fieldClassName = (fieldName) => {
    let className = '';
    switch (fieldName) {
      case 'ID':
        className = 'rank-area-extra-width';
        break;
      case 'Name':
      case 'Name (preferred name)':
        // case 'Membership ID':
        className = fieldsHasStatus
          ? 'common-name-area-with-status'
          : 'common-name-area-without-status';
        break;
      case 'Status':
        className = 'common-status-area';
        break;
      case 'Display order':
        className = 'common-status-order';
        break;
      default:
        className = fieldsHasStatus
          ? 'common-name-area-with-status-count-'
          : 'common-name-area-without-status-count-';
        const commonFieldsCount = getCommonFieldsCount(fields);
        className = `${className}${commonFieldsCount}`;
        break;
    }

    return className;
  };
  const getTableTitles = () => {
    return (
      <tr>
        <th key={0}>
          <CustomListCheckbox
            checked={
              checkedList.length && checkedList.length === dataList.length
            }
            onChange={() => checkAll()}
          />
        </th>
        {fields.map((item, index) => {
          if (item.orderField) {
            return (
              <th key={index + 1} className={fieldClassName(item.displayName)}>
                <CustomEditableRank
                  rank={orientationForSort(item.orderField)}
                  show={
                    searchQuery['sort']?.indexOf(item.orderField) >= 0 ||
                    (item.orderField === 'displayPriority' &&
                      Object.keys(searchQuery).length <= 0)
                  }
                  title={item.displayName}
                  extraAreaStyle={'base-rank-container'}
                  extraRankStyle={extraRankStyle}
                  onClick={(rank) => {
                    history.push({
                      pathname: location.pathname,
                      hash: location.hash,
                      search: queryString.stringify({
                        ...queryString.parse(location.search),
                        ...{
                          page: 1,
                          rank: rank,
                          sort: reDefineSort(
                            item.orderField,
                            item.relatedOrderField,
                          ),
                        },
                      }),
                    });
                  }}
                />
              </th>
            );
          }

          return (
            <th key={index + 1} className={fieldClassName(item.displayName)}>
              <div
                className={`all-list-scrollable-area-table-item-common all-list-scrollable-area-title `}
              >
                {item.displayName}
              </div>
            </th>
          );
        })}
        {hideActions ? null : (
          <th key={fields.length + 2} className="common-action-area">
            <div
              className={`all-list-scrollable-area-table-item-common all-list-scrollable-area-title all-list-scrollable-area-action-title`}
            >
              Action
            </div>
          </th>
        )}
      </tr>
    );
  };

  const renderRowData = (item) => {
    return (
      <tr>
        <td className="all-list-scrollable-area-checkbox-container">
          <CustomListCheckbox
            checked={
              checkedList.find((checkedItem) => checkedItem.pk === item.pk) !==
              undefined
            }
            onChange={() => itemCheck(item)}
          />
        </td>
        {fields.map((field, index) => {
          switch (field.fieldName) {
            case 'pk':
              if (
                field.linked &&
                checkHasPermission(user, `view_${permissionGroup}`)
              ) {
                return (
                  <td className="all-list-scrollable-area-id-container">
                    <div className="all-list-scrollable-area-table-item-common all-list-scrollable-area-id-container">
                      <Link
                        to={`${location.pathname}/${item.pk}/`}
                        className="all-list-scrollable-area-text-common all-list-scrollable-area-clickable-field"
                      >
                        {item.pk || '-'}
                      </Link>
                    </div>
                  </td>
                );
              }
              return (
                <td
                  index={`${index}-${field.fieldName}`}
                  className="all-list-scrollable-area-id-container"
                >
                  <div className="all-list-scrollable-area-table-item-common all-list-scrollable-area-id-container">
                    <label className="all-list-scrollable-area-text-common all-list-scrollable-area-unclickable-field">
                      {item[field.fieldName] || '-'}
                    </label>
                  </div>
                </td>
              );
            case 'name':
              if (
                field.linked === false ||
                !checkHasPermission(
                  user,
                  `${
                    actions.includes('Detail') ? 'view' : 'change'
                  }_${permissionGroup}`,
                )
              ) {
                return (
                  <td className="all-list-scrollable-area-name">
                    <div className="all-list-scrollable-area-name all-list-scrollable-area-table-item-common">
                      <label className="all-list-scrollable-area-text-common list-new-line">
                        {item[field.fieldName] || '-'}
                      </label>
                    </div>
                  </td>
                );
              }
              return (
                <td className="all-list-scrollable-area-name">
                  <CustomLinkableName
                    to={`${location.pathname}/${item.pk}/${
                      actions.includes('Detail') ? '' : 'edit/'
                    }`} //need change link
                    text={item[field.fieldName]}
                  />
                </td>
              );
            case 'status':
            case 'displayStatus':
              return (
                <td className="common-status-area all-list-scrollable-area-table-item-common">
                  {CustomStatusTagFromText(item[field.fieldName])}
                </td>
              );
            case 'coverPhoto':
            case 'icon':
            case 'displayImage':
              return (
                <td className="common-status-area">
                  <div style={{ padding: '20px 0px' }}>
                    <img
                      alt="cover"
                      className="base-display-item-photo"
                      src={item[field.fieldName]}
                      style={{
                        width: 100,
                        height: 100,
                        border: '1px solid #DADADA',
                      }}
                      onClick={
                        field.enlarge
                          ? () => {
                              setEnlargeImage(item[field.fieldName]);
                            }
                          : () => {}
                      }
                    />
                  </div>
                </td>
              );
            case 'codeDisplayImage':
              return (
                <td className="common-status-area">
                  <div style={{ padding: '20px 0px' }}>
                    <QRCodeCollapse {...item} />
                  </div>
                </td>
              );
            case 'order':
              return (
                <DisplayOrderTableData
                  item={item}
                  onDone={onOrderChangedByEdit}
                  editable={
                    checkHasPermission(user, `change_${permissionGroup}`) &&
                    (searchQuery['sort']?.indexOf('displayPriority') === 0 ||
                      (field.orderField === 'displayPriority' &&
                        Object.keys(searchQuery).length <= 0))
                  }
                />
              );
            default:
              return (
                <td>
                  <div
                    className={`all-list-scrollable-area-table-item-common`}
                    style={
                      field.lines
                        ? {
                            padding: '0px 20px',
                            height: '110px',
                            display: 'contents',
                          }
                        : {}
                    }
                  >
                    <label
                      className={`all-list-scrollable-area-text-common list-new-line ${
                        field.lines ? 'label-lines-' + field.lines : ''
                      }`}
                    >
                      {item[field.fieldName] || '-'}
                    </label>
                  </div>
                </td>
              );
          }
        })}
        {hideActions ? null : (
          <td className="all-list-scrollable-area-actions all-list-scrollable-area-table-item-common">
            {useCustomCommonActions ? (
              customCommonActions(item)
            ) : (
              <ActionsDropdownForItem
                object={item}
                actions={getNormalActions(item)}
                otherActions={otherActions(item)}
              />
            )}
          </td>
        )}
      </tr>
    );
  };

  const renderTableBody = () => {
    if (status === APIStatus.calling) {
      return (
        <tbody>
          <tr>
            <td colSpan={hideActions ? fields.length + 1 : fields.length + 2}>
              <Loading />
            </td>
          </tr>
        </tbody>
      );
    }
    if (dataList.length <= 0) {
      return (
        <tbody>
          <tr>
            <td colSpan={hideActions ? fields.length + 1 : fields.length + 2}>
              <NoDataPage />
            </td>
          </tr>
        </tbody>
      );
    }
    if (
      sortable &&
      checkHasPermission(user, `change_${permissionGroup}`) &&
      (searchQuery['sort']?.indexOf('displayPriority') === 0 ||
        (fields.filter((field) => field.orderField === 'displayPriority')
          .length > 0 &&
          Object.keys(searchQuery).length <= 0))
    ) {
      return (
        <SortableTableBody
          dataList={dataList}
          disabled={sortDisabled}
          onSortEnd={onSortEnd}
          renderRowData={(item) => renderRowData(item)}
        />
      );
    }
    return <tbody>{dataList.map((item, index) => renderRowData(item))}</tbody>;
  };

  return (
    <div
      className="campaign-list-section common-table-overflow "
      key={`${model}-list`}
    >
      <PerfectScrollbar>
        <table className={`all-list-table ${customClassName}`}>
          <thead>{getTableTitles()}</thead>
          {renderTableBody()}
        </table>
      </PerfectScrollbar>

      {dataList.length > 0 ? (
        <div className="all-list-scrollable-area-pagination">
          <CustomPagination
            initPage={currentPage}
            pageSize={totalPage}
            pageChange={(page) => {
              history.push({
                pathname: location.pathname,
                hash: location.hash,
                search: queryString.stringify({
                  ...queryString.parse(location.search),
                  ...{
                    page: page + 1,
                  },
                }),
              });
            }}
          />
        </div>
      ) : null}

      <DeletePrompt
        title={deleteInfo.title}
        relatedName={deleteInfo.relatedName}
        relatedSections={deleteInfo.relatedSections}
        data={operatedItem}
        show={showPrompt}
        onClose={() => {
          setShowPrompt(false);
          setOperatedItem(null);
        }}
        onConfirm={() => {
          setShowPrompt(false);
          dispatch({
            type: `${model}/updateState`,
            payload: { checkedList: [operatedItem] },
          });
          dispatch(
            createAction(`${model}/delete`)({
              afterAction: () => {
                history.push({
                  pathname: location.pathname,
                  hash: location.hash,
                  search: queryString.stringify({
                    ...queryString.parse(location.search),
                    page: 1,
                  }),
                });
              },
            }),
          );
        }}
      />
      <PreviewImage
        show={enlargeImage ? true : false}
        onClose={() => {
          setEnlargeImage(null);
        }}
        imageUrl={enlargeImage}
      />
    </div>
  );
}

export default BaseListContainer;
