import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getDashboardData = (startDate, endDate) => {
  console.log(startDate, endDate);
  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}") {
      totalNumberOfCustomers {
        value
        change
      }
      totalNumberOfNewCustomers {
        value
        change
      }
      totalNumberOfNewCustomersChart {
        periods
        points
      }
      totalNumberOfActiveCustomers {
        value
        change
      }
      totalNumberOfCouponActivities {
        value
        change
      }
      totalNumberOfCouponsRedeemedByPoints {
        value
        change
      }
      totalNumberOfCouponsAcquiredByCampaigns {
        value
        change
      }
      totalNumberOfCouponsUsed {
        value
        change
      }
      totalNumberOfPublishedCampaigns {
        value
        change
      }
      totalNumberOfCampaignConversions {
        value
        change
      }
      totalNumberOfCampaignViews {
        value
        change
      }
      campaignShareRate {
        value
        change
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCampaignsOverviewData = (startDate, endDate) => {
  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}") {
      totalNumberOfPublishedCampaigns {
        value
        change
      }
      totalNumberOfCampaignConversions {
        value
        change
      }
      totalNumberOfCampaignViews {
        value
        change
      }
      couponCampaignConversionRate {
        value
        change
      }
      earningCampaignConversionRate {
        value
        change
      }
      campaignShareRate {
        value
        change
      }
      totalPointsEarned {
        value
        change
      }
      totalPointsUsed {
        value
        change
      }
      totalNumberOfCouponActivities {
        value
        change
      }
      totalNumberOfCouponsRedeemedByPoints {
        value
        change
      }
      totalNumberOfCouponsUsed {
        value
        change
      }
      totalNumberOfCouponsAcquiredByCampaigns {
        value
        change
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCustomersOverviewData = (startDate, endDate) => {
  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}") {
      totalNumberOfCustomers {
        value
        change
      }
      totalNumberOfNewCustomers {
        value
        change
      }
      totalNumberOfNewCustomersChart {
        periods
        points
      }
      totalNumberOfActiveCustomers {
        value
        change
      }
      totalNumberOfGuestCustomers {
        value
        change
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCouponsOverviewData = (startDate, endDate) => {
  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}") {
      totalNumberOfCouponActivities {
        value
        change
      }
      totalNumberOfCouponsRedeemedByPoints {
        value
        change
      }
      totalNumberOfCouponsUsed {
        value
        change
      }
      totalNumberOfCouponsAcquiredByCampaigns {
        value
        change
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};
