import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getCouponTransactions = (afterCursor = null, search = {}) => {
  console.log('@@4: ', search);
  let searchString = `, orderBy: "${search.rank ? '-pk' : 'pk'}"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search?.sort}"`;
  }
  if (search.searchKey) {
    searchString += `, customerName: "${search.searchKey}"`;
  }
  if (search.record_type) {
    searchString += `, typeIn: "${search.record_type}"`;
  }

  if (search.expired_date) {
    switch (search.expired_date) {
      case 'absolute':
        if (search.absolute) {
          const expiredDateRange = search.absolute.split(',');
          searchString += `, absoluteGte: "${expiredDateRange[0]}", absoluteLte: "${expiredDateRange[1]}"`;
        }
        break;
      case 'relativeDay':
        if (search.relativeDay) {
          searchString += `, relativeDay: ${search.relativeDay}`;
        }
        break;
      default:
        searchString += `, allTime: true`;
        break;
    }
  }

  const query = `{
        couponTransactions(
          first: 20,
          after: "${afterCursor}"${searchString}
        ) {
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              pk
              type
              pointsSpent
              creationDate
              customer {
                pk
                ssoUid
                firstName
                lastName
                nickname
                membershipId
              }
              campaign {
                pk
                name
                earningCampaignTypeEarningRule {
                    pk
                    name
                  }
              }
              coupon {
                id
                pk
                effectiveDate
                expiryDate
                serialNumber
                template {
                    name
                    validPeriodType
                    absoluteEffectiveDate
                    absoluteExpiryDate
                    numberOfDaysToExpireAfterAcquisition
                  }
              }
              administrator {
                pk
                username
              }
            }
          }
        }
      }`;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCouponTransaction = (couponTransactionID) => {
  const query = `
  {
    couponTransaction(id: "${couponTransactionID}") {
      id
      pk
      type
      pointsSpent
      creationDate
      usedAt
      customer {
        pk
        ssoUid
        firstName
        lastName
        nickname
        membershipId
      }
      campaign {
        pk
        name
        earningCampaignTypeEarningRule {
          pk
          name
        }
      }
      coupon {
        id
        pk
        effectiveDate
        expiryDate
        template {
          name
          validPeriodType
          absoluteEffectiveDate
          absoluteExpiryDate
          numberOfDaysToExpireAfterAcquisition
        }
      }
      administrator {
        pk
        username
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getPointTransactionRecords = (afterCursor = '', search = {}) => {
  // let searchString = `, orderBy: "${search.rank ? '-pk' : 'pk'}"`;
  let searchString = `, orderBy: "-pk"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search?.sort}"`;
  }
  if (search.type === 'REMOVE') {
    searchString += `, pointTypeIn: "TYPE_POINT_REMOVE"`;
  } else if (search.type === 'ADD') {
    searchString += `, pointTypeIn: "TYPE_POINT_ADD"`;
  }
  if (search.searchKey) {
    searchString += `, customerName: "${search.searchKey}"`;
  }
  if (search.record_type) {
    searchString += `, transactionTypeIn: "${search.record_type}"`;
  }
  if (search.value) {
    const valueRange = search.value.split(',');
    searchString += `, valueGte: ${valueRange[0]}, valueLte: ${valueRange[1]}`;
  }
  if (search.create_date) {
    searchString += `, creationDateEqual: "${search.create_date}"`;
  }

  const query = `
  {
    pointTransactions(
      first: 20,
      after: "${afterCursor}",
      hideBeVoidRecords: false${searchString}
    ) {
      totalCount
      maxPointValue
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          eventIdentifier
          customer {
            pk
            ssoUid
            firstName
            lastName
            nickname
            membershipId
          }
          pointType
          value
          transactionType
          remarks
          creationDate
          usedType
          usedRemark
          usedDate
          adminVoidTransaction {
            pk
            eventIdentifier
            transactionType
          }
          expireAtDate
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOnePointTransactionRecord = (pointRecordID) => {
  const query = `
  {
    pointTransaction(id: "${pointRecordID}") {
      id
      pk
      eventIdentifier
      customer {
        pk
        ssoUid
        firstName
        lastName
        nickname
      }
      pointType
      value
      transactionType
      remarks
      creationDate
      usedType
      usedRemark
      usedDate
      adminVoidTransaction {
        pk
        eventIdentifier
        transactionType
      }
      expireAtDate
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createPointAddTypeRecord = (inputBody) => {
  const query = `
  mutation AddPoints($input: AddPointsInput!) {
    addPoints(input: $input) {
      success
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createPointRemoveTypeRecord = (inputBody) => {
  const query = `
  mutation RemovePoints($input: RemovePointsInput!) {
    removePoints(input: $input) {
      success
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getTransactions = (afterCursor = '', search) => {
  console.log('@@261: ', search);
  let searchString = `, orderBy: "${search.rank ? '-pk' : 'pk'}"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search?.sort}"`;
  }
  if (search.searchKey) {
    searchString += `, customerName: "${search.searchKey}"`;
  }
  if (search.transaction_type) {
    searchString += `, transactionTypeIn: "${search.transaction_type}"`;
  }
  if (search.offline_event_type) {
    searchString += `, offlineEventTypeIn: "${search.offline_event_type}"`;
  }
  if (search.online_event_type) {
    searchString += `, onlineEventTypeIn: "${search.online_event_type}"`;
  }
  if (search.transaction_date) {
    searchString += `, creationDateEqual: "${search.transaction_date}"`;
  }

  const query = `
  {
    transactions(
      first: 20,
      after: "${afterCursor}"${searchString}
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          date
          transactionType
          customer {
            pk
            ssoUid
            firstName
            lastName
            nickname
            membershipId
          }
          store {
            pk
            name
            id
            brand {
              pk
              name
            }
          }
          onlineEventType
          offlineEventType
          creationDate
          invoiceId
          totalValue
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getTransaction = (id) => {
  const query = `
  {
    transaction(id: "${id}") {
      id
      pk
      date
      transactionType
      creationDate
      customer {
        pk
        ssoUid
        firstName
        lastName
        nickname
      }
      store {
        pk
        id
        name
      }
      staffName
      onlineEventType
      offlineEventType
      shippingFee
      otherCharge
      totalValue
      remarks
      shopifyOrderId
      shopifyOrderPayload
      posInvoiceId
      invoiceId
      receiptImage
      creditCardSlipImage
      purchasedItems {
        edges {
          node {
            id
            pk
            name
            sku
            category
            brand
            quantity
            value
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const approveTransaction = (id) => {
  const query = `
  mutation ApproveTransaction($input: ApproveTransactionInput!) {
    approveTransaction(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const rejectTransaction = (id) => {
  const query = `
  mutation RejectTransaction($input: RejectTransactionInput!) {
    rejectTransaction(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const recallTransaction = (id) => {
  const query = `
  mutation RecallTransaction($input: RecallTransactionInput!) {
    recallTransaction(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteTransactions = (ids) => {
  const query = `
  mutation DeleteTransactions($input: DeleteTransactionsInput!) {
    deleteTransactions(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createTransaction = (inputBody) => {
  const query = `
  mutation CreateTransaction($input: CreateTransactionInput!) {
    createTransaction(input: $input) {
      node {
        id
        pk
        date
        transactionType
        customer {
                  id
              }
        store {
                  id
              }
        staffName
        shippingFee
        otherCharge
        totalValue
        remarks
        posInvoiceId
        invoiceId
        receiptImage
        creditCardSlipImage
        offlineEventType
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateTransaction = (inputBody) => {
  const query = `
  mutation UpdateTransaction($input: UpdateTransactionInput!) {
    updateTransaction(input: $input) {
      node {
        id
        pk
        date
        transactionType
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
